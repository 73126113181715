import { useState } from 'react';
import { Text, Toggle, Skeleton } from '@point-of-sale/components';
import { SizeChartDTOType } from '@point-of-sale/schemas';
import { getCmMeasurement, getInMeasurement } from '@point-of-sale/utils';
import * as Styles from './styles';

interface SizeChartTableProps {
  data: SizeChartDTOType | null;
  isLoading?: boolean;
  error?: string | null;
}

export const SizeChartTable = ({ data, isLoading, error }: SizeChartTableProps) => {
  const [showCm, setShowCm] = useState(true);
  const columns = data ? data.measurements.length + 1 : 4;

  const renderSkeletonRows = () => (
    <>
      <Styles.GridRow columns={columns}>
        {Array.from({ length: columns }).map((_, index) => (
          <Styles.HeaderCell key={index}>
            <Skeleton width={60} height={20} />
          </Styles.HeaderCell>
        ))}
      </Styles.GridRow>
      {Array.from({ length: 4 }).map((_, rowIdx) => (
        <Styles.GridRow key={rowIdx} columns={columns}>
          {Array.from({ length: columns }).map((_, colIdx) => (
            <Styles.DataCell key={colIdx}>
              <Skeleton width={40} height={20} />
            </Styles.DataCell>
          ))}
        </Styles.GridRow>
      ))}
    </>
  );

  if (error) {
    return (
      <Styles.ErrorContainer>
        <Text color="var(--flamingo)">{error}</Text>
      </Styles.ErrorContainer>
    );
  }

  return (
    <Styles.TableWrapper>
      <Styles.ToggleContainer>
        <Text>Body measurements in {showCm ? 'centimeters' : 'inches'}</Text>
        <Toggle
          leftLabel="In"
          rightLabel="Cm"
          checked={showCm}
          onChange={() => setShowCm(prev => !prev)}
        />
      </Styles.ToggleContainer>
      <Styles.TableContainer>
        {isLoading ? (
          renderSkeletonRows()
        ) : (
          <>
            <Styles.GridRow columns={columns}>
              <Styles.HeaderCell>
                <Text weight="semibold">Size</Text>
              </Styles.HeaderCell>
              {data?.measurements.map((measurement, index) => (
                <Styles.HeaderCell key={`${index}_${measurement}`}>
                  <Text textAlign="center" fontSize={14}>
                    {measurement}
                  </Text>
                </Styles.HeaderCell>
              ))}
            </Styles.GridRow>

            {data?.sizeData.map((sizeData, idx) => (
              <Styles.GridRow key={`${sizeData.size}_${idx}`} columns={columns}>
                <Styles.DataCell
                  style={{
                    background: 'var(--ghost-white)',
                    padding: '0 12px',
                  }}
                >
                  <Text>{sizeData.size}</Text>
                </Styles.DataCell>
                {data?.measurements.map((measurement, index) => (
                  <Styles.DataCell key={`${index}_${measurement}`}>
                    <Text textAlign="center">
                      {showCm
                        ? getCmMeasurement(sizeData.measurements[measurement])
                        : getInMeasurement(sizeData.measurements[measurement])}
                    </Text>
                  </Styles.DataCell>
                ))}
              </Styles.GridRow>
            ))}
          </>
        )}
      </Styles.TableContainer>
    </Styles.TableWrapper>
  );
};
