export const emptyFunction = () => {
  //
};

export const alphabeticallyCategorizeObjects = <T, K extends keyof T>(
  options: Array<T>,
  key: K
): Record<string, Array<T>> => {
  return options.reduce((acc, option) => {
    const firstLetter = String(option[key])[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(option);
    return acc;
  }, {} as Record<string, Array<T>>);
};
