import { Fragment, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { IconButton, Text } from '@point-of-sale/components';
import { getBreadcrumbs } from './getBreadcrumbs';
import { useAppSelector, useIsErpUserSuperAdmin } from '@point-of-sale/store';
import * as Styles from './styles';

const PosHeaderLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const cartId = useAppSelector(store => store.pointOfSale.cart.data.id);

  const breadcrumbs = useMemo(() => getBreadcrumbs(cartId), [cartId]);

  const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);

  const { isErpUserSuperAdmin } = useIsErpUserSuperAdmin();

  return (
    <>
      <Styles.Wrapper>
        <Styles.Left>
          <Text fontSize={16} weight="bold">
            {selectedSalesChannel?.name} | Point Of Sale |
          </Text>
          {isErpUserSuperAdmin && (
            <IconButton
              icon="EditIcon"
              sizeVariant="extraSmall"
              onClick={() => {
                navigate('/login');
              }}
            />
          )}
        </Styles.Left>

        <Styles.Right>
          {breadcrumbs.map((item, index) => {
            const Icon = item.icon;

            const isActive = pathname.includes(item.identifier);

            return (
              <Fragment key={item.identifier}>
                <Styles.BreadCrumb onClick={item.onClick} $isActive={isActive}>
                  <Icon fill={isActive ? 'var(--cod-gray)' : 'var(--dove-gray)'} />
                  {item.title}
                </Styles.BreadCrumb>
                {index !== breadcrumbs.length - 1 && (
                  <Styles.BreadCrumbSeparator>/</Styles.BreadCrumbSeparator>
                )}
              </Fragment>
            );
          })}
        </Styles.Right>
      </Styles.Wrapper>
      <Outlet />
    </>
  );
};

export default PosHeaderLayout;
