import { z } from 'zod';
import { FacilityDTOSchema } from '../common';

export const ServiceabilityDTOSchema = z.object({
  id: z.string(),
  skuCode: z.string(),
  mtoTat: z.number().optional(),
  mtcTat: z.number().optional(),
  jitTat: z.number().optional(),
  destinationPincode: z.string().optional(),
  standardCourierTat: z.number().optional(),
  priorityCourierTat: z.number().optional(),
  priorityShippingAvailable: z.boolean().optional(),
  opsTat: z.number().optional(),
  express: z.boolean().optional(),
  facilityIdToFacilityTatMap: z
    .record(
      z.string(),
      z.object({
        facilityPincode: z.string(),
        facilityName: z.string(),
        facilityType: FacilityDTOSchema.shape.facilityType,
        bestTat: z.number(),
        jit: z.boolean(),
        express: z.boolean(),
        inStock: z.boolean(),
        opsTat: z.number(),
        priorityCourierTat: z.number().optional(),
        priorityShippingAvailable: z.boolean(),
        standardCourierTat: z.number(),
      })
    )
    .optional(),
});

export type ServiceabilityDTOType = z.infer<typeof ServiceabilityDTOSchema>;

type SkuCode = string;

export type INormalizedServiceabilityData = Record<
  SkuCode,
  Array<{
    from: { facilityId: string; pincode: string };
    to: string;
    info: NonNullable<ServiceabilityDTOType['facilityIdToFacilityTatMap']>[string] & {
      mtoTat: number | undefined;
      mtcTat: number | undefined;
      jitTat: number | undefined;
      innerOpsTat: number | undefined;
      outerOpsTat: number | undefined;

      innerPriorityShippingAvailable: boolean | undefined;
      outerPriorityShippingAvailable: boolean | undefined;
    };
  }>
>;
