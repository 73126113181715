import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Row = styled.div`
  /* border: 2px dashed red; */
`;
