import { Text } from '@point-of-sale/components';
import React from 'react';

interface IReturnsAndExchangePolicyProps {
  isReturnable?: boolean;
  isExchangeable?: boolean;
  fontSize?: 12 | 14;
}

const ReturnsAndExchangePolicy = ({
  isExchangeable,
  isReturnable,
  fontSize = 12,
}: IReturnsAndExchangePolicyProps) => {
  return (
    <Text fontSize={fontSize} color="var(--dove-gray)">
      Returns and Exchange Policy
      <br />
      <Text as="span" fontSize={fontSize} color="var(--royal-blue)" weight="bold">
        {isReturnable ? '7 Day Return' : 'Non-returnable'}
        &nbsp; | &nbsp;
        {isExchangeable ? '7 Day Exchange' : 'Non-exchangeable'}
      </Text>
    </Text>
  );
};

export default ReturnsAndExchangePolicy;
