import { Text } from '@point-of-sale/components';
import { formatNumberToLocale } from '@point-of-sale/utils';
import { CURRENCY_SYMBOLS, CurrencyType } from '@point-of-sale/constants';
import KeyValuePair from '../../../../../../components/desktop/OrderDetails/KeyValuePair';
import { useOrderListItem } from '../../context';
import * as Styles from './styles';

const KeyValuePairProps = {
  titleProps: { display: 'flex' },
  valueProps: {
    textAlign: 'right',
    width: 'unset',
    style: {
      flex: '1',
    },
  },
} as const;

function formatMoney(value: number, currency: string) {
  return `${currency} ${formatNumberToLocale(value)}`;
}

const PriceDetails = () => {
  const { data } = useOrderListItem();

  const costBreakup = data.costBreakup;

  const currency = CURRENCY_SYMBOLS[(costBreakup.currency as CurrencyType) ?? 'INR']?.code;

  return (
    <Styles.Wrapper>
      <Styles.Content>
        <Text fontSize={12} color="var(--dove-gray)" textTransform="uppercase">
          Price Details
        </Text>

        <KeyValuePair
          title="MRP"
          value={costBreakup?.mrp ? formatMoney(costBreakup?.mrp, currency) : ''}
          {...KeyValuePairProps}
        />
        {!!costBreakup?.bagDiscount && (
          <KeyValuePair
            title="Bag Discount"
            value={formatMoney(costBreakup?.bagDiscount, currency)}
            {...KeyValuePairProps}
          />
        )}
        {!!costBreakup?.couponDiscount && (
          <KeyValuePair
            title="Coupon Discount"
            value={formatMoney(costBreakup?.couponDiscount, currency)}
            {...KeyValuePairProps}
          />
        )}
        <KeyValuePair
          title="Amount paid"
          value={costBreakup?.amountPaid ? formatMoney(costBreakup?.amountPaid, currency) : ''}
          {...KeyValuePairProps}
        />
        <KeyValuePair
          title="Amount to be paid"
          value={
            costBreakup?.amountRemaining ? formatMoney(costBreakup?.amountRemaining, currency) : ''
          }
          {...KeyValuePairProps}
        />
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default PriceDetails;
