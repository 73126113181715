import { useEffect } from 'react';
import { Button, Input, Text } from '@point-of-sale/components';
import { ScanditBottomSheet } from '@runway/barcode-scanner';
import { useDebounce } from '@point-of-sale/hooks';
import { useAppSelector } from '@point-of-sale/store';
import useCreateSkuMovement from '../hooks/useCreateSkuMovement';
import { useProductDiscovery } from '../../../../hooks/useProductDiscovery';
import ProductListItem from '../../../../components/mobile/ProductsList/ProductListItem';
import * as Styles from './styles';

const CreateSkuMovement = () => {
  const ids = useAppSelector(store => store.pointOfSale.products.data.ids);

  const { isLoading, trackSkuMovement, error, barcode, onChangeBarcode } = useCreateSkuMovement();

  const { onChangeSearch } = useProductDiscovery({
    usage: 'EXPLORE_PRODUCTS',
  });

  const debouncedOnChangeSearch = useDebounce(barcode => onChangeSearch(barcode), 300);

  useEffect(() => {
    debouncedOnChangeSearch.implementation(barcode);
  }, [barcode]);

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Input
          label="Scan Barcode"
          variant="outline"
          value={barcode}
          isFullWidth
          sizeVariant="small"
          onChange={e => onChangeBarcode(e.target.value)}
        />
        <ScanditBottomSheet onChange={barcode => onChangeBarcode(barcode)} />
      </Styles.Row>

      {ids.length === 1 && (
        <>
          <ProductListItem id={ids[0]} shouldShowOnlyBasicDetails />
          <Button
            isFullWidth
            isLoading={isLoading}
            onClick={() => {
              trackSkuMovement();
            }}
          >
            Send For Trial
          </Button>
        </>
      )}

      {error && (
        <Text color="var(--persian-red)" fontSize={16}>
          {error}
        </Text>
      )}
    </Styles.Wrapper>
  );
};

export default CreateSkuMovement;
