import { ProductDTOType } from '@point-of-sale/schemas';

type SelectorType = 'SIZE' | 'COLOR' | string;

export function getVariantFilterOptions(
  productOptions: ProductDTOType['options'],
  selectorType: SelectorType
) {
  const processedProductOptions = productOptions
    .filter(option => option.name === selectorType)
    .map(option => option.optionsValues)
    .flat();

  return processedProductOptions;
}
