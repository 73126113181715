import { formatToReadableDate } from '@point-of-sale/utils';
import { useAppSelector, useUserById } from '@point-of-sale/store';
import KeyValuePair from '../../../../../../components/desktop/OrderDetails/KeyValuePair';
import { useOrderListItem } from '../../context';
import { DECORATIVE_CANCELLATION_REASONS } from '../../../../../../components/desktop/OrderDetails/constants';
import * as Styles from './styles';

const CustomerDetails = () => {
  const { data } = useOrderListItem();

  const facilities = useAppSelector(store => store.common.facilities.data.records);
  const { user } = useUserById(data.salesStaffId);

  const salesChannel = Object.values(facilities).find(
    facility => facility.salesChannelId === data.salesChannelId
  );

  return (
    <Styles.Wrapper>
      {data.customerName && <KeyValuePair title="Customer Name" value={data.customerName} />}
      {data.customerPhone && <KeyValuePair title="Customer Phone" value={data.customerPhone} />}

      {salesChannel?.name && <KeyValuePair title="Sales Channel" value={salesChannel.name} />}

      {data.createdOn && (
        <KeyValuePair title="Placed On" value={formatToReadableDate(data.createdOn)} />
      )}
      {data.salesStaffId && user?.name && <KeyValuePair title="Sales Staff" value={user.name} />}

      {data.status === 'CANCELLED' && data.cancellationReason && (
        <KeyValuePair
          title="Cancellation Reason"
          value={
            DECORATIVE_CANCELLATION_REASONS.find(r => r.value === data.cancellationReason)?.label ??
            data.cancellationReason
          }
        />
      )}

      {data.status === 'CANCELLED' && data.cancellationRemarks && (
        <KeyValuePair title="Cancellation Sub Reason" value={data.cancellationRemarks ?? ''} />
      )}

      {data.deliveryMode === 'HOME_DELIVERY' && data.customerDeliveryAddress && (
        <KeyValuePair
          title="Delivery Address"
          value={`${data.customerDeliveryAddress.addressLine}, ${data.customerDeliveryAddress.cityDisplayName},
              ${data.customerDeliveryAddress.stateDisplayName}, ${data.customerDeliveryAddress.pincode}`}
        />
      )}

      {data.deliveryMode === 'STORE_PICKUP' &&
        data.customerDeliveryStoreId &&
        facilities[data.customerDeliveryStoreId] && (
          <KeyValuePair
            title="Delivery Address"
            value={facilities[data.customerDeliveryStoreId]?.name}
          />
        )}
    </Styles.Wrapper>
  );
};

export default CustomerDetails;
