import { useEffect } from 'react';
import { Select, Text } from '@point-of-sale/components';
import {
  setSalesPoc,
  setSalesStaffIdOfCart,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import * as Styles from './styles';

const Parties = () => {
  const salesPoc = useAppSelector(store => store.checkout.salesPoc);

  const { ids: storeStaffIds, records: storeStaffRecords } = useAppSelector(
    store => store.common.storeStaff.data
  );
  const savedSalesStaffId = useAppSelector(store => store.pointOfSale.cart.data.salesStaffId);

  const hasPaymentsStarted = useAppSelector(
    store => store.checkout.cartTransactions.data.ids.length > 0
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (savedSalesStaffId) {
      const selectedSalesPoc = Object.values(storeStaffRecords).find(
        item => String(item.id) === String(savedSalesStaffId)
      );

      if (selectedSalesPoc) {
        dispatch(setSalesPoc(selectedSalesPoc));
      }
    }
  }, [savedSalesStaffId, storeStaffRecords]);

  const staffOptions = storeStaffIds
    .filter(id => storeStaffIds.includes(id))
    .map(id => ({ label: storeStaffRecords[id].name, value: id }));

  return (
    <Styles.Wrapper>
      <Styles.FormFieldWrapper>
        <Text fontSize={14} weight="bold" color="var(--black)">
          Sales POC
          <Text fontSize={14} color="var(--alizarin-crimson)">
            *
          </Text>
        </Text>

        <Select<(typeof staffOptions)[number]>
          options={staffOptions}
          value={staffOptions.find(option => option.value === salesPoc?.id)}
          onChange={selectedOption => {
            if (!selectedOption) {
              return;
            }

            const newSalesPoc = Object.values(storeStaffRecords).find(
              item => String(item.id) === String(selectedOption.value)
            );
            if (newSalesPoc) {
              dispatch(setSalesStaffIdOfCart(newSalesPoc));
            }
          }}
          placeholder="Select Sales POC"
          isSearchable
          isDisabled={hasPaymentsStarted}
        />
      </Styles.FormFieldWrapper>
    </Styles.Wrapper>
  );
};

export default Parties;
