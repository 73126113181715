import { motion } from 'motion/react';
import styled, { css } from 'styled-components';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectableWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); */
`;

interface ISelectableProps {
  $isSelected: boolean;
  $isDisabled: boolean;
  $size?: 'COMPACT' | 'COMFORTABLE';
}

export const Selectable = styled(motion.li)<ISelectableProps>`
  height: 50px;
  min-width: 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 0.5px solid var(--alto);
  cursor: pointer;
  white-space: nowrap;
  transition: background 200ms ease-in;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:last-child {
    border-right: 0.5px solid var(--alto);
  }

  ${props =>
    props.$size === 'COMPACT' &&
    css`
      padding: 0 10px;
    `}

  ${props =>
    props.$size === 'COMFORTABLE' &&
    css`
      padding: 0 20px;
    `}



  ${props =>
    props.$isSelected &&
    css`
      border: 1.5px solid var(--black);

      &:last-child {
        border-right: 1.5px solid var(--black);
      }
    `}

  ${props =>
    props.$isDisabled &&
    css`
      background: var(--alabaster);
      cursor: not-allowed;
    `}
`;

interface ISelectableFillProps {
  $backgroundColor: string;
}

export const SelectableFill = styled.i<ISelectableFillProps>`
  background-color: ${props => props.$backgroundColor};
  height: 25px;
  width: 25px;
  display: inline-block;
  /* box-shadow: inset 0px 0px 0px 0.5px var(--platinum); */
`;
