import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { getNormalizedServiceabilityData } from '../pointOfSale/utils';
import { RootStateType } from '../store';

const rawServiceability = (state: RootStateType) => state.serviceability.serviceability.data;

export const normalizedServiceabilitySelector = createDraftSafeSelector(
  rawServiceability,
  serviceability => {
    return getNormalizedServiceabilityData(serviceability);
  }
);
