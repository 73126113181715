import { v4 as uuidv4 } from 'uuid';

const FINGERPRINT_KEY = 'fingerprint';

export function getDeviceFingerprint(): string {
  const savedFingerprint = localStorage.getItem(FINGERPRINT_KEY);

  if (savedFingerprint) {
    return savedFingerprint;
  }

  const deviceUuid = uuidv4();

  localStorage.setItem(FINGERPRINT_KEY, deviceUuid);

  return deviceUuid;
}
