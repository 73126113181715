export const getCmMeasurement = (measurement: string | number) => {
  if (typeof measurement === 'string' && measurement.includes('-')) {
    const [min, max] = measurement.split('-');
    return `${(Math.floor(Number(min) * 10) / 10).toFixed(1)}-${(
      Math.floor(Number(max) * 10) / 10
    ).toFixed(1)}`;
  }

  return (Math.floor(Number(measurement) * 10) / 10).toFixed(1);
};

export const getInMeasurement = (measurement: string | number) => {
  if (typeof measurement === 'string' && measurement.includes('-')) {
    const [min, max] = getCmMeasurement(measurement).split('-');
    return `${convertCmToIn(min)}-${convertCmToIn(max)}`;
  }

  return convertCmToIn(String(measurement));
};

export function convertCmToIn(cm: string) {
  const inchesPerCm = 2.54;
  return (Number(cm) / inchesPerCm).toFixed(1);
}
