import { ENV } from '@point-of-sale/env';
import { RootStateType, store } from '../store';
import toast from 'react-hot-toast';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SentryService } from '@point-of-sale/services';
import { logout } from '../identity/thunks';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getDeviceFingerprint } from '@point-of-sale/utils';

export interface ICallApiArgs<T> {
  method: RequestInit['method'];
  endPoint: string;
  data?: T;
  headers?: HeadersInit;
}

export class BaseApiService {
  private backendUrl: string;

  constructor(backendUrl: string) {
    this.backendUrl = backendUrl;
  }

  // eslint-disable-next-line class-methods-use-this
  getBaseApiHeaders(token?: string | null): Record<string, string> {
    const headers: Record<string, string> = {
      'abs-referer': window.location.href,
      'page-referer': document.referrer,
      'x-lk-portalcode': `pos`,
      'x-lk-orgid': `lyskraft`,
      'x-lk-deviceid': getDeviceFingerprint(),
      'x-lk-app': `runway`,
    };

    // TODO: Refactor this...
    const salesChannelId =
      (store.getState() as RootStateType).identity.selectedSalesChannel?.id ??
      (store.getState() as RootStateType).identity.erpUser?.storeSalesChannelIds[0];

    if (salesChannelId) {
      headers['x-lk-scid'] = String(salesChannelId ?? '');
    }

    // TODO: Remove this hardcoded value
    headers['x-lk-posterminalid'] = '1';

    const authToken = (store.getState() as RootStateType).identity.token;
    if (authToken) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }

  private constructOptions<T>({ method, data, headers }: ICallApiArgs<T>): Record<string, unknown> {
    const token = (store.getState() as RootStateType).identity.token;
    const options: Record<string, unknown> = {
      method,
      headers: {
        ...this.getBaseApiHeaders(token),
        'Content-Type': 'application/json',
        ...headers,
      },
    };
    if (method !== 'get') {
      options['body'] = JSON.stringify(data);
    }
    return options;
  }

  async callApi<T>({ method, endPoint, data, headers }: ICallApiArgs<T>) {
    const options = this.constructOptions({ method, endPoint, data, headers });
    const json = await this.makeRequest(this.backendUrl + endPoint, options);
    return json;
  }

  private async makeRequest(inputUrl: string, options: Record<string, unknown>) {
    const response = await fetch(inputUrl, options);

    if (!response.ok) {
      if (response.status === 403) {
        SentryService.catchError(new Error('403 - Forbidden'));
        toast.error('You are not authorized to perform this action');
        return;
      }

      if (response.status === 401) {
        // toast.error('Session expired. Please login again');
        SentryService.catchError(new Error('401 - Session expired'));
        store.dispatch(logout());
        return;
      }

      SentryService.catchError(new Error(`Network response was not ok ${response.status}`));

      throw new Error(`Network response was not ok ${response.status}`);
    }

    return response.json();
  }
}
