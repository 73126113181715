import { ReactNode } from 'react';
import { ErrorBoundary, ErrorBoundaryPropsWithComponent } from 'react-error-boundary';
import { ENV } from '@point-of-sale/env';
import { ISentryErrorInfo, SentryService } from '@point-of-sale/services';

const Comp = () => <div></div>;

// TODO: add this to ENV variables
const isErrorBoundaryEnabled =
  ENV.VITE_ENVIRONMENT === 'production' ||
  ENV.VITE_ENVIRONMENT === 'staging' ||
  ENV.VITE_ENVIRONMENT === 'local';

function handleError(error: Error, info: ISentryErrorInfo) {
  console.error(error);
  SentryService.catchError(error, info);
}

interface IMainErrorBoundaryProps extends ErrorBoundaryPropsWithComponent {
  children: ReactNode | null;
}

const MainErrorBoundary = ({
  FallbackComponent,
  onError = handleError,
  ...props
}: IMainErrorBoundaryProps) => {
  if (isErrorBoundaryEnabled) {
    return (
      <ErrorBoundary
        {...props}
        onError={onError}
        // resetKeys={[pathname]} // add pathname to resetKeys
        FallbackComponent={ENV.VITE_ENVIRONMENT === 'production' ? Comp : FallbackComponent}
      />
    );
  }
  return props.children;
};

export default MainErrorBoundary;
