import { useEffect, useRef } from 'react';
import { AnimatePresence } from 'motion/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { getSkuMovements, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import StockKeepingUnit from './SkuMovementItem';
import { useFetchOnListEnd } from '@point-of-sale/hooks';
import * as Styles from './styles';

// TODO: Add skeleton loader
// TODO: Add motion animation keeping list virtualization as is
const SkuMovementList = () => {
  const isLoading = useAppSelector(store => store.skuMovement.stockKeepingUnits.isLoading);
  const ids = useAppSelector(store => store.skuMovement.stockKeepingUnits.data.ids);

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(getSkuMovements());
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  useEffect(() => {
    dispatch(getSkuMovements());
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: ids.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 2,
    gap: 8,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <Styles.Wrapper
      ref={parentRef}
      initial="hidden"
      animate="show"
      exit="hidden"
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 10,
          },
        },
      }}
    >
      <Styles.ListWrapper
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        <AnimatePresence>
          {virtualItems.map(virtualItem => (
            <StockKeepingUnit
              key={virtualItem.key}
              index={virtualItem.index}
              lastItemRef={virtualItem.index === ids.length - 2 ? lastItemRef : undefined}
              style={{
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            />
          ))}
        </AnimatePresence>
      </Styles.ListWrapper>
    </Styles.Wrapper>
  );
};

export default SkuMovementList;
