import { Context, createContext, useContext } from 'react';

export interface ISettingsContext {
  selectedSection: string;
  selectedSubsection: string;

  setSelectedSection: (section: string) => void;
  setSelectedSubsection: (subsection: string) => void;
}

export const SettingsContext: Context<ISettingsContext> = createContext({} as ISettingsContext);

export function useSettingsContext() {
  const contextData = useContext(SettingsContext);
  return contextData;
}
