import toast from 'react-hot-toast';
import { getFCMToken, canGetFCMToken } from '@lyskraft/firebase';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler } from '@point-of-sale/utils';
import { NotificationTokensDTOSchema, NotificationTokensDTOType } from '@point-of-sale/schemas';
import { ApiResponseEnum } from '@point-of-sale/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ThunkActionType } from '../store';
import { searchNotificationTokenApi, upsertNotificationTokenApi } from './api';
import { setFcmToken } from './actions';

export const searchNotificationToken = (): ThunkActionType => {
  return async (dispatch, getState) => {
    try {
      const promise = searchNotificationTokenApi();
      const response = await apiResponseHandler<NotificationTokensDTOType>(
        promise,
        NotificationTokensDTOSchema
      );

      if (response.type === ApiResponseEnum.Failure) {
        toast.error(response.meta?.message ?? 'searchNotificationTokenApi failed');
        return;
      }

      const existingFcmToken = getState().notifications.fcmToken;

      // * notification token found in the backend
      if (response.data.length > 0) {
        const notificationToken = response.data[0];

        if (!existingFcmToken || existingFcmToken !== notificationToken.token) {
          dispatch(requestNotificationPermission());
          return;
        }
      }

      // * No notification token found in the backend
      if (response.data.length === 0) {
        dispatch(requestNotificationPermission());
      }
    } catch (error) {
      console.log('cannot happen', error);
    }
  };
};

export const generateAndUpsertNotificationToken = (): ThunkActionType => {
  return async dispatch => {
    const isSupported = await canGetFCMToken();

    if (!isSupported) {
      toast('FCM is not supported');
      return;
    }

    try {
      const fcmToken = await getFCMToken();

      dispatch(setFcmToken(fcmToken));

      await upsertNotificationTokenApi({
        platform: 'FIREBASE',
        token: fcmToken,
      });

      toast('Notification token sent to server');
    } catch (error) {
      toast(`Notification token could not be sent ${JSON.stringify(error)} `);
      console.log('cannot happen', error);
    }
  };
};

export const requestNotificationPermission = (): ThunkActionType => {
  return async dispatch => {
    try {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        dispatch(generateAndUpsertNotificationToken());
      } else {
        // * Maybe do something here
        // * like track the user who denied the permission
        // toast('Notification permission denied');
      }
    } catch (error) {
      toast('Error, check logs');
      console.log('cannot happen', error);
    }
  };
};
