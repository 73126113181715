import {
  INormalizedServiceabilityData,
  ProductVariantDTOType,
  ServiceabilityDTOType,
} from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { pick } from '@point-of-sale/utils';
import {
  jitOrMtoOrMtcItemsSelector,
  readyMadeItemsFromOtherStoreOrWarehouseSelector,
  storePurchaseItemsWithAlterationSelector,
  storePurchaseItemsWithoutAlterationSelector,
  storePurchaseItemsWithHomeDeliveryAndWithoutAlterationSelector,
} from './selectors';
import { store as reduxStore, RootStateType, store } from '../store';
import { ENV } from '@point-of-sale/env';
import { selectSelectedSalesChannelOnlineStatus } from '../common/selectors';

/**
 * @param search string
 * @description returns `true` if the input string
 *               is possibly a barcode,
 */
export const isBarcodish = (search: string): boolean => {
  const regex = /^\d{10,14}$/;
  return regex.test(search);
};

export const getInventoryStatus = (variant: ProductVariantDTOType | null) => {
  const store = reduxStore.getState() as RootStateType;

  const facilities = store.common.facilities;
  const selectedSalesChannelId = store.identity.selectedSalesChannel?.id as number;
  const currentStore = Object.values(facilities.data.records).find(
    item => item.salesChannelId === selectedSalesChannelId
  );
  const { isSelectedSalesChannelOnline } = selectSelectedSalesChannelOnlineStatus(store);
  const sourceForOnlinePos = store.pointOfSale.sourceForOnlinePos;

  if (!currentStore) {
    console.log('selectedSalesChannelId', selectedSalesChannelId);
    console.log('facilities', facilities);
    console.log('currentStore', currentStore);

    throw new Error('[getInventoryStatus] currentStore not found');
  }

  const currentStoreAndWarehouses = Object.values(facilities.data.records).filter(
    item => item.facilityGroupId === currentStore.facilityGroupId
  );

  const hasInventoryInCurrentStoreOrWarehouse = Object.values(
    pick(
      variant?.facilityInventory ?? {},
      currentStoreAndWarehouses.map(item => String(item.id))
    )
  ).some(item => item.availableInventory && item.availableInventory > 0);

  const otherFacilities = Object.values(facilities.data.records).filter(
    item => item.facilityGroupId !== currentStore.facilityGroupId
  );

  const otherFacilitiesWithNonZeroInventoryMap = Object.entries(
    pick(
      variant?.facilityInventory ?? {},
      otherFacilities.map(item => String(item.id))
    )
  )
    .filter(([_, item]) => item.availableInventory && item.availableInventory > 0)
    .reduce((acc, [facilityId, item]) => {
      acc[facilityId] = item;
      return acc;
    }, {} as Record<string, any>);

  const inventoryInOtherStoreAndWarehouses = otherFacilities.filter(item =>
    Object.prototype.hasOwnProperty.call(otherFacilitiesWithNonZeroInventoryMap, item.id)
  );

  const hasInventoryInOtherStoreOrWarehouse =
    Object.values(otherFacilitiesWithNonZeroInventoryMap).length > 0;

  const hasNoInventoryInAnyStoreOrWarehouse =
    !hasInventoryInCurrentStoreOrWarehouse && !hasInventoryInOtherStoreOrWarehouse;

  if (isSelectedSalesChannelOnline && sourceForOnlinePos === 'TRY_AT_HOME') {
    inventoryInOtherStoreAndWarehouses.sort((a, b) => {
      if (String(a.id) === ENV.VITE_TRY_AT_HOME_FACILITY_ID) return -1;
      if (String(b.id) === ENV.VITE_TRY_AT_HOME_FACILITY_ID) return 1;
      return 0;
    });
  }

  const hasInventoryInTryAtHomeFacility =
    inventoryInOtherStoreAndWarehouses.length > 0 &&
    String(inventoryInOtherStoreAndWarehouses[0].id) === ENV.VITE_TRY_AT_HOME_FACILITY_ID;

  return {
    hasInventoryInCurrentStoreOrWarehouse,
    hasInventoryInOtherStoreOrWarehouse,
    hasNoInventoryInAnyStoreOrWarehouse,
    inventoryInOtherStoreAndWarehouses,
    hasInventoryInTryAtHomeFacility,
  };
};

export const getWarehouseAndStoreInventoryOfVariantByCurrentStore = (
  variant: ProductVariantDTOType
) => {
  const store = reduxStore.getState() as RootStateType;

  const facilities = store.common.facilities;
  const selectedSalesChannelId = store.identity.selectedSalesChannel?.id as number;
  const currentStore = Object.values(facilities.data.records).find(
    item => item.salesChannelId === selectedSalesChannelId
  );

  if (!currentStore) {
    throw new Error(
      '[getWarehouseAndStoreInventoryOfVariantByCurrentStore] currentStore not found'
    );
  }

  const currentWarehouses = Object.values(facilities.data.records).filter(
    item =>
      item.facilityGroupId === currentStore.facilityGroupId && item.facilityType === 'WAREHOUSE'
  );

  const currentWarehousesWithInventory = currentWarehouses.filter(
    item =>
      variant.facilityInventory?.[item.id]?.availableInventory &&
      variant.facilityInventory?.[item.id]?.availableInventory > 0
  );

  return {
    storeInventory: variant?.facilityInventory?.[currentStore.id]?.availableInventory ?? 0,
    combinedWarehouseInventory: currentWarehousesWithInventory.reduce(
      (acc, item) => acc + (variant?.facilityInventory?.[item.id].availableInventory ?? 0),
      0
    ),
    storeFacilityId: currentStore.id,
    warehouseFacilityIds: currentWarehouses.map(item => item.id),
  };
};

export const getCurrentStoreAndWarehouseFacilityIds = () => {
  const store = reduxStore.getState() as RootStateType;
  const facilities = store.common.facilities;

  const selectedSalesChannelId = store.identity.selectedSalesChannel?.id as number;

  const currentStore = Object.values(facilities.data.records).find(
    item => item.salesChannelId === selectedSalesChannelId
  );

  const currentStoreAndWarehouses = Object.values(facilities.data.records).filter(
    item => item.facilityGroupId === currentStore?.facilityGroupId
  );

  return {
    currentStoreAndWarehousesFacilityIds: currentStoreAndWarehouses.map(item => item.id),
  };
};

export function getNormalizedServiceabilityData(rawData: Array<ServiceabilityDTOType>) {
  const normalizedData: INormalizedServiceabilityData = {};

  rawData.forEach(serviceability => {
    if (!normalizedData[serviceability.skuCode]) {
      normalizedData[serviceability.skuCode] = [];
    }

    if (!serviceability.facilityIdToFacilityTatMap) {
      return;
    }

    Object.entries(serviceability.facilityIdToFacilityTatMap).forEach(([key, value]) => {
      normalizedData[serviceability.skuCode].push({
        from: {
          facilityId: key,
          pincode: value.facilityPincode,
        },
        to: serviceability.destinationPincode || '',
        info: {
          ...value,
          jitTat: serviceability.jitTat,
          mtcTat: serviceability.mtcTat,
          mtoTat: serviceability.mtoTat,
          innerOpsTat: value.opsTat,
          outerOpsTat: serviceability.opsTat,
          innerPriorityShippingAvailable: value.priorityShippingAvailable,
          outerPriorityShippingAvailable: serviceability.priorityShippingAvailable,
        },
      });
    });
  });

  return normalizedData;
}

/**
 * Grouped to same destinations:
 * 1. `jitOrMtoOrMtcItems`
 * 2. `readyMadeItemsFromOtherStoreOrWarehouse`
 * 3. `storePurchaseItemsWithoutAlteration`
 * 4. `storePurchaseItemsWithAlteration`
 * 5. `storePurchaseItemsWithHomeDeliveryAndWithoutAlteration`
 */
export function getBucketizedCartItems() {
  const reduxState = store.getState() as RootStateType;

  /**
   * * Grouped to destinations
   */
  const jitOrMtoOrMtcItems = jitOrMtoOrMtcItemsSelector(reduxState);
  /**
   * * Grouped to destinations
   */
  const readyMadeItemsFromOtherStoreOrWarehouse =
    readyMadeItemsFromOtherStoreOrWarehouseSelector(reduxState);
  const storePurchaseItemsWithoutAlteration =
    storePurchaseItemsWithoutAlterationSelector(reduxState);
  const storePurchaseItemsWithAlteration = storePurchaseItemsWithAlterationSelector(reduxState);
  const storePurchaseItemsWithHomeDeliveryAndWithoutAlteration =
    storePurchaseItemsWithHomeDeliveryAndWithoutAlterationSelector(reduxState);

  return {
    jitOrMtoOrMtcItems: jitOrMtoOrMtcItems.sort((a, b) => (a > b ? 1 : -1)),
    readyMadeItemsFromOtherStoreOrWarehouse: readyMadeItemsFromOtherStoreOrWarehouse.sort((a, b) =>
      a > b ? 1 : -1
    ),
    storePurchaseItemsWithoutAlteration: storePurchaseItemsWithoutAlteration.sort((a, b) =>
      a > b ? 1 : -1
    ),
    storePurchaseItemsWithAlteration: storePurchaseItemsWithAlteration.sort((a, b) =>
      a > b ? 1 : -1
    ),
    storePurchaseItemsWithHomeDeliveryAndWithoutAlteration:
      storePurchaseItemsWithHomeDeliveryAndWithoutAlteration.sort((a, b) => (a > b ? 1 : -1)),
  };
}
