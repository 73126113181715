import { useAppSelector } from './useAppSelector';

/**
 * Queries store staff & erp Users in common reducer
 *
 * @param userId
 * @returns ErpUser
 */
export const useUserById = (userId: number | undefined) => {
  const storeStaffIds = useAppSelector(store => store.common.storeStaff.data.ids);
  const storeStaffRecords = useAppSelector(store => store.common.storeStaff.data.records);

  const erpUserIds = useAppSelector(store => store.common.erpUsers.data.ids);
  const erpUserRecords = useAppSelector(store => store.common.erpUsers.data.records);

  if (!userId) {
    return {
      user: null,
    };
  }

  if (storeStaffIds.includes(userId)) {
    return {
      user: storeStaffRecords[userId],
    };
  }

  if (erpUserIds.includes(userId)) {
    return {
      user: erpUserRecords[userId],
    };
  }

  return {
    user: null,
  };
};
