import { DeliveryModeType, FulfillmentModeType, LineItemDTOType } from '@point-of-sale/schemas';
import {
  Button,
  Capsule,
  CheckBox,
  CheckFilledIcon,
  Image,
  Input,
  Select,
  Text,
} from '@point-of-sale/components';
import { CURRENCY_SYMBOLS, CurrencyType, PLACEHOLDERS } from '@point-of-sale/constants';
import {
  formatNumberToLocale,
  formatToReadableDate,
  openInNewTab,
  removeUnderscoresAndCapitalize,
  updateAttributesOfCdnImage,
} from '@point-of-sale/utils';
import {
  useAppSelector,
  useIsErpUserStoreAdmin,
  useIsErpUserSuperAdmin,
} from '@point-of-sale/store';
import KeyValuePair from '../KeyValuePair';
import { useOrderDetailsContext } from '../context';
import LineItemStatusGraph from '../LineItemStatusGraph';
import ReturnsAndExchangePolicy from '../../ReturnsAndExchangePolicy';
import RemarksAndCustomizations from './RemarksAndCustomizations';
import {
  CANCELLATION_REASONS,
  DECORATIVE_CANCELLATION_REASONS,
  DECORATIVE_RETURN_REASONS,
  RETURN_REASONS,
  RETURN_SUB_REASONS,
} from '../constants';
import * as Styles from './styles';
import { Ribbon } from '../../Ribbon';

interface ILineItemProps {
  data: LineItemDTOType;
}

const LineItem = ({ data }: ILineItemProps) => {
  const {
    lineItemsSelection,
    onToggleLineItemSelection,
    reasons,
    onReasonChange,
    operation,
    specificReasons: { [data.id]: specificReason },
    onSpecificReasonChange,
  } = useOrderDetailsContext();

  const facilities = useAppSelector(state => state.common.facilities.data.records);
  const storeStaffRecords = useAppSelector(store => store.common.storeStaff.data.records);

  const { isErpUserStoreAdmin } = useIsErpUserStoreAdmin();
  const { isErpUserSuperAdmin } = useIsErpUserSuperAdmin();

  const currency = CURRENCY_SYMBOLS[(data.costBreakup?.currency as CurrencyType) ?? 'INR']?.code;

  function getDeliveryModeNotFulfillmentModeText(deliveryMode: DeliveryModeType): string {
    if (deliveryMode === 'STORE_PURCHASE') {
      return 'On hand';
    }

    if (deliveryMode === 'STORE_PICKUP') {
      const pickupFacility = Object.values(facilities).find(
        facility => facility.id === data.customerDeliveryStoreId
      );
      return `Store pickup ${pickupFacility?.name ? `(${pickupFacility.name})` : ''}`;
    }
    if (deliveryMode === 'HOME_DELIVERY') {
      return 'Home delivery';
    }

    return 'Ship to store';
  }

  function getFulfillmentModeText(fulfillmentMode: FulfillmentModeType): string {
    if (fulfillmentMode === 'JIT') {
      return 'JIT';
    }

    if (fulfillmentMode === 'MTC') {
      return 'MTC';
    }

    if (fulfillmentMode === 'MTO') {
      return 'MTO';
    }

    if (fulfillmentMode === 'ONHAND') {
      return 'Store Purchase';
    }

    return 'Store Purchase';
  }

  function getReasonsList() {
    if (operation === 'RETURNS_AND_EXCHANGES') {
      return RETURN_REASONS;
    }

    if (operation === 'CANCELLATION') {
      return CANCELLATION_REASONS;
    }

    return RETURN_REASONS;
  }

  const options = getReasonsList();

  const isCancelable = data.cancellable;
  const isCancellableByAdmin = data.cancellableByAdmin;

  function getIsOperationEnabled() {
    if (operation === 'RETURNS_AND_EXCHANGES') {
      if (isErpUserStoreAdmin || isErpUserSuperAdmin) {
        return data.currentlyReturnableByAdmin;
      }

      return data.currentlyReturnable || data.currentlyExchangeable;
    }

    if (operation === 'CANCELLATION') {
      if (isErpUserStoreAdmin || isErpUserSuperAdmin) {
        return isCancellableByAdmin || isCancelable;
      }

      return isCancelable;
    }

    return false;
  }

  const isOperationEnabled = getIsOperationEnabled();
  const isReturned = data.status === 'RETURNED';
  const isCancelled = data.status === 'CANCELLED';
  const isReturnInProgress = data.status === 'RETURN_WIP';

  const ReturnBillButton = () =>
    data.refundRequest?.refundReceiptAsset?.url ? (
      <Button
        variant="outline"
        rightIcon="LinkIcon"
        onClick={() => {
          openInNewTab(data.refundRequest?.refundReceiptAsset?.url ?? '');
        }}
        sizeVariant="xs"
      >
        View Return Bill
      </Button>
    ) : null;

  const Cancellability = () => {
    // if (operation !== 'CANCELLATION') {
    //   return null;
    // }

    if (!isCancelable && !isCancellableByAdmin) {
      return <KeyValuePair value="Item cannot be cancelled" />;
    }

    if (!isCancelled && isCancellableByAdmin) {
      return <KeyValuePair value="Item can be cancelled only by admin" />;
    }

    if (isCancelable) {
      return <KeyValuePair value="Item can be cancelled" />;
    }

    return <KeyValuePair value="Item cannot be cancelled" />;
  };

  const dropdownValue = reasons[data.id];

  function getLineItemRefundOperator() {
    if (data.status === 'CANCELLED') {
      const idOfPersonResponsible = data.cancellationSalesStaffId;

      if (!idOfPersonResponsible) {
        return [null, null];
      }

      return ['Cancelled By', storeStaffRecords[idOfPersonResponsible]?.name];
    }

    if (data.status === 'RETURNED') {
      const idOfPersonResponsible = data.returnEntry?.salesStaffId;

      if (!idOfPersonResponsible) {
        return [null, null];
      }

      return ['Returned By', storeStaffRecords[idOfPersonResponsible]?.name];
    }

    return [null, null];
  }

  const [refundOperation, lineItemRefundOperator] = getLineItemRefundOperator();

  console.log(data);
  console.log('returnEntry', data.returnEntry);
  console.log('refundRequest', data.refundRequest);

  return (
    <Styles.Wrapper>
      <Styles.Right>
        <Styles.Top>
          {isOperationEnabled && (
            <Styles.Header>
              <CheckBox
                isChecked={lineItemsSelection[data.id]}
                onChange={() => {
                  onToggleLineItemSelection(data.id);
                }}
                name={data.skuCode}
              />
              <Select<(typeof options)[number]>
                className="half"
                options={options}
                value={options.find(item => item.value === dropdownValue)}
                onChange={selectedValues => {
                  if (selectedValues) {
                    onReasonChange(data.id, selectedValues.value);
                  } else {
                    onReasonChange(data.id, '');
                  }
                }}
              />
              <Input
                variant="outline"
                placeholder="Enter specific reason"
                sizeVariant="extraSmall"
                value={specificReason ?? ''}
                onChange={e => {
                  onSpecificReasonChange(data.id, e.target.value);
                }}
              />
            </Styles.Header>
          )}
          {isReturned && (
            <Styles.Header>
              <CheckFilledIcon />
              <Text fontSize={12} color="var(--royal-blue)" weight="bold">
                Return Successful
              </Text>
              <ReturnBillButton />
            </Styles.Header>
          )}
          {isCancelled && (
            <Styles.Header>
              <CheckFilledIcon />
              <Text fontSize={12} color="var(--royal-blue)" weight="bold">
                Cancellation Successful
              </Text>
              <ReturnBillButton />
            </Styles.Header>
          )}
          {isReturnInProgress && (
            <Styles.Header>
              <CheckFilledIcon />
              <Text fontSize={12} color="var(--royal-blue)" weight="bold">
                Return In Progress
              </Text>
              <ReturnBillButton />
            </Styles.Header>
          )}
        </Styles.Top>
        <Styles.Top style={{ padding: 12 }}>
          <div style={{ position: 'relative' }}>
            <Image
              height={120}
              width={80}
              src={updateAttributesOfCdnImage(data.productImage?.url, {
                quality: 100,
                width: 120 * 5,
              })}
            />
            {data.costBreakup.offerDiscountEntry && (
              <Ribbon $background={'var(--alizarin-crimson)'} $position="top">
                Bundle #{data.costBreakup.offerDiscountEntry.bundleId}
              </Ribbon>
            )}
            {data.isExchange && (
              <Capsule essence="light" complexion="neutral" size="small" content="Exchange" />
            )}
          </div>
          <Styles.Column>
            <Text fontSize={14} weight="bold" color="var(--dove-gray)">
              {data.brandName}
              <br />
              <Text as="span" fontSize={14} weight="semibold" color="var(--cod-gray)">
                {data.productName}
              </Text>
            </Text>
            <KeyValuePair title="SKU" value={data.skuCode} />
            <KeyValuePair title="Size" value={data.size} />
            <KeyValuePair title="Color" value={data.color} />
            <KeyValuePair title="Order type" value={getFulfillmentModeText(data.fulfillmentMode)} />
            {data.costBreakup.offerDiscountEntry && (
              <Capsule
                essence="light"
                complexion="danger"
                size="small"
                content={`Item was part of a bundle offer`}
              />
            )}
          </Styles.Column>
          <Styles.Column>
            <KeyValuePair
              title="MRP"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.mrp ?? 0)}`}
            />
            {data.costBreakup.offerDiscountEntry && (
              <KeyValuePair
                title="Offer Discount"
                value={`${currency} ${formatNumberToLocale(data.costBreakup.offerDiscount ?? 0)}`}
              />
            )}
            <KeyValuePair
              title="Bag Discount"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.bagDiscount ?? 0)}`}
            />
            <KeyValuePair
              title="Total"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.chargeableAmount ?? 0)}`}
            />
            <KeyValuePair
              title="Amount Paid"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.amountPaid ?? 0)}`}
            />
            <KeyValuePair
              title="Remaining Amount"
              value={`${currency} ${formatNumberToLocale(data.costBreakup.amountRemaining ?? 0)}`}
            />
          </Styles.Column>
          <Styles.Column>
            <ReturnsAndExchangePolicy
              isExchangeable={data.currentlyExchangeable}
              isReturnable={data.currentlyReturnable}
              fontSize={14}
            />

            <KeyValuePair
              // WTF
              title="Fulfillment mode"
              value={getDeliveryModeNotFulfillmentModeText(data.deliveryMode)}
            />

            {isReturned || isReturnInProgress
              ? data.returnEntry?.createdOn && (
                  <KeyValuePair
                    title={'Return placed on'}
                    value={formatToReadableDate(data.returnEntry?.createdOn)}
                  />
                )
              : data.returnPolicy &&
                operation === 'RETURNS_AND_EXCHANGES' && <KeyValuePair value={data.returnPolicy} />}

            {operation === 'RETURNS_AND_EXCHANGES' && data.currentlyReturnable && (
              <KeyValuePair
                // br
                title="Return expires on"
                value={new Date(data.returnableUntil ?? 0).toDateString()}
                valueProps={{
                  color: 'var(--chateau-green)',
                }}
              />
            )}

            <Cancellability />

            {operation === 'RETURNS_AND_EXCHANGES' && data.currentlyExchangeable && (
              <KeyValuePair
                // br
                title="Exchange expires on"
                value={new Date(data.exchangeableUntil ?? 0).toDateString()}
                valueProps={{
                  color: 'var(--chateau-green)',
                }}
              />
            )}

            <RemarksAndCustomizations data={data} />

            <KeyValuePair
              title="Status"
              value={removeUnderscoresAndCapitalize(data.status ?? '')}
            />

            {refundOperation && lineItemRefundOperator && (
              <KeyValuePair title={refundOperation} value={lineItemRefundOperator} />
            )}

            {(isReturned || isReturnInProgress) && (
              <KeyValuePair
                title="Return Reason"
                value={
                  DECORATIVE_RETURN_REASONS.find(r => r.value === data.returnReason)?.label ??
                  PLACEHOLDERS.NOT_AVAILABLE
                }
              />
            )}

            {(isReturned || isReturnInProgress) && data.returnSubReason && (
              <KeyValuePair
                title="Sub Reason"
                value={
                  RETURN_SUB_REASONS[data.returnSubReason as keyof typeof RETURN_SUB_REASONS] ??
                  data.returnSubReason ??
                  ''
                }
              />
            )}
            {data.returnEntry?.returnMode && (
              <KeyValuePair
                title={'Return Mode'}
                value={removeUnderscoresAndCapitalize(data.returnEntry?.returnMode ?? '')}
              />
            )}

            {isCancelled && (
              <KeyValuePair
                title="Cancellation Reason"
                value={
                  DECORATIVE_CANCELLATION_REASONS.find(r => r.value === data.cancellationReason)
                    ?.label ?? ''
                }
              />
            )}

            {isCancelled && data.cancellationRemarks && (
              <KeyValuePair title="Sub Reason" value={data.cancellationRemarks ?? ''} />
            )}
          </Styles.Column>
        </Styles.Top>
        <Styles.Top style={{ padding: 12 }}>
          <Styles.Column>
            {data.courierPartner && (
              <KeyValuePair
                title="Courier Partner"
                value={data.courierPartner}
                titleProps={{
                  width: 'unset',
                }}
              />
            )}
            {data.courierTrackingId && (
              <KeyValuePair
                title="Courier Tracking Id"
                value={data.courierTrackingId}
                titleProps={{
                  width: 'unset',
                }}
              />
            )}
            {data.vendorDeliveryDate && (
              <KeyValuePair
                title="Vendor Delivery Date"
                value={formatToReadableDate(data.vendorDeliveryDate)}
              />
            )}
            {data.courierPartner &&
              data.courierPartner.toUpperCase() === 'SHIPROCKET' &&
              data.courierTrackingId && (
                <Button
                  onClick={() => {
                    openInNewTab(`https://shiprocket.co/tracking/${data.courierTrackingId}`);
                  }}
                  variant="outline"
                  sizeVariant="xs"
                  colorScheme="dark"
                  leftIcon="TruckIcon"
                >
                  Track Shipment
                </Button>
              )}
          </Styles.Column>
        </Styles.Top>
        <Styles.Bottom style={{ padding: 12 }}>
          <LineItemStatusGraph data={data} />
        </Styles.Bottom>
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default LineItem;
