import toast from 'react-hot-toast';
import { shallowEqual } from 'react-redux';
import { Button, Modal, Skeleton, Text } from '@point-of-sale/components';
import {
  addCustomerToCart,
  createNewCustomer,
  searchCustomer,
  setCustomerInfoModalUpdateCallback,
  setIsEditCustomerInfoModalOpen,
  updateCustomer,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { isEmptyObject } from '@point-of-sale/utils';
import { EMAIL_REGEX, PHONE_REGEX } from '@point-of-sale/constants';
import CustomerDetails from '../../CustomerDetails';
import * as Styles from './styles';

const CustomerInfo = () => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const isCartLoading = useAppSelector(store => store.pointOfSale.cart.isLoading);
  const isLoading = useAppSelector(store => store.pointOfSale.customer.isLoading);
  const customerData = useAppSelector(store => store.pointOfSale.customer.data);

  const isEditCustomerInfoModalOpen = useAppSelector(
    store => store.pointOfSale.isEditCustomerInfoModalOpen,
    shallowEqual
  );
  const customerInfoModalUpdateCallback = useAppSelector(
    store => store.pointOfSale.customerInfoModalUpdateCallback,
    shallowEqual
  );

  const dispatch = useAppDispatch();

  const isGuestCheckout = isEmptyObject(customerData);

  return (
    <Styles.Wrapper>
      {isLoading ? (
        <Skeleton height={30} width={100} />
      ) : (
        <Styles.Left>
          {isGuestCheckout ? (
            <Text fontSize={16} weight="semibold">
              GUEST
            </Text>
          ) : (
            <>
              <Text fontSize={16} weight="semibold">
                {customerData.name}
              </Text>
              <Text fontSize={16} weight="semibold">
                {customerData.phone}
              </Text>
            </>
          )}
        </Styles.Left>
      )}

      <Button
        sizeVariant="large"
        variant="ghost"
        onClick={() => {
          dispatch(setIsEditCustomerInfoModalOpen(true));
          // modalOpenActions.on();
        }}
        colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
      >
        {isGuestCheckout ? ' Add' : 'Edit'}
      </Button>

      <Modal
        isOpen={isEditCustomerInfoModalOpen}
        onClose={() => {
          dispatch(setIsEditCustomerInfoModalOpen(false));
          // modalOpenActions.off();
        }}
        heading={
          <Text fontSize={20} weight="bold">
            Customer Details
          </Text>
        }
      >
        <CustomerDetails
          isCartLoading={isCartLoading}
          actionables={formData => {
            return (
              <Styles.ButtonContainer>
                <Button
                  onClick={() => {
                    function successCallback(doesCustomerExist?: boolean) {
                      if (doesCustomerExist) {
                        dispatch(addCustomerToCart(() => toast.success('Customer Added')));
                      } else {
                        dispatch(
                          createNewCustomer(formData, () =>
                            dispatch(addCustomerToCart(() => toast.success('Customer Added')))
                          )
                        );
                      }
                    }

                    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
                      toast.error('Please Enter a valid email');
                      return;
                    }

                    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
                      toast.error('Please Enter a valid phone number');
                      return;
                    }

                    if (formData.name && formData.name.length < 3) {
                      toast.error('Please enter a real name');
                      return;
                    }

                    // if (formData.phone && formData.phone?.length !== 10) {
                    //   toast.error('Please Enter a valid phone number');
                    //   return;
                    // }

                    if (!customerData.id) {
                      if (formData.phone) {
                        dispatch(
                          searchCustomer({
                            searchParams: {
                              countryCode: formData.country ?? '+91',
                              phoneNumber: formData.phone,
                            },
                            callbacks: {
                              onComplete: successCallback,
                            },
                          })
                        );
                      } else if (formData.email) {
                        dispatch(
                          searchCustomer({
                            searchParams: {
                              email: formData.email,
                            },
                            callbacks: {
                              onComplete: successCallback,
                            },
                          })
                        );
                      }
                    } else if (customerData.id) {
                      if (!formData.name) {
                        toast.error('Please enter a name');
                        return;
                      }

                      customerInfoModalUpdateCallback?.();
                      dispatch(setCustomerInfoModalUpdateCallback(undefined));

                      dispatch(
                        updateCustomer(customerData.id, formData, () =>
                          dispatch(setIsEditCustomerInfoModalOpen(false))
                        )
                      );
                    }
                    //  else {
                    //   // this should never ever happen
                    //   toast.error('Customer ID not found, Cannot Update');
                    // }
                  }}
                  colorScheme="dark"
                >
                  {isGuestCheckout ? 'Search' : 'Update'}
                </Button>
              </Styles.ButtonContainer>
            );
          }}
        />
      </Modal>
    </Styles.Wrapper>
  );
};

export default CustomerInfo;
