import { useMemo } from 'react';
import { Capsule, Image, Text } from '@point-of-sale/components';
import { updateAttributesOfCdnImage } from '@point-of-sale/utils';
import { useProductListItem } from '../context';
import useDiscount from '../../../../../components/desktop/Product/hooks/useDiscount';
import * as Styles from './styles';

interface IBasicInfoProps {
  shouldShowOnlyBasicDetails?: boolean;
}

const BasicInfo = ({ shouldShowOnlyBasicDetails }: IBasicInfoProps) => {
  const { data, selectedVariant } = useProductListItem();

  const firstImage = data.images?.[0];

  const displayedImage = selectedVariant ? selectedVariant.images?.[0] ?? firstImage : firstImage;

  const highResImage = useMemo(
    () =>
      updateAttributesOfCdnImage(displayedImage?.url, {
        width: 240,
        quality: 100,
      }),
    [displayedImage]
  );

  const { currencySymbol, determinedVariant, discount } = useDiscount({
    product: data,
    selectedVariant: null,
  });

  return (
    <Styles.Wrapper>
      <Styles.ImageContainer>
        <Image height={'100%'} width={'100%'} src={highResImage} />
      </Styles.ImageContainer>
      <Styles.InfoContainer>
        <Styles.InfoContainerLeft>
          <Text color="var(--dove-gray)" fontSize={16} weight="semibold">
            {data.brand.name}
          </Text>
          <Text color="var(--cod-gray)" fontSize={18} weight="semibold">
            {data.name}
          </Text>
          {shouldShowOnlyBasicDetails && selectedVariant && (
            <Text
              color="var(--dove-gray)"
              fontSize={16}
              weight="semibold"
              textTransform="uppercase"
            >
              SKU: {selectedVariant.skuCode}
            </Text>
          )}

          <Styles.StyledProductPrice
            currencySymbol={currencySymbol}
            determinedVariantPrices={determinedVariant}
            discount={discount}
            isExpanded={false}
            selectedVariant={selectedVariant}
            textAlign="left"
          />
        </Styles.InfoContainerLeft>
        {!shouldShowOnlyBasicDetails && (
          <Styles.InfoContainerRight>
            {selectedVariant && (
              <>
                <Capsule
                  essence="light"
                  complexion={selectedVariant?.mtoAcceptedByBrand ? 'success' : 'danger'}
                  size="small"
                  content="MTO"
                  style={{ margin: 4 }}
                  iconName={selectedVariant?.mtoAcceptedByBrand ? 'CheckMarkIcon' : 'CrossIcon'}
                  iconProps={{ size: 24 }}
                />
                <Text textAlign="center" display="inline-block">
                  {selectedVariant?.mtoAcceptedByBrand &&
                    selectedVariant?.mtoTAT &&
                    `${selectedVariant?.mtoTAT} Days`}
                </Text>
              </>
            )}

            {selectedVariant && (
              <>
                <Capsule
                  essence="light"
                  complexion={selectedVariant?.mtcAcceptedByBrand ? 'success' : 'danger'}
                  size="small"
                  content="MTC"
                  style={{ margin: 4 }}
                  iconName={selectedVariant?.mtcAcceptedByBrand ? 'CheckMarkIcon' : 'CrossIcon'}
                  iconProps={{ size: 24 }}
                />
                <Text textAlign="center" display="inline-block">
                  {selectedVariant?.mtcAcceptedByBrand &&
                    selectedVariant?.mtcTAT &&
                    `${selectedVariant?.mtcTAT} Days`}
                </Text>
              </>
            )}

            {selectedVariant && (
              <>
                <Capsule
                  essence="light"
                  complexion={
                    (selectedVariant?.jitFacilities?.length ?? 0) > 0 ? 'success' : 'danger'
                  }
                  size="small"
                  content="JIT"
                  style={{ margin: 4 }}
                  iconName={
                    (selectedVariant?.jitFacilities?.length ?? 0) > 0
                      ? 'CheckMarkIcon'
                      : 'CrossIcon'
                  }
                  iconProps={{ size: 24 }}
                />
                <Text textAlign="center" display="inline-block">
                  {(selectedVariant?.jitFacilities?.length ?? 0) > 0 &&
                    selectedVariant?.jitTAT &&
                    `${selectedVariant?.jitTAT} Days`}
                </Text>
              </>
            )}
          </Styles.InfoContainerRight>
        )}
      </Styles.InfoContainer>
    </Styles.Wrapper>
  );
};

export default BasicInfo;
