import { motion } from 'motion/react';
import styled from 'styled-components';

export const Wrapper = styled(motion.li)`
  border: 1px solid var(--dove-gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 1px;
  padding: 8px 12px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const ModalContent = styled.div`
  padding: 12px 24px;
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;
