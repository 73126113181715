export const RETURN_REASONS = [
  {
    label: 'Incorrect size/ did not fit',
    value: 'INCORRECT_SIZE',
  },
  {
    label: 'Item defective/ damaged',
    value: 'DEFECTIVE_DAMAGED',
  },
  {
    label: 'Wrong item delivered',
    value: 'WRONG_ITEM_DELIVERED',
  },
  {
    label: 'Product not as described',
    value: 'NOT_AS_DESCRIBED',
  },
  {
    label: 'Quality not as expected',
    value: 'NOT_AS_EXPECTED',
  },
  {
    label: 'Change of mind',
    value: 'CHANGE_OF_MIND',
  },
  {
    label: 'Color mismatch',
    value: 'COLOR_MISMATCH',
  },
  {
    label: 'Late delivery',
    value: 'LATE_DELIVERY',
  },
  {
    label: 'Too expensive',
    value: 'TOO_EXPENSIVE',
  },
  {
    label: 'Better price found',
    value: 'BETTER_PRICE_FOUND',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
];

export const DECORATIVE_RETURN_REASONS = [
  ...RETURN_REASONS,
  {
    label: 'Received a wrong or defective product',
    value: 'WRONG_PRODUCT',
  },
  {
    label: 'Size or fit issues',
    value: 'SIZE_FIT_WRONG',
  },
  {
    label: 'Quality issues',
    value: 'QUALITY_ISSUES',
  },
  {
    label: 'Changed my mind',
    value: 'CHANGED_MIND',
  },
];

/**
 * From coyu.com source code
 * app/routes/account_.orderline.$lineid.return.tsx
 */
export const RETURN_SUB_REASONS = {
  DEFECTIVE: 'Received a defective product',
  DIFFERENT: 'Received a different product',
  SIZE_TOO_LARGE: 'Size is too large',
  SIZE_TOO_SMALL: 'Size is too small',
  SIZE_FIT_ISSUE: 'I did not like the fit',
  POOR_QUALITY: 'Received a poor quality product',
  QUALITY_PIC_BETTER: 'Product image was better than actual product',
  DO_NOT_NEED: 'Do not need it anymore',
} as const;

export type ReturnReasonValue = (typeof RETURN_REASONS)[number]['value'];

export const CANCELLATION_REASONS = [
  {
    label: 'Delayed fulfillment',
    value: 'DELAYED_FULFILLMENT',
  },
  {
    label: 'Found better price somewhere else',
    value: 'BETTER_PRICE_FOUND',
  },
  {
    label: 'Customer does not want this item anymore',
    value: 'NOT_WANT_ITEM',
  },
  {
    label: 'Ordered by mistake',
    value: 'ORDERED_BY_MISTAKE',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
];

export type CancellationReasonValue = (typeof CANCELLATION_REASONS)[number]['value'];

export const DECORATIVE_CANCELLATION_REASONS = [
  ...CANCELLATION_REASONS,
  {
    label: 'Stock unavailable at brand',
    value: 'BRAND_OOS',
  },
];
