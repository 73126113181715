import { z } from 'zod';
import { ProductTypeDTOSchema } from '../cart';
import { CustomizationCategorySchema } from '../cart/customizationCategory';
import { AddressDTOSchema } from '../customer';

export const ProductOptionDTOSchema = z.object({
  name: z.string(),
  values: z.array(z.string()),
  optionsValues: z.array(
    z.object({
      displayName: z.string(),
      colorCodes: z.array(z.string()).optional(),
    })
  ),
  position: z.number(),
});
// .strict();

const ProductVariantFacilityInventoryDTOSchema = z.object({
  availableInventory: z.number().optional(),
});
// .strict();

export const ProductImageDTOSchema = z
  .object({
    id: z.number().optional(),
    uploaded: z.boolean(),
    url: z.string().url(),
    originalUrl: z.string().optional(),
  })
  .strict();

export const ProductVariantDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  skuId: z.number(),
  skuCode: z.string(),
  sellingPrice: z.number(),
  mrp: z.number(),
  vendorArticleNumber: z.string().optional(),
  enabled: z.boolean(),
  barcode: z.string(),
  color: z.string(),
  size: z.string(),
  currency: z.string(),
  option: z
    .object({
      SIZE: z.string(),
      COLOR: z.string(),
    })
    .strict(),

  facilityInventory: z.record(z.string(), ProductVariantFacilityInventoryDTOSchema).optional(),
  availableFacilities: z.array(z.string()).optional(),
  inventorySummary: z.record(z.string(), z.number()).optional(),
  mtoAcceptedByBrand: z.boolean(),
  mtcAcceptedByBrand: z.boolean().optional(),
  inStockFacilities: z.array(z.string()).optional(),
  mtoTAT: z.number().optional(),
  mtcTAT: z.number().optional(),
  jitTAT: z.number().optional(),

  exchangeable: z.boolean().optional(),
  returnable: z.boolean().optional(),

  images: z.array(ProductImageDTOSchema).optional(),

  mtoReturnable: z.boolean().optional(),
  mtcReturnable: z.boolean().optional(),

  mtoSellingPrice: z.number().optional(),

  jitFacilities: z.array(z.string()).optional(),
  discountGroupId: z.number().optional(),
});
// .strict();

export type ProductVariantDTOType = z.infer<typeof ProductVariantDTOSchema>;

// const AddressSchema = z.object({
//   pincode: z.string(),
//   cityCode: z.string(),
//   locality: z.string(),
//   stateCode: z.string().optional(),
//   addressLine: z.string(),
//   countryCode: z.string(),
//   cityDisplayName: z.string(),
//   stateDisplayName: z.string().optional(),
//   countryDisplayName: z.string(),
// });

export const VendorDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  name: z.string(),
  emails: z.string(),
  phones: z.string().optional(),
  vin: z.string(),
  vinType: z.string(),
  currency: z.string(),
  isDomestic: z.boolean(),
  platformMargin: z.number().optional(),
  status: z.string(),
  brands: z.array(
    z.object({
      name: z.string(),
      website: z.string(),
      logoAssetUrl: z.string(),
      websiteType: z.string().optional(),
      tags: z.array(z.unknown()),
    })
  ),
  brandManagerId: z.number(),
  billingAddress: AddressDTOSchema.optional(),
  gstAddress: AddressDTOSchema.optional(),
  jitOrderFulfillmentModel: z.string().optional(),
  mtoEnabled: z.boolean().optional(),
  mtcEnabled: z.boolean().optional(),
  jitEnabled: z.boolean().optional(),
  unicomVendorCode: z.string(),
  shortCode: z.string().optional(),
  jitTat: z.number().optional(),
  mtoTat: z.number().optional(),
  onlineMtoTat: z.number().optional(),
  mtcTat: z.number().optional(),
  onlineMtcTat: z.number().optional(),
  vendorBrandName: z.string(),
  salesDashboardEnabled: z.boolean(),
  purchaseModel: z.string(),
  mtoReturnable: z.boolean(),
  discountingModel: z.string(),
});

export type VendorDTOType = z.infer<typeof VendorDTOSchema>;

export const ProductDTOSchema = z.object({
  id: z.number(),
  name: z.string(),
  components: z.string().optional(),
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  vendorArticleNumber: z.string().optional(),
  brand: z.object({
    name: z.string(),
  }),
  vendorId: z.number(),
  variants: z.array(ProductVariantDTOSchema),
  options: z.array(ProductOptionDTOSchema),
  images: z.array(ProductImageDTOSchema).optional(),
  vendor: VendorDTOSchema,
  productTypeId: z.number(),
  productType: ProductTypeDTOSchema.optional(),
  customizationCategory: CustomizationCategorySchema.optional(),
  isSizeChartValid: z.boolean().optional(),
});

export type ProductDTOType = z.infer<typeof ProductDTOSchema>;

export const SizeChartDTOSchema = z.object({
  measurements: z.array(z.string()),
  sizes: z.array(z.string()),
  sizeData: z.array(
    z.object({
      size: z.string(),
      measurements: z.record(z.string(), z.string()),
    })
  ),
  sizeChartImageAssetUrl: z.string(),
});

export type SizeChartDTOType = z.infer<typeof SizeChartDTOSchema>;
