import { useIsErpUserSuperAdmin } from '@point-of-sale/store';
import WithMobileHeader from '../../../components/mobile/WithMobileHeader';
import SelectSalesChannel from '../../../components/shared/Settings/SelectSalesChannel';
import * as Styles from './styles';

const Settings = () => {
  const { isErpUserSuperAdmin } = useIsErpUserSuperAdmin();

  return <Styles.Wrapper>{isErpUserSuperAdmin && <SelectSalesChannel />}</Styles.Wrapper>;
};

export default WithMobileHeader(Settings, {
  name: 'Settings',
});
