import { useCallback, useState } from 'react';
import { SizeChartDTOType } from '@point-of-sale/schemas';
import { getSizeChart, useAppDispatch } from '@point-of-sale/store';

const useSizeChartData = (productId: number) => {
  const dispatch = useAppDispatch();
  const [sizeChartData, setSizeChartData] = useState<SizeChartDTOType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSizeChart = useCallback(async () => {
    if (sizeChartData) {
      return;
    }

    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      const sizeChart = await dispatch(getSizeChart(productId)).unwrap();
      setSizeChartData(sizeChart);
    } catch (err) {
      setError('Failed to load size chart');
      console.error('Size chart fetch error:', err);
    } finally {
      setIsLoading(false);
    }
  }, [sizeChartData, productId, dispatch, isLoading]);

  return { sizeChartData, isLoading, error, fetchSizeChart };
};

export default useSizeChartData;
