import { z } from 'zod';
import toast from 'react-hot-toast';
import { ApiResponseEnum, ObjectType } from '@point-of-sale/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, convertArrayToObject } from '@point-of-sale/utils';
import {
  setAddressesData,
  setBuyingWithoutInventoryAllowedForBarcode,
  setCartDetails,
  setCustomerDetails,
  setCustomerGstDetails,
  setFacets,
  setIsBarcodish,
  setPosPagination,
  setProducts,
  setSearch,
  setSelectedAddressId,
  setSelectedColor,
  setSelectedSize,
} from '../actions';
import {
  AddressDTOSchema,
  AddressDTOType,
  CartDTOSchema,
  CartDTOType,
  FacetsDTOSchema,
  GstDetailsDTOSchema,
  GstDetailsDTOType,
  OrderDTOSchema,
  OrderDTOType,
  ProductDTOSchema,
  SizeChartDTOSchema,
  SizeChartDTOType,
} from '@point-of-sale/schemas';
import { ThunkActionType } from '../../store';
import {
  addAddressApi,
  addInvoiceToOrderApi,
  addressSearchApi,
  addUserToCartApi,
  getGstDetailsApi,
  getSizeChartApi,
  productSearchApi,
  updateAddressApi,
  updateCartItemSkuApi,
} from '../api';
import {
  getInventoryStatus,
  getWarehouseAndStoreInventoryOfVariantByCurrentStore,
  isBarcodish,
} from '../utils';
import { IProductListItem, SearchProductsUsage } from '../types';
import { normalizeArray } from '../../utils/normalizeArray';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';
import { setProcessedOrder } from '../../checkout/actions';
import { assignCustomerDeliveryAddressToCart } from './cartRefreshThunks';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addItemToCart } from './cartThunks';

export const searchProducts =
  (usage: SearchProductsUsage = 'POS'): ThunkActionType =>
  async (dispatch, getState) => {
    const { search, filters } = getState().pointOfSale;
    const { page, size, shouldFetchMore } = getState().pointOfSale.pagination;

    if (!shouldFetchMore) {
      return;
    }

    dispatch(
      setProducts({
        isLoading: true,
      })
    );

    // * Set whether the search is barcodish
    dispatch(setIsBarcodish(false));

    const filterBuilder = new FilterBuilder();

    if (isBarcodish(search.query)) {
      filterBuilder.addFilter({
        field: 'variants.barcode',
        filterType: 'EQ',
        value: search.query,
      });

      // * Set whether the search is barcodish
      dispatch(setIsBarcodish(true));
    } else {
      dispatch(setBuyingWithoutInventoryAllowedForBarcode(''));

      if (search.query.length > 0) {
        filterBuilder.addFilter({
          field: 'query',
          filterType: 'QUERY',
          value: search.query,
        });
      }
    }

    const applicableFilters = [...filterBuilder.build(), ...Object.values(filters)];

    const newPage = page + 1;

    const promise = productSearchApi({
      filters: applicableFilters,
      page: newPage,
      size: size,
    });

    const responseSchema = z
      .object({
        results: z.array(ProductDTOSchema),
        facets: FacetsDTOSchema,
      })
      .strict();

    const response = await apiResponseHandler<z.infer<typeof responseSchema>>(
      promise,
      responseSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          error: response.meta?.message,
          isSuccess: false,
        })
      );
      return;
    }

    const shouldAppend = newPage !== 1;

    const normalized = normalizeArray(response.data.results);
    const rawIds = normalized.ids;
    const rawRecords = normalized.records;
    let records = {} as Record<string, IProductListItem>;
    let ids: Array<number> = [];

    // Add default selections here
    rawIds.forEach(id => {
      records[id] = {
        ...rawRecords[id],
        selectedColor: '',
        selectedSize: '',
      };
    });

    if (shouldAppend) {
      ids = [...getState().pointOfSale.products.data.ids, ...rawIds];
      records = {
        ...getState().pointOfSale.products.data.records,
        ...rawRecords,
      };
    } else {
      ids = rawIds;
      records = rawRecords as typeof records;
    }

    const filtersFromState = Object.values(filters);

    if (search.query.length !== 0 || filtersFromState.length !== 0) {
      dispatch(
        setProducts({
          isLoading: false,
          isSuccess: true,
          data: {
            ids,
            records,
          },
          timestamp: new Date().getTime(),
        })
      );

      if (rawIds.length === 0) {
        dispatch(setPosPagination({ page: newPage, shouldFetchMore: false }));
      } else {
        dispatch(setPosPagination({ page: newPage, shouldFetchMore: true }));
      }
    } else {
      dispatch(
        setProducts({
          isLoading: false,
          isSuccess: true,
          // TODO: do i really need to do this?
          data: {
            ids: [],
            records: {},
          },
        })
      );
    }

    dispatch(
      setFacets(
        response.data.facets.map(facet => ({
          ...facet,
          values: facet.values.sort((a, b) => a.count - b.count),
        }))
      )
    );

    dispatch(handleAddingToCartViaSearch(usage === 'EXPLORE_PRODUCTS'));
  };

export const handleAutomaticVariantSelection =
  (): ThunkActionType => async (dispatch, getState) => {
    const { ids, records } = getState().pointOfSale.products.data;

    ids.forEach(id => {
      const product = records[id];

      const sizeOptions = product.options.find(o => o.name === 'SIZE')?.optionsValues;

      const colorOptions = product.options.find(o => o.name === 'COLOR')?.optionsValues;

      // * Select Color if only one color option is available
      if (colorOptions?.length === 1) {
        dispatch(setSelectedColor({ id, value: colorOptions[0].displayName }));
      }

      // * Select Size if only one size option is available, maybe
      if (sizeOptions?.length === 1) {
        // dispatch(setSelectedSize({ id, value: product.options[0].displayName }));
      }
    });
  };

export const handleAddingToCartViaSearch =
  (returnAfterSelectingVariant: boolean): ThunkActionType =>
  async (dispatch, getState) => {
    const mode = getState().pointOfSale.mode;
    const searchQuery = getState().pointOfSale.search.query;
    const { ids, records } = getState().pointOfSale.products.data;

    dispatch(handleAutomaticVariantSelection());

    // * check for barcodish search results here:
    if (isBarcodish(searchQuery)) {
      // * Choose variant in the state
      ids.forEach(id => {
        const variant = records[id].variants.find(v => v.barcode === searchQuery);

        if (variant) {
          // TODO: separate out variant selection logic from this
          // it might cause bugs
          dispatch(setSelectedColor({ id, value: variant.color }));
          dispatch(setSelectedSize({ id, value: variant.size }));

          const { hasInventoryInCurrentStoreOrWarehouse } = getInventoryStatus(variant);

          // * Do not add to cart if no inventory in current store or warehouse
          if (!hasInventoryInCurrentStoreOrWarehouse) {
            dispatch(setBuyingWithoutInventoryAllowedForBarcode(searchQuery));
            return;
          } else {
            dispatch(setBuyingWithoutInventoryAllowedForBarcode(''));
          }

          dispatch(setBuyingWithoutInventoryAllowedForBarcode(''));

          if (returnAfterSelectingVariant) {
            return;
          }

          const selectedSalesChannelId = getState().identity.selectedSalesChannel
            ?.facilityId as number;

          const {
            storeInventory,
            storeFacilityId,
            combinedWarehouseInventory,
            warehouseFacilityIds,
          } = getWarehouseAndStoreInventoryOfVariantByCurrentStore(variant);

          let determinedFulfillmentFacilityId;

          if (storeInventory > 0) {
            determinedFulfillmentFacilityId = storeFacilityId;
          } else if (combinedWarehouseInventory > 0 && warehouseFacilityIds.length > 0) {
            determinedFulfillmentFacilityId = warehouseFacilityIds[0];
          }

          const fulfillmentFacilityId = determinedFulfillmentFacilityId ?? selectedSalesChannelId;
          const fulfillmentFacility =
            getState().common.facilities.data.records[fulfillmentFacilityId];

          // * fulfillmentFacilityId should be facility Id or warehouse id

          if (mode === 'BUY_NOW') {
            dispatch(
              addItemToCart(
                {
                  skuId: variant.skuId,
                  skuCode: variant.skuCode,
                  fulfillmentFacilityId: fulfillmentFacilityId,
                  quantity: 1,
                  deliveryMode: 'STORE_PURCHASE',
                  fulfillmentMode: 'ONHAND',
                  fulfillmentFacilityGroupId: fulfillmentFacility.facilityGroupId,
                  fulfillmentFacilityType: fulfillmentFacility.facilityType,
                },
                () => {
                  dispatch(
                    setSearch({
                      query: '',
                    })
                  );
                }
              )
            );
          }
        }
      });
    }
  };

export const addCustomerToCart =
  (successCallback: (cartId: string) => void): ThunkActionType =>
  async (dispatch, getState) => {
    const customer = getState().pointOfSale.customer.data;
    const cartId = getState().pointOfSale.cart.data.id;

    if (!customer.id || !cartId || cartId === -1) {
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const promise = addUserToCartApi({
      cartId: String(cartId),
      userId: customer.id,
      customerBillingName: customer.name ?? '',
      customerPhone: customer.phone ?? '',
    });

    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback(String(response.data.id));
  };

export const addressSearchAndUpdateCart =
  (customerId: number): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setAddressesData({
        isLoading: true,
      })
    );

    const promise = addressSearchApi(customerId);
    const response = await apiResponseHandler<Array<AddressDTOType>>(
      promise,
      z.array(AddressDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setAddressesData({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setAddressesData({
        isLoading: false,
        isSuccess: true,
        data: normalizeArray(response.data),
      })
    );

    const selectedAddressId = getState().pointOfSale.selectedAddressId;

    const defaultAddress = response.data.find(address => address.isDefault);
    if (defaultAddress && defaultAddress.id && selectedAddressId === -1) {
      dispatch(setSelectedAddressId(defaultAddress.id));
      dispatch(assignCustomerDeliveryAddressToCart(defaultAddress.id));
    }
  };

export const addAddressToCustomer =
  (
    customerId: number,
    data: Partial<AddressDTOType>,
    successCallback: (addressId: number) => void,
    { shouldMakeDeliveryAddress: shouldMakeDeliveryAddress = false } = {}
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setAddressesData({
        isLoading: true,
      })
    );

    const promise = addAddressApi({ customerId, ...data });

    const response = await apiResponseHandler<AddressDTOType>(promise, AddressDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setAddressesData({
          isLoading: false,
          isError: true,
        })
      );
      dispatch(
        setCustomerDetails({
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data.id) {
      const existingAddresses = getState().pointOfSale.addresses.data;
      dispatch(
        setAddressesData({
          isLoading: false,
          isError: false,
          data: {
            records: {
              ...existingAddresses.records,
              [response.data.id]: response.data,
            },
            ids: [...existingAddresses.ids, response.data.id],
          },
        })
      );
    }

    // if (shouldMakeDeliveryAddress && response.data.id) {
    //   dispatch(assignCustomerDeliveryAddressToCart(response.data.id as number));
    // }

    dispatch(
      setAddressesData({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback(response.data.id as number);
  };

export const updateAddressOfCustomer =
  (
    addressId: number,
    address: Partial<AddressDTOType>,
    successCallback: VoidFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setAddressesData({
        isLoading: true,
      })
    );

    const promise = updateAddressApi(addressId, address);

    const response = await apiResponseHandler<AddressDTOType>(promise, AddressDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setAddressesData({
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data.id) {
      const existingAddresses = getState().pointOfSale.addresses.data;

      dispatch(
        setAddressesData({
          data: {
            records: {
              ...existingAddresses.records,
              [response.data.id]: response.data,
            },
            ids: existingAddresses.ids,
          },
        })
      );
    }

    dispatch(
      setAddressesData({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback();
  };

export const getProductBySkuCode =
  ({ skuCode }: { skuCode: string }): ThunkActionType =>
  async dispatch => {
    dispatch(
      setProducts({
        isLoading: true,
      })
    );

    dispatch(setIsBarcodish(false));

    const filterBuilder = new FilterBuilder();

    filterBuilder.addFilter({
      field: 'variants.skuCode',
      filterType: 'EQ',
      value: skuCode,
    });

    const promise = productSearchApi({
      filters: filterBuilder.build(),
      page: 1,
      size: 10,
    });

    const responseSchema = z
      .object({
        results: z.array(ProductDTOSchema),
        facets: FacetsDTOSchema,
      })
      .strict();

    const response = await apiResponseHandler<z.infer<typeof responseSchema>>(
      promise,
      responseSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');

      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          error: response.meta?.message,
          isSuccess: false,
        })
      );
      return;
    }

    if (response.data.results.length !== 1) {
      // TODO: Add sentry
      toast.error('Multiple products found with same SKU code');
      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          isSuccess: false,
        })
      );
      return;
    }

    const product = response.data.results[0];
    const variant = product.variants.find(v => v.skuCode === skuCode);

    if (!variant) {
      toast.error('Cannot find product');
      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          isSuccess: false,
        })
      );
      return;
    }

    // Modularize this , repetitive code
    const ids = response.data.results.map(product => product.id);
    const rawRecords = convertArrayToObject(response.data.results, 'id');
    const records = {} as Record<string, IProductListItem>;

    ids.forEach(id => {
      records[id] = {
        ...rawRecords[id],
        selectedColor: variant.color,
        selectedSize: variant.size,
      };
    });

    dispatch(
      setProducts({
        isLoading: false,
        isSuccess: true,
        data: {
          ids,
          records,
        },
      })
    );
  };

/**
 *
 *  Can update the SKU of a cart item
 * things like color, size, skuCode, quantity etc.
 */
export const updateCartItemStockKeepingUnit =
  (
    data: {
      skuCode: string;
      quantity: number;
      fulfillmentMode: string;
      deliveryMode: string;
      fulfillmentFacilityId: number | null;
      customerDeliveryStoreId: number | undefined;
    },
    turnOffLoader: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const promise = updateCartItemSkuApi({
      cartId: getState().pointOfSale.cart.data.id as number,
      // cartItemId: getState().pointOfSale.cartItemEditCache.id,
      cartItemId: -1,
      data,
    });
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      turnOffLoader();
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
    turnOffLoader();
  };

export const addInvoiceToOrder =
  (
    orderId: number,
    asset: {
      id: number;
      url: string;
    },
    callback: CallableFunction
  ): ThunkActionType =>
  async dispatch => {
    const promise = addInvoiceToOrderApi({
      orderId,
      asset,
    });
    const response = await apiResponseHandler<OrderDTOType>(promise, OrderDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );

      return;
    }

    dispatch(setProcessedOrder({ data: response.data }));

    callback();
  };

export const getGstDetails =
  (
    gstNumber: string,
    cartUpdater?: (gstNumber: string, isVerified: boolean, gstObject?: ObjectType) => void
  ): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCustomerGstDetails({
        isLoading: true,
      })
    );

    const promise = getGstDetailsApi(gstNumber);
    const response = await apiResponseHandler<GstDetailsDTOType>(promise, GstDetailsDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerGstDetails({
          isLoading: false,
          isError: true,
        })
      );
      cartUpdater?.(gstNumber, false);
      return;
    }

    dispatch(
      setCustomerGstDetails({
        isLoading: false,
        data: response.data,
        isError: false,
      })
    );

    if (response.data.gstin) {
      cartUpdater?.(response.data.gstin, true, response.data);

      // create new address,
    } else {
      cartUpdater?.(gstNumber, false);
      dispatch(
        setCustomerGstDetails({
          isError: true,
          data: {
            ...response.data,
            gstin: gstNumber,
          },
        })
      );
    }
  };

export const getSizeChart = createAsyncThunk<
  SizeChartDTOType,
  number,
  {
    rejectValue: { message: string };
  }
>('pointOfSale/getSizeChart', async (productId, { rejectWithValue }) => {
  const promise = getSizeChartApi(productId);
  const response = await apiResponseHandler<SizeChartDTOType>(promise, SizeChartDTOSchema);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    return rejectWithValue({ message: response.meta?.message ?? 'Something went wrong' });
  }

  return response.data;
});
