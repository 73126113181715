import { Sheet } from 'react-modal-sheet';
import { emptyFunction } from '@point-of-sale/utils';
import { useProductListItem } from '../context';
import InventoryTable from '../../../../shared/InventoryTable';
import CopyableSkuInfo from '../../../../shared/CopyableSkuInfo';
import * as Styles from './styles';

interface IInventoryBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const InventoryBottomSheet = ({ isOpen, onClose }: IInventoryBottomSheetProps) => {
  const { selectedVariant } = useProductListItem();

  return (
    <Sheet rootId="root" snapPoints={[0.5]} isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Styles.Wrapper>
            <CopyableSkuInfo selectedVariant={selectedVariant} orientation="vertical" />
            <InventoryTable inventory={selectedVariant?.facilityInventory} />
          </Styles.Wrapper>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={emptyFunction} />
    </Sheet>
  );
};

export default InventoryBottomSheet;
