import { useCallback } from 'react';
import { IOption } from '@point-of-sale/components';
import {
  deleteOMSFilter,
  getLineItems,
  OmsFilterNames,
  setLineItems,
  setOrdersSearch,
  updateOMSCreatedOnFilters,
  updateOMSFilters,
  useAppDispatch,
  setLineItemsPagination,
} from '@point-of-sale/store';
import { IDateRange } from '@point-of-sale/types';

export const useLineItemsDiscovery = () => {
  const dispatch = useAppDispatch();

  const onChangeFilter = useCallback(
    (field: OmsFilterNames, options: Array<IOption> | IDateRange) => {
      dispatch(
        setLineItemsPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );

      if (field === 'createdOn') {
        const selectedRange = options as IDateRange;
        dispatch(updateOMSCreatedOnFilters(selectedRange));
        return;
      }

      if (field === 'status') {
        const isExclusiveFilter =
          Array.isArray(options) && options.some(o => o.label === 'Pending');

        dispatch(
          updateOMSFilters({
            filter: {
              field,
              value: (options as Array<IOption>).map(option => option.value).join(','),
              filterType: isExclusiveFilter ? 'NOTIN' : 'IN',
            },
            name: field,
          })
        );
        return;
      }

      dispatch(
        updateOMSFilters({
          filter: {
            field,
            value: (options as Array<IOption>).map(option => option.value).join(','),
            filterType: field === 'salesChannelId' ? 'EQ' : 'IN',
          },
          name: field,
        })
      );
    },
    [dispatch]
  );

  const onChangeSearch = useCallback(
    (searchQuery: string) => {
      dispatch(
        setLineItemsPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );

      dispatch(
        setLineItems({
          data: {
            ids: [],
            records: {},
          },
        })
      );

      dispatch(
        setOrdersSearch({
          query: searchQuery,
        })
      );

      dispatch(getLineItems());
    },
    [dispatch]
  );

  const onRemoveFilter = useCallback(
    (filterName: OmsFilterNames) => {
      dispatch(deleteOMSFilter(filterName as OmsFilterNames));
    },
    [dispatch]
  );

  return { onChangeFilter, onChangeSearch, onRemoveFilter };
};
