import { useMotionValue, animate } from 'motion/react';
import { useVirtualKeyboard } from './useVirtualKeyboard';
import { useEffect } from 'react';

export function useAnimatedVirtualKeyboard() {
  const { isKeyboardOpen, keyboardHeight } = useVirtualKeyboard();
  const animatedKeyboardHeight = useMotionValue(keyboardHeight);

  useEffect(() => {
    if (isKeyboardOpen) {
      animate(animatedKeyboardHeight, keyboardHeight);
    } else {
      animate(animatedKeyboardHeight, 0);
    }
  }, [isKeyboardOpen, keyboardHeight]);

  return { keyboardHeight: animatedKeyboardHeight, isKeyboardOpen };
}
