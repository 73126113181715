import styled from 'styled-components';

export const Wrapper = styled.div`
  /* border: 1px solid yellowgreen; */
`;

export const Profile = styled.div`
  display: flex;
  gap: 12px;
`;

export const ProfileImage = styled.div`
  border: 1px solid var(--athens-gray);
  height: 85px;
  width: 85px;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;
`;

export const Roles = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  align-items: flex-end;
`;
