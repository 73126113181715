import { createDescribedState } from '../utils/createDescribedState';
import { CommonActionsTypes } from './constants';
import { CommonActionsType, ICommonState } from './types';

const INITIAL_STATE: ICommonState = {
  storeStaff: createDescribedState({
    ids: [],
    records: {},
  }),
  erpUsers: createDescribedState({
    ids: [],
    records: {},
  }),
  salesChannels: createDescribedState({
    ids: [],
    records: {},
  }),

  facilities: createDescribedState({
    ids: [],
    records: {},
  }),
  vendors: createDescribedState({
    ids: [],
    records: {},
  }),
  analyticsDashboard: createDescribedState({
    url: '',
  }),
} as const;

export const commonReducer = (state = INITIAL_STATE, action: CommonActionsType): ICommonState => {
  switch (action.type) {
    case CommonActionsTypes.SET_STORE_STAFF: {
      return {
        ...state,
        storeStaff: {
          ...state.storeStaff,
          ...action.payload,
        },
      };
    }

    case CommonActionsTypes.SET_SALES_CHANNELS: {
      return {
        ...state,
        salesChannels: {
          ...state.salesChannels,
          ...action.payload,
        },
      };
    }
    case CommonActionsTypes.SET_FACILITIES: {
      return {
        ...state,
        facilities: {
          ...state.facilities,
          ...action.payload,
        },
      };
    }

    case CommonActionsTypes.SET_VENDORS: {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          ...action.payload,
        },
      };
    }

    case CommonActionsTypes.SET_ERP_USERS: {
      const { payload, operation } = action;

      if (operation === 'APPEND' && payload.data) {
        return {
          ...state,
          erpUsers: {
            ...state.erpUsers,
            ...payload,
            data: {
              ids: [...new Set([...state.erpUsers.data.ids, ...payload.data.ids])],
              records: {
                ...state.erpUsers.data.records,
                ...payload.data.records,
              },
            },
          },
        };
      }

      return {
        ...state,
        erpUsers: {
          ...state.erpUsers,
          ...action.payload,
        },
      };
    }

    case CommonActionsTypes.SET_ANALYTICS_DASHBOARD: {
      return {
        ...state,
        analyticsDashboard: {
          ...state.analyticsDashboard,
          ...action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};
