export const CURRENCY_SYMBOLS = {
  INR: {
    symbol: '₹',
    code: 'Rs.',
  },
  USD: {
    symbol: '$',
    code: '$',
  },
  EUR: {
    symbol: '€',
    code: '€',
  },
  GBP: {
    symbol: '£',
    code: '£',
  },
  JPY: {
    symbol: '¥',
    code: '¥',
  },
  AED: {
    symbol: 'د.إ',
    code: 'د.إ',
  },
} as const;

export type CurrencyType = keyof typeof CURRENCY_SYMBOLS;
