import { useMemo } from 'react';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';
import { useAppSelector } from '@point-of-sale/store';
import { differenceInDays, startOfDay } from 'date-fns';

interface IUseCartItemLogisticsProps {
  itemId: number;
  serviceabilityData: INormalizedServiceabilityData[string];
}

const useCartItemLogistics = ({ itemId, serviceabilityData }: IUseCartItemLogisticsProps) => {
  const cartItem = useAppSelector(store => store.pointOfSale.cart.data?.cartItems.records[itemId]);

  const facilities = useAppSelector(store => store.common.facilities.data);
  const addresses = useAppSelector(store => store.pointOfSale.addresses.data);

  const shouldShowBrandTat = useMemo(() => {
    if (cartItem.fulfillmentMode === 'ONHAND') {
      return false;
    }

    return true;
  }, [cartItem.fulfillmentMode]);

  const possibleServiceability = useMemo(() => {
    if (!serviceabilityData) {
      return null;
    }

    let maybeServiceability;

    // * FROM - a Lyskraft facility
    if (cartItem.fulfillmentMode === 'ONHAND') {
      maybeServiceability = serviceabilityData.filter(
        item => item.from.facilityId === String(cartItem.fulfillmentFacilityId)
      );

      // * TO - another Lyskraft facility
      if (cartItem.deliveryMode === 'STORE_PICKUP' && cartItem.customerDeliveryStoreId) {
        const deliveryStoreFacility = facilities.records[cartItem.customerDeliveryStoreId];

        maybeServiceability = maybeServiceability?.filter(
          item => item.to === deliveryStoreFacility.address?.pincode
        );
      }

      // * TO - customer's address
      if (cartItem.deliveryMode === 'HOME_DELIVERY' && cartItem.customerDeliveryAddressId) {
        const deliveryAddress = addresses.records[cartItem.customerDeliveryAddressId];

        maybeServiceability = maybeServiceability?.filter(
          item => item.to === deliveryAddress?.pincode
        );
      }
    }

    // * FROM - the brand's location, not from a Lyskraft facility
    if (cartItem.fulfillmentMode === 'MTC' || cartItem.fulfillmentMode === 'MTO') {
      // * TO - another Lyskraft facility
      if (cartItem.deliveryMode === 'STORE_PICKUP' && cartItem.customerDeliveryStoreId) {
        const deliveryAddress = facilities.records[cartItem.customerDeliveryStoreId];

        maybeServiceability = serviceabilityData.filter(
          item => item.to === deliveryAddress.address?.pincode
        );
      }

      // * TO - customer's address
      if (cartItem.deliveryMode === 'HOME_DELIVERY' && cartItem.customerDeliveryAddressId) {
        const deliveryAddress = addresses.records[cartItem.customerDeliveryAddressId];

        maybeServiceability = serviceabilityData.filter(
          item => item.to === deliveryAddress?.pincode
        );
      }
    }

    // * FROM - the brand's location, not from a Lyskraft facility
    if (cartItem.fulfillmentMode === 'JIT') {
      const filteredServiceability = serviceabilityData.filter(
        item => item.from.facilityId === String(cartItem.fulfillmentFacilityId)
      );

      // * TO - another Lyskraft facility
      if (cartItem.deliveryMode === 'STORE_PICKUP' && cartItem.customerDeliveryStoreId) {
        const deliveryAddress = facilities.records[cartItem.customerDeliveryStoreId];

        maybeServiceability = filteredServiceability.filter(
          item => item.to === deliveryAddress.address?.pincode
        );
      }

      // * TO - customer's address
      if (cartItem.deliveryMode === 'HOME_DELIVERY' && cartItem.customerDeliveryAddressId) {
        const deliveryAddress = addresses.records[cartItem.customerDeliveryAddressId];

        maybeServiceability = filteredServiceability.filter(
          item => item.to === deliveryAddress?.pincode
        );
      }
    }

    return maybeServiceability;
  }, [cartItem, serviceabilityData, facilities, addresses]);

  const [particularServiceability] = possibleServiceability ?? [];

  const brandTatInDays = useMemo(() => {
    if (cartItem.vendorDeliveryDate) {
      return differenceInDays(
        startOfDay(new Date(cartItem.vendorDeliveryDate)),
        startOfDay(new Date())
      );
    }

    if (cartItem.fulfillmentMode === 'MTC') {
      return particularServiceability?.info.mtcTat;
    }

    if (cartItem.fulfillmentMode === 'MTO') {
      return particularServiceability?.info.mtoTat;
    }

    return null;
  }, [cartItem, particularServiceability]);

  return {
    shouldShowBrandTat,
    particularServiceability,
    brandTatInDays,
  };
};

export default useCartItemLogistics;
