import SelectSalesChannel from '../../../../../components/shared/Settings/SelectSalesChannel';
import * as Styles from './styles';

const SalesChannel = () => {
  return (
    <Styles.Wrapper>
      <SelectSalesChannel />
    </Styles.Wrapper>
  );
};

export default SalesChannel;
