/**
 * Key must only be in kebab-case format
 * so that the object's keys can be used as CSS variables
 */
export const PALETTE = {
  '--white': '#FFFFFF',
  '--black': '#000000',
  '--big-stone': '#192B47',
  '--spring-wood': '#F8F5EF',
  '--swiss-coffee': '#E2DEDC',
  '--brown-pod': '#402002',
  '--seashell-peach': '#FFF3EA',

  '--romantic': '#FFD4B4',

  '--alto': '#D4D4D4',
  '--titan-white': '#FBFBFF',

  '--island-spice': '#FFFBED',

  '--platinum': '#E3E3E3',
  '--mine-shaft': '#262626',
  '--tulip-tree': '#E9A13B',

  // * Gray

  '--athens-gray': '#EDEEF2',
  '--silver': '#CCCCCC',
  '--silver-chalice': '#A3A3A3',
  '--alabaster': '#FAFAFA',
  '--gallery': '#EAEAEA',
  '--dusty-gray': '#979797',
  '--wild-sand': '#F5F5F5',

  '--soapstone': '#FCFCFC',
  '--cod-gray': '#171717',
  '--dove-gray': '#737373',
  '--ghost-white': '#F8F9FA',
  '--porcelain': '#FBFCFC',
  '--oslo-gray': '#868E96',

  // * Red
  '--chablis': '#FFF1F1',
  '--cosmos': '#FFD7D9',
  '--sundown': '#FFB3B8',
  '--geraldine': '#FF8389',
  '--carnation': '#FA4D56',
  '--persian-red': '#C93532',
  '--alizarin-crimson': '#DA1E28',

  '--flamingo': '#F03E3E',

  // * Orange
  '--web-orange': '#FFA500',

  // * Purple

  '--purple-lilac': '#EEE4F8',
  '--white-lilac': '#F3EEF8',

  '--selago': '#F4EBFD',
  '--purple': '#660EB9',
  '--purple-heart': '#821AC8',
  '--moon-raker': '#D6B5F0',

  '--bossanova': '#503664',
  '--haiti': '#26103B',

  '--chinese-purple': '#710B94',

  // * Blue
  '--zircon': '#F9FAFF',
  '--water': '#F1F3FF',
  '--link-water': '#e6f1fa',
  '--royal-blue': '#393AFB',
  '--zumthor': '#EEF7FF',
  '--alice-blue': '#F3F4FF',
  '--blue-ribbon': '#2A46FF',
  '--lavender-mist': '#E7E9FF',

  // * Green

  '--lunar-green': '#304130',
  '--silver-tree': '#55B685',
  '--hippie-green': '#4D814F',
  '--chateau-green': '#40C057',
  '--granny-apple': '#EBFBEE',
} as const;

export type ColorsType = keyof typeof PALETTE;
