import { useMemo } from 'react';
import { useAppSelector } from '@point-of-sale/store';
import { DeliveryModeSchema, FulfillmentModeSchema } from '@point-of-sale/schemas';
import { removeUnderscoresAndCapitalize } from '@point-of-sale/utils';
import { IDiscoveryFilter } from '../components/desktop/Discovery';

export const LINE_ITEM_STATUS_FILTERS = [
  {
    // * Filter Type: NOTIN
    label: 'Pending',
    value: 'RETURN_WIP,RETURNED,RTO,RTO_INITIATED,DELIVERED,CANCELLED',
  },
  {
    // * Filter Type: IN
    label: 'Delivered',
    value: 'DELIVERED',
  },
  {
    // * Filter Type: IN
    label: 'Returned',
    value: 'RETURN_WIP,RETURNED,RTO,RTO_INITIATED',
  },
  {
    // * Filter Type: IN
    label: 'Cancelled',
    value: 'CANCELLED',
  },
];

interface IUseLineItemsDiscoveryFiltersProps {
  usage?: 'DEFAULT' | 'CANCELLED_ORDERS';
}

export const useLineItemsDiscoveryFilters = ({
  usage = 'DEFAULT',
}: IUseLineItemsDiscoveryFiltersProps) => {
  const createdOn = useAppSelector(store => store.oms.createdOn);
  const filters = useAppSelector(store => store.oms.filters);
  const salesChannels = useAppSelector(store => store.common.salesChannels);
  const storeStaff = useAppSelector(store => store.common.storeStaff.data);

  const discoveryFilters: Array<IDiscoveryFilter> = useMemo(() => {
    const basicFilters = [
      {
        name: 'salesChannelId',
        placeholder: 'Sales Channel',
        options: salesChannels.data.ids.map(id => ({
          label: salesChannels.data.records[id].name,
          value: id ?? '',
        })),
        values: filters.salesChannelId
          ? String(filters.salesChannelId?.value).split(',').map(Number) ?? []
          : [],
        type: 'MULTISELECT',
      },
      {
        name: 'salesStaffId',
        placeholder: 'Sales POC',
        options: storeStaff.ids.map(id => ({
          label: storeStaff.records[id].name,
          value: id,
        })),
        values: filters.salesStaffId
          ? String(filters.salesStaffId?.value).split(',').map(Number) ?? []
          : [],
        type: 'MULTISELECT',
      },
      {
        name: 'deliveryMode',
        placeholder: 'Delivery Mode',
        options: Object.values(DeliveryModeSchema.Enum).map(value => ({
          label: removeUnderscoresAndCapitalize(value),
          value,
        })),
        values: filters.deliveryMode ? String(filters.deliveryMode?.value).split(',') ?? [] : [],
        type: 'MULTISELECT',
      },
      {
        name: 'fulfillmentMode',
        placeholder: 'Fulfillment Mode',
        options: Object.values(FulfillmentModeSchema.Enum).map(value => ({
          label: value,
          value,
        })),
        values: filters.fulfillmentMode
          ? String(filters.fulfillmentMode?.value).split(',') ?? []
          : [],
        type: 'MULTISELECT',
      },
      {
        name: 'createdOn',
        placeholder: 'Date',
        options: [],
        values: {
          from: createdOn.from,
          to: createdOn.to,
        },
        type: 'DATE_RANGE',
      },
      {
        name: 'status',
        placeholder: 'Status',
        options: LINE_ITEM_STATUS_FILTERS,
        values: filters.status ? [String(filters.status?.value)] : [],
        type: 'SINGLE_SELECT',
      },
    ] satisfies Array<IDiscoveryFilter>;

    if (usage === 'CANCELLED_ORDERS') {
      return basicFilters.filter(filter => filter.name !== 'status');
    }

    return basicFilters;
  }, [
    salesChannels.data.ids,
    salesChannels.data.records,
    filters,
    storeStaff.ids,
    storeStaff.records,
    createdOn.from,
    createdOn.to,
    usage,
  ]);

  return { discoveryFilters };
};
