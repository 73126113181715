export const SkuMovementActionsTypes = {
  SET_SEARCH: 'sku-movement/SET_SEARCH',
  SET_STOCK_KEEPING_UNITS: 'sku-movement/SET_STOCK_KEEPING_UNITS',

  ADD_STOCK_KEEPING_UNIT: 'sku-movement/ADD_STOCK_KEEPING_UNIT',
  REMOVE_STOCK_KEEPING_UNIT: 'sku-movement/REMOVE_STOCK_KEEPING_UNIT',

  ADD_RECENTLY_ADDED_ID: 'sku-movement/ADD_RECENTLY_ADDED_ID',
  // RESE_RECENTLY_ADDED_ID: 'sku-movement/REMOVE_RECENTLY_ADDED_ID',

  SET_PAGINATION: 'sku-movement/SET_PAGINATION',

  RESET_STATE: 'sku-movement/RESET_STATE',
} as const;
