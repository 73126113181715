import { Button, Modal, Text } from '@point-of-sale/components';
import { ENV } from '@point-of-sale/env';
import { setIsReleasePromptModalOpen, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  gap: 24px;
`;

const Content = styled.div`
  padding: 24px;
`;

const ReleasePromptModal = () => {
  const isReleasePromptOpen = useAppSelector(store => store.firebase.isReleasePromptOpen);

  const dispatch = useAppDispatch();

  if (ENV.VITE_ENVIRONMENT === 'local') {
    return null;
  }

  return (
    <Modal
      heading={`New version available`}
      size="small"
      isOpen={isReleasePromptOpen}
      footer={
        <Footer>
          <Button
            isFullWidth
            variant="outline"
            onClick={() => {
              dispatch(setIsReleasePromptModalOpen(false));
            }}
          >
            Ignore
          </Button>
          <Button
            isFullWidth
            onClick={() => {
              dispatch(setIsReleasePromptModalOpen(false));
              window.location.reload();
            }}
          >
            Update Now
          </Button>
        </Footer>
      }
    >
      <Content>
        <Text>
          You can choose to update the Runway app now by clicking the "Update Now" button. If you
          choose to ignore this update, the app will get updated when you close this tab and open it
          again.
        </Text>
      </Content>
    </Modal>
  );
};

export default ReleasePromptModal;
