import { useEffect } from 'react';
import {
  resetPointOfSaleState,
  resetSkuMovementsState,
  useAppDispatch,
} from '@point-of-sale/store';
import WithMobileHeader from '../../../components/mobile/WithMobileHeader';
import CreateSkuMovement from './CreateSkuMovement';
import SkuMovementList from './SkuMovementList';
import * as Styles from './styles';

const SkuMovement = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSkuMovementsState());
      dispatch(resetPointOfSaleState());
    };
  }, []);

  return (
    <Styles.Wrapper>
      <CreateSkuMovement />
      <SkuMovementList />
    </Styles.Wrapper>
  );
};

export default WithMobileHeader(SkuMovement, {
  name: 'Trials',
});
