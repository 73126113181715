import styled from 'styled-components';

export const Wrapper = styled.div`
  .rdp-root {
    --rdp-accent-color: var(--royal-blue);
    --rdp-day-width: 34px;
    --rdp-day-height: 34px;
    --rdp-day_button-width: 32px;
    --rdp-day_button-height: 32px;
  }

  .rdp-month {
    border: 1px solid var(--alto);

    .rdp-month_caption {
      padding: 12px;
    }

    .rdp-day {
      //
    }
  }
`;
