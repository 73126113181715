import styled, { css } from 'styled-components';
import { CheckBox, Text } from '@point-of-sale/components';

const Wrapper = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

interface IShippingPartnerProps {
  id: string;
  name: string;
  tat: number;
  isChecked: boolean;
  onSelect: (id: string) => void;
  isDisabled?: boolean;
}

const ShippingPartner = ({
  id,
  name,
  tat,
  isChecked,
  isDisabled,
  onSelect,
}: IShippingPartnerProps) => {
  return (
    <CheckBox
      name={name}
      isChecked={isChecked}
      isDisabled={isDisabled}
      onChange={() => {
        onSelect(id);
      }}
      label={
        <Wrapper $isDisabled={isDisabled}>
          <Text
            fontSize={14}
            weight="semibold"
            color={isDisabled ? 'var(--dove-gray)' : 'var(--cod-gray)'}
          >
            {name}
          </Text>
          {typeof tat === 'number' && tat > 0 && (
            <Text fontSize={12} color={isDisabled ? 'var(--oslo-gray)' : 'var(--dove-gray)'}>
              Est Delivery: {tat} days
            </Text>
          )}
        </Wrapper>
      }
    />
  );
};

export default ShippingPartner;
