import { InputHTMLAttributes, useRef } from 'react';
import { SearchIcon } from '../icons';
import * as Styles from './styles';

const SearchBar = ({
  value = '',
  type = 'search',
  className,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Styles.Wrapper className={className}>
      <SearchIcon onClick={() => inputRef?.current?.focus()} />
      <Styles.Input autoFocus ref={inputRef} type="search" {...props} value={value} />
      {value?.toString().length > 0 && (
        <Styles.CloseButton
          icon="CrossIcon"
          sizeVariant="medium"
          shapeVariant="circle"
          onClick={() => {
            props.onChange?.({ target: { value: '', type: 'clear' } } as any);
          }}
        />
      )}
    </Styles.Wrapper>
  );
};

export default SearchBar;
