import { callNotificationsApi } from '../services/callnotificationApi';
import { callApi } from '../services/callApi';
import { IMarkReadSeenProps } from './types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';

export function putNotificationMarkReadApi(data: IMarkReadSeenProps) {
  return callNotificationsApi('put', '/notifications/mark-read/', data);
}

export function putNotificationMarkSeenApi(data: IMarkReadSeenProps) {
  return callNotificationsApi('put', '/notifications/mark-seen/', data);
}

export function getNotificationCountApi() {
  return callNotificationsApi('get', '/notifications/unread-count/');
}

export const getAllNotificationsApi = (pageNumber: number) =>
  callNotificationsApi('get', `/notifications/list/?page=${pageNumber}`);

// * Bhaskar's APIs

export const upsertNotificationTokenApi = ({
  platform,
  token,
}: {
  platform: 'FIREBASE';
  token: string;
}) =>
  callApi('post', '/pos/notification-token/upsert', {
    platform,
    token,
  });

export const searchNotificationTokenApi = (filters: Array<IFilter> = []) =>
  callApi('post', '/pos/notification-token/search', {
    filters: [
      {
        filterType: 'EQ',
        field: 'platform',
        value: 'FIREBASE',
      },
      ...filters,
    ],
  });
