import { z } from 'zod';
import type {
  SalesChannelDTOType,
  StoreStaffDTOType,
  FacilityDTOType,
  VendorDTOType,
} from '@point-of-sale/schemas';
import {
  SalesChannelDTOSchema,
  StoreStaffDTOSchema,
  FacilityDTOSchema,
  VendorDTOSchema,
} from '@point-of-sale/schemas';
import {
  setStoreStaff,
  setSalesChannels,
  setFacilities,
  setAnalyticsDashboard,
  setVendors,
  setErpUsers,
} from './actions';
import { createListFetchingThunk } from '../utils/createListFetchingThunk';
import {
  getSalesChannelsApi,
  getFacilitiesApi,
  getStoreStaffApi,
  getAnalyticsDashboardUrlApi,
  getVendorsApi,
  findBulkErpUsersApi,
} from './api';
import { RootStateType, ThunkActionType } from '../store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, convertArrayToObject } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import toast from 'react-hot-toast';
import { ENV } from '@point-of-sale/env';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getStoreStaff = () =>
  createListFetchingThunk<StoreStaffDTOType>({
    dispatchable: setStoreStaff,
    dataFetcher: getStoreStaffApi,
    zodSchema: z.array(StoreStaffDTOSchema),
  });

export const getSalesChannels = () =>
  createListFetchingThunk<SalesChannelDTOType>({
    dispatchable: setSalesChannels,
    dataFetcher: getSalesChannelsApi,
    zodSchema: z.array(SalesChannelDTOSchema),
  });

export const getVendors = () =>
  createListFetchingThunk<VendorDTOType>({
    dispatchable: setVendors,
    dataFetcher: getVendorsApi,
    zodSchema: z.array(VendorDTOSchema),
  });

export const getAnalyticsDashboardUrl = (): ThunkActionType => async (dispatch, getState) => {
  const name = getState().identity.selectedSalesChannel?.name;

  if (!name) {
    return;
  }

  dispatch(
    setAnalyticsDashboard({
      isLoading: true,
      isSuccess: false,
      isError: false,
    })
  );

  const promise = getAnalyticsDashboardUrlApi(name);

  const response = await apiResponseHandler<{
    url: string;
  }>(promise);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setAnalyticsDashboard({
        isLoading: false,
        isError: true,
        isSuccess: false,
      })
    );
    return;
  }

  dispatch(
    setAnalyticsDashboard({
      isLoading: false,
      isSuccess: true,
      isError: false,
      data: response.data,
    })
  );
};

export const getFacilities = (): ThunkActionType => async (dispatch, getState) => {
  dispatch(
    setFacilities({
      isLoading: true,
    })
  );

  const promise = getFacilitiesApi();
  const response = await apiResponseHandler<Array<FacilityDTOType>>(
    promise,
    z.array(FacilityDTOSchema)
  );

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setFacilities({
        isLoading: false,
        isError: true,
        error: response.meta?.message,
        isSuccess: false,
      })
    );

    return;
  }

  const facilities = getState().common.facilities.data.records;
  const selectedSalesChannelFacilityId = getState().identity.selectedSalesChannel?.facilityId;
  const selectedFacility = selectedSalesChannelFacilityId
    ? facilities[selectedSalesChannelFacilityId]
    : null;

  let rawData = response.data;

  if (selectedFacility && !selectedFacility.isOnline) {
    // * filter out Try At Home facility if it is not online
    rawData = response.data.filter(
      facility => String(facility.id) !== ENV.VITE_TRY_AT_HOME_FACILITY_ID
    );
  }

  const ids = rawData.map(item => item.id);
  const records = convertArrayToObject(rawData, 'id');

  dispatch(
    setFacilities({
      data: {
        records,
        ids,
      },
      error: null,
      isError: false,
      isLoading: false,
      isSuccess: true,
    })
  );
};

export const findBulkErpUsers = createAsyncThunk<
  Array<StoreStaffDTOType>,
  { userIds: Array<number>; operation?: 'APPEND' | 'REPLACE' },
  { rejectValue: string }
>(
  'common/findBulkErpUsers',
  async ({ userIds, operation = 'APPEND' }, { dispatch, rejectWithValue }) => {
    dispatch(
      setErpUsers({
        isLoading: true,
      })
    );

    const response = await apiResponseHandler<Array<StoreStaffDTOType>>(
      findBulkErpUsersApi(userIds),
      z.array(StoreStaffDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      dispatch(
        setErpUsers({
          isLoading: false,
        })
      );

      toast.error(response.meta?.message ?? 'Failed to fetch ERP users');
      return rejectWithValue(response.meta?.message ?? 'Failed to fetch ERP users');
    }

    const ids = response.data.map(item => item.id);
    const records = convertArrayToObject(response.data, 'id');

    dispatch(
      setErpUsers(
        {
          data: {
            ids,
            records,
          },
          isLoading: false,
        },
        operation
      )
    );

    return response.data;
  }
);

export const findBulkErpUsersIfNotPresentAlready = createAsyncThunk<
  void,
  { userIds: Array<number>; operation?: 'APPEND' | 'REPLACE' },
  { rejectValue: string }
>(
  'common/findBulkErpUsersIfNotPresentAlready',
  async ({ userIds, operation = 'APPEND' }, { dispatch, getState }) => {
    const store = getState() as RootStateType;

    const storeStaffIds = store.common.storeStaff.data.ids;
    const erpUserIds = store.common.erpUsers.data.ids;

    const missingUserIds = userIds.filter(
      userId => !storeStaffIds.includes(userId) && !erpUserIds.includes(userId)
    );

    if (missingUserIds.length === 0) {
      return;
    }

    dispatch(
      findBulkErpUsers({
        userIds: missingUserIds,
        operation: operation,
      })
    );
  }
);
