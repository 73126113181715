import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 52px);
  display: flex;
  padding: 12px;
  gap: 24px;
  /* background: var(--white); */
`;

export const Left = styled.div`
  width: 20%;
`;

export const Right = styled.div`
  width: 80%;
`;
