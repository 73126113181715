import styled from 'styled-components';
import { Sheet } from 'react-modal-sheet';
import { Button, Text } from '@point-of-sale/components';
import { emptyFunction } from '@point-of-sale/utils';
import { setIsReleasePromptModalOpen, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { ENV } from '@point-of-sale/env';

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  padding: 12px 12px 36px 12px;
  flex: 1;
`;

const Content = styled.div`
  padding: 12px;
`;

const ReleasePromptBottomSheet = () => {
  const isReleasePromptOpen = useAppSelector(store => store.firebase.isReleasePromptOpen);

  const dispatch = useAppDispatch();

  if (ENV.VITE_ENVIRONMENT === 'local') {
    return null;
  }

  return (
    <Sheet
      rootId="root"
      isOpen={isReleasePromptOpen}
      onClose={() => {
        // dispatch(setIsReleasePromptModalOpen(false));
      }}
      onCloseEnd={() => {
        // * this refreshes the app,
        // * un-registers the existing service workers and
        // * registers the new service workers
        window.location.reload();
      }}
      disableDrag
      snapPoints={[0.5]}
    >
      <Sheet.Container>
        <Sheet.Header>
          <Content>
            <Text>New version available</Text>
          </Content>
        </Sheet.Header>
        <Sheet.Content>
          <Content>
            <Text>Please update the Runway app now by clicking the "Update Now" button.</Text>
          </Content>
          <Footer>
            <Button
              isFullWidth
              onClick={() => {
                dispatch(setIsReleasePromptModalOpen(false));
              }}
            >
              Update Now
            </Button>
          </Footer>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={emptyFunction} />
    </Sheet>
  );
};

export default ReleasePromptBottomSheet;
