import { getCurrentStoreAndWarehouseFacilityIds, ICommonState } from '@point-of-sale/store';
import { CheckBox } from '@point-of-sale/components';
import {
  CartItemDTOType,
  DeliveryModeType,
  INormalizedServiceabilityData,
  ProductVariantDTOType,
} from '@point-of-sale/schemas';
import { emptyFunction } from '@point-of-sale/utils';
import * as Styles from './styles';
import { getDeliveryDateByServiceability } from './helpers';

interface IFacilitiesListProps {
  facilities: ICommonState['facilities']['data'];

  shouldShowInventory: boolean;
  shouldShowDelivery: boolean;

  variant: ProductVariantDTOType | null;

  onChange: (selectedFacilityId: number, promiseDate: string) => void;

  customerDeliveryLocationId: number | DeliveryModeType;

  serviceabilityData: INormalizedServiceabilityData[string];

  isMTO: boolean;
  isMTC: boolean;
  isJIT: boolean;

  shouldShowOtherFacilities: boolean;

  defaultTat: number;

  savedPromiseDate?: string;

  cartItem?: CartItemDTOType;
}

const FacilitiesList = ({
  facilities,
  shouldShowDelivery,
  shouldShowInventory,
  variant,
  onChange,
  customerDeliveryLocationId,
  serviceabilityData,
  isMTC,
  isMTO,
  isJIT,
  shouldShowOtherFacilities,
  defaultTat,
  savedPromiseDate,
  cartItem,
}: IFacilitiesListProps) => {
  const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

  return (
    <tbody>
      {facilities.ids
        // * SHOW ONLY STORES
        .filter(facilityId => facilities.records[facilityId].facilityType === 'STORE')
        .filter(facilityId => facilities.records[facilityId].enabled)
        .filter(facilityId => {
          if (shouldShowOtherFacilities) {
            return true;
          }

          return currentStoreAndWarehousesFacilityIds.includes(facilityId);
        })
        .map(facilityId => {
          const facilityName = facilities.records[facilityId].name;

          const date = getDeliveryDateByServiceability(
            serviceabilityData,
            facilityId,
            facilities,
            isMTO,
            isMTC,
            defaultTat,
            isJIT,
            cartItem
          );

          const isSelected = String(facilityId) === String(customerDeliveryLocationId);

          return (
            <Styles.Tr
              key={facilityId}
              $isSelected={isSelected}
              onClick={() => {
                onChange(facilityId, date);
              }}
            >
              <Styles.Td>
                <CheckBox
                  shape="round"
                  name={facilityName}
                  isChecked={isSelected}
                  onChange={emptyFunction}
                />
              </Styles.Td>
              <Styles.Td>{facilityName}</Styles.Td>

              <Styles.Td>
                {shouldShowInventory && variant?.facilityInventory?.[facilityId].availableInventory}
              </Styles.Td>
              <Styles.Td>
                {shouldShowDelivery && (
                  // eslint-disable-next-line react/jsx-no-useless-fragment

                  <>
                    {date !== 'EXPRESS' && <>Est. Delivery: &nbsp;</>}
                    {date}
                  </>
                )}
              </Styles.Td>
            </Styles.Tr>
          );
        })}
    </tbody>
  );
};

export default FacilitiesList;
