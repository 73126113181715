import toast from 'react-hot-toast';
import {
  AlterationEntryDTOType,
  CartDTOSchema,
  CartDTOType,
  CartItemDTOType,
  DeliveryModeType,
  INormalizedServiceabilityData,
  ServiceabilityDTOSchema,
  ServiceabilityDTOType,
  ServiceChargeDTOType,
  SourceForOnlinePosEnumType,
  StoreStaffDTOType,
} from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  apiResponseHandler,
  normalizeArrayToIdsAndRecords,
  pick,
  setValuesToEmptyString,
} from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import { normalizeArray } from '../../utils/normalizeArray';
import { ThunkActionType } from '../../store';
import { setCartDetails, setSelectedAddressId, setSourceForOnlinePos } from '../actions';
import { cartRefreshApi } from '../api';
import { getCurrentStoreAndWarehouseFacilityIds } from '../utils';
import { setSalesPoc } from '../../checkout/actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getServiceabilityApi } from '../../serviceability/api';
import { z } from 'zod';

export const upsertPersonalization =
  ({
    personalizationData,
    itemId,
    successCallback,
  }: {
    itemId: number;
    personalizationData: AlterationEntryDTOType;
    successCallback?: CallableFunction;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = structuredClone(getState().pointOfSale.cart.data);

    const newCartItem = cart.cartItems.records[itemId];

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
    };

    newCartItem.alterationEntry = personalizationData;

    // TODO: WTF in going on ?
    if (newCartItem.deliveryMode === 'STORE_PURCHASE') {
      newCartItem.deliveryMode = 'STORE_PICKUP';
      newCartItem.fulfillmentMode = 'ONHAND';

      if (!newCartItem.customerDeliveryStoreId) {
        newCartItem.customerDeliveryStoreId = getState().identity.selectedSalesChannel?.facilityId;
      }
    } else if (newCartItem.deliveryMode === 'STORE_PICKUP') {
      if (!newCartItem.customerDeliveryStoreId) {
        newCartItem.customerDeliveryStoreId = getState().identity.selectedSalesChannel?.facilityId;
      }
    } else if (newCartItem.deliveryMode === 'HOME_DELIVERY') {
      // newCartItem.deliveryMode = 'STORE_PICKUP';
      // newCartItem.fulfillmentMode = 'MTC';

      if (!newCartItem.customerDeliveryAddressId) {
        const selectedAddressId = getState().pointOfSale.selectedAddressId;
        newCartItem.customerDeliveryAddressId = selectedAddressId;
      }
    }

    if (newCartItem.fulfillmentMode === 'MTO') {
      newCartItem.fulfillmentMode = 'MTC';
    }

    // * no change in isExpress and promiseDate : as Bhaskar said

    cartItemRecords[itemId] = newCartItem;

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArrayToIdsAndRecords(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };

const updateCartItemDestinationDetails = async (
  cartItem: CartItemDTOType,
  info: {
    deliveryMode?: DeliveryModeType;
    customerDeliveryStoreId?: number;
    isExpress?: boolean;
    promiseDate?: string;
    customerDeliveryAddressId?: number;
  },
  rawServiceabilityData: INormalizedServiceabilityData,
  determinedPincode: string,
  deliverability: any,
  getState: any
) => {
  if (info.deliveryMode) {
    cartItem.deliveryMode = info.deliveryMode;
  }

  if (info.customerDeliveryStoreId) {
    cartItem.customerDeliveryStoreId = info.customerDeliveryStoreId;
    cartItem.customerDeliveryAddressId = null;
  }

  let filteredServiceability = rawServiceabilityData[cartItem.skuCode];

  if (cartItem.fulfillmentMode === 'JIT') {
    filteredServiceability = rawServiceabilityData[cartItem.skuCode]?.filter(
      s => String(s.from.facilityId) === String(cartItem.fulfillmentFacilityId)
    );
  }

  let serviceabilityForItem = filteredServiceability?.find(s => s.to === determinedPincode);

  if (!serviceabilityForItem) {
    const facilities = getState().common.facilities.data.records;

    const destinationPincodes = [determinedPincode].filter(Boolean);

    if (destinationPincodes.length === 0) {
      return cartItem;
    }

    // fetch serviceability again
    const response = await apiResponseHandler<Array<ServiceabilityDTOType>>(
      getServiceabilityApi({
        skuCodes: [cartItem.skuCode],
        destinationPincodes: destinationPincodes,
        sourceFacilities: Object.values(facilities),
      }),
      z.array(ServiceabilityDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      return cartItem;
    }

    let newFilteredServiceability;

    if (cartItem.fulfillmentMode === 'JIT') {
      newFilteredServiceability = rawServiceabilityData[cartItem.skuCode]?.filter(
        s => String(s.from.facilityId) === String(cartItem.fulfillmentFacilityId)
      );
    }

    serviceabilityForItem = newFilteredServiceability?.find(s => s.to === determinedPincode);
  }

  const isExpress =
    cartItem.fulfillmentMode === 'ONHAND' &&
    (info.isExpress || serviceabilityForItem?.info.express);

  const vendorTat = cartItem.vendorTat ?? 0;

  const shouldConsiderPriorityTat = isExpress
    ? false
    : cartItem?.isPriority || deliverability?.preferredShipping === 'PRIORITY';

  const courierTat =
    (shouldConsiderPriorityTat
      ? serviceabilityForItem?.info.priorityCourierTat
      : serviceabilityForItem?.info.standardCourierTat) ?? 0;

  const opsTat =
    (cartItem.fulfillmentMode === 'ONHAND'
      ? serviceabilityForItem?.info.innerOpsTat
      : serviceabilityForItem?.info.outerOpsTat) ?? 0;

  cartItem.isExpress = isExpress;

  cartItem.courierTat = courierTat;
  cartItem.opsTat = opsTat;
  cartItem.vendorTat = vendorTat;
  cartItem.isPriority = isExpress
    ? false
    : cartItem?.isPriority || deliverability?.preferredShipping === 'PRIORITY';

  if (
    cartItem.fulfillmentMode === 'ONHAND'
      ? !serviceabilityForItem?.info.priorityShippingAvailable
      : !serviceabilityForItem?.info.priorityShippingAvailable
  ) {
    cartItem.isPriority = false;
  }

  if (info.customerDeliveryAddressId) {
    cartItem.customerDeliveryAddressId = info.customerDeliveryAddressId;
    delete cartItem.customerDeliveryStoreId;
  }

  return cartItem;
};

/**
 *
 * Changes the basic details of a cart item
 * like delivery mode, fulfillment mode, etc.
 * cannot update the sku itself
 */
export const updateSingleCartItemDestinations =
  (
    info: {
      cartItemId: number;
      customerDeliveryStoreId?: number;
      deliveryMode?: DeliveryModeType;
      isExpress?: boolean;
      promiseDate?: string;
      customerDeliveryAddressId?: number;
    },
    rawServiceabilityData: INormalizedServiceabilityData
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = structuredClone(getState().pointOfSale.cart.data);

    let newCartItem = cart.cartItems.records[info.cartItemId];

    const selectedAddressId = getState().pointOfSale.selectedAddressId;
    const selectedAddress = getState().pointOfSale.addresses.data.records[selectedAddressId];

    let determinedPincode = '';

    if (info.deliveryMode === 'HOME_DELIVERY') {
      determinedPincode = selectedAddress?.pincode ?? '';
    }

    if (info.deliveryMode === 'STORE_PICKUP') {
      //customerDeliveryStoreId
      const deliveryFacility = info.customerDeliveryStoreId
        ? getState().common.facilities.data.records[info.customerDeliveryStoreId]
        : null;
      determinedPincode = deliveryFacility?.address?.pincode ?? '';
    }

    const deliverability = getState().pointOfSale.deliverability[newCartItem.id];

    newCartItem = await updateCartItemDestinationDetails(
      newCartItem,
      info,
      rawServiceabilityData,
      determinedPincode,
      deliverability,
      getState
    );

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
    };

    cartItemRecords[info.cartItemId] = newCartItem;

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const updateMultipleCartItemDestinations =
  (
    info: {
      cartItemIds: Array<number>;
      customerDeliveryStoreId?: number;
      deliveryMode?: DeliveryModeType;
      isExpress?: boolean;
      promiseDate?: string;
      customerDeliveryAddressId?: number;
    },
    rawServiceabilityData: INormalizedServiceabilityData
  ): ThunkActionType =>
  async (dispatch, getState) => {
    if (getState().pointOfSale.cart.isLoading) {
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const selectedAddressId = getState().pointOfSale.selectedAddressId;
    const selectedAddress = getState().pointOfSale.addresses.data.records[selectedAddressId];

    let determinedPincode = '';

    if (info.deliveryMode === 'HOME_DELIVERY') {
      determinedPincode = selectedAddress?.pincode ?? '';
    }

    if (info.deliveryMode === 'STORE_PICKUP') {
      //customerDeliveryStoreId
      const deliveryFacility = info.customerDeliveryStoreId
        ? getState().common.facilities.data.records[info.customerDeliveryStoreId]
        : null;
      determinedPincode = deliveryFacility?.address?.pincode ?? '';
    }

    const cart = structuredClone(getState().pointOfSale.cart.data);

    const newCartItems = pick(cart.cartItems.records, info.cartItemIds);

    for (let cartItem of Object.values(newCartItems)) {
      const deliverability = getState().pointOfSale.deliverability[cartItem.id];

      cartItem = await updateCartItemDestinationDetails(
        cartItem,
        info,
        rawServiceabilityData,
        determinedPincode,
        deliverability,
        getState
      );
    }

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
      ...newCartItems,
    };

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const deletePersonalization =
  ({
    alterationEntry,
    itemId,
  }: {
    itemId: number;
    alterationEntry: Partial<AlterationEntryDTOType>;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = structuredClone(getState().pointOfSale.cart.data);

    const newCartItem = cart.cartItems.records[itemId];

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
    };

    newCartItem.alterationEntry = setValuesToEmptyString(alterationEntry) as AlterationEntryDTOType;

    newCartItem.alterationEntry.customizationCategory = null as any;

    if (newCartItem.fulfillmentMode === 'MTC') {
      newCartItem.fulfillmentMode = 'MTO';
    }

    if (newCartItem.deliveryMode === 'STORE_PICKUP') {
      const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

      if (currentStoreAndWarehousesFacilityIds.includes(newCartItem.fulfillmentFacilityId)) {
        newCartItem.deliveryMode = 'STORE_PURCHASE';
        newCartItem.fulfillmentMode = 'ONHAND';
      }
    }

    cartItemRecords[itemId] = newCartItem;

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);

    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const modifyServiceCharge =
  (
    charge: ServiceChargeDTOType | null, // null means remove charge
    level: 'TOP' | 'ITEM',
    itemId?: string | number, // required if level is 'item'
    index?: number, // required if removing a charge
    successCallback?: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = getState().pointOfSale.cart.data;

    const updatedCartItems = cart.cartItems.ids.map(id => {
      const cartItem = cart.cartItems.records[id];

      if (level === 'ITEM' && String(id) === String(itemId)) {
        return {
          ...cartItem,
          customCharges: charge
            ? [...(cartItem.customCharges || []), charge]
            : cartItem.customCharges?.filter((_, i) => i !== index),
        };
      }

      return cartItem;
    });

    const updatedCustomCharges =
      level === 'TOP'
        ? charge
          ? [...(cart.customCharges || []), charge]
          : cart.customCharges?.filter((_, i) => i !== index)
        : cart.customCharges;

    const payload: CartDTOType = {
      ...cart,
      cartItems: updatedCartItems,
      customCharges: updatedCustomCharges,
    };

    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };

export const applyUnApplyCouponCodeToCart =
  ({
    couponCode,
    couponApplicationRemarks,
    successCallback,
    failureCallback,
  }: {
    couponCode: string;
    couponApplicationRemarks: string;
    successCallback?: CallableFunction;
    failureCallback?: CallableFunction;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = getState().pointOfSale.cart.data;

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
      couponCode,
      couponApplicationRemarks,
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    if (response.data.couponErrorMessage) {
      failureCallback?.();
    } else {
      successCallback?.();
    }
  };

export const setSalesStaffIdOfCart =
  (salesStaff: StoreStaffDTOType, successCallback?: CallableFunction): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = getState().pointOfSale.cart.data;

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
      salesStaffId: salesStaff.id,
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(setSalesPoc(salesStaff));

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };

export const assignCustomerDeliveryAddressToCart =
  (customerDeliveryAddressId: number): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(setSelectedAddressId(customerDeliveryAddressId));

    const cart = getState().pointOfSale.cart.data;

    if (cart.id === -1) {
      return;
    }

    const isCartLoading = getState().pointOfSale.cart.isLoading;

    if (isCartLoading) {
      return;
    }

    const selectedAddress =
      getState().pointOfSale.addresses.data.records[customerDeliveryAddressId];

    const serviceability = getState().pointOfSale.serviceabilityForCart;

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      customerPincode: selectedAddress.pincode,
      cartItems: cart.cartItems.ids.map(id => {
        const cartItem = cart.cartItems.records[id];

        // TODO: Modularize this logic
        if (cartItem.deliveryMode === 'HOME_DELIVERY') {
          let filteredServiceability = serviceability?.[cartItem.skuCode];

          if (cartItem.fulfillmentMode === 'JIT') {
            filteredServiceability = serviceability?.[cartItem.skuCode]?.filter(
              s => String(s.from.facilityId) === String(cartItem.fulfillmentFacilityId)
            );
          }

          const itemServiceability = filteredServiceability?.find(
            s => s.to === selectedAddress.pincode
          );

          if (!itemServiceability) {
            return cartItem;
          }

          let determinedVendorTat = 0;

          if (cartItem?.fulfillmentMode !== 'ONHAND') {
            switch (cartItem?.fulfillmentMode) {
              case 'MTO': {
                determinedVendorTat = itemServiceability?.info.mtoTat ?? 0;
                break;
              }
              case 'MTC': {
                determinedVendorTat = itemServiceability?.info.mtcTat ?? 0;
                break;
              }
              case 'JIT': {
                determinedVendorTat = itemServiceability?.info.jitTat ?? 0;
                break;
              }
              default: {
                determinedVendorTat = 0;
              }
            }
          }

          const opsTat =
            (cartItem?.fulfillmentMode === 'ONHAND'
              ? itemServiceability?.info.innerOpsTat
              : itemServiceability?.info.outerOpsTat) ?? 0;
          // * this is wrong
          // const courierTat = cartItem?.courierTat;

          const deliverability = getState().pointOfSale.deliverability[cartItem.id];

          const courierTat =
            (deliverability?.preferredShipping === 'PRIORITY'
              ? itemServiceability?.info.priorityCourierTat
              : itemServiceability?.info.standardCourierTat) ??
            itemServiceability?.info.standardCourierTat;

          const savedVendorTat = cartItem
            ? cartItem.vendorTat
              ? cartItem.vendorTat
              : determinedVendorTat
            : 0;

          // const sumOfAllTats = cartItem ? (courierTat ?? 0) + (savedVendorTat ?? 0) + opsTat : 0;

          // const promiseDate = getUnixTime(addDays(new Date(), sumOfAllTats)).toString();

          return {
            ...cartItem,
            customerDeliveryAddressId,
            // promiseDate,
            isExpress: cartItem?.fulfillmentMode === 'ONHAND' && itemServiceability?.info.express,
            courierTat,
            opsTat,
            vendorTat: savedVendorTat,
          };
        }

        return cartItem;
      }),
    };

    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const updateCartGstNumber =
  (gstNumber: string): ThunkActionType =>
  async (dispatch, getState) => {
    const cart = structuredClone(getState().pointOfSale.cart.data);

    if (cart.id === -1) {
      dispatch(
        setCartDetails({
          isLoading: false,
          isSuccess: true,
          data: {
            ...cart,
            customerGSTIN: gstNumber,
          },
        })
      );

      return;
    }

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
      customerGSTIN: gstNumber,
    };

    // delete payload.customerBillingAddressId;

    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const assignCustomerBillingAddressToCart =
  (customerBillingAddressId: number): ThunkActionType =>
  async (dispatch, getState) => {
    const cart = getState().pointOfSale.cart.data;

    if (cart.id === -1) {
      dispatch(
        setCartDetails({
          isLoading: false,
          isSuccess: true,
          data: {
            ...cart,
            customerBillingAddressId: customerBillingAddressId,
          },
        })
      );

      return;
    }

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      customerBillingAddressId: customerBillingAddressId,
      cartItems: cart.cartItems.ids.map(id => {
        const cartItem = cart.cartItems.records[id];
        return {
          ...cartItem,
        };
      }),
    };

    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

// We might not need this, as we are initializing cart with it in the beginning
export const assignSourceToCart =
  (source: SourceForOnlinePosEnumType, successCallback?: CallableFunction): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = getState().pointOfSale.cart.data;

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
      source: source,
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(setSourceForOnlinePos(source));

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };
