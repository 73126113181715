import { useState } from 'react';
import toast from 'react-hot-toast';
import { useBoolean } from '@point-of-sale/hooks';
import {
  isBarcodish,
  createSkuMovementApi,
  useAppSelector,
  addStockKeepingUnit,
  useAppDispatch,
  addRecentlyAddedSkuMovement,
} from '@point-of-sale/store';
import { apiResponseHandler } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import { SkuMovementDTOSchema, SkuMovementDTOType } from '@point-of-sale/schemas';

const useCreateSkuMovement = () => {
  const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);

  const dispatch = useAppDispatch();

  const [isLoading, loadingActions] = useBoolean();
  const [error, setError] = useState('');

  const [barcode, setBarcode] = useState('');

  function onChangeBarcode(value: string) {
    setBarcode(value);
    setError('');
  }

  async function trackSkuMovement() {
    try {
      if (!barcode) {
        toast.error('Barcode cannot be empty');
        return;
      }

      if (!isBarcodish(barcode)) {
        toast.error('Barcode seems wrong');
        return;
      }

      if (!selectedSalesChannel?.facilityId) {
        return;
      }

      loadingActions.on();

      const promise = await createSkuMovementApi({
        barcode,
        movementType: 'STORE_TRIAL',
        fromFacilityId: selectedSalesChannel?.facilityId,
        toFacilityId: selectedSalesChannel?.facilityId,
      });

      const response = await apiResponseHandler<SkuMovementDTOType>(promise, SkuMovementDTOSchema);

      if (response.type === ApiResponseEnum.Failure) {
        toast.error(response.meta?.message ?? 'Something went wrong', {
          position: 'bottom-center',
        });
        if (response.meta?.message) {
          setError(response.meta?.message);
        }
        return;
      }

      toast.success(`${response.data.skuCode} successfully tracked`, {
        position: 'bottom-center',
      });

      dispatch(addStockKeepingUnit(response.data));
      dispatch(addRecentlyAddedSkuMovement(response.data.id));

      loadingActions.off();
      onChangeBarcode('');
    } catch (e) {
      console.error(error);
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      loadingActions.off();
    }
  }

  return {
    isLoading,
    error,
    trackSkuMovement,
    barcode,
    onChangeBarcode,
  };
};

export default useCreateSkuMovement;
