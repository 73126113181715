import { VIEW_PORTS } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 52px;
  background: var(--white);

  position: sticky;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 2;

  box-shadow: rgba(0, 0, 0, 0.1) 0px -3px 8px;

  padding: 0 12px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 12px;
`;
