import { useEffect } from 'react';
import {
  PosFilterNames,
  searchProducts,
  setPosPagination,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import Discovery from '../Discovery';
import { useProductDiscoveryFilter } from '../../../hooks/useProductDiscoveryFilter';
import { useProductDiscovery } from '../../../hooks/useProductDiscovery';
import { ScanditBottomSheet } from '@runway/barcode-scanner';
import SelectedFilters from './SelectedFilters';
import * as Styles from './styles';

const ProductDiscovery = () => {
  const searchQuery = useAppSelector(store => store.pointOfSale.search.query);

  const { discoveryFilters } = useProductDiscoveryFilter();

  const { onChangeSearch, onChangeFilter, onRemoveFilter } = useProductDiscovery({
    usage: 'EXPLORE_PRODUCTS',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchProducts('EXPLORE_PRODUCTS'));
  }, []);

  function onApply(filtersToBeApplied: Record<string, any>) {
    Object.entries(filtersToBeApplied).forEach(([field, options]) => {
      onChangeFilter(field as PosFilterNames, options);
    });

    dispatch(searchProducts('EXPLORE_PRODUCTS'));
  }

  function onClearAll() {
    dispatch(
      setPosPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );

    discoveryFilters.map(filter => {
      onRemoveFilter(filter.name as PosFilterNames);
    });

    dispatch(searchProducts('EXPLORE_PRODUCTS'));
  }

  return (
    <Styles.Wrapper>
      <Discovery
        searchQuery={searchQuery}
        filters={discoveryFilters}
        onPressEnter={onChangeSearch}
        customInput={<ScanditBottomSheet onChange={barcode => onChangeSearch(barcode)} />}
        onApply={onApply}
        onClearAll={onClearAll}
        renderSelectedFilters={(filtersToBeApplied, setFiltersToBeApplied, onApply) => (
          <SelectedFilters
            filtersToBeApplied={filtersToBeApplied}
            setFiltersToBeApplied={setFiltersToBeApplied}
            onClick={onApply}
          />
        )}
      />
    </Styles.Wrapper>
  );
};

export default ProductDiscovery;
