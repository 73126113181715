import { FirebaseActionsTypes } from './constants';
import { IFirebaseState } from './types';

export const setReleaseData = (payload: {
  environment: keyof IFirebaseState['releases'];
  github_run_number: number;
  timestamp: string;
}) => ({
  type: FirebaseActionsTypes.SET_RELEASE_DATA,
  payload,
});

export const setIsReleasePromptModalOpen = (payload: boolean) => ({
  type: FirebaseActionsTypes.SET_IS_RELEASE_PROMPT_MODAL_OPEN,
  payload,
});
