import AboutMe from './Sections/AboutMe';
import SalesChannel from './Sections/SalesChannel';
import { ConfigurationType } from './types';

export const CONFIGURATION: ConfigurationType = {
  ACCOUNT: {
    groupHeading: 'Account',
    slug: 'ACCOUNT',
    groupItems: {
      ABOUT_ME: { name: 'About Me', slug: 'ABOUT_ME', component: AboutMe, roles: ['*'] },
    },
  },
  APPLICATION: {
    groupHeading: 'Application',
    slug: 'APPLICATION',
    groupItems: {
      SALES_CHANNEL: {
        name: 'Sales Channel',
        slug: 'SALES_CHANNEL',
        component: SalesChannel,
        roles: ['SUPER_ADMIN'],
      },
    },
  },
};
