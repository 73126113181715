import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { EmptyCart } from '@runway/illustrations';
import { Button, Text } from '@point-of-sale/components';
import {
  clearCartById,
  getBucketizedCartItems,
  resetPointOfSaleState,
  updateMultipleCartItemDestinations,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { formatNumberToLocale, openInSameTab } from '@point-of-sale/utils';
import { CURRENCY_SYMBOLS, CurrencyType } from '@point-of-sale/constants';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';
import CartItem from './CartItem';
import CheckoutButton from './CheckoutButton';
import useGetServiceability from '../Product/useGetServiceability';
import RecoverCartButton from './RecoverCartButton';
import { CartUsageType } from './types';
import { CartContext } from './context';
import * as Styles from './styles';

interface ICartProps {
  usage: CartUsageType;
}

const Cart = ({ usage }: ICartProps) => {
  const mode = useAppSelector(state => state.pointOfSale.mode);

  const facilityRecords = useAppSelector(state => state.common.facilities.data.records);

  // const isCartLoading = useAppSelector(state => state.pointOfSale.cart.isLoading);
  const cartId = useAppSelector(state => state.pointOfSale.cart.data.id);
  const cartCurrency = useAppSelector(store => store.pointOfSale.cart.data.currency);

  const allCustomerAddressIds = useAppSelector(
    state => state.pointOfSale.addresses.data.ids,
    shallowEqual
  );
  const allCustomerAddressRecords = useAppSelector(
    store => store.pointOfSale.addresses.data.records,
    shallowEqual
  );
  const selectedCustomerAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[store.pointOfSale.selectedAddressId]
  );

  const allCartItemIds = useAppSelector(
    state => state.pointOfSale.cart.data.cartItems.ids,
    shallowEqual
  );
  const cartItemsRecords = useAppSelector(
    state => state.pointOfSale.cart.data.cartItems.records,
    shallowEqual
  );
  const chargeableAmount = useAppSelector(state => state.pointOfSale.cart.data.chargeableAmount);

  const {
    jitOrMtoOrMtcItems,
    readyMadeItemsFromOtherStoreOrWarehouse,
    storePurchaseItemsWithAlteration,
    storePurchaseItemsWithoutAlteration,
    storePurchaseItemsWithHomeDeliveryAndWithoutAlteration,
  } = getBucketizedCartItems();

  const storePurchaseItemsWithoutAlterationQuantity = storePurchaseItemsWithoutAlteration.reduce(
    (acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    },
    0
  );

  const storePurchaseItemsWithAlterationQuantity = storePurchaseItemsWithAlteration.reduce(
    (acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    },
    0
  );

  const readyMadeItemsFromOtherStoreOrWarehouseQuantity =
    readyMadeItemsFromOtherStoreOrWarehouse.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);

  const jitOrMtoOrMtcItemsQuantity = jitOrMtoOrMtcItems.reduce((acc, id) => {
    return acc + cartItemsRecords[id].quantity;
  }, 0);

  const storePurchaseItemsWithHomeDeliveryAndWithoutAlterationQuantity =
    storePurchaseItemsWithHomeDeliveryAndWithoutAlteration.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);

  const dispatch = useAppDispatch();

  const { serviceabilityData, fetchServiceabilityData, isFetchingServiceability } =
    useGetServiceability();

  const skuCodes = useMemo(
    () => Object.values(cartItemsRecords).map(item => item.skuCode),
    [cartItemsRecords]
  );

  const customerAddressPincodes = useMemo(
    () => allCustomerAddressIds.map(id => allCustomerAddressRecords[id as number].pincode),
    [allCustomerAddressIds, allCustomerAddressRecords]
  );

  const destinationPincodes = useMemo(() => {
    const dps = Object.values(facilityRecords)
      .map(facility => {
        if (facility.facilityType !== 'STORE') {
          return null;
        }

        return facility.address.pincode;
      })
      .filter(item => item !== null);

    if (selectedCustomerAddress) {
      dps.push(selectedCustomerAddress.pincode);
    }

    if (customerAddressPincodes.length > 0) {
      dps.push(...customerAddressPincodes);
    }

    return dps;
  }, [facilityRecords, customerAddressPincodes, selectedCustomerAddress]);

  useDeepCompareEffect(() => {
    if (usage !== 'ADD_PRODUCTS') {
      return;
    }

    if (skuCodes.length === 0) {
      return;
    }

    fetchServiceabilityData(
      skuCodes,
      // @ts-expect-error destinationPincodes will never be undefined
      Array.from(new Set(destinationPincodes)),
      Object.values(facilityRecords),
      {
        // successCallback: updateTatsAfterFetchingServiceability
      }
    );
  }, [usage, facilityRecords, skuCodes, destinationPincodes]);

  useDeepCompareEffect(() => {
    const updateTatsAfterFetchingServiceability = (
      newServiceabilityData: INormalizedServiceabilityData
    ) => {
      if (allCartItemIds.length === 0) {
        return;
      }

      if (usage !== 'ADD_PRODUCTS') {
        return;
      }

      if (isFetchingServiceability) {
        return;
      }

      const cartItemToBeUpdated: Array<number> = [];

      allCartItemIds.forEach(id => {
        const record = cartItemsRecords[id];

        if (
          record.deliveryMode !== 'HOME_DELIVERY' ||
          !newServiceabilityData[record.skuCode] ||
          !record.promiseDate ||
          !selectedCustomerAddress?.pincode
        ) {
          return;
        }

        cartItemToBeUpdated.push(id);
      });

      dispatch(
        updateMultipleCartItemDestinations(
          {
            cartItemIds: cartItemToBeUpdated,
            deliveryMode: 'HOME_DELIVERY',
          },
          newServiceabilityData
        )
      );
    };

    updateTatsAfterFetchingServiceability(serviceabilityData);
  }, [serviceabilityData]);

  const itemCount = useMemo(() => {
    return allCartItemIds.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);
  }, [allCartItemIds, cartItemsRecords]);

  const currency = CURRENCY_SYMBOLS[(cartCurrency as CurrencyType) ?? 'INR']?.code;

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  return (
    <CartContext.Provider value={{}}>
      <Styles.Wrapper>
        <Styles.Header>
          <Styles.HeaderLeft>
            <Text fontSize={14} color="var(--dove-gray)">
              {cartId === -1 ? <>...</> : <>Cart #{cartId}</>}
            </Text>
            <Text fontSize={16} color="var(--cod-gray)" weight="semibold">
              Total Cart Value {currency} {formatNumberToLocale(chargeableAmount ?? 0)}
            </Text>
          </Styles.HeaderLeft>
          <Styles.HeaderRight>
            {usage === 'ADD_PRODUCTS' && (
              <Button
                onClick={() => {
                  dispatch(clearCartById(String(cartId)));
                }}
                variant="ghost"
                colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
                sizeVariant="xs"
              >
                CLEAR
              </Button>
            )}

            {usage === 'CHECKOUT' && (
              <Button
                variant="ghost"
                sizeVariant="xs"
                onClick={() => {
                  dispatch(resetPointOfSaleState());
                  openInSameTab(`/point-of-sale/cart?id=${cartId}`);
                }}
              >
                ADD MORE
              </Button>
            )}

            <Text fontSize={14} weight="bold" color="var(--dove-gray)">
              No of Items: {itemCount}
            </Text>
          </Styles.HeaderRight>
        </Styles.Header>
        <Styles.Content>
          {allCartItemIds.length === 0 && (
            <EmptyCart
              text={
                usage === 'RECOVER'
                  ? cartId === -1
                    ? 'Please Select a cart'
                    : 'Cart has no items'
                  : 'Your cart is empty'
              }
            />
          )}

          {storePurchaseItemsWithoutAlteration.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Store Purchase Items ({storePurchaseItemsWithoutAlterationQuantity})
              </Styles.CartSectionHeading>
              {storePurchaseItemsWithoutAlteration.map(id => (
                <CartItem
                  key={id}
                  data={{
                    ...cartItemsRecords[id],
                    chargeableAmount: cartItemsRecords[id].chargeableAmount ?? 0,
                  }}
                  currency={cartCurrency ?? ''}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                  shouldShowOtherFacilities={false}
                />
              ))}
            </Styles.CartSection>
          )}
          {storePurchaseItemsWithHomeDeliveryAndWithoutAlteration.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Store Purchase Items With Home Delivery (
                {storePurchaseItemsWithHomeDeliveryAndWithoutAlterationQuantity})
              </Styles.CartSectionHeading>
              {storePurchaseItemsWithHomeDeliveryAndWithoutAlteration.map(id => (
                <CartItem
                  key={id}
                  data={{
                    ...cartItemsRecords[id],
                    chargeableAmount: cartItemsRecords[id].chargeableAmount ?? 0,
                  }}
                  currency={cartCurrency ?? ''}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                  shouldShowOtherFacilities={false}
                  overrideDeliveryMode="STORE_PURCHASE"
                />
              ))}
            </Styles.CartSection>
          )}
          {storePurchaseItemsWithAlteration.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Store Purchase Items With Alterations ({storePurchaseItemsWithAlterationQuantity})
              </Styles.CartSectionHeading>
              {storePurchaseItemsWithAlteration.map(id => (
                <CartItem
                  key={id}
                  data={{
                    ...cartItemsRecords[id],
                    chargeableAmount: cartItemsRecords[id].chargeableAmount ?? 0,
                  }}
                  currency={cartCurrency ?? ''}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                />
              ))}
            </Styles.CartSection>
          )}
          {readyMadeItemsFromOtherStoreOrWarehouse.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Other Locations ({readyMadeItemsFromOtherStoreOrWarehouseQuantity})
              </Styles.CartSectionHeading>
              {readyMadeItemsFromOtherStoreOrWarehouse.map(id => (
                <CartItem
                  key={id}
                  data={{
                    ...cartItemsRecords[id],
                    chargeableAmount: cartItemsRecords[id].chargeableAmount ?? 0,
                  }}
                  currency={cartCurrency ?? ''}
                  destinationGroupIds={readyMadeItemsFromOtherStoreOrWarehouse}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                  shouldBlockAlteration={!isSelectedSalesChannelOnline}
                  shouldShowOtherFacilities={true}
                />
              ))}
            </Styles.CartSection>
          )}
          {jitOrMtoOrMtcItems.length > 0 && (
            <Styles.CartSection>
              <Styles.CartSectionHeading fontSize={14} weight="semibold">
                Made To Order ({jitOrMtoOrMtcItemsQuantity})
              </Styles.CartSectionHeading>
              {jitOrMtoOrMtcItems.map(id => (
                <CartItem
                  key={id}
                  data={{
                    ...cartItemsRecords[id],
                    chargeableAmount: cartItemsRecords[id].chargeableAmount ?? 0,
                  }}
                  currency={cartCurrency ?? ''}
                  destinationGroupIds={jitOrMtoOrMtcItems}
                  serviceabilityData={serviceabilityData[cartItemsRecords[id].skuCode]}
                  customerAddress={selectedCustomerAddress}
                  usage={usage}
                  shouldShowOtherFacilities={true}
                />
              ))}
            </Styles.CartSection>
          )}
        </Styles.Content>
        {usage !== 'CHECKOUT' && (
          <Styles.Footer>
            <Styles.FooterBottom>
              {usage === 'ADD_PRODUCTS' && (
                <CheckoutButton
                  chargeableAmount={`${currency} ${formatNumberToLocale(chargeableAmount ?? 0)}`}
                />
              )}
              {usage === 'RECOVER' && <RecoverCartButton />}
            </Styles.FooterBottom>
          </Styles.Footer>
        )}
      </Styles.Wrapper>
    </CartContext.Provider>
  );
};

export default Cart;
