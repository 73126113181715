import { onValue, ref } from 'firebase/database';
import { ThunkActionType } from '../store';
import { getFirebaseRealtimeDatabase } from '@lyskraft/firebase';
import { IFirebaseState } from './types';
import { setReleaseData, setIsReleasePromptModalOpen } from './actions';

export const listenToNewReleases =
  (environment: 'staging' | 'production' | 'local' = 'staging'): ThunkActionType =>
  async (dispatch, getState) => {
    if (environment === 'local') {
      return;
    }

    const db = getFirebaseRealtimeDatabase();

    // const environment = 'staging';
    // const versionRef = ref(db, `/releases/${env}`);
    const releasesRef = ref(db, `/releases/`);
    // const versionRef = ref(db, `/releases/${environment}`);
    // const versionRef = ref(db, `/releases/staging`);

    onValue(releasesRef, snapshot => {
      const releases = snapshot.val() as IFirebaseState['releases'];

      if (!releases) {
        return;
      }

      const data = releases[environment];

      const currentGithubRunNumber = getState().firebase.releases[environment].github_run_number;

      if (currentGithubRunNumber !== -1 && currentGithubRunNumber !== data.github_run_number) {
        dispatch(setIsReleasePromptModalOpen(true));
      }

      if (data.github_run_number && data.timestamp) {
        dispatch(
          setReleaseData({
            environment: environment,
            github_run_number: data.github_run_number,
            timestamp: data.timestamp,
          })
        );
      }
    });
  };
