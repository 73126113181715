import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
`;

export const Content = styled.div`
  border-radius: 4px;
  border: 1px solid var(--athens-gray);
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  padding: 12px 8px;
`;
