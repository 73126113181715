import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, RadioGroup, Select, Text } from '@point-of-sale/components';
import {
  BulkCancelOrderItemDTOType,
  LineItemDTOType,
  OrderDTOType,
  RefundModeEnumSchema,
  RefundModeType,
  StoreStaffDTOType,
} from '@point-of-sale/schemas';
import { bulkCancelOrderItems, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { CURRENCY_SYMBOLS, CurrencyType } from '@point-of-sale/constants';
import { useBoolean } from '@point-of-sale/hooks';
import { formatNumberToLocale } from '@point-of-sale/utils';
import KeyValuePair from '../KeyValuePair';
import Box from '../Box';
import { useOrderDetailsContext } from '../context';
import { getRefundOptions } from '../helpers';
import BankDetailsModal from '../BankDetailsModal';
import useBankDetails from '../BankDetailsModal/useBankDetails';
import * as Styles from './styles';

interface ICancellationDetailsProps {
  data: OrderDTOType;
  setPdfData: (data: Array<LineItemDTOType>) => void;
}

const CancellationDetails = ({ data, setPdfData }: ICancellationDetailsProps) => {
  const {
    lineItemsSelection,
    reasons: cancellationReasons,
    specificReasons,
  } = useOrderDetailsContext();

  const { ids: storeStaffIds, records: storeStaffRecords } = useAppSelector(
    store => store.common.storeStaff.data
  );

  const [salesStaff, setSalesStaff] = useState<StoreStaffDTOType | null>(null);

  const dispatch = useAppDispatch();

  const [isModalOpen, modalOpenActions] = useBoolean();

  const itemsToBeCancelled = Object.entries(lineItemsSelection)
    .filter(([id, isSelected]) => isSelected)
    .map(([id]) => id)
    .map(Number);

  const [refundMode, setRefundMode] = useState<RefundModeType | null>(
    RefundModeEnumSchema.Enum.STORE_CREDIT
  );
  const [refundRemarks, setRefundRemarks] = useState('');

  const { bankDetails, setBankDetails, getAreBankDetailsValid } = useBankDetails();

  const [upiHandle, setUpiHandle] = useState('');

  const currency = CURRENCY_SYMBOLS[(data.costBreakup?.currency as CurrencyType) ?? 'INR']?.code;

  const refundAmount = data.lineItems
    ?.filter(item => itemsToBeCancelled.includes(item.id))
    .reduce((acc, curr) => {
      if (!curr.costBreakup?.amountPaid) {
        return acc + 0;
      }

      return acc + curr.costBreakup?.amountPaid;
    }, 0);

  function getIsCancelButtonDisabled() {
    if (refundMode === RefundModeEnumSchema.Enum.BANK_ACCOUNT) {
      return getAreBankDetailsValid() || itemsToBeCancelled.length === 0;
    }

    if (refundMode === RefundModeEnumSchema.Enum.UPI) {
      return upiHandle === '' || itemsToBeCancelled.length === 0;
    }

    return itemsToBeCancelled.length === 0;
  }

  const staffOptions = storeStaffIds.map(id => ({ label: storeStaffRecords[id].name, value: id }));

  return (
    <Box header="Cancellation Details" hasPadding={false} flexOne>
      <Styles.Content>
        <Styles.Section>
          <KeyValuePair title="No of items" value={itemsToBeCancelled.length} />
        </Styles.Section>
        <Styles.Section $flexOne>
          <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
            Refund Mode
            <Text weight="semibold" color="var(--alizarin-crimson)">
              *
            </Text>
          </Text>

          {data.costBreakup?.amountPaid
            ? data.costBreakup?.amountPaid > 0 && (
                <RadioGroup
                  horizontal
                  name="refund-mode"
                  options={getRefundOptions(data)}
                  selectedValue={refundMode ?? ''}
                  onChange={selectedValue => {
                    setRefundMode(selectedValue as RefundModeType);

                    if (selectedValue === RefundModeEnumSchema.Enum.BANK_ACCOUNT) {
                      modalOpenActions.on();
                    }
                  }}
                />
              )
            : null}

          {refundMode === RefundModeEnumSchema.Enum.UPI && (
            <Input
              label="Upi Handle"
              value={upiHandle}
              onChange={e => {
                setUpiHandle(e.target.value);
              }}
              sizeVariant="extraSmall"
              variant="outline"
            />
          )}
        </Styles.Section>

        {refundMode === RefundModeEnumSchema.Enum.BANK_ACCOUNT && (
          <Styles.Section>
            <Styles.BankDetails>
              <KeyValuePair title="Name of the Bank" value={bankDetails.bankName} />
              <KeyValuePair title="Account Holder's Name" value={bankDetails.accountName} />
              <KeyValuePair title="Account Number" value={bankDetails.accountNumber} />
              <KeyValuePair title="IFSC" value={bankDetails.ifsc} />
              <Button
                colorScheme="dark"
                variant="outline"
                onClick={modalOpenActions.on}
                sizeVariant="xs"
                leftIcon="EditIcon"
              >
                Edit
              </Button>
            </Styles.BankDetails>
          </Styles.Section>
        )}
        {refundMode === RefundModeEnumSchema.Enum.OTHERS && (
          <Styles.Section>
            <Input
              label="Refund Remarks"
              variant="outline"
              sizeVariant="small"
              value={refundRemarks}
              onChange={e => setRefundRemarks(e.target.value)}
            />
          </Styles.Section>
        )}
        <Styles.Section>
          <KeyValuePair
            title="Refund Amount"
            value={`${currency} ${formatNumberToLocale(refundAmount)}`}
          />
        </Styles.Section>
        <Styles.Section>
          <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
            Store Staff
          </Text>
          <Select<(typeof staffOptions)[number]>
            options={staffOptions}
            value={staffOptions.find(option => option.value === salesStaff?.id)}
            onChange={selectedOption => {
              if (!selectedOption) {
                return;
              }

              const newSalesPoc = Object.values(storeStaffRecords).find(
                item => String(item.id) === String(selectedOption.value)
              );

              if (newSalesPoc) {
                setSalesStaff(newSalesPoc);
              }
            }}
            placeholder="Select Store Staff"
            isSearchable
          />
        </Styles.Section>
        <Styles.Section>
          <Button
            isFullWidth
            isDisabled={getIsCancelButtonDisabled()}
            onClick={() => {
              if (!refundMode) {
                toast.error('Please select refund mode');
                return;
              }

              let payload = {} as BulkCancelOrderItemDTOType;
              let shouldProceed = true;

              itemsToBeCancelled.forEach(id => {
                if (cancellationReasons[id].length === 0) {
                  shouldProceed = false;
                }
              });

              if (!shouldProceed) {
                toast.error('Please choose appropriate reasons');
                return;
              }

              const formattedCancellationReasons = itemsToBeCancelled.map(
                id => cancellationReasons[id]
              );

              const cancellationReason = formattedCancellationReasons[0];
              const remarks = specificReasons[itemsToBeCancelled[0]];

              if (cancellationReason === 'OTHERS' && !remarks) {
                toast.error('Please specify a reason');
                return;
              }

              if (data.costBreakup?.amountPaid === 0) {
                payload = {
                  ids: itemsToBeCancelled,
                  cancellationReason: cancellationReason,
                  bankAccount: bankDetails,
                  remarks: remarks,
                  salesStaffId: salesStaff?.id,
                  refundRemarks: refundRemarks,
                };
              } else {
                payload = {
                  ids: itemsToBeCancelled,
                  cancellationReason: cancellationReason,
                  refundMode: refundMode,
                  bankAccount: bankDetails,
                  remarks: remarks,
                  salesStaffId: salesStaff?.id,
                  refundRemarks: refundRemarks,
                };
              }

              dispatch(bulkCancelOrderItems(payload, setPdfData));
              setSalesStaff(null);
            }}
          >
            Cancel {itemsToBeCancelled.length} Selected Items
          </Button>
        </Styles.Section>
      </Styles.Content>

      <BankDetailsModal
        bankDetails={bankDetails}
        isOpen={isModalOpen}
        isSubmitButtonDisabled={getAreBankDetailsValid()}
        onClose={modalOpenActions.off}
        setBankDetails={setBankDetails}
      />
    </Box>
  );
};

export default CancellationDetails;
