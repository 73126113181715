import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import {
  offlineFacilitiesSelector,
  useAppSelector,
  useIsErpUserSuperAdmin,
} from '@point-of-sale/store';
import { IDiscoveryFilter } from '../components/desktop/Discovery';
import { useGetOptionsFromFacets } from './useGetOptionsFromFacets';
import { getDiscountFilterOptions } from '../components/desktop/ProductDiscovery/helpers';
import { Capsule, Text } from '@point-of-sale/components';
import { ENV } from '@point-of-sale/env';

// TODO: Refactor
export const useProductDiscoveryFilter = (): {
  discoveryFilters: Array<IDiscoveryFilter>;
} => {
  const { isErpUserSuperAdmin } = useIsErpUserSuperAdmin();

  const { getOptionsFromFacets } = useGetOptionsFromFacets({
    withCount: isMobile,
  });

  const filters = useAppSelector(store => store.pointOfSale.filters);
  const facilities = useAppSelector(offlineFacilitiesSelector);
  const vendorsWithJitEnabled = useAppSelector(store => store.common.vendors.data.ids);

  const availabilityOptions = facilities.data.ids
    .filter(id => facilities.data.records[id].facilityType !== 'VENDOR')
    .filter(id => facilities.data.records[id].enabled)
    .map(id => ({
      label: facilities.data.records[id].name,
      value: id,
    }));

  const vendorsToIncludeInAvailability = facilities.data.ids
    .filter(id => {
      if (isErpUserSuperAdmin) {
        return true;
      }

      return String(id) !== ENV.VITE_TEST_FACILITY;
    })
    .filter(id => facilities.data.records[id].facilityType === 'VENDOR')
    .filter(id =>
      facilities.data.records[id].vendorId
        ? vendorsWithJitEnabled.includes(facilities.data.records[id].vendorId)
        : false
    )
    .map(id => ({
      label: (
        <Text
          key={id}
          fontSize={14}
          style={{ display: 'flex', alignItems: 'center', gap: 4, fontWeight: 'inherit' }}
        >
          {facilities.data.records[id].name}{' '}
          <Capsule complexion="accent" content="JIT" size="small" />
        </Text>
      ),
      value: id,
    }));

  const discoveryFilters: Array<IDiscoveryFilter> = useMemo(
    () => [
      {
        name: 'brandName',
        placeholder: 'Brand',
        options: getOptionsFromFacets('brandName'),
        values: filters.brandName?.value ? String(filters.brandName.value).split(',') : [],
        type: 'MULTISELECT',
        shouldCategorizeAlphabetically: true,
        shouldShowSelectAll: true,
        shouldSortAlphabetically: true,
      },
      {
        name: 'variants.inStockFacilities',
        placeholder: 'Availability',
        options: [...availabilityOptions, ...vendorsToIncludeInAvailability],
        values: filters['variants.inStockFacilities']?.value
          ? String(filters['variants.inStockFacilities'].value).split(',').map(Number)
          : [],
        type: 'MULTISELECT',
        shouldShowSelectAll: true,
      },
      {
        name: 'variants.size',
        placeholder: 'Size',
        options: getOptionsFromFacets('variants.size'),
        values: filters['variants.size']?.value
          ? String(filters['variants.size'].value).split(',')
          : [],
        type: 'MULTISELECT',
        shouldShowSelectAll: true,
      },
      {
        name: 'variants.color',
        placeholder: 'Color',
        options: getOptionsFromFacets('variants.color'),
        values: filters['variants.color']?.value
          ? String(filters['variants.color'].value).split(',')
          : [],
        type: 'MULTISELECT',
        shouldSortAlphabetically: true,
        shouldShowSelectAll: true,
      },
      {
        name: 'discounts',
        placeholder: 'Discounts',
        options: getDiscountFilterOptions(),
        values: [
          ...(filters['variants.discountGroupId']?.value
            ? String(filters['variants.discountGroupId'].value).split(',')
            : []),
          ...(filters['variants.totalDiscount']?.value
            ? String(filters['variants.totalDiscount'].value).split(',')
            : []),
        ],
        type: 'SINGLE_SELECT',
      },
    ],
    [filters, getOptionsFromFacets, availabilityOptions, vendorsToIncludeInAvailability]
  );

  return { discoveryFilters };
};
