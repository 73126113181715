import { scanImageData, setModuleArgs } from '@undecaf/zbar-wasm';
import { isMobileOnly } from 'react-device-detect';
import { beep } from './utils';

import wasmURL from '@undecaf/zbar-wasm/dist/zbar.wasm?url';

type ZbarBarcodeScannerEvent = 'scan';

export class ZbarBarcodeScanner {
  private static instance: ZbarBarcodeScanner;
  private events: Record<string, CallableFunction> = {};

  private requestId: number | null = null;
  private video: HTMLVideoElement | null = null;
  private canvas: HTMLCanvasElement | null = null;
  private wasmInitialized = false;

  private async initializeWasm() {
    if (this.wasmInitialized) {
      return;
    }

    try {
      // Dynamic import the WASM file
      // const wasmUrl = new URL(
      //   '../node_modules/@undecaf/zbar-wasm/dist/zbar.wasm',
      //   import.meta.url
      // ).toString();

      setModuleArgs({
        locateFile: () => wasmURL,
      });

      this.wasmInitialized = true;
    } catch (error) {
      console.error('Failed to initialize WASM:', error);
      throw error;
    }
  }

  public subscribe(eventName: ZbarBarcodeScannerEvent, callback: CallableFunction): void {
    if (typeof this.events[eventName] === 'function') {
      return;
    }

    this.events[eventName] = callback;
  }

  public unsubscribe(eventName: ZbarBarcodeScannerEvent): void {
    delete this.events[eventName];
  }

  public emit(eventName: ZbarBarcodeScannerEvent, ...args: Array<any>): void {
    const eventCallback = this.events[eventName];
    if (eventCallback) {
      eventCallback(...args);
    }
  }

  public static getInstance(): ZbarBarcodeScanner {
    if (!ZbarBarcodeScanner.instance) {
      ZbarBarcodeScanner.instance = new ZbarBarcodeScanner();
    }
    return ZbarBarcodeScanner.instance;
  }

  // private drawDetectionOverlay(context: CanvasRenderingContext2D, symbol: any) {
  //   const points = symbol.points;
  //   const bounds = {
  //     minX: Math.min(...points.map((p: any) => p.x)),
  //     minY: Math.min(...points.map((p: any) => p.y)),
  //     maxX: Math.max(...points.map((p: any) => p.x)),
  //     maxY: Math.max(...points.map((p: any) => p.y)),
  //   };

  //   const padding = 10;
  //   const cornerLength = 20;

  //   // Save current context state
  //   context.save();

  //   // Draw semi-transparent overlay
  //   context.fillStyle = 'rgba(0, 0, 0, 0.3)';
  //   context.fillRect(0, 0, context.canvas.width, context.canvas.height);

  //   // Create clear rectangle where barcode is
  //   context.globalCompositeOperation = 'destination-out';
  //   context.fillStyle = 'white';
  //   context.fillRect(
  //     bounds.minX - padding,
  //     bounds.minY - padding,
  //     bounds.maxX - bounds.minX + padding * 2,
  //     bounds.maxY - bounds.minY + padding * 2
  //   );

  //   // Reset composite operation
  //   context.globalCompositeOperation = 'source-over';

  //   // Draw corners
  //   context.strokeStyle = '#393AFB';
  //   context.lineWidth = 3;
  //   context.beginPath();

  //   // Top-left corner
  //   context.moveTo(bounds.minX - padding, bounds.minY - padding + cornerLength);
  //   context.lineTo(bounds.minX - padding, bounds.minY - padding);
  //   context.lineTo(bounds.minX - padding + cornerLength, bounds.minY - padding);

  //   // Top-right corner
  //   context.moveTo(bounds.maxX + padding - cornerLength, bounds.minY - padding);
  //   context.lineTo(bounds.maxX + padding, bounds.minY - padding);
  //   context.lineTo(bounds.maxX + padding, bounds.minY - padding + cornerLength);

  //   // Bottom-right corner
  //   context.moveTo(bounds.maxX + padding, bounds.maxY + padding - cornerLength);
  //   context.lineTo(bounds.maxX + padding, bounds.maxY + padding);
  //   context.lineTo(bounds.maxX + padding - cornerLength, bounds.maxY + padding);

  //   // Bottom-left corner
  //   context.moveTo(bounds.minX - padding + cornerLength, bounds.maxY + padding);
  //   context.lineTo(bounds.minX - padding, bounds.maxY + padding);
  //   context.lineTo(bounds.minX - padding, bounds.maxY + padding - cornerLength);

  //   context.stroke();

  //   // Add scanning line animation
  //   const scanLineY =
  //     ((Date.now() % 2000) / 2000) * (bounds.maxY - bounds.minY + padding * 2) +
  //     (bounds.minY - padding);

  //   context.beginPath();
  //   context.moveTo(bounds.minX - padding, scanLineY);
  //   context.lineTo(bounds.maxX + padding, scanLineY);
  //   context.strokeStyle = '#171717';
  //   context.lineWidth = 2;
  //   context.stroke();

  //   // Add success indicator
  //   const pulseSize = Math.sin(Date.now() / 200) * 5 + 10;
  //   context.strokeStyle = '#40C057';
  //   context.lineWidth = 2;
  //   context.strokeRect(
  //     bounds.minX - padding - pulseSize,
  //     bounds.minY - padding - pulseSize,
  //     bounds.maxX - bounds.minX + (padding + pulseSize) * 2,
  //     bounds.maxY - bounds.minY + (padding + pulseSize) * 2
  //   );

  //   // Restore context state
  //   context.restore();
  // }

  private async getCameraIdWithFlash(): Promise<string | null> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    for (const device of devices) {
      const constraints = {
        video: {
          deviceId: device.deviceId,
          facingMode: 'environment',
        },
      };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const videoTrack = stream.getVideoTracks()[0];
        const capabilities = videoTrack.getCapabilities();

        if (capabilities.torch) {
          stream.getTracks().forEach(track => track.stop());
          return device.deviceId;
        }

        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        console.error(`Error with device ${device.deviceId}:`, error);
      }
    }
    return null;
  }

  private async getAndSetCameraIdWithFlash(): Promise<string | null> {
    let cameraId = localStorage.getItem('cameraIdWithFlash');
    if (!cameraId) {
      cameraId = await this.getCameraIdWithFlash();
      if (cameraId) {
        localStorage.setItem('cameraIdWithFlash', cameraId);
      }
    }
    return cameraId;
  }

  private async getMediaConstraints(facingMode = 'environment') {
    const baseSettings = isMobileOnly
      ? {
          height: { ideal: 1080 },
          width: { ideal: 1920 },
        }
      : {
          height: { ideal: 720 },
          width: { ideal: 1280 },
        };

    const customConstraints: MediaStreamConstraints = {
      audio: false,
      video: {
        ...baseSettings,
        aspectRatio: undefined,
        facingMode,
        resizeMode: false,
        exposureMode: 'continuous',
        zoom: facingMode === 'user' ? 1 : 1.5,
        // frameRate: { ideal: 15, max: 30 },
      },
    };

    if (facingMode === 'environment' && isMobileOnly) {
      const cameraId = await this.getAndSetCameraIdWithFlash();
      if (cameraId) {
        (customConstraints.video as any).deviceId = cameraId;
      }
    }

    return customConstraints;
  }

  private async startScanning() {
    try {
      const mediaConstraints = await this.getMediaConstraints('environment');
      const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);

      if (!this.video || !this.canvas) {
        return;
      }

      this.video.srcObject = stream;
      this.video.onloadedmetadata = () => {
        if (this.video && this.canvas) {
          this.detect(this.video, this.canvas);
        }
      };
    } catch (error) {
      console.error('Camera access error:', error);
    }
  }

  stopScanning() {
    if (this.requestId) {
      cancelAnimationFrame(this.requestId);
      this.requestId = null;
    }

    if (this.video?.srcObject) {
      const tracks = (this.video.srcObject as MediaStream).getTracks();
      tracks.forEach(track => track.stop());
      this.video.srcObject = null;
    }

    // Clean up elements
    this.video?.remove();
    this.canvas?.remove();
    this.video = null;
    this.canvas = null;
  }

  private async detect(video: HTMLVideoElement, canvas: HTMLCanvasElement) {
    if (!this.wasmInitialized) {
      await this.initializeWasm();
    }

    if (!canvas) {
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    // const grayscaleImageData = convertToGrayscale(imageData);

    try {
      const symbols = await scanImageData(imageData);
      // const symbols = await (window as any).zbarWasm?.scanImageData(imageData);

      if (symbols?.length > 0) {
        // symbols.forEach((symbol: any) => {
        //   this.drawDetectionOverlay(context, symbol);
        // });

        // Process first detected symbol and emit event
        const symbol = symbols[0];
        const result = {
          type: symbol.type,
          value: symbol.decode(),
        };

        beep({});
        this.emit('scan', result.value);
        this.stopScanning();
        return;
      }
    } catch (error) {
      console.error('detect', error);

      if (this.requestId) {
        cancelAnimationFrame(this.requestId);
        this.requestId = null;
        this.stopScanning();
      }
    }

    this.requestId = requestAnimationFrame(() => this.detect(video, canvas));
  }

  async run(container: HTMLDivElement) {
    this.video = document.createElement('video');
    Object.assign(this.video.style, {
      width: '100%',
      height: '100%',
    });

    this.video.muted = true;
    this.video.autoplay = true;
    this.video.playsInline = true;

    this.canvas = document.createElement('canvas');
    Object.assign(this.canvas.style, {
      position: 'absolute',
      top: 0,
      left: 0,
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
    });

    container.innerHTML = '';
    container.style.position = 'relative';
    container.appendChild(this.video);
    container.appendChild(this.canvas);

    await this.startScanning();
  }
}
