import { Context, createContext, Dispatch, SetStateAction } from 'react';
import {
  DeliveryModeType,
  FacilityDTOType,
  FulfillmentModeType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import { IProductListItem } from '@point-of-sale/store';
import { BooleanActions } from '@point-of-sale/hooks';

export interface IMannerOfPurchase {
  shouldOverride: boolean;
  // fulfillmentFacilityId: number;
  customerDeliveryStoreId?: number;
  deliveryMode: DeliveryModeType;
  fulfillmentMode: FulfillmentModeType;
  estimatedDeliveryDate?: string;
  fulfillmentFacility?: {
    name: string;
    city: string;
    pincode: string;
  };
}

export interface IProductContext {
  data: IProductListItem;

  isExpanded: boolean;

  selectedVariant: IProductListItem['variants'][number] | null;

  quantity: number;
  setQuantity: (quantity: number) => void;

  fulfillmentFacilityId: number | null;
  setFulfillmentFacilityId: (facilityId: number) => void;

  mannerOfPurchase: IMannerOfPurchase;
  setMannerOfPurchase: Dispatch<SetStateAction<IMannerOfPurchase>>;

  expandActions: BooleanActions;

  deliverToPincode: FacilityDTOType['address']['pincode'];

  // chosenFulfillmentFacility: FacilityDTOType | null;
  // setChosenFulfillmentFacility: (facility: FacilityDTOType) => void;

  serviceabilityData: INormalizedServiceabilityData;
}

export const ProductContext: Context<IProductContext> = createContext({} as IProductContext);
