import { IconPropsType } from '../types';

const CrossIcon = ({
  stroke = 'var(--cod-gray)',
  size = 24,
  strokeWidth = 1.5,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m6 6 11.986 12M18 6 6.014 18" stroke={stroke} strokeWidth={strokeWidth} />
  </svg>
);

export default CrossIcon;
