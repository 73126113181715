import { Capsule, Text } from '@point-of-sale/components';
import { formatToReadableDate, removeUnderscoresAndCapitalize } from '@point-of-sale/utils';
import { useOrderListItem } from '../../context';
import * as Styles from './styles';
import { LineItemDTOType } from '@point-of-sale/schemas';

function getDateText(data: LineItemDTOType) {
  if (data.status && ['CANCELLED', 'RTO', 'RETURNED', 'DELIVERED'].includes(data.status)) {
    const foundHistory = data.history?.find(history => history.status === data.status);

    let label = 'Expected Delivery';

    if (data.status === 'CANCELLED') {
      label = 'Cancelled Date';
    }

    if (data.status === 'RTO') {
      label = 'RTO Date';
    }

    if (data.status === 'RETURNED') {
      label = 'Return Date';
    }

    if (data.status === 'DELIVERED') {
      label = 'Delivery Date';
    }

    if (foundHistory) {
      return {
        label: label,
        value: foundHistory.eventDate && formatToReadableDate(foundHistory.eventDate),
      };
    }
  }

  // if (data.status === 'RETURN_WIP') {
  //   return {
  //     label: '',
  //     value: foundHistory.eventDate && formatToReadableDate(foundHistory.eventDate),
  //   };
  // }

  return {
    label: 'Expected Delivery',
    value: data.estimatedDeliveryDate && formatToReadableDate(data.estimatedDeliveryDate),
  };
}

const DeliveryDetails = () => {
  const { data } = useOrderListItem();

  const { label, value } = getDateText(data);

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Styles.Left>
          <Capsule
            essence="light"
            complexion="info"
            size="small"
            content={removeUnderscoresAndCapitalize(data.status ?? '').toLowerCase()}
          />
        </Styles.Left>
        <Styles.Right>
          <Text fontSize={12}>
            Order no: <br /> #{data.salesChannelOrderId}
          </Text>
        </Styles.Right>
      </Styles.Row>
      <Styles.Row>
        <Styles.Left>
          <Text fontSize={14}>
            {label}: <br />
            <b>{value}</b>
          </Text>
        </Styles.Left>
        <Styles.Right>
          <Capsule
            essence="light"
            complexion="info"
            size="small"
            content={removeUnderscoresAndCapitalize(data.deliveryMode)}
          />
          <Capsule essence="light" complexion="info" size="small" content={data.fulfillmentMode} />
        </Styles.Right>
      </Styles.Row>
    </Styles.Wrapper>
  );
};

export default DeliveryDetails;
