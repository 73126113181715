import { useMemo } from 'react';
import { updateAttributesOfCdnImage } from '@point-of-sale/utils';
import { Image } from '@point-of-sale/components';
import { getInventoryStatus, useAppSelector } from '@point-of-sale/store';
import { CUSTOM_TAILORED } from '@point-of-sale/constants';
import useProductContext from '../context/useProductContext';
import ReturnsAndExchangePolicy from '../../ReturnsAndExchangePolicy';
import { Ribbon } from '../../Ribbon';
import useDiscount from '../hooks/useDiscount';
import * as Styles from './styles';

const ProductImage = () => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const { data, selectedVariant, isExpanded } = useProductContext();

  const { name, images } = data;

  const { discount } = useDiscount({
    product: data,
    selectedVariant,
  });

  const firstImage = images?.[0];

  const displayedImage = selectedVariant ? selectedVariant.images?.[0] : firstImage;

  const highResImage = useMemo(
    () =>
      updateAttributesOfCdnImage(displayedImage?.url, {
        width: 720,
        quality: 100,
      }),
    [displayedImage]
  );

  const {
    hasInventoryInCurrentStoreOrWarehouse,
    hasInventoryInOtherStoreOrWarehouse,
    inventoryInOtherStoreAndWarehouses,
  } = getInventoryStatus(selectedVariant);

  let maybeJit = false;

  if (!hasInventoryInCurrentStoreOrWarehouse && hasInventoryInOtherStoreOrWarehouse) {
    const firstFacilityWithInventory = inventoryInOtherStoreAndWarehouses[0];
    maybeJit = firstFacilityWithInventory.facilityType === 'VENDOR';
  }

  return (
    <>
      <Styles.ImageWrapper
        variants={{
          expanded: { height: '376px', width: '237px' },
          collapsed: { height: '90px', width: '60px' },
        }}
        transition={{ duration: 0.45 }}
      >
        <Image
          key={selectedVariant?.skuCode ?? name}
          height={isExpanded ? 376 : 90}
          width={isExpanded ? 237 : 60}
          duration={0.45}
          src={highResImage}
          alt="Product"
          iconSize={isExpanded ? 56 : 28}
        />
        {selectedVariant?.discountGroupId === 1 && (
          <Ribbon $position="top" $background="var(--chateau-green)">
            Buy 2 Get 20% Off
          </Ribbon>
        )}
        {selectedVariant?.discountGroupId !== 1 && discount > 0 && (
          <Ribbon $position="top" $background="var(--chateau-green)">
            {discount}% OFF
          </Ribbon>
        )}
      </Styles.ImageWrapper>

      {mode === 'BUY_NOW' && isExpanded && selectedVariant && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {maybeJit ? (
            <ReturnsAndExchangePolicy
              isExchangeable={selectedVariant?.mtoReturnable}
              isReturnable={selectedVariant?.mtoReturnable}
            />
          ) : (
            <ReturnsAndExchangePolicy
              isExchangeable={selectedVariant.exchangeable}
              isReturnable={selectedVariant.returnable}
            />
          )}
        </>
      )}

      {mode === 'MADE_TO_ORDER' && isExpanded && selectedVariant && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {selectedVariant?.size === CUSTOM_TAILORED.key ? (
            <ReturnsAndExchangePolicy
              isExchangeable={selectedVariant?.mtcReturnable}
              isReturnable={selectedVariant?.mtcReturnable}
            />
          ) : (
            <ReturnsAndExchangePolicy
              isExchangeable={selectedVariant?.mtoReturnable}
              isReturnable={selectedVariant?.mtoReturnable}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductImage;
