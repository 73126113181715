import { IconPropsType } from '../types';

const HamBurgerIcon = ({ stroke = 'var(--cod-gray)', size = 24, ...props }: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 1920 1920"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1920 1468.412v112.94H0v-112.94zm0-564.706v112.941H0V903.706zM1920 339v112.941H0V339z"
      fillRule="evenodd"
      fill={stroke}
    />
  </svg>
);
export default HamBurgerIcon;
