import React, { useState, useCallback, useRef, ReactNode } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

export interface TooltipProps {
  /** Content to be displayed in the tooltip (can be text, JSX, or React components) */
  content: ReactNode;
  /** The element that triggers the tooltip */
  children: React.ReactElement;
  /** Position of the tooltip relative to the trigger element */
  placement?: 'top' | 'bottom' | 'left' | 'right';
  /** Delay before showing tooltip on hover (in milliseconds) */
  delay?: number;
  /** Duration user needs to hold on touch devices (in milliseconds) */
  touchHoldDuration?: number;
  /** Maximum width of the tooltip */
  maxWidth?: number;
  /** Optional custom styles for the tooltip */
  className?: string;
}

const TooltipContainer = styled.div<{ $isVisible: boolean; $maxWidth?: number }>`
  background-color: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  max-width: ${({ $maxWidth }) => $maxWidth ?? 200}px;
  z-index: 1000;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.2s, visibility 0.2s;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  border: 1px solid black;
`;

const Tooltip = ({
  content,
  children,
  placement = 'top',
  delay = 200,
  touchHoldDuration = 500,
  maxWidth,
  className,
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const touchTimeoutRef = useRef<NodeJS.Timeout>();
  const touchStartTimeRef = useRef<number>(0);
  const showTimeoutRef = useRef<NodeJS.Timeout>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
      {
        name: 'arrow',
        options: {
          padding: 5,
        },
      },
    ],
  });

  const clearTimeouts = useCallback(() => {
    if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current);
    if (touchTimeoutRef.current) clearTimeout(touchTimeoutRef.current);
  }, []);

  const showTooltip = useCallback(() => {
    console.log('MUST SHOW TOOLTIP');
    clearTimeouts();
    showTimeoutRef.current = setTimeout(() => setIsVisible(true), delay);
  }, [delay, clearTimeouts]);

  const hideTooltip = useCallback(() => {
    clearTimeouts();
    setIsVisible(false);
  }, [clearTimeouts]);

  const handleTouchStart = useCallback(() => {
    touchStartTimeRef.current = Date.now();
    touchTimeoutRef.current = setTimeout(() => {
      setIsVisible(true);
    }, touchHoldDuration);
  }, [touchHoldDuration]);

  const handleTouchEnd = useCallback(() => {
    clearTimeouts();
    const touchDuration = Date.now() - touchStartTimeRef.current;
    if (touchDuration < touchHoldDuration) {
      setIsVisible(false);
    }
  }, [touchHoldDuration, clearTimeouts]);

  React.useEffect(() => {
    return () => {
      clearTimeouts();
    };
  }, [clearTimeouts]);

  const childrenWithProps = React.cloneElement(children, {
    ref: setReferenceElement,
    onMouseEnter: showTooltip,
    onMouseLeave: hideTooltip,
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
    onTouchCancel: handleTouchEnd,
  });

  return (
    <>
      {childrenWithProps}
      <TooltipContainer
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        $isVisible={isVisible}
        $maxWidth={maxWidth}
        className={className}
        onMouseEnter={() => {
          console.log('MUST WORK');
        }}
      >
        {content}
      </TooltipContainer>
    </>
  );
};

export default Tooltip;
