import { NotificationActionTypes } from './constants';
import { ActionsType, INotificationsState } from './types';

const INITIAL_STATE: INotificationsState = {
  fcmToken: '',
  notifications: [],
  isDesktopNotificationsModalOpen: false,
} as const;

export const notificationsReducer = (
  state = INITIAL_STATE,
  action: ActionsType
): INotificationsState => {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      };
    }

    case NotificationActionTypes.SET_FCM_TOKEN: {
      return {
        ...state,
        fcmToken: action.payload,
      };
    }

    case NotificationActionTypes.SET_DESKTOP_NOTIFICATIONS_MODAL_OPEN: {
      return {
        ...state,
        isDesktopNotificationsModalOpen: action.payload,
      };
    }

    case NotificationActionTypes.RESET_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
