import { useState } from 'react';
import toast from 'react-hot-toast';
import { useBoolean } from '@point-of-sale/hooks';
import { deleteSkuMovementApi, removeStockKeepingUnit, useAppDispatch } from '@point-of-sale/store';
import { apiResponseHandler } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';

const useDeleteSkuMovement = () => {
  const [isLoading, loadingActions] = useBoolean();
  const [error, setError] = useState('');

  const dispatch = useAppDispatch();

  async function deleteSkuMovement(
    id: number,
    {
      successCallback,
    }: {
      successCallback: CallableFunction;
    }
  ) {
    try {
      loadingActions.on();

      const promise = await deleteSkuMovementApi(id);

      const response = await apiResponseHandler(promise);

      if (response.type === ApiResponseEnum.Failure) {
        toast.error(response.meta?.message ?? 'Something went wrong', {
          position: 'bottom-center',
        });
        if (response.meta?.message) {
          setError(response.meta?.message);
        }
        return;
      }

      toast.success(`Item successfully removed`, {
        position: 'bottom-center',
      });

      loadingActions.off();

      successCallback();

      dispatch(removeStockKeepingUnit(id));
    } catch (e) {
      console.error(error);
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      loadingActions.off();
    }
  }

  return {
    isLoading,
    error,
    deleteSkuMovement,
  };
};

export default useDeleteSkuMovement;
