import { useMemo } from 'react';
import { IDiscoveryFilter } from '../components/desktop/Discovery';
import { useAppSelector } from '@point-of-sale/store';
import { FulfillmentModeSchema } from '@point-of-sale/schemas';

export const useOrdersDiscoveryFilters = () => {
  const createdOn = useAppSelector(store => store.oms.createdOn);
  const filters = useAppSelector(store => store.oms.filters);
  const salesChannels = useAppSelector(store => store.common.salesChannels);

  const discoveryFilters: Array<IDiscoveryFilter> = useMemo(
    () => [
      {
        name: 'salesChannelId',
        placeholder: 'Sales Channel',
        options: salesChannels.data.ids.map(id => ({
          label: salesChannels.data.records[id].name,
          value: id,
        })),
        values: String(filters.salesChannelId?.value).split(',').map(Number) ?? [],
        type: 'MULTISELECT',
      },
      {
        name: 'lineItems.fulfillmentMode',
        placeholder: 'Fulfillment Mode',
        options: Object.values(FulfillmentModeSchema.Enum).map(value => ({
          label: value,
          value,
        })),
        values: String(filters['lineItems.fulfillmentMode']?.value).split(',') ?? [],
        type: 'MULTISELECT',
      },
      {
        name: 'createdOn',
        placeholder: 'Date',
        options: [],
        values: {
          from: createdOn.from,
          to: createdOn.to,
        },
        type: 'DATE_RANGE',
      },
    ],
    [filters, salesChannels, createdOn]
  );

  return { discoveryFilters };
};
