import { Button, ColorSelector, SizeSelector } from '@point-of-sale/components';
import { emptyFunction } from '@point-of-sale/utils';
import { useProductListItem } from '../context';
import * as Styles from './styles';
import { useBoolean } from '@point-of-sale/hooks';
import SizeChartBottomSheet from '../SizeChartBottomSheet';

interface IVariantsInfoProps {
  shouldShowOnlyBasicDetails?: boolean;
}

const VariantsInfo = ({ shouldShowOnlyBasicDetails }: IVariantsInfoProps) => {
  const { allColorOptions, allSizeOptions, data, onSelectColor, onSelectSize, enabledSizes } =
    useProductListItem();

  const [isSizeChartOpen, sizeChartOpenActions] = useBoolean();

  const allEnabledColors = allColorOptions.map(item => item.displayName);

  let availableColors = allColorOptions.map(item => ({
    id: item.displayName,
    name: item.displayName,
    colorCodes: item.colorCodes ?? [],
  }));

  let availableSizes = allSizeOptions;

  if (shouldShowOnlyBasicDetails) {
    if (data.selectedColor) {
      availableColors = availableColors.filter(color => color.name === data.selectedColor);
    }

    if (data.selectedSize) {
      availableSizes = availableSizes.filter(size => size.name === data.selectedSize);
    }
  }

  return (
    <Styles.Wrapper>
      <ColorSelector
        label="Colors"
        availableColors={availableColors}
        enabledColors={allEnabledColors}
        selectedColor={data.selectedColor}
        onSelectColor={shouldShowOnlyBasicDetails ? emptyFunction : onSelectColor}
        shouldAnimate={false}
      />

      <SizeSelector
        boxSize="COMPACT"
        label="Sizes"
        rightLabel={
          data.isSizeChartValid ? (
            <Button
              onClick={sizeChartOpenActions.on}
              sizeVariant="xs"
              variant="ghost"
              rightIcon="ArrowEastIcon"
              colorScheme="dark"
            >
              Size Guide
            </Button>
          ) : null
        }
        shouldShowCustomTailored={false}
        availableSizes={availableSizes}
        enabledSizes={enabledSizes}
        selectedSize={data.selectedSize}
        onSelectSize={shouldShowOnlyBasicDetails ? emptyFunction : onSelectSize}
        shouldAnimate={false}
      />

      {data.isSizeChartValid && (
        <SizeChartBottomSheet
          isOpen={isSizeChartOpen}
          onClose={sizeChartOpenActions.off}
          product={data}
        />
      )}
    </Styles.Wrapper>
  );
};

export default VariantsInfo;
