import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
/**
 *  TODO : figure out some way to clean this up
 * - maybe use a separate file to define the routes
 *  layout and page must be coupled together
 *  or should they ?
 */
import BasicLayout from '../components/desktop/BasicLayout';
import {
  getFacilities,
  getSalesChannels,
  getStoreStaff,
  getVendors,
  listenToNewReleases,
  useAppDispatch,
} from '@point-of-sale/store';

import PosHeaderLayout from '../components/desktop/PosHeaderLayout';
import OrderDetails from '../components/desktop/OrderDetails';

import Home from '../pages/desktop/Home';
import PointOfSale from '../pages/desktop/PointOfSale';
import OpenCarts from '../pages/desktop/OpenCarts';
import Customer from '../pages/desktop/Customer';
import Checkout from '../pages/desktop/Checkout';
import PendingTasks from '../pages/desktop/PendingTasks';
import ExploreProducts from '../pages/desktop/ExploreProducts';
import OrderManagementSystem from '../pages/desktop/OrderManagementSystem';
import Settings from '../pages/desktop/Settings';
import { ENV } from '@point-of-sale/env';
import ReleasePromptModal from '../components/desktop/ReleasePromptModal';
import NotificationsModal from '../components/desktop/NotificationsModal';
import { initializeRealtimeDatabaseApp } from '@lyskraft/firebase';

const BaseRoutes = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStoreStaff());
    dispatch(getSalesChannels());
    dispatch(getFacilities());
    dispatch(getVendors());

    // TODO: move this a level up
    initializeRealtimeDatabaseApp({
      config: {
        apiKey: ENV.VITE_FIREBASE_RUNWAY_API_KEY as string,
        authDomain: ENV.VITE_FIREBASE_RUNWAY_AUTH_DOMAIN as string,
        projectId: ENV.VITE_FIREBASE_RUNWAY_PROJECT_ID as string,
        storageBucket: ENV.VITE_FIREBASE_RUNWAY_STORAGE_BUCKET as string,
        messagingSenderId: ENV.VITE_FIREBASE_RUNWAY_MESSAGING_SENDER_ID as string,
        appId: ENV.VITE_FIREBASE_RUNWAY_APP_ID as string,
        measurementId: ENV.VITE_FIREBASE_RUNWAY_MEASUREMENT_ID as string,
        databaseURL: ENV.VITE_FIREBASE_RUNWAY_REALTIME_DATABASE_URL as string,
      },
    });

    dispatch(listenToNewReleases(ENV.VITE_ENVIRONMENT));
  }, []);

  return (
    <>
      <Routes>
        <Route path="*" element={<BasicLayout />}>
          <Route path="" element={<>Page Not Found</>} />
        </Route>
        <Route path="/" element={<BasicLayout />}>
          <Route path="" element={<Home />} />
        </Route>

        <Route path="/point-of-sale" element={<BasicLayout />}>
          <Route path="" element={<PosHeaderLayout />}>
            <Route index element={<Navigate to="/point-of-sale/add-customer" replace={true} />} />
            <Route path="add-customer" element={<Customer />} />
            <Route path="cart" element={<PointOfSale />} />
            <Route path="checkout" element={<Checkout />} />
            <Route
              path="*"
              element={<Navigate to="/point-of-sale/add-customer" replace={true} />}
            />
          </Route>
        </Route>

        <Route path="/open-carts" element={<BasicLayout />}>
          <Route path="" element={<OpenCarts />} />
        </Route>

        <Route path="/pending-tasks" element={<BasicLayout />}>
          <Route path="" element={<PendingTasks />} />
        </Route>

        <Route path="/products" element={<BasicLayout />}>
          <Route path="" element={<ExploreProducts />} />
        </Route>

        <Route path="/settings" element={<BasicLayout />}>
          <Route path="" element={<Settings />} />
        </Route>

        <Route path="/orders" element={<BasicLayout />}>
          <Route path="" element={<OrderManagementSystem />} />
          <Route path="/orders?status=cancelled" element={<OrderManagementSystem />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
        </Route>
      </Routes>

      <ReleasePromptModal />

      <NotificationsModal />
    </>
  );
};

export default BaseRoutes;
