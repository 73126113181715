export const CheckoutActionsTypes = {
  SET_SALES_POC: 'checkout/SET_SALES_POC',
  SET_PAYMENT_OPTIONS: 'checkout/SET_PAYMENT_OPTIONS',
  SET_PAYMENT_TERMINALS: 'checkout/SET_PAYMENT_TERMINALS',
  SET_CART_TRANSACTIONS: 'checkout/SET_CART_TRANSACTIONS',
  SET_CART_MIN_AMOUNT_TO_BE_PAID: 'checkout/SET_CART_AMOUNT_DEtAILS',
  SET_CART_TOTAL_AMOUNT_TO_BE_PAID: 'checkout/SET_CART_TOTAL_AMOUNT_TO_BE_PAID',
  SET_CART_TOTAL_AMOUNT_PAID: 'checkout/SET_CART_TOTAL_AMOUNT_PAID',
  SET_PROCESSED_ORDER: 'checkout/SET_PROCESSED_ORDER',
  SET_UPI_META_DATA: 'checkout/SET_UPI_META_DATA',
  SET_CARD_META_DATA: 'checkout/SET_CARD_META_DATA',
  SET_PAYMENT_META_DATA: 'checkout/SET_PAYMENT_META_DATA',

  RESET: 'checkout/RESET',
} as const;
