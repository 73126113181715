import { useEffect, useState } from 'react';
import { useBoolean } from '@point-of-sale/hooks';
import type { IProductListItem } from '@point-of-sale/store';
import { formatToReadableDate } from '@point-of-sale/utils';
import { FacilityDTOType } from '@point-of-sale/schemas';
import {
  getInventoryStatus,
  getWarehouseAndStoreInventoryOfVariantByCurrentStore,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { IMannerOfPurchase, ProductContext } from './context/ProductContext';
import Inventory from './Inventory';
import ProductImage from './ProductImage';
import VariantSelection from './VariantSelection';
import Nomenclature from './Nomenclature';
import Footer from './Footer';
import useGetServiceability from './useGetServiceability';
import * as Styles from './styles';

interface IProductProps {
  data: IProductListItem;
  componentRef?: (node: HTMLElement | null) => void;
}

const Product = ({ data, componentRef }: IProductProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const isSearchBarcodish = useAppSelector(store => store.pointOfSale.isBarcodish);

  const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);
  const facilityRecords = useAppSelector(store => store.common.facilities.data.records);

  const customerAddress = useAppSelector(
    store => store.pointOfSale.addresses.data.records[store.pointOfSale.selectedAddressId]
  );

  const [isExpanded, expandActions] = useBoolean();

  // TODO: Modularize this, so it can be reused in other components
  const [selectedVariant, setSelectedVariant] = useState<
    IProductProps['data']['variants'][number] | null
  >(null);

  const [quantity, setQuantity] = useState<number>(1);

  const [fulfillmentFacilityId, setFulfillmentFacilityId] = useState<number | null>(null);

  const [mannerOfPurchase, setMannerOfPurchase] = useState<IMannerOfPurchase>({
    shouldOverride: false,
    deliveryMode: 'STORE_PURCHASE',
    fulfillmentMode: 'ONHAND',
  });

  const [demandedPincode, setDemandedPincode] = useState('');

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  function onChangeDemandedPincode(newPincode: string) {
    setDemandedPincode(newPincode);
  }

  // TODO: Modularize this, so it can be reused in other components
  useEffect(() => {
    if (!data.selectedSize || !data.selectedColor) {
      setSelectedVariant(null);
      return;
    }

    const variant =
      data.variants.find(
        variant => variant.size === data.selectedSize && variant.color === data.selectedColor
      ) ?? null;

    setSelectedVariant(variant);
  }, [data.selectedColor, data.selectedSize, data.variants]);

  useEffect(() => {
    if (selectedSalesChannel && selectedVariant) {
      const { storeInventory, storeFacilityId, combinedWarehouseInventory, warehouseFacilityIds } =
        getWarehouseAndStoreInventoryOfVariantByCurrentStore(selectedVariant);

      let determinedFulfillmentFacilityId;

      if (storeInventory > 0) {
        determinedFulfillmentFacilityId = storeFacilityId;
      } else if (combinedWarehouseInventory > 0 && warehouseFacilityIds.length > 0) {
        determinedFulfillmentFacilityId = warehouseFacilityIds[0];
      }

      setFulfillmentFacilityId(
        Number(determinedFulfillmentFacilityId ?? selectedSalesChannel.facilityId)
      );
      const facilityKey = determinedFulfillmentFacilityId ?? selectedSalesChannel.facilityId;
      const selectedFacility = facilityRecords[facilityKey];

      setMannerOfPurchase(prev => ({
        ...prev,
        fulfillmentFacility: {
          name: selectedFacility.name,
          city: selectedFacility.address.cityDisplayName ?? '',
          pincode: selectedFacility.address.pincode ?? '',
        },
      }));
    }
  }, [selectedSalesChannel, facilityRecords, selectedVariant]);

  useEffect(() => {
    if (mode === 'MADE_TO_ORDER') {
      setMannerOfPurchase(prev => ({
        ...prev,
        shouldOverride: true,
        deliveryMode: 'STORE_PICKUP',
        fulfillmentMode: 'MTO',
      }));
    } else {
      setMannerOfPurchase(prev => ({
        ...prev,
        shouldOverride: false,
        deliveryMode: 'STORE_PURCHASE',
        fulfillmentMode: 'ONHAND',
        estimatedDeliveryDate: formatToReadableDate(new Date()),
      }));
    }
  }, [mode]);

  useEffect(() => {
    if (isSearchBarcodish) {
      expandActions.on();
    }
  }, [isSearchBarcodish]);

  const { fetchServiceabilityData, serviceabilityData, isFetchingServiceability } =
    useGetServiceability();

  useEffect(() => {
    if (!isExpanded) {
      return;
    }

    const doesAnyVariantHaveInventory = data.variants
      .map(variant => {
        const { hasInventoryInOtherStoreOrWarehouse } = getInventoryStatus(variant);

        return hasInventoryInOtherStoreOrWarehouse;
      })
      .some(item => item);

    if (isExpanded || doesAnyVariantHaveInventory) {
      const skuCodes = data.variants.map(variant => variant.skuCode);
      const destinationPincodes = Object.values(facilityRecords)
        .filter(item => !item.isOnline)
        .filter(item => item.facilityType === 'STORE')
        .map(item => item.address.pincode)
        .filter(item => item !== null);

      if (customerAddress) {
        destinationPincodes.push(customerAddress.pincode);
      } else if (demandedPincode) {
        destinationPincodes.push(demandedPincode);
      }

      fetchServiceabilityData(
        skuCodes,
        // @ts-expect-error destinationPincodes will not have undefined
        Array.from(new Set(destinationPincodes)),
        Object.values(facilityRecords),
        {}
      );
    }
    // TODO: Revisit this dependency array
  }, [isExpanded, customerAddress, facilityRecords, demandedPincode, selectedVariant]);

  let deliverToPincode: FacilityDTOType['address']['pincode'] = '';

  if (isSelectedSalesChannelOnline) {
    deliverToPincode = customerAddress?.pincode;
  }

  return (
    <ProductContext.Provider
      value={{
        data,
        isExpanded,
        selectedVariant,
        quantity,
        setQuantity,
        fulfillmentFacilityId,
        setFulfillmentFacilityId,
        mannerOfPurchase,
        setMannerOfPurchase,
        expandActions,
        deliverToPincode,
        serviceabilityData,
      }}
    >
      <Styles.Wrapper animate={isExpanded ? 'expanded' : 'collapsed'} ref={componentRef}>
        <Styles.Left>
          <ProductImage />
        </Styles.Left>
        <Styles.Right>
          <Nomenclature
            serviceabilityData={serviceabilityData}
            isFetchingServiceability={isFetchingServiceability}
          />

          <VariantSelection />

          <Inventory
            serviceabilityData={serviceabilityData}
            demandedPincode={demandedPincode}
            onChangeDemandedPincode={onChangeDemandedPincode}
          />

          <Footer
            serviceabilityData={serviceabilityData}
            isFetchingServiceability={isFetchingServiceability}
          />
        </Styles.Right>
      </Styles.Wrapper>
    </ProductContext.Provider>
  );
};

export default Product;
