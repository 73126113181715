import { SkuMovementActionsTypes } from './constants';
import { ISkuMovementsState, SkuMovementActionsType } from './types';
import { STANDARD_PAGINATION_SIZE } from '../constants';
import { createDescribedState } from '../utils/createDescribedState';

const INITIAL_STATE: ISkuMovementsState = {
  search: {
    query: '',
  },

  stockKeepingUnits: createDescribedState({
    ids: [],
    records: {},
  }),

  recentlyAddedIds: [],

  pagination: {
    page: 0,
    size: STANDARD_PAGINATION_SIZE,
    shouldFetchMore: true,
  },
} as const;

export const skuMovementReducer = (
  state = INITIAL_STATE,
  action: SkuMovementActionsType
): ISkuMovementsState => {
  switch (action.type) {
    case SkuMovementActionsTypes.SET_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    }

    case SkuMovementActionsTypes.SET_STOCK_KEEPING_UNITS: {
      return {
        ...state,
        stockKeepingUnits: {
          ...state.stockKeepingUnits,
          ...action.payload,
        },
      };
    }

    case SkuMovementActionsTypes.ADD_STOCK_KEEPING_UNIT: {
      return {
        ...state,
        stockKeepingUnits: {
          ...state.stockKeepingUnits,
          data: {
            ids: [action.payload.id, ...state.stockKeepingUnits.data.ids],
            records: {
              ...state.stockKeepingUnits.data.records,
              [action.payload.id]: action.payload,
            },
          },
        },
      };
    }

    case SkuMovementActionsTypes.REMOVE_STOCK_KEEPING_UNIT: {
      return {
        ...state,
        stockKeepingUnits: {
          ...state.stockKeepingUnits,
          data: {
            ids: state.stockKeepingUnits.data.ids.filter(id => id !== action.payload),
            records: Object.keys(state.stockKeepingUnits.data.records).reduce((acc, key) => {
              if (key !== action.payload.toString()) {
                // @ts-expect-error - TS can't infer that key is a number
                acc[key] = state.stockKeepingUnits.data.records[key];
              }

              return acc;
            }, {}),
          },
        },
      };
    }

    case SkuMovementActionsTypes.ADD_RECENTLY_ADDED_ID: {
      return {
        ...state,
        recentlyAddedIds: [action.payload, ...state.recentlyAddedIds],
      };
    }

    case SkuMovementActionsTypes.SET_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }

    case SkuMovementActionsTypes.RESET_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
