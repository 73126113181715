import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

interface ISectionProps {
  $flexOne?: boolean;
}

export const Section = styled.div<ISectionProps>`
  border-bottom: 1px solid var(--athens-gray);
  padding: 12px 16px;

  ${({ $flexOne }) =>
    $flexOne &&
    css`
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
    `}
`;

export const BankDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
