import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ImageContainer = styled.div`
  height: 100px;
  max-height: 100px;
  aspect-ratio: 0.75;
  flex-shrink: 0;
`;

export const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
