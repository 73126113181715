import { format } from 'date-fns';
import { Text } from '@point-of-sale/components';
import * as Styles from './styles';

interface INotificationData {
  id: string;
  title: string;
  body: string;
  created_on: number;
  is_read: boolean;
  is_seen: boolean;
  image?: string | null;
  deeplink?: string | null;
}

interface INotificationItemProps {
  data: INotificationData;
}

const NotificationItem = ({ data }: INotificationItemProps) => {
  return (
    <Styles.Wrapper $isRead={data.is_read}>
      {!data.is_read && <Styles.UnreadDot />}
      <Styles.ContentWrapper>
        <Styles.TextContent>
          <Text fontSize={16} color="var(--mine-shaft)">
            {data.title}
          </Text>
          <Text fontSize={14} color="var(--dove-gray)">
            {data.body}
          </Text>
          <Text fontSize={12} color="var(--silver-chalice)">
            {format(data.created_on, 'dd MMM, yyyy • hh:mm a')}
          </Text>
        </Styles.TextContent>
        {data.image && (
          <Styles.ImageContainer>
            <img src={data.image} alt={data.title} />
          </Styles.ImageContainer>
        )}
      </Styles.ContentWrapper>
    </Styles.Wrapper>
  );
};

export default NotificationItem;
