import { ComponentType, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { setIsMobileNavigationOpen, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { IconButton, Text } from '@point-of-sale/components';
import NotificationBell from '../../../components/shared/NotificationBell';
import * as Styles from './styles';
import { useNavigate } from 'react-router';

interface IWithMobileHeaderProps {
  name?: ReactNode;
  right?: ReactNode;
}

function WithMobileHeader<P extends object>(
  WrappedComponent: ComponentType<P>,
  headerProps?: IWithMobileHeaderProps
) {
  const HigherOrderComponent: React.FC<P> = props => {
    const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);
    // const isMobileNavigationOpen = useAppSelector(store => store.userInterface.isMobileNavigationOpen);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
      <>
        <Helmet>
          <title>Runway | {headerProps?.name}</title>
        </Helmet>
        <Styles.Wrapper>
          <Styles.Left>
            <IconButton
              icon="HamBurgerIcon"
              onClick={() => {
                dispatch(setIsMobileNavigationOpen(true));
              }}
              sizeVariant="medium"
              shapeVariant="circle"
            />
            <Text fontSize={16} weight="semibold" fontFamily="secondary">
              {selectedSalesChannel?.name} | {headerProps?.name}
            </Text>
          </Styles.Left>
          {headerProps?.right && <Styles.Right>{headerProps.right}</Styles.Right>}
          {/* <NotificationBell count={13} onClick={() => navigate('/notifications')} /> */}
        </Styles.Wrapper>
        <WrappedComponent {...props} />
      </>
    );
  };

  return HigherOrderComponent;
}

export default WithMobileHeader;
