/**
 *
 * https://jsbin.com/tupoyi/4/edit?html,js,output
 */
export function beep({
  volume = 1,
  frequency = 3000,
  duration = 160,
  type = 'sine',
}: {
  volume?: number;
  frequency?: number;
  duration?: number;
  type?: OscillatorType;
}) {
  const audioContext = new window.AudioContext();

  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();

  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  gainNode.gain.value = volume;
  oscillator.frequency.value = frequency;
  oscillator.type = type;

  oscillator.start();

  setTimeout(function () {
    oscillator.stop();
  }, duration);
}
