import { RadioGroupProps } from '@point-of-sale/components';
import { OrderDTOType, RefundModeEnumSchema } from '@point-of-sale/schemas';

export function getRefundOptions(orderData: OrderDTOType): RadioGroupProps['options'] {
  const isOfflineOrder = orderData.salesChannelType === 'POS';
  const isOnlineOrder = orderData.salesChannelType === 'SHOPIFY';

  if (isOfflineOrder) {
    return [
      {
        label: 'Upi',
        value: RefundModeEnumSchema.Enum.UPI,
      },
      {
        label: 'Store Credit',
        value: RefundModeEnumSchema.Enum.STORE_CREDIT,
      },
      {
        label: 'Cash',
        value: RefundModeEnumSchema.Enum.CASH,
      },
      {
        label: 'Bank Account',
        value: RefundModeEnumSchema.Enum.BANK_ACCOUNT,
      },
      {
        label: 'Others',
        value: RefundModeEnumSchema.Enum.OTHERS,
      },
    ];
  }

  if (isOnlineOrder) {
    const firstLineItem = orderData.lineItems[0];

    const isCashOnDelivery = firstLineItem.paymentMethod === 'COD';
    const isPrepaid = firstLineItem.paymentMethod === 'PREPAID';

    if (isPrepaid) {
      return [
        {
          label: 'Store Credit',
          value: RefundModeEnumSchema.Enum.STORE_CREDIT,
        },
        {
          label: 'Source',
          value: RefundModeEnumSchema.Enum.SOURCE,
        },
        {
          label: 'Others',
          value: RefundModeEnumSchema.Enum.OTHERS,
        },
      ];
    }

    if (isCashOnDelivery) {
      return [
        {
          label: 'Store Credit',
          value: RefundModeEnumSchema.Enum.STORE_CREDIT,
        },
        {
          label: 'Source',
          value: RefundModeEnumSchema.Enum.SOURCE,
        },
        {
          label: 'Upi',
          value: RefundModeEnumSchema.Enum.UPI,
        },
        {
          label: 'Others',
          value: RefundModeEnumSchema.Enum.OTHERS,
        },
      ];
    }
  }

  return [];
}
