import {
  addMinutes,
  format,
  getUnixTime,
  isBefore,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { PLACEHOLDERS } from '@point-of-sale/constants';

export const formatToReadableDate = (date: Date | string) => {
  try {
    if (date instanceof Date) {
      return format(date, 'd MMM yyyy');
    }

    return format(new Date(date), 'd MMM yyyy');
  } catch (error) {
    if (error instanceof Error) {
      return error.message;
    }

    return PLACEHOLDERS.NOT_AVAILABLE;
  }
};

export const getExpressPromiseDate = () => {
  const now = new Date();

  // 5 PM
  const sevenPM = setHours(setMinutes(setSeconds(now, 0), 0), 17);
  // const sevenPM = setHours(setMinutes(setSeconds(now, 0), 0), 19);

  let hasMovedToNextDay = false;

  let result;
  if (isBefore(now, sevenPM)) {
    // * If before 7 PM, add 90 minutes to the current time
    result = addMinutes(now, 90);
  } else {
    // * after 7 PM, move to the next day
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    result = tomorrow;
    hasMovedToNextDay = true;
  }

  return {
    calculatedPromiseDate: getUnixTime(result).toString(),
    hasMovedToNextDay,
  };
};

/**
 * Determines if the provided compareDate is the latest date in the given array of date strings.
 *
 * @param dates - An array of date strings to compare against.
 * @param compareDate - The date string to compare.
 * @returns A boolean indicating whether the compareDate is the latest date.
 */
export function isLatestDate(dates: string[], compareDate: string): boolean {
  // Convert the array of date strings to Date objects
  const dateObjects = dates.map(date => new Date(date));

  // Convert the compareDate to a Date object
  const compareDateObject = new Date(compareDate);

  // Find the maximum date in the array
  const latestDate = new Date(Math.max(...dateObjects.map(date => date.getTime())));

  // Check if the compareDateObject is the same as the latest date
  return compareDateObject.getTime() === latestDate.getTime();
}
