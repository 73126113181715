import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TableContainer = styled.div`
  overflow: hidden;
  border: 1px solid var(--athens-gray);
  border-bottom: none;
  border-radius: 1px;
`;

export const GridRow = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, minmax(0, 1fr))`};
  height: 34px;
`;

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--ghost-white);
  border-right: 1px solid var(--athens-gray);
  border-bottom: 1px solid var(--athens-gray);
  padding: 0 12px;

  &:last-child {
    border-right: none;
  }
`;

export const DataCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid var(--athens-gray);
  border-bottom: 1px solid var(--athens-gray);

  &:last-child {
    border-right: none;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  -webkit-tap-highlight-color: transparent;
`;
