import { IconPropsType } from '../types';

const OrdersIcon = ({
  size = 24,
  stroke = 'var(--cod-gray)',
  strokeWidth = 1.5,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 8v5.429c0 4.04 0 6.06 1.303 7.316C4.606 22 6.73 22 11 22h2.221c4.191 0 6.286 0 7.587-1.255C22.109 19.489 22.109 17.469 22.109 13.429V8m-20 0h20m-20 0 .961-2.308c.747-1.79 1.119-2.684 1.875-3.188S6.56 2 8.5 2h7c1.939 0 2.908 0 3.664.504s1.129 1.4 1.875 3.188L22.109 8M12 8V2M10 12h4"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OrdersIcon;
