import { useCallback } from 'react';
import { IOption } from '@point-of-sale/components';
import {
  deleteOMSFilter,
  getOrders,
  OmsFilterNames,
  setOrders,
  setOrdersPagination,
  setOrdersSearch,
  updateOMSCreatedOnFilters,
  updateOMSFilters,
  useAppDispatch,
} from '@point-of-sale/store';
import { IDateRange } from '@point-of-sale/types';

export const useOrdersDiscovery = () => {
  const dispatch = useAppDispatch();

  const onChangeFilter = useCallback(
    (field: OmsFilterNames, options: Array<IOption> | IDateRange) => {
      dispatch(
        setOrdersPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );

      if (field === 'createdOn') {
        const selectedRange = options as IDateRange;
        dispatch(updateOMSCreatedOnFilters(selectedRange));
        return;
      }

      dispatch(
        updateOMSFilters({
          filter: {
            field,
            value: (options as Array<IOption>).map(option => option.value).join(','),
            filterType: field === 'salesChannelId' ? 'IN' : 'IN',
          },
          name: field,
        })
      );
    },
    [dispatch]
  );

  const onChangeSearch = useCallback(
    (searchQuery: string) => {
      dispatch(
        setOrdersPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );

      dispatch(
        setOrders({
          data: {
            ids: [],
            records: {},
          },
        })
      );

      dispatch(
        setOrdersSearch({
          query: searchQuery,
        })
      );

      dispatch(getOrders());
    },
    [dispatch]
  );

  const onRemoveFilter = useCallback(
    (filterName: OmsFilterNames) => {
      dispatch(deleteOMSFilter(filterName as OmsFilterNames));
    },
    [dispatch]
  );

  return { onChangeFilter, onChangeSearch, onRemoveFilter };
};
