import { z } from 'zod';
import toast from 'react-hot-toast';
import { createListFetchingThunk } from '../utils/createListFetchingThunk';
import type {
  CartTransactionAddDTOType,
  CartTransactionDTOType,
  PaymentOptionDTOType,
  PaymentTerminalDTOType,
  OrderDTOType,
} from '@point-of-sale/schemas';
import {
  CartTransactionDTOSchema,
  PaymentOptionDTOSchema,
  PaymentTerminalDTOSchema,
} from '@point-of-sale/schemas';
import {
  addCartTransactionsApi,
  getCartTransactionsApi,
  getPaymentOptionsApi,
  getPaymentTerminalsApi,
  processTransactionApi,
} from './api';
import {
  setCartTransactions,
  setPaymentOptions,
  setPaymentTerminals,
  setCartMinAmountToBePaid,
  setCartTotalAmountPaid,
  setCartTotalAmountToBePaid,
  setProcessedOrder,
} from './actions';
import { ThunkActionType } from '../store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, omit } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';

import { normalizeArray } from '../utils/normalizeArray';
import { getCartById } from '../pointOfSale/thunks/cartThunks';

export const getPaymentOptions = (cartId: number) =>
  createListFetchingThunk<PaymentOptionDTOType>({
    dispatchable: setPaymentOptions,
    dataFetcher: getPaymentOptionsApi.bind(null, cartId),
    zodSchema: z.array(PaymentOptionDTOSchema),
  });

export const getPaymentTerminals = () =>
  createListFetchingThunk<PaymentTerminalDTOType>({
    dispatchable: setPaymentTerminals,
    dataFetcher: getPaymentTerminalsApi,
    zodSchema: z.array(PaymentTerminalDTOSchema),
  });

export const getCartTransactions = (cartId: number, successCallback?: () => void) =>
  createListFetchingThunk<CartTransactionDTOType>({
    dispatchable: setCartTransactions,
    dataFetcher: getCartTransactionsApi.bind(null, cartId),
    zodSchema: z.array(CartTransactionDTOSchema),
    successCallback: successCallback,
  });

// TODO: Refactor this
export const addTransactionToCart =
  (
    item: CartTransactionAddDTOType,
    {
      successCallback,
      failCallback,
    }: {
      successCallback?: (id: number) => void;
      failCallback?: () => void;
    }
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const cartId = getState().pointOfSale.cart.data.id;
    const salesPoc = getState().checkout.salesPoc;

    if (cartId === -1 || !salesPoc) {
      return;
    }

    dispatch(
      setCartTransactions({
        isLoading: true,
      })
    );

    const salesStaffId = salesPoc.id;

    const promise = addCartTransactionsApi(cartId, { ...item, salesStaffId });

    const response = await apiResponseHandler<CartTransactionDTOType>(
      promise,
      CartTransactionDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');

      dispatch(
        setCartTransactions({
          isLoading: false,
          isError: true,
          error: response.meta?.message,
        })
      );

      failCallback?.();
      return;
    }

    const cartMinAmountToBePaid = response.data.cartMinAmountToBePaid;
    const cartTotalAmountToBePaid = response.data.cartTotalAmountToBePaid;
    const cartTotalAmountPaid = response.data.cartTotalAmountPaid;
    // TODO: move this logic to reducer and use operations - append/replace etc
    const newTransactions = normalizeArray([
      omit(response.data, [
        'cartMinAmountToBePaid',
        'cartTotalAmountPaid',
        'cartTotalAmountToBePaid',
      ]),
    ]);

    // normalizeArray
    dispatch(
      setCartTransactions({
        isLoading: false,
        isSuccess: true,
        data: {
          ids: newTransactions.ids,
          records: newTransactions.records,
        },
      })
    );

    dispatch(setCartMinAmountToBePaid(cartMinAmountToBePaid ?? 0));
    dispatch(setCartTotalAmountToBePaid(cartTotalAmountToBePaid ?? 0));
    dispatch(setCartTotalAmountPaid(cartTotalAmountPaid ?? 0));

    if (response.data.order) {
      dispatch(
        setProcessedOrder({
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: response.data.order,
        })
      );
    }

    successCallback?.(response.data.id);
    dispatch(getCartTransactions(cartId));
    dispatch(getCartById(String(cartId)));
  };

export const processCartTransaction = (): ThunkActionType => async (dispatch, getState) => {
  dispatch(
    setProcessedOrder({
      isLoading: true,
      isSuccess: false,
      isError: false,
    })
  );

  const cartId = getState().pointOfSale.cart.data.id;

  const promise = processTransactionApi(String(cartId));

  const response = await apiResponseHandler<OrderDTOType>(promise);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setProcessedOrder({
        isLoading: false,
        isError: true,
        isSuccess: false,
      })
    );
    return;
  }

  dispatch(
    setProcessedOrder({
      isLoading: false,
      isSuccess: true,
      isError: false,
      data: response.data,
    })
  );
};
