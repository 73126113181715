import { IOption } from './types';
import * as Styles from './styles';

const OptionItem = ({
  option,
  isChecked,
  onChange,
}: {
  option: IOption;
  isChecked: boolean;
  onChange: () => void;
}) => (
  <Styles.OptionCheckbox
    key={`${option.label}--${option.value}`}
    name={option.value as string}
    onChange={onChange}
    isDisabled={option.isDisabled}
    isChecked={isChecked}
    label={option.label}
  />
);

export default OptionItem;
