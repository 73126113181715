import { useCallback, useState } from 'react';
import { IBankDetails } from './types';

const useBankDetails = () => {
  const [bankDetails, setBankDetails] = useState<IBankDetails>({
    ifsc: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    confirmAccountNumber: '',
  });

  const getAreBankDetailsValid = useCallback(() => {
    if (
      bankDetails.accountNumber.length > 0 &&
      bankDetails.accountNumber !== bankDetails.confirmAccountNumber
    ) {
      return true;
    }

    return (
      bankDetails.accountName === '' ||
      bankDetails.bankName === '' ||
      bankDetails.accountNumber === '' ||
      bankDetails.ifsc === ''
    );
  }, [bankDetails]);

  return {
    bankDetails,
    setBankDetails,
    getAreBankDetailsValid,
  };
};

export default useBankDetails;
