import { addDays } from 'date-fns';
import { DeliverabilityType, ICommonState, RootStateType, store } from '@point-of-sale/store';
import { formatToReadableDate } from '@point-of-sale/utils';
import { CartItemDTOType, INormalizedServiceabilityData } from '@point-of-sale/schemas';

export const getDeliveryDateByServiceability = (
  serviceabilityData: INormalizedServiceabilityData[string],
  facilityId: number | undefined,
  facilities: ICommonState['facilities']['data'],
  isMTO: boolean,
  isMTC: boolean,
  defaultTat: number,
  isJIT: boolean,
  cartItem?: CartItemDTOType
): string => {
  const defaultDate = formatToReadableDate(addDays(new Date(), defaultTat));

  if (!facilityId) {
    return defaultDate;
  }

  let serviceability = serviceabilityData.find(
    item => item.to === facilities.records[facilityId]?.address?.pincode
  );

  if (!serviceability && (isMTC || isMTO || isJIT) && serviceabilityData.length > 0) {
    serviceability = serviceabilityData[0];
  }

  const deliverability: DeliverabilityType[number] | null = cartItem
    ? (store.getState() as RootStateType).pointOfSale.deliverability[cartItem.id]
    : null;

  const opsTat =
    (isMTC || isMTO || isJIT
      ? serviceability?.info.outerOpsTat
      : serviceability?.info.innerOpsTat) ?? 0;
  // const courierTat = cartItem?.courierTat;
  const maybePriority = cartItem?.isPriority || deliverability?.preferredShipping === 'PRIORITY';
  const courierTat =
    (maybePriority
      ? serviceability?.info.priorityCourierTat
      : serviceability?.info.standardCourierTat) ?? 0;

  let determinedVendorTat = 0;

  if (cartItem?.fulfillmentMode !== 'ONHAND') {
    switch (cartItem?.fulfillmentMode) {
      case 'MTO': {
        determinedVendorTat = serviceability?.info.mtoTat ?? 0;
        break;
      }
      case 'MTC': {
        determinedVendorTat = serviceability?.info.mtcTat ?? 0;
        break;
      }
      case 'JIT': {
        determinedVendorTat = serviceability?.info.jitTat ?? 0;
        break;
      }
      default: {
        determinedVendorTat = 0;
      }
    }
  }

  const savedVendorTat =
    (cartItem ? (cartItem.vendorDeliveryDate ? cartItem.vendorTat : determinedVendorTat) : 0) ?? 0;

  const sumOfAllTats = cartItem ? courierTat + savedVendorTat + opsTat : 0;

  if (isJIT) {
    return serviceability?.info.jitTat
      ? formatToReadableDate(addDays(new Date(), savedVendorTat + courierTat + opsTat))
      : defaultDate;
  }

  if (serviceability?.info.express) {
    if (cartItem?.fulfillmentMode === 'ONHAND') {
      return 'EXPRESS';
    }

    // return 'EXPRESS';
  }

  if (isMTO) {
    if (cartItem) {
      return formatToReadableDate(addDays(new Date(), sumOfAllTats));
    }

    return serviceability?.info.mtoTat
      ? formatToReadableDate(addDays(new Date(), serviceability?.info.mtoTat))
      : defaultDate;
  }

  if (isMTC) {
    if (cartItem) {
      return formatToReadableDate(addDays(new Date(), sumOfAllTats));
    }

    return serviceability?.info.mtcTat
      ? formatToReadableDate(addDays(new Date(), serviceability?.info.mtcTat))
      : defaultDate;
  }

  // if (serviceability?.info.express) {
  //   return 'EXPRESS';
  // }

  if (cartItem) {
    return formatToReadableDate(addDays(new Date(), sumOfAllTats));
  }

  return serviceability?.info.bestTat
    ? formatToReadableDate(addDays(new Date(), serviceability?.info.bestTat))
    : defaultDate;
};
