import { registerFirebaseServiceWorker } from '@lyskraft/firebase';
import { ENV } from '@point-of-sale/env';

export function registerServiceWorkers(config?: Record<string, CallableFunction>) {
  if ('serviceWorker' in navigator && window) {
    window.addEventListener('load', () => {
      registerFirebaseServiceWorker({
        apiKey: ENV.VITE_FIREBASE_NOTIFICATIONS_API_KEY as string,
        authDomain: ENV.VITE_FIREBASE_NOTIFICATIONS_AUTH_DOMAIN as string,
        projectId: ENV.VITE_FIREBASE_NOTIFICATIONS_PROJECT_ID as string,
        storageBucket: ENV.VITE_FIREBASE_NOTIFICATIONS_STORAGE_BUCKET as string,
        messagingSenderId: ENV.VITE_FIREBASE_NOTIFICATIONS_MESSAGING_SENDER_ID as string,
        appId: ENV.VITE_FIREBASE_NOTIFICATIONS_APP_ID as string,
        measurementId: ENV.VITE_FIREBASE_NOTIFICATIONS_MEASUREMENT_ID as string,
        vapidKey: ENV.VITE_FIREBASE_NOTIFICATIONS_VAPID_KEY as string,
      });
    });
  }
}

export function unregisterServiceWorkers() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
