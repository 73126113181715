import styled from 'styled-components';
import { motion } from 'motion/react';
import { ThemeType } from '../themes/types';
import { IButtonProps } from './index';
import { BUTTON_HEIGHTS, BUTTON_PADDING_VALUES, COLOR_CONFIG } from './constants';

export interface IStyledButtonProps {
  $variant: NonNullable<IButtonProps['variant']>;
  $isFullWidth?: NonNullable<IButtonProps['isFullWidth']>;
  $colorScheme: NonNullable<IButtonProps['colorScheme']>;
  $sizeVariant: NonNullable<IButtonProps['sizeVariant']>;
  $isLoading: NonNullable<IButtonProps['isLoading']>;
  theme: ThemeType;
}

export const Button = styled(motion.button)<IStyledButtonProps>`
  // *─── Static Properties ───────────────────────────────────────────────────────

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 1px;
  white-space: nowrap;
  gap: 8px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  // TODO: view this
  /* overflow: hidden; */

  // *─── Dynamic Properties ──────────────────────────────────────────────────────

  width: ${props => (props.$isFullWidth ? '100%' : 'fit-content')};

  padding: ${props => BUTTON_PADDING_VALUES[props.$sizeVariant]};

  cursor: ${props => (props.$isLoading ? 'progress' : 'pointer')};

  background: ${props => COLOR_CONFIG[props.$colorScheme][props.$variant].background};

  font-weight: ${props => props.theme.typography.fonts.weight.regular};

  color: ${props => COLOR_CONFIG[props.$colorScheme][props.$variant].color};

  height: ${props => BUTTON_HEIGHTS[props.$sizeVariant]}px;

  font-size: ${props => (props.$sizeVariant === 'xs' ? '12px' : '14px')};

  text-decoration: ${props => COLOR_CONFIG[props.$colorScheme][props.$variant].textDecoration};

  border: 1px solid
    ${(props: IStyledButtonProps) => COLOR_CONFIG[props.$colorScheme][props.$variant].borderColor};

  // *─── Hover Properties ────────────────────────────────────────────────────────
  &:hover {
    background: ${props =>
      props.$isLoading
        ? COLOR_CONFIG[props.$colorScheme][props.$variant].backgroundOnHover.whileLoading
        : COLOR_CONFIG[props.$colorScheme][props.$variant].backgroundOnHover.atRest};
  }

  // *─── Disabled Properties ─────────────────────────────────────────────────────

  &:disabled {
    cursor: not-allowed;
    opacity: 50%;
    background: ${(props: IStyledButtonProps) =>
      COLOR_CONFIG[props.$colorScheme][props.$variant].disabledBackground};
  }
`;

export const SpinnerWrapper = styled(motion.div)<{
  $colorScheme: NonNullable<IButtonProps['colorScheme']>;
  $variant: NonNullable<IButtonProps['variant']>;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${props => COLOR_CONFIG[props.$colorScheme][props.$variant].background};
`;
