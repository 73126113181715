import { IconPropsType } from '../types';

const CancelledOrderIcon = ({
  stroke = 'var(--cod-gray)',
  size = 24,
  strokeWidth = 1.5,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" stroke={stroke}>
      <path
        d="M3.25 9.23V2.125C3.25 1.504 3.754 1 4.375 1h11.25c.621 0 1.125.504 1.125 1.125v3.761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 5.75v8.375a.375.375 0 0 1-.375.375H7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 1.25v4.46a.5.5 0 0 0 .812.39l1.063-.85a1 1 0 0 1 1.25 0l1.063.85a.5.5 0 0 0 .812-.39V1.25" />
      <circle cx={4} cy={12.75} r={3.5} />
      <path d="m2.5 11.25 2.997 3m.003-3-2.996 3" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="var(--white)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CancelledOrderIcon;
