import { Button, Input, Modal, Text } from '@point-of-sale/components';
import { IBankDetails } from './types';
import * as Styles from './styles';

interface IBankDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSubmitButtonDisabled: boolean;
  bankDetails: IBankDetails;
  setBankDetails: React.Dispatch<React.SetStateAction<IBankDetails>>;
}

const BankDetailsModal = ({
  isOpen,
  onClose,
  isSubmitButtonDisabled,
  bankDetails,
  setBankDetails,
}: IBankDetailsModalProps) => {
  const warning =
    bankDetails.confirmAccountNumber.length > 0 &&
    bankDetails.confirmAccountNumber !== bankDetails.accountNumber
      ? 'Account numbers do not match'
      : '';

  return (
    <Modal
      heading={<Text fontSize={20}>Bank Details</Text>}
      isOpen={isOpen}
      size={'small'}
      onClose={onClose}
      footer={
        <Button
          onClick={onClose}
          sizeVariant="medium"
          isDisabled={isSubmitButtonDisabled}
          isFullWidth
        >
          Done
        </Button>
      }
    >
      <Styles.Content>
        <Input
          label="Account Holder's Name"
          value={bankDetails.accountName}
          onChange={e => {
            setBankDetails({
              ...bankDetails,
              accountName: e.target.value,
            });
          }}
          sizeVariant="extraSmall"
          variant="outline"
        />
        <Input
          label="Bank Name"
          value={bankDetails.bankName}
          onChange={e => {
            setBankDetails({
              ...bankDetails,
              bankName: e.target.value,
            });
          }}
          sizeVariant="extraSmall"
          variant="outline"
        />

        <Input
          sizeVariant="extraSmall"
          variant="outline"
          isFullWidth
          label="IFSC"
          value={bankDetails.ifsc}
          onChange={e => {
            setBankDetails({
              ...bankDetails,
              ifsc: e.target.value,
            });
          }}
        />
        <div
          style={{
            display: 'flex',
            gap: '12px',
          }}
        >
          <Input
            label="Account Number"
            value={bankDetails.accountNumber}
            sizeVariant="extraSmall"
            variant="outline"
            isFullWidth
            onChange={e => {
              setBankDetails({
                ...bankDetails,
                accountNumber: e.target.value,
              });
            }}
          />
          <Input
            label="Confirm Account Number"
            value={bankDetails.confirmAccountNumber}
            sizeVariant="extraSmall"
            variant="outline"
            isFullWidth
            onChange={e => {
              setBankDetails({
                ...bankDetails,
                confirmAccountNumber: e.target.value,
              });
            }}
            warning={warning}
          />
        </div>
      </Styles.Content>
    </Modal>
  );
};

export default BankDetailsModal;
