import React, { ReactNode } from 'react';
import WithMobileHeader from '../WithMobileHeader';

interface WithMobileHeaderWrapperProps {
  name: ReactNode;
  right?: ReactNode;
  children: ReactNode;
}

const WithMobileHeaderWrapper: React.FC<WithMobileHeaderWrapperProps> = ({
  name,
  right,
  children,
}) => {
  const WrappedComponent: React.FC = () => children;

  const HeaderComponent = WithMobileHeader(WrappedComponent, { name, right });

  return <HeaderComponent />;
};

export default WithMobileHeaderWrapper;
