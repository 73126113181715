import styled from 'styled-components';

export const Wrapper = styled.div<{ $isRead: boolean }>`
  padding: 16px;
  padding-left: 32px; // Added extra padding for unread dot
  background: ${({ $isRead }) => ($isRead ? 'var(--white)' : 'var(--zircon)')};
  border-bottom: 1px solid var(--gallery);
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--wild-sand);
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: var(--mine-shaft);
  margin: 0;
`;

export const Body = styled.p`
  font-size: 14px;
  color: var(--dove-gray);
  margin: 0;
  line-height: 1.4;
`;

export const Timestamp = styled.span`
  font-size: 12px;
  color: var(--silver-chalice);
`;

export const UnreadDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--royal-blue);
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;
