import { Counter } from '@point-of-sale/components';
import useProductContext from '../context/useProductContext';
import { getInventoryStatus, useAppSelector } from '@point-of-sale/store';
import AddToBag from '../AddOperations/AddToBag';
import BuyWithoutInventory from '../AddOperations/BuyWithNoInventory';
import PlaceMadeToOrder from '../AddOperations/PlaceMadeToOrder';
// import UpdateCartItem from '../AddOperations/UpdateCartItem';

const Actionables = () => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const { quantity, setQuantity, selectedVariant } = useProductContext();

  const { hasInventoryInCurrentStoreOrWarehouse } = getInventoryStatus(selectedVariant);

  return (
    <>
      <Counter
        value={quantity}
        min={1}
        onDecrement={val => setQuantity(val)}
        onIncrement={val => setQuantity(val)}
      />

      {mode === 'BUY_NOW' && (
        <>
          {hasInventoryInCurrentStoreOrWarehouse && <AddToBag />}
          {!hasInventoryInCurrentStoreOrWarehouse && <BuyWithoutInventory />}
        </>
      )}
      {mode === 'MADE_TO_ORDER' && <PlaceMadeToOrder />}
    </>
  );
};

export default Actionables;
