import { createDescribedState } from '../utils/createDescribedState';
import { ServiceabilityActionsTypes } from './constants';
import { IServiceabilityState, ServiceabilityActionsType } from './types';

const INITIAL_STATE: IServiceabilityState = {
  serviceability: createDescribedState([]),
} as const;

export const serviceabilityReducer = (
  state = INITIAL_STATE,
  action: ServiceabilityActionsType
): IServiceabilityState => {
  switch (action.type) {
    case ServiceabilityActionsTypes.SET_SERVICEABILITY: {
      return {
        ...state,
        serviceability: {
          ...state.serviceability,
          ...action.payload,
        },
      };
    }

    case ServiceabilityActionsTypes.RESET_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
