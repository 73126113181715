import { ThemeType } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px;

  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 1;
`;

export const Row = styled.div<{ theme: ThemeType }>`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  input {
    font-size: ${props => props.theme.typography.fonts.size[16]};
  }
`;
