import React, { ReactNode } from 'react';
import WithHeader from '../WithHeader';

interface WithHeaderWrapperProps {
  name: ReactNode;
  right?: ReactNode;
  children: ReactNode;
}

const WithHeaderWrapper: React.FC<WithHeaderWrapperProps> = ({ name, right, children }) => {
  const WrappedComponent: React.FC = () => children as ReactNode;

  const HeaderComponent = WithHeader(WrappedComponent, { name, right });

  return <HeaderComponent />;
};

export default WithHeaderWrapper;
