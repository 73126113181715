import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootStateType } from '../store';
import { getCurrentStoreAndWarehouseFacilityIds } from './utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { areAllValuesEmptyStrings } from '@point-of-sale/utils';

const selectCart = (state: RootStateType) => state.pointOfSale.cart;

export const allCartItemsSelector = createDraftSafeSelector(
  selectCart,
  cart => cart.data.cartItems.ids
);

export const jitOrMtoOrMtcItemsSelector = createDraftSafeSelector(selectCart, cart => {
  return cart.data.cartItems.ids.filter(id =>
    ['JIT', 'MTO', 'MTC'].includes(cart.data.cartItems.records[id].fulfillmentMode)
  );
});

export const readyMadeItemsFromOtherStoreOrWarehouseSelector = createDraftSafeSelector(
  selectCart,
  cart => {
    const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

    return cart.data.cartItems.ids.filter(
      id =>
        cart.data.cartItems.records[id].deliveryMode !== 'STORE_PURCHASE' &&
        cart.data.cartItems.records[id].fulfillmentMode === 'ONHAND' &&
        !currentStoreAndWarehousesFacilityIds.includes(
          cart.data.cartItems.records[id].fulfillmentFacilityId
        )
    );
  }
);

export const storePurchaseItemsWithoutAlterationSelector = createDraftSafeSelector(
  selectCart,
  cart => {
    return cart.data.cartItems.ids.filter(
      id =>
        cart.data.cartItems.records[id].deliveryMode === 'STORE_PURCHASE' &&
        cart.data.cartItems.records[id].fulfillmentMode === 'ONHAND'
    );
  }
);

export const storePurchaseItemsWithHomeDeliveryAndWithoutAlterationSelector =
  createDraftSafeSelector(selectCart, cart => {
    const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

    return cart.data.cartItems.ids.filter(
      id =>
        cart.data.cartItems.records[id].deliveryMode === 'HOME_DELIVERY' &&
        cart.data.cartItems.records[id].fulfillmentMode === 'ONHAND' &&
        currentStoreAndWarehousesFacilityIds.includes(
          cart.data.cartItems.records[id].fulfillmentFacilityId
        ) &&
        areAllValuesEmptyStrings(cart.data.cartItems.records[id].alterationEntry)
    );
  });

export const storePurchaseItemsWithAlterationSelector = createDraftSafeSelector(
  selectCart,
  cart => {
    const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

    return cart.data.cartItems.ids.filter(
      id =>
        currentStoreAndWarehousesFacilityIds.includes(
          cart.data.cartItems.records[id].fulfillmentFacilityId
        ) && !areAllValuesEmptyStrings(cart.data.cartItems.records[id].alterationEntry)
    );
  }
);
