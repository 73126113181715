import { memo } from 'react';
import { Button } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { getInventoryStatus } from '@point-of-sale/store';
import { useProductListItem } from '../context';
import LossOfSaleBottomSheet from '../LossOfSaleBottomSheet';
import InventoryBottomSheet from '../InventoryBottomSheet';
import * as Styles from './styles';

const Footer = () => {
  const { selectedVariant } = useProductListItem();

  const [isInventoryBottomSheetOpen, inventoryBottomSheetActions] = useBoolean();
  const [isLossOfSaleSheetOpen, lossOfSaleBottomSheetActions] = useBoolean();

  const { hasInventoryInCurrentStoreOrWarehouse } = getInventoryStatus(selectedVariant);

  return (
    <Styles.Wrapper>
      <Button
        onClick={inventoryBottomSheetActions.on}
        variant="outline"
        sizeVariant="xs"
        isDisabled={!selectedVariant}
        isFullWidth
      >
        View Inventory Details
      </Button>
      {selectedVariant && !hasInventoryInCurrentStoreOrWarehouse && (
        <Button
          onClick={lossOfSaleBottomSheetActions.on}
          variant="outline"
          sizeVariant="xs"
          isFullWidth
        >
          Notify Me
        </Button>
      )}
      <InventoryBottomSheet
        isOpen={isInventoryBottomSheetOpen}
        onClose={inventoryBottomSheetActions.off}
      />
      <LossOfSaleBottomSheet
        isOpen={isLossOfSaleSheetOpen}
        onClose={lossOfSaleBottomSheetActions.off}
      />
    </Styles.Wrapper>
  );
};

export default memo(Footer);
