import styled from 'styled-components';

export const Header = styled.div`
  padding: 12px;
  border-bottom: 1px solid var(--athens-gray);
  width: 100%;
`;

export const ContentWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

export const ChartImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid var(--athens-gray);
`;
