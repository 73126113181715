import { Input } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div<{ $hasMarginBottom: boolean }>`
  width: 100%;
  margin-bottom: ${props => (props.$hasMarginBottom ? '48px' : '0')};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Bottom = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 2fr 4fr;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;
