import { ReactNode, useEffect, useState } from 'react';
import { AnimatePresence } from 'motion/react';
import { Input, SpinnerIcon, Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';

import Address from './Address';
import GstDetails from './GstDetails';
import CountrySelector from '../CountrySelector';
import * as Styles from './styles';

const ActionablesVariants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
};

interface ICustomerDetailBaseProps {
  actionables: (formData: {
    phone: string;
    name?: string;
    email?: string;
    country?: string;
  }) => ReactNode;
  shouldShowAddresses?: boolean;
  isCartLoading?: boolean;
}

const CustomerDetails = ({
  actionables,
  isCartLoading = false,
  shouldShowAddresses = true,
}: ICustomerDetailBaseProps) => {
  const [formData, setFormData] = useState({
    phone: '',
    name: '',
    email: '',
    country: '+91',
  });

  const [status, setStatus] = useState<'IDLE' | 'EDITING'>('IDLE');

  const customerData = useAppSelector(store => store.pointOfSale.customer.data);
  const addresses = useAppSelector(store => store.pointOfSale.addresses.data);
  const isAddressesLoading = useAppSelector(store => store.pointOfSale.addresses.isLoading);

  function handleFormInputChanges(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    if (customerData) {
      setFormData({
        email: customerData.email ?? '',
        phone: customerData.phone ?? '',
        name: customerData.name ?? '',
        country: customerData.country ?? '+91',
      });
    }
  }, [customerData]);

  const selectedAddressId = useAppSelector(store => store.pointOfSale.selectedAddressId);

  const addressIds = [...addresses.ids].sort((a, b) => (a === selectedAddressId ? -1 : 1));

  const phoneNumber = formData.country
    ? formData.phone?.replace(formData.country, '')
    : formData.phone;

  const isActionablesVisible = status === 'IDLE';

  return (
    <Styles.Wrapper>
      {isCartLoading && <Styles.Overlay />}

      <Styles.Content>
        <Styles.PhoneNumberWrapper>
          <CountrySelector
            onChange={country => {
              setFormData({
                ...formData,
                country,
              });
            }}
            value={formData.country}
            isDisabled={!!customerData.id}
          />
          <Input
            placeholder="Phone Number"
            sizeVariant="extraSmall"
            name="phone"
            type={customerData.id ? 'text' : 'number'}
            variant="outline"
            value={phoneNumber}
            onChange={handleFormInputChanges}
            disabled={!!customerData.id}
          />
        </Styles.PhoneNumberWrapper>
        <Input
          label="Name"
          placeholder="Name"
          sizeVariant="extraSmall"
          name="name"
          variant="outline"
          value={formData.name}
          onChange={handleFormInputChanges}
        />
        <Input
          label="Email id (optional)"
          placeholder="Email id (optional)"
          sizeVariant="extraSmall"
          name="email"
          variant="outline"
          value={formData.email}
          onChange={handleFormInputChanges}
        />
        {customerData.id && <GstDetails customerId={customerData.id} />}

        {shouldShowAddresses && (
          <>
            {customerData.id && (
              <Text fontSize={14} color="var(--dove-gray)">
                Addresses
              </Text>
            )}
            {isAddressesLoading && <SpinnerIcon />}
            <AnimatePresence mode="popLayout">
              {addressIds.map(id => (
                <Address
                  key={id}
                  data={structuredClone(addresses.records[id])}
                  setStatus={setStatus}
                />
              ))}
            </AnimatePresence>
            {customerData.id && (
              <Address
                isAddMode
                customerId={customerData.id}
                isFirst={addressIds.length === 0}
                setStatus={setStatus}
              />
            )}
          </>
        )}
      </Styles.Content>
      <AnimatePresence initial={false}>
        {isActionablesVisible && (
          <Styles.Actionables
            variants={ActionablesVariants}
            key="actionables"
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            {actionables(formData)}
          </Styles.Actionables>
        )}
      </AnimatePresence>
    </Styles.Wrapper>
  );
};

export default CustomerDetails;
