import { ReactNode } from 'react';
import { Text } from '@point-of-sale/components';
import * as Styles from './styles';

interface IBoxProps {
  header: string;
  children: ReactNode;
  rightHeader?: ReactNode;
  hasPadding?: boolean;
  flexOne?: boolean;
}

const Box = ({ header, children, rightHeader, hasPadding = true, flexOne = false }: IBoxProps) => (
  <Styles.Wrapper $flexOne={flexOne}>
    <Styles.Header>
      <Text fontSize={16} weight="semibold" color="var(--cod-gray)">
        {header}
      </Text>
      {rightHeader}
    </Styles.Header>
    <Styles.Content $flexOne={flexOne} $hasPadding={hasPadding}>
      {children}
    </Styles.Content>
  </Styles.Wrapper>
);

export default Box;
