import { Fragment } from 'react';
import { Sheet } from 'react-modal-sheet';
import { AlterationEntryDTOType } from '@point-of-sale/schemas';
import { emptyFunction, removeUnderscoresAndCapitalize, unCamelCase } from '@point-of-sale/utils';
import { Text } from '@point-of-sale/components';
import KeyValuePair from '../../../../../../components/desktop/OrderDetails/KeyValuePair';
import * as Styles from './styles';

interface IAlterationEntryBottomSheetProps {
  data: AlterationEntryDTOType;
  isOpen: boolean;
  onClose: () => void;
}

function renderCustomization(object: Record<string, any>) {
  return Object.entries(object).map(([key, value]) => {
    if (typeof value === 'object') {
      return (
        <Fragment key={key}>
          <Text>{unCamelCase(key)} :</Text>
          <Styles.NestedContainer key={key}>{renderCustomization(value)}</Styles.NestedContainer>
        </Fragment>
      );
    }

    return (
      <KeyValuePair
        key={key}
        title={unCamelCase(key)}
        value={removeUnderscoresAndCapitalize(value)}
      />
    );
  });
}

const AlterationEntryBottomSheet = ({
  data,
  isOpen,
  onClose,
}: IAlterationEntryBottomSheetProps) => {
  return (
    <Sheet rootId="root" snapPoints={[0.75]} isOpen={isOpen} onClose={onClose}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Styles.Container>{renderCustomization(data)}</Styles.Container>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={emptyFunction} />
    </Sheet>
  );
};

export default AlterationEntryBottomSheet;
