import { ThemeType } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 52px;
  background: var(--white);

  position: sticky;
  top: 0;

  padding: 0 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 2;

  box-shadow: rgba(0, 0, 0, 0.1) 0px -3px 8px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 12px;
`;

export const BreadCrumb = styled.button<{ $isActive: boolean }>`
  border: none;
  outline: none;

  border-radius: 8px;
  background: var(--wild-sand);

  padding: 0 12px;

  height: 28px;

  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  gap: 8px;

  cursor: pointer;

  color: ${props => (props.$isActive ? 'var(--cod-gray)' : 'var(--dove-gray)')};
`;

export const BreadCrumbSeparator = styled.div<{ theme: ThemeType }>`
  color: var(--silver);
  font-size: ${props => props.theme.typography.fonts.size[16]};
  font-weight: ${props => props.theme.typography.fonts.weight.medium};
`;
