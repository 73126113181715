import { z } from 'zod';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';
import { AppDispatch, ThunkActionType } from '../../store';
import toast from 'react-hot-toast';
import { setCustomerDetails } from '../actions';
import { createCustomerApi, customerSearchApi, updateCustomerApi } from '../api';
import { CustomerDTOSchema, CustomerDTOType } from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import { addressSearchAndUpdateCart } from './index';

type CustomerSearchByEmail = {
  email: string;
  countryCode?: never;
  phoneNumber?: never;
};

type CustomerSearchByPhone = {
  email?: never;
  countryCode: string;
  phoneNumber: string;
};

type CustomerSearchById = {
  customerId: string;
  email?: never;
  countryCode?: never;
  phoneNumber?: never;
};

type CustomerSearchParams = CustomerSearchByEmail | CustomerSearchByPhone | CustomerSearchById;

type CustomerCallbacks = {
  onExistingCustomer?: (customer: CustomerDTOType) => Promise<void> | void;
  onNewCustomer?: (customerData: Partial<CustomerDTOType>) => Promise<void> | void;
  onError?: (error: unknown) => Promise<void> | void;
  onComplete?: (doesCustomerExist: boolean) => Promise<void> | void;
};

export const searchCustomer =
  ({
    searchParams,
    callbacks = {},
  }: {
    searchParams: CustomerSearchParams;
    callbacks?: CustomerCallbacks;
  }): ThunkActionType =>
  async dispatch => {
    const filterBuilder = new FilterBuilder();

    if ('customerId' in searchParams) {
      filterBuilder.addFilter({
        field: 'id',
        filterType: 'EQ',
        value: searchParams.customerId,
      });
    } else if (
      'phoneNumber' in searchParams &&
      searchParams.phoneNumber &&
      searchParams.countryCode
    ) {
      filterBuilder.addFilter({
        field: 'phone',
        filterType: 'EQ',
        value: `${searchParams.countryCode}${searchParams.phoneNumber}`,
      });
    } else if ('email' in searchParams && searchParams.email) {
      filterBuilder.addFilter({
        field: 'email',
        filterType: 'EQ',
        value: searchParams.email,
      });
    } else {
      toast('Please provide either email or phone number');
      return;
    }
    dispatch(setCustomerDetails({ isLoading: true }));

    try {
      const response = await apiResponseHandler(
        customerSearchApi({ filters: filterBuilder.build() }),
        z.array(CustomerDTOSchema)
      );

      if (response.type === ApiResponseEnum.Failure) {
        throw new Error(response.meta?.message ?? 'Something went wrong');
      }

      if (response.data.length > 0) {
        const customerData = response.data[0];
        await handleExistingCustomer(dispatch as AppDispatch, customerData, callbacks);
        return;
      }

      await handleNewCustomer(dispatch as AppDispatch, searchParams, callbacks);
    } catch (error) {
      handleSearchError(dispatch as AppDispatch, error, callbacks);
    }
  };

const handleExistingCustomer = async (
  dispatch: AppDispatch,
  customerData: CustomerDTOType,
  callbacks: CustomerCallbacks
) => {
  dispatch(
    setCustomerDetails({
      data: customerData,
      isLoading: false,
      isSuccess: true,
    })
  );

  // Call custom callback if provided, otherwise use default behavior
  if (callbacks.onExistingCustomer) {
    await callbacks.onExistingCustomer(customerData);
  } else {
    // Default cart-specific behavior
    dispatch(addressSearchAndUpdateCart(customerData.id));
  }

  callbacks.onComplete?.(true);
};

const handleNewCustomer = async (
  dispatch: AppDispatch,
  searchParams: CustomerSearchParams,
  callbacks: CustomerCallbacks
) => {
  const customerData = {
    phone: 'phoneNumber' in searchParams ? searchParams.phoneNumber : undefined,
    email: 'email' in searchParams ? searchParams.email : undefined,
    country: 'countryCode' in searchParams ? searchParams.countryCode : undefined,
  };

  dispatch(
    setCustomerDetails({
      isLoading: false,
      isSuccess: true,
      data: customerData,
    })
  );

  await callbacks.onNewCustomer?.(customerData);
  callbacks.onComplete?.(false);
};

const handleSearchError = (dispatch: AppDispatch, error: unknown, callbacks: CustomerCallbacks) => {
  toast.error(error instanceof Error ? error.message : 'Something went wrong');
  dispatch(
    setCustomerDetails({
      data: {},
      isLoading: false,
      isSuccess: true,
    })
  );
};

export const createNewCustomer =
  (
    data: Partial<CustomerDTOType>,
    successCallback?: (customer: CustomerDTOType) => void
  ): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCustomerDetails({
        isLoading: true,
      })
    );

    const country = data.country ?? '+91';

    data.phone = `${country}${data.phone}`;

    const promise = createCustomerApi({ ...data, country });

    const response = await apiResponseHandler<CustomerDTOType>(promise, CustomerDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerDetails({
          data: {},
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data) {
      dispatch(
        setCustomerDetails({
          data: response.data,
        })
      );
    }

    dispatch(
      setCustomerDetails({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.(response.data);
  };

export const updateCustomer =
  (
    customerId: number,
    data: Partial<CustomerDTOType>,
    successCallback: VoidFunction
  ): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCustomerDetails({
        isLoading: true,
      })
    );

    const promise = updateCustomerApi({ customerId, data });

    const response = await apiResponseHandler<CustomerDTOType>(promise, CustomerDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerDetails({
          data: {},
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data) {
      dispatch(
        setCustomerDetails({
          data: response.data,
        })
      );
    }

    dispatch(
      setCustomerDetails({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback();
  };
