import { z } from 'zod';
import { AddressDTOSchema } from '../customer';
import { AlterationEntryDTOSchema } from './alterationEntry';
import { CustomizationCategorySchema } from '../cart/customizationCategory';

export const DeliveryModeSchema = z.enum(['STORE_PURCHASE', 'STORE_PICKUP', 'HOME_DELIVERY']);

export type DeliveryModeType = z.infer<typeof DeliveryModeSchema>;

export const SalesChannelDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number().int(),
  id: z.number().int(),
  name: z.string(),
  salesChannelType: z.enum(['POS', 'SHOPIFY']),
  enabled: z.boolean(),
  facilityId: z.number().int().optional(),
  // TODO: Remove default value after API is fixed
  numPosTerminals: z.number().int().optional(),
});

export type SalesChannelDTOType = z.infer<typeof SalesChannelDTOSchema>;

export const FacilityDTOSchema = z.object({
  address: AddressDTOSchema.extend({ id: z.number().optional() }),
  facilityType: z.enum(['WAREHOUSE', 'STORE', 'VENDOR']),
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  name: z.string(),
  enabled: z.boolean(),
  grnEnabled: z.boolean(),
  salesChannelId: z.number().optional(),
  facilityGroupId: z.number().optional(),
  unicomFacilityCode: z.string().optional(),
  jitInvSyncEnabled: z.boolean().optional(),
  gstin: z.string().optional(),
  isOnline: z.boolean().optional(),
  vendorId: z.number().optional(),
});

export type FacilityDTOType = z.infer<typeof FacilityDTOSchema>;

export const CostBreakupDTOSchema = z.object({
  mrp: z.number().optional(),
  currency: z.string().optional(),
  tradeDiscount: z.number().optional(),
  sellingPrice: z.number().optional(),
  cgst: z.number().optional(),
  sgst: z.number().optional(),
  igst: z.number().optional(),
  taxValue: z.number().optional(),
  taxableValue: z.number().optional(),
  couponDiscount: z.number().optional(),
  shippingCharge: z.number().optional(),
  codCharge: z.number().optional(),
  chargeableAmount: z.number().optional(),
  amountPaid: z.number().optional(),
  amountRemaining: z.number().optional(),
  bagDiscount: z.number().optional(),
  customCharges: z
    .array(
      z.object({
        amount: z.number(),
        type: z.string(),
        remarks: z.string().optional(),
        taxClassPerc: z.number().optional(),
        taxValue: z.number().optional(),
        taxableValue: z.number().optional(),
      })
    )
    .optional(),
  chargeableAmountExCustomCharges: z.number().optional(),
  customChargesViaOrder: z
    .array(
      z.object({
        amount: z.number(),
        type: z.string(),
        remarks: z.string().optional(),
        taxClassPerc: z.number(),
      })
    )
    .optional(),

  offerDiscount: z.number().optional(),
  offerDiscountEntry: z
    .object({
      name: z.string(),
      bundleId: z.number(),
      amount: z.number(),
      quantity: z.number(),
    })
    .optional(),
});

export type CostBreakupDTOType = z.infer<typeof CostBreakupDTOSchema>;

export const InvoiceAssetSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  type: z.string(),
  bucket: z.string(),
  path: z.string(),
  usage: z.string(),
  source: z.string(),
  url: z.string(),
  uploaded: z.boolean(),
});

export type InvoiceAssetType = z.infer<typeof InvoiceAssetSchema>;

export const FulfillmentModeSchema = z.enum(['ONHAND', 'JIT', 'MTO', 'MTC']);

export type FulfillmentModeType = z.infer<typeof FulfillmentModeSchema>;

// ***********************************************************************

export const LineItemStatusEnumSchema = z.enum([
  'CREATED', // * show : Order confirmed
  'BRAND_WIP', // * show brand status in PB as described below
  'WIP', // * don't show -> show last brand status
  'PACKED', // * don't show -> show last brand status
  'DISPATCHED', // * show : Ready for store pickup || Shipped to customer :: based on delivery mode
  'READY_FOR_PICKUP',
  'DELIVERED', // * show : Delivery Complete || Home Delivery Complete :: based on delivery mode
  'CANCELLED', // * show : Order Cancelled
  'RTO', // * don't show
  'RETURN_WIP', // * don't show
  'RETURNED', // * don't show
  'FULFILLABLE',
  'READY_FOR_PICKUP',
  'UNFULFILLABLE',
  'RTO_INITIATED',
]);

export const LineItemBrandStatusEnumSchema = z.enum([
  'ADDED', // * don't show
  'INTERNAL_REVIEW_DONE', // * don't show
  'SHARED', // * show : PO sent to brand
  'ACCEPTED', // *  show : PO accepted by brand
  'DECLINED', // * show : PO rejected by brand
  'DISPATCHED', // * show : Dispatched
  'RECEIVED_AT_WAREHOUSE', // * don't show
  'QC_FAILED', // * show : QC failed
  'QC_PASSED', // * show : QC passed
]);

export type LineItemStatusEnumType = z.infer<typeof LineItemStatusEnumSchema>;

export type LineItemBrandStatusEnumType = z.infer<typeof LineItemBrandStatusEnumSchema>;

export const LineItemBrandJitFulfillmentModelEnumSchema = z.enum(['B2B', 'B2B2C']);

export type LineItemBrandJitFulfillmentModelEnumType = z.infer<
  typeof LineItemBrandJitFulfillmentModelEnumSchema
>;

export const LineItemHistorySchema = z.object({
  status: z.string().optional(),
  eventDate: z.string(),
  brandStatus: z.string().optional(),
  remark: z.string().optional(),
  icon: z.string().optional(),
  actorId: z.number().optional(),
});

export type LineItemHistoryType = z.infer<typeof LineItemHistorySchema>;

export const LineItemDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  orderId: z.number(),
  cartItemId: z.number().optional(),
  customerDeliveryAddress: z.intersection(z.object({}), AddressDTOSchema.optional()).optional(),
  customerDeliveryStoreId: z.number().optional(),
  isSameStorePurchase: z.boolean(),
  deliveryMode: DeliveryModeSchema,
  fulfillmentMode: FulfillmentModeSchema,
  fulfillmentFacilityId: z.number(),
  unicomFacilityCode: z.string().optional(),
  fulfillmentFacilityType: z.string().optional(),
  fulfillmentFacilityGroupId: z.number().optional(),
  skuCode: z.string(),
  productName: z.string(),
  productImage: z.object({ url: z.string(), uploaded: z.boolean() }).optional(),
  color: z.string(),
  size: z.string(),
  brandId: z.number().optional(),
  vendorId: z.number(),
  brandName: z.string(),

  alterationEntry: AlterationEntryDTOSchema.optional(),

  returnable: z.boolean(),
  exchangeable: z.boolean(),
  cgstPerc: z.number().optional(),
  sgstPerc: z.number().optional(),
  igstPerc: z.number().optional(),
  costBreakup: CostBreakupDTOSchema,
  vendorDiscount: z.number().optional(),
  platformDiscount: z.number().optional(),
  onhold: z.boolean(),
  onholdReason: z.string().optional(),
  packageId: z.number().optional(),
  isExpress: z.boolean(),
  history: z.array(LineItemHistorySchema).optional(),

  customerName: z.string().optional(),
  customerPhone: z.string().optional(),
  salesStaffId: z.number().optional(),
  salesChannelOrderId: z.string().optional(),

  returnPolicy: z.string().optional(),
  washCareDescription: z.string().optional(),

  brandJitFulfillmentModel: LineItemBrandJitFulfillmentModelEnumSchema.optional(),

  actualDeliveryDate: z.string().optional(),
  estimatedDeliveryDate: z.string().optional(),

  cancellable: z.boolean().optional(),
  hsnCode: z.string().optional(),
  taxClassPerc: z.number().optional(),

  status: LineItemStatusEnumSchema.optional(),

  brandStatus: LineItemBrandStatusEnumSchema.optional(),

  currentlyExchangeable: z.boolean().optional(),
  currentlyReturnable: z.boolean().optional(),

  returnableUntil: z.string().optional(),
  exchangeableUntil: z.string().optional(),

  refunded: z.boolean().optional(),
  refundRequest: z
    .object({
      createdBy: z.string().optional(),
      createdOn: z.string().optional(),
      lastModifiedOn: z.string().optional(),
      version: z.number().optional(),
      id: z.number().optional(),
      status: z.string().optional(),
      orderId: z.number().optional(),
      salesChannelOrderId: z.string().optional(),
      orderLineItemIds: z.array(z.number()).optional(),
      refundMode: z.string().optional(),
      refundAmount: z.number().optional(),
      creditNoteNumber: z.string().optional(),
      payoutTxnId: z.string().optional(),
      storeCreditNumber: z.string().optional(),
      refundReceiptAsset: z
        .object({
          id: z.number(),
          url: z.string(),
          uploaded: z.boolean(),
        })
        .optional(),
    })
    .optional(),
  returnEntry: z
    .object({
      createdBy: z.string().optional(),
      createdOn: z.string().optional(),
      lastModifiedOn: z.string().optional(),
      version: z.number().optional(),
      id: z.number().optional(),
      orderId: z.number().optional(),
      returnMode: z.enum(['AT_STORE', 'HOME_PICKUP']).optional(),
      salesChannelId: z.number().optional(),
      refundRequestId: z.number().optional(),
      orderLineItemIds: z.array(z.number()).optional(),
      status: z.string().optional(),
      returnReason: z.string().optional(),
      salesStaffId: z.number().optional(),
    })
    .optional(),

  refundRequestId: z.number().optional(),
  customizationCategory: CustomizationCategorySchema.optional(),

  couponCode: z.string().optional(),
  isTestOrder: z.boolean(),
  washType: z.string().optional(),
  salesChannelId: z.number().optional(),
  salesChannelType: SalesChannelDTOSchema.shape['salesChannelType'].optional(),
  customerId: z.number().optional(),
  skuBarcode: z.string().optional(),
  poReceiveFacilityId: z.number().optional(),
  promisedDeliveryDate: z.string().optional(),
  vendorName: z.string().optional(),
  unicomVendorCode: z.string().optional(),
  feedbackId: z.number().optional(),
  returnRequestId: z.number().optional(),
  returnReason: z.string().optional(),
  invoiceAsset: InvoiceAssetSchema.optional(),
  purchaseOrderCreatedOn: z.string().optional(),
  purchaseOrderPdfAsset: InvoiceAssetSchema.optional(),
  productCare: z.string().optional(),
  paymentMethod: z.string().optional(),
  platformMargin: z.number().optional(),
  unicomPurchaseOrderCode: z.string().optional(),
  cancelledOn: z.string().optional(),
  cancellationReason: z.string().optional(),
  cancellationRemarks: z.string().optional(),
  refundedOn: z.string().optional(),

  isExchange: z.boolean().optional(),

  vendorDiscountingModel: z.string().optional(),
  vendorDeliveryDate: z.string().optional(),

  cancellableByAdmin: z.boolean().optional(),
  returnSubReason: z.string().optional(),

  cancellationSalesStaffId: z.number().optional(),
  courierTrackingId: z.string().optional(),
  courierPartner: z.string().optional(),

  currentlyReturnableByAdmin: z.boolean().optional(),
});

export type LineItemDTOType = z.infer<typeof LineItemDTOSchema>;

export const LineItemsDTOSchema = z.array(LineItemDTOSchema);

export type LineItemsDTOType = z.infer<typeof LineItemsDTOSchema>;
