import { DayPicker as ReactDayPicker, type DayPickerProps } from 'react-day-picker';
import * as Styles from './styles';

const DayPicker = (props: DayPickerProps) => {
  return (
    <Styles.Wrapper>
      <ReactDayPicker {...props} />
    </Styles.Wrapper>
  );
};

export default DayPicker;
