import React from 'react';
import { Button } from '@point-of-sale/components';
import { OperationType } from '../types';
import { useAppSelector } from '@point-of-sale/store';
import { OrderDTOType } from '@point-of-sale/schemas';
import * as CommonStyles from '../styles';
import * as Styles from './styles';

interface IOperationSwitchProps {
  operation: OperationType;
  setOperation: React.Dispatch<React.SetStateAction<OperationType>>;
  resetReasonsAndSelections: () => void;
  data: OrderDTOType;
}

const OperationSwitch = ({
  operation,
  setOperation,
  resetReasonsAndSelections,
  data,
}: IOperationSwitchProps) => {
  const selectedSalesChannelFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );
  const isSelectedSalesChannelOnline = useAppSelector(store =>
    selectedSalesChannelFacilityId
      ? store.common.facilities.data?.records?.[selectedSalesChannelFacilityId]?.isOnline
      : false
  );

  const isAnyLineItemReturnable = Object.values(data.lineItems ?? {}).some(
    item => item.currentlyReturnable
  );
  const isAnyLineItemReturnableByAdmin = Object.values(data.lineItems ?? {}).some(
    item => item.currentlyReturnableByAdmin
  );
  const isAnyLineItemExchangeable = Object.values(data.lineItems ?? {}).some(
    item => item.currentlyExchangeable
  );
  const isAnyLineItemCancellable = Object.values(data.lineItems ?? {}).some(
    item => item.cancellable
  );
  const isAnyLineItemCancelableByAdmin = Object.values(data.lineItems ?? {}).some(
    item => item.cancellableByAdmin
  );

  if (
    !isAnyLineItemReturnable &&
    !isAnyLineItemExchangeable &&
    !isAnyLineItemCancellable &&
    !isAnyLineItemCancelableByAdmin &&
    !isAnyLineItemReturnableByAdmin
  ) {
    return null;
  }

  if (isSelectedSalesChannelOnline) {
    return null;
  }

  const shouldShowReturnExchangeButton =
    isAnyLineItemReturnableByAdmin || isAnyLineItemReturnable || isAnyLineItemExchangeable;
  const shouldShowCancellationButton = isAnyLineItemCancelableByAdmin || isAnyLineItemCancellable;

  return (
    <CommonStyles.LeftTop $doesTopHaveBorder={false}>
      <Styles.OperationSwitchContainer>
        {shouldShowReturnExchangeButton && (
          <Button
            sizeVariant="small"
            onClick={() => {
              if (operation === 'RETURNS_AND_EXCHANGES') {
                setOperation('READ_ONLY');
              } else {
                setOperation('RETURNS_AND_EXCHANGES');
              }

              resetReasonsAndSelections();
            }}
            colorScheme="primary"
            variant={operation === 'RETURNS_AND_EXCHANGES' ? 'fill' : 'outline'}
          >
            Return & Exchange
          </Button>
        )}
        {shouldShowCancellationButton && (
          <Button
            sizeVariant="small"
            onClick={() => {
              if (operation === 'CANCELLATION') {
                setOperation('READ_ONLY');
              } else {
                setOperation('CANCELLATION');
              }

              resetReasonsAndSelections();
            }}
            colorScheme="primary"
            variant={operation === 'CANCELLATION' ? 'fill' : 'outline'}
          >
            Cancel Items
          </Button>
        )}
      </Styles.OperationSwitchContainer>
    </CommonStyles.LeftTop>
  );
};

export default OperationSwitch;
