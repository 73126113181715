import styled from 'styled-components';
import { motion } from 'motion/react';
import { ThemeType } from '../themes/types';
import CheckBox from '../CheckBox';
import Input from '../Input';

interface IWrapperProps {
  $isOutlined: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  gap: 4px;
  position: relative;
  min-width: 150px;
  background: var(--white);
  cursor: pointer;
  border-radius: 8px;
`;

export const Trigger = styled.div<{ theme: ThemeType }>`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px 0 12px 16px;

  height: 45px;

  user-select: none;
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  padding: 0 12px;
`;

export const PopoverContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 1px;
  max-height: 500px;
  box-shadow: 0px 24px 34px 0px #00000040;

  z-index: 2;
`;

export const PopoverHeader = styled.header`
  padding: 24px 60px 24px 24px;
  border-bottom: 1px solid var(--alto);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .close-button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const PopoverFooter = styled.footer`
  padding: 24px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 80%;
  }
`;

export const OptionsGrid = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 12px;
`;

export const OptionCheckbox = styled(CheckBox)`
  padding: 12px;
  /* border: 1px dashed blue; */
`;

export const SearchBox = styled(Input)`
  /* margin-right: 48px; */
`;

export const CategoryTitleContainer = styled.div`
  padding: 12px 12px 6px 10px;
  /* border: 1px dashed red; */
`;
