import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100dvh - 52px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: var(--white);
  gap: 8px;
`;
