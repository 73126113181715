import { POSActionsTypes } from './constants';
import { ShopActionsType, IPointOfSaleState } from './types';
import { normalizeArray } from '../utils/normalizeArray';
import { ObjectType } from '@point-of-sale/types';
import { STANDARD_PAGINATION_SIZE } from '../constants';
import { createDescribedState } from '../utils/createDescribedState';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { omit } from '@point-of-sale/utils';

const INITIAL_STATE: IPointOfSaleState = {
  search: {
    query: '',
  },

  pagination: {
    page: 0,
    size: STANDARD_PAGINATION_SIZE,
    shouldFetchMore: true,
  },

  isBarcodish: false,
  mode: 'BUY_NOW',
  filters: {
    // ! DO NOT REMOVE THE COMMENTED CODE
    // 'variants.availableFacilities': DEFAULT_SEARCH_FILTER,
  },
  products: createDescribedState({
    ids: [],
    records: {},
  }),
  facets: [],

  customer: createDescribedState({}),

  customerGstDetails: createDescribedState({}),

  selectedAddressId: -1,
  addresses: createDescribedState({
    ids: [],
    records: {},
  }),

  // TODO: Refactor
  cart: createDescribedState({
    createdBy: '',
    createdOn: '',
    lastModifiedOn: '',
    version: -1,
    id: -1,
    salesChannelId: -1,
    salesChannelTerminalId: -1,
    customerId: -1,
    couponEnabled: false,
    shippingCharge: 0,
    codCharge: 0,
    bagDiscount: 0,
    chargeableAmount: 0,
    amountPaid: 0,
    minAmountToBePaid: 0,
    cartItems: {
      ids: [],
      records: {},
    },
  }),

  // cartItemEditCache: {
  //   isPopulated: false,
  //   id: -1,
  // },

  isEditCustomerInfoModalOpen: false,

  customerInfoModalUpdateCallback: undefined,

  buyingWithoutInventoryAllowedForBarcode: '',

  serviceabilityForCart: null,

  deliverability: {},

  sourceForOnlinePos: null,
} as const;

export const pointOfSaleReducer = (
  state = INITIAL_STATE,
  action: ShopActionsType
): IPointOfSaleState => {
  switch (action.type) {
    case POSActionsTypes.UPDATE_FILTER: {
      const persistentFilter: ObjectType = {};

      // * DO NOT REMOVE THE COMMENTED CODE
      // if (
      //   action.payload.name === 'variants.availableFacilities' &&
      //   action.payload.filter.value === ''
      // ) {
      //   persistentFilter['variants.availableFacilities'] =
      //     DEFAULT_SEARCH_FILTER;
      // }

      if (action.payload.filter.value === '') {
        const newFilters = omit(state.filters, [action.payload.name]);

        return {
          ...state,
          filters: newFilters,
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.filter,
          ...persistentFilter,
        },
      };
    }

    case POSActionsTypes.DELETE_FILTER: {
      const newFilters = omit(state.filters, [action.payload]);

      return {
        ...state,
        filters: newFilters,
      };
    }

    case POSActionsTypes.SET_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.SET_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.SET_IS_BARCODISH: {
      return {
        ...state,
        isBarcodish: action.payload,
      };
    }

    case POSActionsTypes.SET_PRODUCTS: {
      return {
        ...state,
        products: {
          ...state.products,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.SET_SELECTED_COLOR: {
      return {
        ...state,
        products: {
          ...state.products,
          data: {
            ...state.products.data,
            records: {
              ...state.products.data.records,
              [action.payload.id]: {
                ...state.products.data.records[action.payload.id],
                selectedColor: action.payload.value,
              },
            },
          },
        },
      };
    }

    case POSActionsTypes.SET_SELECTED_SIZE: {
      return {
        ...state,
        products: {
          ...state.products,
          data: {
            ...state.products.data,
            records: {
              ...state.products.data.records,
              [action.payload.id]: {
                ...state.products.data.records[action.payload.id],
                selectedSize: action.payload.value,
              },
            },
          },
        },
      };
    }

    case POSActionsTypes.SET_MODE: {
      return {
        ...state,
        mode: action.payload,
      };
    }

    case POSActionsTypes.SET_FACETS: {
      return {
        ...state,
        facets: action.payload,
      };
    }

    case POSActionsTypes.SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.SET_CART_DETAILS: {
      return {
        ...state,
        cart: {
          ...state.cart,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.RESET_CART_STATE: {
      return {
        ...state,
        cart: INITIAL_STATE.cart,
      };
    }

    case POSActionsTypes.SET_CART_ITEM_QUANTITY: {
      const { cartItemId, quantity } = action.payload;

      return {
        ...state,
        cart: {
          ...state.cart,
          data: {
            ...state.cart.data,
            cartItems: {
              ...state.cart.data.cartItems,
              records: {
                ...state.cart.data.cartItems.records,
                [cartItemId]: {
                  ...state.cart.data.cartItems.records[cartItemId],
                  quantity,
                },
              },
            },
          },
        },
      };
    }
    case POSActionsTypes.DELETE_CART_ITEM: {
      const { cartItemId } = action.payload;

      return {
        ...state,
        cart: {
          ...state.cart,
          data: {
            ...state.cart.data,
            cartItems: {
              ids: state.cart.data.cartItems.ids.filter(id => id !== cartItemId),
              records: omit(state.cart.data.cartItems.records, [cartItemId]),
            },
          },
        },
      };
    }

    case POSActionsTypes.ADD_TO_CART: {
      const existingCartItems = state.cart.data.cartItems;
      const newCartItems = normalizeArray(action.payload);

      const uniqueIds = Array.from(new Set([...existingCartItems.ids, ...newCartItems.ids]));

      return {
        ...state,
        cart: {
          ...state.cart,
          data: {
            ...state.cart.data,
            cartItems: {
              ids: uniqueIds,
              records: {
                ...existingCartItems.records,
                ...newCartItems.records,
              },
            },
          },
        },
      };
    }

    case POSActionsTypes.SET_ADDRESSES_DATA: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.SET_SELECTED_ADDRESS_ID: {
      return {
        ...state,
        selectedAddressId: action.payload,
      };
    }

    // case POSActionsTypes.UPDATE_CART_ITEM_EDIT_CACHE: {
    //   return {
    //     ...state,
    //     cartItemEditCache: {
    //       ...state.cartItemEditCache,
    //       ...action.payload,
    //     },
    //   };
    // }

    case POSActionsTypes.SET_IS_EDIT_CUSTOMER_INFO_MODAL_OPEN: {
      return {
        ...state,
        isEditCustomerInfoModalOpen: action.payload,
      };
    }

    case POSActionsTypes.SET_CUSTOMER_INFO_MODAL_UPDATE_CALLBACK: {
      return {
        ...state,
        customerInfoModalUpdateCallback: action.payload,
      };
    }

    case POSActionsTypes.SET_CUSTOMER_GST_DETAILS: {
      return {
        ...state,
        customerGstDetails: {
          ...state.customerGstDetails,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.SET_BUYING_WITHOUT_INVENTORY_ALLOWED_FOR_BARCODE: {
      return {
        ...state,
        buyingWithoutInventoryAllowedForBarcode: action.payload,
      };
    }

    case POSActionsTypes.SET_SERVICEABILITY_FOR_CART: {
      return {
        ...state,
        serviceabilityForCart: {
          ...state.serviceabilityForCart,
          ...action.payload,
        },
      };
    }

    case POSActionsTypes.SET_DELIVERABILITY_OF_CART_ITEM: {
      return {
        ...state,
        deliverability: {
          ...state.deliverability,
          [action.payload.cartItemId]: {
            ...state.deliverability[action.payload.cartItemId],
            ...action.payload.deliverability,
          },
        },
      };
    }

    case POSActionsTypes.SET_SOURCE_FOR_ONLINE_POS: {
      return {
        ...state,
        sourceForOnlinePos: action.payload,
      };
    }

    case POSActionsTypes.RESET: {
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};
