import { useState } from 'react';
import toast from 'react-hot-toast';
import { useBoolean } from '@point-of-sale/hooks';
import { outOfStockDemandTrackerApi } from '@point-of-sale/store';
import { apiResponseHandler } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import { OutOfStockDemandAddDTOType } from '@point-of-sale/schemas';

export const useOutOfStockDemandTrackerApi = () => {
  const [isLoading, loadingActions] = useBoolean();
  const [error, setError] = useState('');

  async function trackOutOfStockDemand(
    payload: OutOfStockDemandAddDTOType,
    {
      successCallback,
    }: {
      successCallback: CallableFunction;
    }
  ) {
    try {
      loadingActions.on();

      const promise = await outOfStockDemandTrackerApi(payload);
      const response = await apiResponseHandler(promise);

      if (response.type === ApiResponseEnum.Failure) {
        toast.error(response.meta?.message ?? 'Something went wrong', {
          position: 'bottom-center',
        });
        if (response.meta?.message) {
          setError(response.meta?.message);
        }
        return;
      }

      loadingActions.off();

      successCallback();
    } catch (e) {
      console.error(error);
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      loadingActions.off();
    }
  }

  return {
    isLoading,
    error,
    trackOutOfStockDemand,
  };
};
