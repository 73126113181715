import { useEffect } from 'react';
import { AnimatePresence } from 'motion/react';
import { Button, Text } from '@point-of-sale/components';
import { getInventoryStatus, useAppSelector } from '@point-of-sale/store';
import { formatToReadableDate } from '@point-of-sale/utils';
import { useBoolean } from '@point-of-sale/hooks';
import { PLACEHOLDERS } from '@point-of-sale/constants';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';
import InventoryDetailsModal from '../../InventoryDetailsModal';
import useProductContext from '../context/useProductContext';
import * as Styles from './styles';

interface IInventoryProps {
  serviceabilityData: INormalizedServiceabilityData;
  demandedPincode: string;
  onChangeDemandedPincode: (pincode: string) => void;
}

const Inventory = ({
  serviceabilityData,
  demandedPincode,
  onChangeDemandedPincode,
}: IInventoryProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const facilities = useAppSelector(store => store.common.facilities.data);
  const currentFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const {
    isExpanded,
    selectedVariant,
    mannerOfPurchase,
    setMannerOfPurchase,
    setFulfillmentFacilityId,
  } = useProductContext();

  const {
    hasInventoryInCurrentStoreOrWarehouse,
    hasInventoryInOtherStoreOrWarehouse,
    hasNoInventoryInAnyStoreOrWarehouse,
    inventoryInOtherStoreAndWarehouses,
  } = getInventoryStatus(selectedVariant);

  const isViewInventoryDisabled = selectedVariant ? hasNoInventoryInAnyStoreOrWarehouse : true;

  const [isModalOpen, modalOpenActions] = useBoolean();

  const currentFacility = currentFacilityId ? facilities.records[currentFacilityId] : null;

  useEffect(() => {
    if (isViewInventoryDisabled && currentFacility) {
      setMannerOfPurchase(prev => ({
        ...prev,
        shouldOverride: true,
        deliveryMode: 'STORE_PICKUP',
        fulfillmentMode: 'ONHAND',
        estimatedDeliveryDate: formatToReadableDate(new Date()),
        fulfillmentFacility: {
          name: currentFacility?.name,
          city: currentFacility.address.cityDisplayName ?? '',
          pincode: currentFacility.address.pincode ?? '',
        },
      }));

      setFulfillmentFacilityId(currentFacility.id);
    }
  }, [isViewInventoryDisabled, currentFacility]);

  const information = [
    {
      title: 'Buy From',
      text: mannerOfPurchase.fulfillmentFacility?.name,
      subText: mannerOfPurchase.fulfillmentFacility?.city,
    },
  ];

  const mtoInfo = {
    title: 'MTO Min TAT',
    text: selectedVariant?.mtoTAT ? `${selectedVariant.mtoTAT} Days` : PLACEHOLDERS.NOT_AVAILABLE,
    subText: '',
  };

  const mtcInfo = {
    title: 'MTC Min TAT',
    text: selectedVariant?.mtcTAT ? `${selectedVariant.mtcTAT} Days` : PLACEHOLDERS.NOT_AVAILABLE,
    subText: '',
  };

  let Left;

  if (hasInventoryInCurrentStoreOrWarehouse) {
    information.push(mtoInfo);
    Left = () =>
      information.map(item => (
        <Styles.Box key={item.title}>
          <Text fontSize={12} color="var(--dove-gray)" weight="semibold">
            {item.title}
          </Text>
          <Text fontSize={12} weight="semibold">
            {item.text}
          </Text>
          {item.subText && (
            <Text fontSize={12} color="var(--silver-chalice)">
              {item.subText}
            </Text>
          )}
        </Styles.Box>
      ));
  } else if (hasInventoryInOtherStoreOrWarehouse) {
    const firstFacilityWithInventory = inventoryInOtherStoreAndWarehouses[0];

    Left = () => (
      <Text color="var(--persian-red)" fontSize={12}>
        No inventory in current store. <br />
        {mode === 'BUY_NOW' && <> Product available in {firstFacilityWithInventory.name}</>}
      </Text>
    );
  } else if (hasNoInventoryInAnyStoreOrWarehouse) {
    Left = () => (
      <>
        <Styles.Box>
          <Text color="var(--persian-red)" fontSize={12}>
            No inventory in any location <br />
            {selectedVariant?.mtoAcceptedByBrand ? '' : 'Cannot'} Place Sales Order
          </Text>
        </Styles.Box>

        {selectedVariant?.mtoAcceptedByBrand && (
          <Styles.Box>
            <Text fontSize={12} color="var(--dove-gray)" weight="semibold">
              {mtoInfo.title}
            </Text>
            <Text fontSize={12} weight="semibold">
              {mtoInfo.text}
            </Text>
          </Styles.Box>
        )}

        {selectedVariant?.mtcAcceptedByBrand && (
          <Styles.Box>
            <Text fontSize={12} color="var(--dove-gray)" weight="semibold">
              {mtcInfo.title}
            </Text>
            <Text fontSize={12} weight="semibold">
              {mtcInfo.text}
            </Text>
          </Styles.Box>
        )}
      </>
    );
  }

  if (mode === 'MADE_TO_ORDER') {
    Left = () => (
      <>
        {selectedVariant?.mtoAcceptedByBrand && (
          <Styles.Box>
            <Text fontSize={12} color="var(--dove-gray)" weight="semibold">
              {mtoInfo.title}
            </Text>
            <Text fontSize={12} weight="semibold">
              {mtoInfo.text}
            </Text>
          </Styles.Box>
        )}
        {selectedVariant?.mtcAcceptedByBrand && (
          <Styles.Box>
            <Text fontSize={12} color="var(--dove-gray)" weight="semibold">
              {mtcInfo.title}
            </Text>
            <Text fontSize={12} weight="semibold">
              {mtcInfo.text}
            </Text>
          </Styles.Box>
        )}
      </>
    );
  }

  return (
    <AnimatePresence>
      {isExpanded && (
        <Styles.Wrapper>
          {selectedVariant && Left && <Left />}
          {mode === 'BUY_NOW' && (
            <>
              <Styles.ModalTriggerContainer>
                <Button
                  rightIcon="LinkIcon"
                  sizeVariant="xs"
                  onClick={modalOpenActions.on}
                  variant="ghost"
                  isDisabled={isViewInventoryDisabled}
                >
                  View Inventory
                </Button>
              </Styles.ModalTriggerContainer>

              {isModalOpen && selectedVariant?.skuCode && (
                <InventoryDetailsModal
                  closeModal={modalOpenActions.off}
                  isOpen={isModalOpen}
                  selectedVariant={selectedVariant}
                  skuCode={selectedVariant.skuCode}
                  serviceabilityData={serviceabilityData}
                  demandedPincode={demandedPincode}
                  onChangeDemandedPincode={onChangeDemandedPincode}
                />
              )}
            </>
          )}
        </Styles.Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Inventory;
