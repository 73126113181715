import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
  min-width: 450px;
  width: 50vw;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  button {
    width: 420px;
  }
`;

export const SourceInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding-bottom: 48px;
`;
