import { Context, createContext, useContext } from 'react';
import { OperationType } from './types';

export interface IOrderDetailsContext {
  operation: OperationType;
  lineItemsSelection: Record<number, boolean>;
  onToggleLineItemSelection: (lineItemId: number) => void;

  reasons: Record<number, string>;
  onReasonChange: (lineItemId: number, reason: string) => void;

  specificReasons: Record<number, string>;
  onSpecificReasonChange: (lineItemId: number, reason: string) => void;
}

export const OrderDetailsContext: Context<IOrderDetailsContext> = createContext(
  {} as IOrderDetailsContext
);

export function useOrderDetailsContext() {
  const contextData = useContext(OrderDetailsContext);
  return contextData;
}
