import { useEffect, useState } from 'react';
import { ENV } from '@point-of-sale/env';

export const useReleaseIdentifier = () => {
  const [releaseIdentifier, setReleaseIdentifier] = useState(null);

  useEffect(() => {
    if (ENV.VITE_ENVIRONMENT === 'local') {
      return;
    }

    fetch('/release-info.json')
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        // throw new Error('Failed to fetch release info');
      })
      .then(data => {
        setReleaseIdentifier(data.github_run_number);
        // localStorage.setItem('releaseIdentifier', data.github_run_number);
      })
      .catch(error => {
        console.error('Error:', error);
        // localStorage.removeItem('releaseIdentifier');
      });
  }, []);

  return { releaseIdentifier };
};
