import { useMemo } from 'react';
import { CURRENCY_SYMBOLS, CurrencyType } from '@point-of-sale/constants';
import { getDiscountPercentage } from '@point-of-sale/utils';
import { IProductListItem, useAppSelector } from '@point-of-sale/store';
import { DeterminedVariantPrices } from './types';

interface IUseDiscountProps {
  product: IProductListItem;
  selectedVariant: IProductListItem['variants'][number] | null;
}

const useDiscount = ({
  product,
  selectedVariant,
}: IUseDiscountProps): {
  discount: number;
  currencySymbol: string;
  determinedVariant: DeterminedVariantPrices;
} => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const prices = product.variants.map(variant => ({
    sellingPrice: variant.sellingPrice,
    currency: variant.currency,
    mrp: variant.mrp,
    mtoSellingPrice: variant.mtoSellingPrice,
  }));

  const determinedVariant = selectedVariant
    ? {
        sellingPrice: selectedVariant.sellingPrice,
        mrp: selectedVariant.mrp,
        mtoSellingPrice: selectedVariant.mtoSellingPrice,
      }
    : prices.reduce((minVariant, currentVariant) =>
        currentVariant.sellingPrice < minVariant.sellingPrice ? currentVariant : minVariant
      );

  // * This is done assuming that the currency will be the same for all the variants.
  const currency = selectedVariant?.currency || prices[0].currency;

  const mrp = determinedVariant.mrp;
  const sellingPrice = determinedVariant.sellingPrice;
  const mtoSellingPrice = determinedVariant.mtoSellingPrice ?? sellingPrice;

  const discount = useMemo(() => {
    return mode === 'BUY_NOW'
      ? getDiscountPercentage(mrp, sellingPrice)
      : getDiscountPercentage(mrp, mtoSellingPrice);
  }, [mode, mrp, sellingPrice, mtoSellingPrice]);

  const currencySymbol = CURRENCY_SYMBOLS[(currency as CurrencyType) ?? 'INR']?.code;

  return {
    discount,
    currencySymbol,
    determinedVariant,
  };
};

export default useDiscount;
