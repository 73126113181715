import { useEffect } from 'react';
import {
  getOrders,
  OmsFilterNames,
  resetOrdersList,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useDebounce } from '@point-of-sale/hooks';
import SelectedFilters from './SelectedFilters';
import Discovery from '../../../components/desktop/Discovery';
import { useOrdersDiscoveryFilters } from '../../../hooks/useOrdersDiscoveryFilters';
import { useOrdersDiscovery } from '../../../hooks/useOrdersDiscovery';

interface IOrderDiscoveryProps {
  usage?: 'DEFAULT' | 'CANCELLED_ORDERS';
}

const OrderDiscovery = ({ usage = 'DEFAULT' }: IOrderDiscoveryProps) => {
  const search = useAppSelector(store => store.oms.search);
  const filters = useAppSelector(store => store.oms.filters);
  const createdOn = useAppSelector(store => store.oms.createdOn);

  const selectedSalesChannelId = useAppSelector(store => store.identity.selectedSalesChannel?.id);

  const dispatch = useAppDispatch();

  const { onChangeFilter, onRemoveFilter, onChangeSearch } = useOrdersDiscovery();

  const debouncedApiCall = useDebounce(() => dispatch(getOrders()), 300);

  useEffect(() => {
    debouncedApiCall.implementation();
  }, [filters, createdOn, debouncedApiCall]);

  useEffect(() => {
    dispatch(resetOrdersList());

    if (usage === 'CANCELLED_ORDERS') {
      onChangeFilter('lineItems.status', [{ label: 'Cancelled', value: 'CANCELLED' }]);
    } else {
      onRemoveFilter('lineItems.status');
    }
  }, [usage]);

  useEffect(() => {
    if (selectedSalesChannelId) {
      onChangeFilter('salesChannelId', [
        { label: selectedSalesChannelId, value: selectedSalesChannelId },
      ]);
    }
  }, [selectedSalesChannelId]);

  const { discoveryFilters } = useOrdersDiscoveryFilters();

  return (
    <Discovery
      filters={discoveryFilters}
      placeholder="Search by Customer Name, Customer Phone, Order Number, Barcode"
      onFilterChange={onChangeFilter}
      onFilterClear={filterName => onRemoveFilter(filterName as OmsFilterNames)}
      onPressEnter={onChangeSearch}
      searchQuery={search.query}
      renderSelectedFilters={() => <SelectedFilters onChangeFilter={onChangeFilter} />}
    />
  );
};

export default OrderDiscovery;
