import { Text } from '@point-of-sale/components';
import CountrySelector from '../CountrySelector';
import * as Styles from './styles';

interface IPhoneNumberInputProps {
  inputValue: string;
  countryCode: string;
  onChange: (value: string) => void;
  setCountryCode: (countryCode: string) => void;
  label?: string;
  hasMarginBottom?: boolean;
}

const PhoneNumberInput = ({
  countryCode,
  inputValue,
  onChange,
  setCountryCode,
  label = 'Enter phone',
  hasMarginBottom = true,
}: IPhoneNumberInputProps) => {
  return (
    <Styles.Wrapper $hasMarginBottom={hasMarginBottom}>
      <Text fontSize={14} color="var(--dove-gray)">
        {label}
      </Text>
      <Styles.Bottom>
        <CountrySelector
          value={countryCode}
          onChange={newCountryCode => {
            setCountryCode(newCountryCode);
          }}
        />

        <Styles.StyledInput
          placeholder="ex 9999 8888 77"
          value={inputValue}
          onChange={e => onChange(e.target.value)}
          sizeVariant="small"
          variant="outline"
          type="number"
          isFullWidth
        />
      </Styles.Bottom>
    </Styles.Wrapper>
  );
};

export default PhoneNumberInput;
