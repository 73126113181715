import { createSlice } from '@reduxjs/toolkit';
import { IUserInterfaceState } from './types';

const initialState: IUserInterfaceState = {
  isMobileNavigationOpen: false,
};

const userInterfaceSlice = createSlice({
  name: 'userInterface',
  initialState,
  reducers: {
    setIsMobileNavigationOpen(state, action) {
      state.isMobileNavigationOpen = action.payload;
    },
  },
});

export const userInterfaceReducer = userInterfaceSlice.reducer;

export const { setIsMobileNavigationOpen } = userInterfaceSlice.actions;
