import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { addDays, differenceInDays, startOfDay, startOfTomorrow } from 'date-fns';
import { Button, DayPicker, IconButton, Modal, Text } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { formatToReadableDate, isNumber } from '@point-of-sale/utils';
import {
  setDeliverabilityOfCartItem,
  updateItemTurnAroundTime,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { CartItemTatUpdateDTOType, INormalizedServiceabilityData } from '@point-of-sale/schemas';
import ShippingPartner from './ShippingPartner';
import useCartItemLogistics from './useCartItemLogistics';
import { CartUsageType } from '../../types';
import { TextPair } from '../styles';
import * as Styles from './styles';
import { CalendarLottie } from '@runway/illustrations';

const SHIPPING_PREFERENCES = {
  PRIORITY: {
    value: 'PRIORITY',
    name: 'Priority',
  },
  STANDARD: {
    value: 'STANDARD',
    name: 'Standard',
  },
} as const;

interface IDeliveryDateEditProps {
  readablePromiseDate: string;
  cartId: number;
  itemId: number;
  isLoading: boolean;
  serviceabilityData: INormalizedServiceabilityData[string];
  usage: CartUsageType;
}

const DeliveryDateEdit = ({
  readablePromiseDate,
  cartId,
  itemId,
  serviceabilityData,
  isLoading,
  usage,
}: IDeliveryDateEditProps) => {
  const cartItem = useAppSelector(store => store.pointOfSale.cart.data?.cartItems.records[itemId]);

  const addresses = useAppSelector(store => store.pointOfSale.addresses.data);
  const facilities = useAppSelector(store => store.common.facilities.data);

  const [isModalOpen, modalOpenActions] = useBoolean();

  const [selectedBrandDeliveryDate, setSelectedBrandDeliveryDate] = useState<Date>();
  const [shippingPreference, setShippingPreference] =
    useState<keyof typeof SHIPPING_PREFERENCES>('STANDARD');

  const deliverability = useAppSelector(store => store.pointOfSale.deliverability[itemId]);

  useEffect(() => {
    if (
      deliverability?.preferredShipping &&
      deliverability?.preferredShipping !== shippingPreference
    ) {
      setShippingPreference(deliverability?.preferredShipping);
    }
  }, [deliverability?.preferredShipping]);

  const { shouldShowBrandTat, particularServiceability, brandTatInDays } = useCartItemLogistics({
    itemId,
    serviceabilityData,
  });

  const dispatch = useAppDispatch();

  const opsTat =
    (cartItem.fulfillmentMode === 'ONHAND'
      ? particularServiceability?.info.innerOpsTat
      : particularServiceability?.info.outerOpsTat) ?? 0;

  useEffect(() => {
    if (brandTatInDays && isNumber(brandTatInDays)) {
      const brandTatDate = addDays(new Date(), brandTatInDays);
      onChangeBrandDeliveryDate(brandTatDate);
    }
  }, [brandTatInDays]);

  useEffect(() => {
    if (cartItem.isPriority) {
      onChangeShippingPreference(SHIPPING_PREFERENCES.PRIORITY.value);

      dispatch(
        setDeliverabilityOfCartItem({
          cartItemId: itemId,
          deliverability: {
            preferredShipping: SHIPPING_PREFERENCES.PRIORITY.value,
          },
        })
      );
    }
  }, [cartItem.isPriority]);

  function onChangeBrandDeliveryDate(date: Date | undefined) {
    setSelectedBrandDeliveryDate(date);
  }

  function onChangeShippingPreference(newShippingPreference: keyof typeof SHIPPING_PREFERENCES) {
    setShippingPreference(newShippingPreference);
  }

  const shouldShowEdit =
    cartItem.fulfillmentMode === 'ONHAND'
      ? !cartItem.isExpress
      : cartItem.fulfillmentMode === 'MTC' ||
        cartItem.fulfillmentMode === 'MTO' ||
        cartItem.fulfillmentMode === 'JIT';

  function getNewDeliveryDate(
    referenceData: Date,
    supposedShippingPreference: keyof typeof SHIPPING_PREFERENCES
  ) {
    let daysToAdd =
      (supposedShippingPreference === 'PRIORITY'
        ? particularServiceability?.info?.priorityCourierTat
        : particularServiceability?.info?.standardCourierTat) ?? 0;

    daysToAdd += opsTat;

    return addDays(referenceData, daysToAdd);
  }

  function determineDeliveryLocation() {
    if (cartItem.deliveryMode === 'HOME_DELIVERY' && cartItem.customerDeliveryAddressId) {
      const deliveryAddress = addresses.records[cartItem.customerDeliveryAddressId];
      return `Delivering to Home in ${deliveryAddress?.cityDisplayName}`;
    }

    if (cartItem.deliveryMode === 'STORE_PICKUP' && cartItem.customerDeliveryStoreId) {
      const deliveryAddress = facilities.records[cartItem.customerDeliveryStoreId];
      return `Delivering to ${deliveryAddress?.name} in ${deliveryAddress?.address?.cityDisplayName}`;
    }
  }

  const newDeliveryDate = getNewDeliveryDate(
    selectedBrandDeliveryDate ?? new Date(),
    shippingPreference
  );

  return (
    <Styles.Wrapper>
      <TextPair
        fontSize={14}
        color="var(--dove-gray)"
        weight="semibold"
        width="fit-content"
        style={{
          height: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Est Delivery:
        {!isLoading && <b>&nbsp;{readablePromiseDate}</b>}
      </TextPair>
      {isLoading && <CalendarLottie height={24} width={24} />}
      {/* <CalendarLottie height={24} width={24} /> */}
      {usage === 'ADD_PRODUCTS' && shouldShowEdit && (
        <IconButton
          sizeVariant="extraSmall"
          icon="EditIcon"
          shapeVariant="circle"
          onClick={modalOpenActions.on}
        />
      )}

      <Modal
        heading={
          <Text fontSize={20} weight="semibold">
            Update promised delivery date &nbsp;
            <Text fontSize={16}>
              {determineDeliveryLocation() && `(${determineDeliveryLocation()})`}
            </Text>
          </Text>
        }
        isOpen={isModalOpen}
        onClose={modalOpenActions.off}
        size="regular"
        footer={
          <Styles.FooterWrapper>
            {newDeliveryDate && (
              <Text fontSize={18}>
                Final estimated delivery date is <b>{formatToReadableDate(newDeliveryDate)}</b>
              </Text>
            )}
            <Button
              isFullWidth
              onClick={() => {
                if (!shippingPreference) {
                  toast('Please choose a shipping mode');
                  return;
                }

                const payload: Partial<CartItemTatUpdateDTOType> = {};

                if (cartItem.fulfillmentMode !== 'ONHAND' && !selectedBrandDeliveryDate) {
                  toast.error('Please select a brand delivery date');
                  return;
                }

                if (cartItem.fulfillmentMode !== 'ONHAND' && selectedBrandDeliveryDate) {
                  // payload.vendorDeliveryDate = getUnixTime(selectedBrandDeliveryDate).toString();
                  payload.vendorTat = differenceInDays(
                    startOfDay(selectedBrandDeliveryDate),
                    startOfDay(new Date())
                  );
                }

                // * set priority shipping only if express is not available
                payload.isPriority = shippingPreference === 'PRIORITY';
                payload.isExpress =
                  cartItem.fulfillmentMode === 'ONHAND' && particularServiceability?.info.express;

                payload.opsTat = opsTat;

                if (payload.isPriority) {
                  payload.courierTat = particularServiceability?.info.priorityCourierTat;
                } else {
                  payload.courierTat = particularServiceability?.info.standardCourierTat;
                }

                dispatch(
                  setDeliverabilityOfCartItem({
                    cartItemId: itemId,
                    deliverability: {
                      preferredShipping: shippingPreference,
                    },
                  })
                );

                dispatch(
                  updateItemTurnAroundTime({
                    cartId,
                    itemId,
                    data: payload,
                    successCallback: modalOpenActions.off,
                  })
                );
              }}
            >
              Apply
            </Button>
          </Styles.FooterWrapper>
        }
      >
        <Styles.ModalContent>
          {shouldShowBrandTat && (
            <Styles.Section>
              <Text fontSize={18} weight="semibold">
                Brand TAT &nbsp;
                {selectedBrandDeliveryDate && (
                  <Text fontSize={14} color="var(--dove-gray)">
                    {Math.abs(differenceInDays(startOfDay(new Date()), selectedBrandDeliveryDate))}{' '}
                    days | {formatToReadableDate(selectedBrandDeliveryDate)}
                  </Text>
                )}
              </Text>
              <DayPicker
                fixedWeeks
                mode="single"
                disabled={{ before: startOfTomorrow() }}
                selected={selectedBrandDeliveryDate}
                numberOfMonths={1}
                onSelect={newSelected => {
                  onChangeBrandDeliveryDate(newSelected);
                }}
                // month={selectedBrandDeliveryDate}
              />
              {!selectedBrandDeliveryDate && (
                <Text fontSize={10} color="var(--alizarin-crimson)">
                  Please Select A Date
                </Text>
              )}
            </Styles.Section>
          )}
          <Styles.Section>
            <Text fontSize={18} weight="semibold">
              Shipping Preference
            </Text>

            <Styles.ShippingPartnersWrapper>
              {cartItem.isExpress ? (
                <ShippingPartner
                  id={'EXPRESS'}
                  name={'Express'}
                  // TODO: ask for fallback value
                  tat={0}
                  isChecked
                  onSelect={() => {
                    //
                  }}
                />
              ) : (
                <>
                  {particularServiceability?.info.priorityShippingAvailable && (
                    <ShippingPartner
                      id={SHIPPING_PREFERENCES.PRIORITY.value}
                      name={SHIPPING_PREFERENCES.PRIORITY.name}
                      // TODO: ask for fallback value
                      tat={(particularServiceability?.info.priorityCourierTat ?? 0) + opsTat}
                      isChecked={SHIPPING_PREFERENCES.PRIORITY.value === shippingPreference}
                      onSelect={() => {
                        onChangeShippingPreference(SHIPPING_PREFERENCES.PRIORITY.value);
                      }}
                    />
                  )}

                  <ShippingPartner
                    id={SHIPPING_PREFERENCES.STANDARD.value}
                    name={SHIPPING_PREFERENCES.STANDARD.name}
                    // TODO: ask for fallback value
                    tat={(particularServiceability?.info.standardCourierTat ?? 0) + opsTat}
                    isChecked={SHIPPING_PREFERENCES.STANDARD.value === shippingPreference}
                    onSelect={() => {
                      onChangeShippingPreference(SHIPPING_PREFERENCES.STANDARD.value);
                    }}
                  />
                </>
              )}
            </Styles.ShippingPartnersWrapper>
          </Styles.Section>
        </Styles.ModalContent>
      </Modal>
    </Styles.Wrapper>
  );
};

export default DeliveryDateEdit;
