import { z } from 'zod';

export const SkuMovementMovementType = z.enum(['STORE_TRIAL']);

export const SkuMovementDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  skuCode: z.string(),
  fromFacilityId: z.number(),
  toFacilityId: z.number(),
  movementType: SkuMovementMovementType,
});

export type SkuMovementDTOType = z.infer<typeof SkuMovementDTOSchema>;

export const SkuMovementsDTOSchema = z.array(SkuMovementDTOSchema);

export type SkuMovementsDTOType = z.infer<typeof SkuMovementsDTOSchema>;
