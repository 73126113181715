import { CommonActionsTypes } from './constants';
import { ICommonState } from './types';

export const setStoreStaff = (payload: Partial<ICommonState['storeStaff']>) => ({
  type: CommonActionsTypes.SET_STORE_STAFF,
  payload,
});

export const setSalesChannels = (payload: Partial<ICommonState['salesChannels']>) => ({
  type: CommonActionsTypes.SET_SALES_CHANNELS,
  payload,
});

export const setFacilities = (payload: Partial<ICommonState['facilities']>) => ({
  type: CommonActionsTypes.SET_FACILITIES,
  payload,
});

export const setVendors = (payload: Partial<ICommonState['vendors']>) => ({
  type: CommonActionsTypes.SET_VENDORS,
  payload,
});

export const setAnalyticsDashboard = (payload: Partial<ICommonState['analyticsDashboard']>) => ({
  type: CommonActionsTypes.SET_ANALYTICS_DASHBOARD,
  payload,
});

export const setErpUsers = (
  payload: Partial<ICommonState['erpUsers']>,
  operation: 'APPEND' | 'REPLACE' = 'REPLACE'
) => ({
  type: CommonActionsTypes.SET_ERP_USERS,
  payload,
  operation,
});
