import { motion } from 'motion/react';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  display: flex;
  padding: 12px;

  flex: 1;
  overflow: auto;
`;

export const ListWrapper = styled.ol`
  position: relative;
  gap: 4px;
  width: 100%;
`;
