import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence } from 'motion/react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useBoolean, useOnClickOutside } from '@point-of-sale/hooks';
import { ChevronRightIcon } from '../icons';
import { IOption } from './types';
import Text from '../Typography/Text';
import { ICON_MOTION_VARIANTS } from './constants';
import PopoverContent from './PopoverContent';
import OptionItem from './OptionItem';
import SelectAllCheckbox from './SelectAllCheckbox';
import * as Styles from './styles';
import { alphabeticallyCategorizeObjects } from '@point-of-sale/utils';

interface IMultiSelectProps {
  label: string;
  options: Array<IOption>;
  onChange: (values: Array<IOption>) => void;
  values: Array<string | number>;
  placeholder?: string;
  isSearchable?: boolean;
  className?: string;
  onClear?: () => void;
  isOutlined?: boolean;
  isLoading?: boolean;
  isSingleSelect?: boolean;
  shouldShowSelectAll?: boolean;
  shouldCategorizeAlphabetically?: boolean;
  shouldSortAlphabetically?: boolean;
}

const handleOptionChange = (
  option: IOption,
  selectedOptions: IOption[],
  setSelectedOptions: (options: IOption[]) => void,
  isSingleSelect: boolean
) => {
  if (isSingleSelect) {
    if (selectedOptions[0]?.value === option.value) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions([option]);
    }
  } else {
    if (selectedOptions.map(item => item.value).includes(option.value)) {
      setSelectedOptions(
        selectedOptions.filter(selectedOption => selectedOption.value !== option.value)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  }
};

const MultiSelect = ({
  options,
  values,
  onChange,
  onClear,
  label,
  className = '',
  placeholder = 'Select',
  isSearchable = false,
  isOutlined = false,
  isLoading = false,
  isSingleSelect = false,
  shouldShowSelectAll = false,
  shouldCategorizeAlphabetically,
  shouldSortAlphabetically,
}: IMultiSelectProps) => {
  const [search, setSearch] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<Array<IOption>>([]);

  const [isSelectOpen, selectOpenActions] = useBoolean();
  const [isClickOutsideAllowed, clickOutsideAllowedActions] = useBoolean(true);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  useOnClickOutside(wrapperRef, () => {
    if (isClickOutsideAllowed) {
      selectOpenActions.off();
    }
  });

  useEffect(() => {
    if (values.length) {
      setSelectedOptions(options.filter(option => values.includes(option.value)));
    } else {
      setSelectedOptions([]);
    }
  }, [values]);

  const listableOptions = useMemo(() => {
    let filteredOptions =
      isSearchable && search
        ? options.filter(option =>
            String(option.label).toLowerCase().includes(search.toLowerCase())
          )
        : options;

    if (shouldSortAlphabetically) {
      filteredOptions = [...filteredOptions].sort((a, b) =>
        String(a.label).localeCompare(String(b.label))
      );
    }

    return filteredOptions;
  }, [options, search, isSearchable, shouldSortAlphabetically]);

  const areAllOptionsSelected = useMemo(() => {
    const selectableOptions = listableOptions.filter(option => !option.isDisabled);
    return selectedOptions.length === selectableOptions.length;
  }, [listableOptions, selectedOptions]);

  const handleSelectAll = () => {
    if (areAllOptionsSelected) {
      setSelectedOptions([]);
    } else {
      const selectableOptions = listableOptions.filter(option => !option.isDisabled);
      setSelectedOptions(selectableOptions);
    }
  };

  const renderOptions = () => {
    const selectAllCheckbox =
      shouldShowSelectAll && !isSingleSelect ? (
        <SelectAllCheckbox onChange={handleSelectAll} isChecked={areAllOptionsSelected} />
      ) : null;

    if (shouldCategorizeAlphabetically) {
      const categorizedOptions = alphabeticallyCategorizeObjects(listableOptions, 'label');

      return (
        <>
          {selectAllCheckbox}
          {Object.entries(categorizedOptions).map(([letter, options]) => (
            <Fragment key={letter}>
              <Styles.CategoryTitleContainer>
                <Text fontSize={14} weight="semibold" color="var(--dove-gray)">
                  {letter}
                </Text>
              </Styles.CategoryTitleContainer>
              {options.map(option => (
                <OptionItem
                  key={option.value}
                  option={option}
                  isChecked={selectedOptions.map(item => item.value).includes(option.value)}
                  onChange={() =>
                    handleOptionChange(option, selectedOptions, setSelectedOptions, isSingleSelect)
                  }
                />
              ))}
            </Fragment>
          ))}
        </>
      );
    }

    return (
      <>
        {selectAllCheckbox}
        {listableOptions?.map(option => (
          <OptionItem
            key={option.value}
            option={option}
            isChecked={selectedOptions.map(item => item.value).includes(option.value)}
            onChange={() =>
              handleOptionChange(option, selectedOptions, setSelectedOptions, isSingleSelect)
            }
          />
        ))}
      </>
    );
  };

  return (
    <Styles.Wrapper className={className} ref={wrapperRef} $isOutlined={isOutlined}>
      <Styles.Trigger ref={ref => setReferenceElement(ref)} onClick={selectOpenActions.toggle}>
        <Text fontSize={16} color="var(--cod-gray)">
          {placeholder}
        </Text>
        <Styles.IconWrapper>
          <ChevronRightIcon
            animate={isSelectOpen ? 'open' : 'closed'}
            variants={ICON_MOTION_VARIANTS}
          />
        </Styles.IconWrapper>
      </Styles.Trigger>

      {createPortal(
        <AnimatePresence mode="wait">
          {isSelectOpen && (
            <PopoverContent
              label={label}
              isSearchable={isSearchable}
              search={search}
              setSearch={setSearch}
              onClear={onClear}
              selectOpenActions={selectOpenActions}
              isLoading={isLoading}
              renderOptions={renderOptions}
              selectedOptions={selectedOptions}
              onChange={onChange}
              setPopperElement={setPopperElement}
              styles={styles}
              attributes={attributes}
              clickOutsideAllowedActions={clickOutsideAllowedActions}
              setSelectedOptions={setSelectedOptions}
            />
          )}
        </AnimatePresence>,
        document.body
      )}
    </Styles.Wrapper>
  );
};

export default MultiSelect;
