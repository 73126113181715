import Button from '../Button';
import Text from '../Typography/Text';
import Skeletons from './Skeletons';
import { IOption } from './types';
import * as Styles from './styles';
import IconButton from '../IconButton';

interface PopoverContentProps {
  label: string;
  isSearchable: boolean;
  search: string;
  setSearch: (value: string) => void;
  onClear?: () => void;
  selectOpenActions: { off: () => void };
  isLoading: boolean;
  renderOptions: () => JSX.Element;
  selectedOptions: Array<IOption>;
  onChange: (values: Array<IOption>) => void;
  setPopperElement: (node: HTMLDivElement | null) => void;
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  clickOutsideAllowedActions: { off: () => void; on: () => void };
  setSelectedOptions: (options: Array<IOption>) => void;
}

const PopoverContent = ({
  label,
  isSearchable,
  search,
  setSearch,
  onClear,
  selectOpenActions,
  isLoading,
  renderOptions,
  selectedOptions,
  onChange,
  setPopperElement,
  styles,
  attributes,
  clickOutsideAllowedActions,
  setSelectedOptions,
}: PopoverContentProps) => (
  <Styles.PopoverContainer
    ref={setPopperElement}
    style={{
      ...styles.popper,
      width: 600,
      height: 500,
    }}
    {...attributes.popper}
    onMouseEnter={clickOutsideAllowedActions.off}
    onMouseLeave={clickOutsideAllowedActions.on}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ type: 'tween', duration: 0.2 }}
  >
    <Styles.PopoverHeader>
      <Text fontSize={16} weight="bold">
        {label}
      </Text>
      {isSearchable && (
        <Styles.SearchBox
          sizeVariant="extraSmall"
          variant="outline"
          leftIcon="SearchIcon"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      )}
      {onClear && (
        <Button
          onClick={() => {
            setSelectedOptions([]);
            onClear();
          }}
          sizeVariant="xs"
          variant="ghost"
        >
          Clear
        </Button>
      )}
      <IconButton
        className="close-button"
        shapeVariant="circle"
        icon="CrossIcon"
        onClick={selectOpenActions.off}
      />
    </Styles.PopoverHeader>
    <Styles.OptionsGrid>{isLoading ? <Skeletons /> : renderOptions()}</Styles.OptionsGrid>
    <Styles.PopoverFooter>
      <Button
        onClick={() => {
          onChange(selectedOptions);
          selectOpenActions.off();
        }}
      >
        APPLY
      </Button>
    </Styles.PopoverFooter>
  </Styles.PopoverContainer>
);

export default PopoverContent;
