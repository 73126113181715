import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { AnimatePresence } from 'motion/react';
import { addDays } from 'date-fns';
import { ChevronDownIcon, ChevronRightIcon, Portal } from '@point-of-sale/components';
import { useBoolean, useOnClickOutside } from '@point-of-sale/hooks';
import { offlineFacilitiesSelector, useAppSelector } from '@point-of-sale/store';
import {
  DeliveryModeType,
  ProductVariantDTOType,
  FulfillmentModeType,
  AddressDTOType,
  INormalizedServiceabilityData,
  CartItemDTOType,
} from '@point-of-sale/schemas';
import { formatToReadableDate } from '@point-of-sale/utils';
import FacilitiesList from './FacilitiesList';
import DeliverToHome from './DeliverToHome';
import {
  CUSTOMER_DELIVERY_LOCATION_NAMES,
  HOME_DELIVERY,
  STORE_PICKUP,
  STORE_PURCHASE,
} from './constants';
import * as Styles from './styles';

type Columns = 'inventory' | 'deliveryDate';

interface IDestinationSelectorProps {
  variant: ProductVariantDTOType | null;
  fulfillmentMode: FulfillmentModeType;
  isLoading?: boolean;
  columns?: Array<Columns>;
  fulfillmentFacilityId?: number;
  deliveryMode?: DeliveryModeType;
  customerDeliveryStoreId?: number;
  onChange?: ({
    customerDeliveryStoreId,
    deliveryMode,
    promiseDate,
    callback,
  }: {
    customerDeliveryStoreId?: number;
    deliveryMode: DeliveryModeType;
    promiseDate?: string;
    callback: (passThrough: boolean) => void;
  }) => void;
  serviceabilityData: INormalizedServiceabilityData[string];
  isMTO: boolean;
  isMTC: boolean;
  isJIT: boolean;
  width?: string;
  customerAddress: AddressDTOType | undefined;

  shouldShowOtherFacilities?: boolean;

  defaultTat: number;
  savedPromiseDate?: string;

  cartItem?: CartItemDTOType;
}

const DestinationSelector = ({
  variant,
  onChange,
  columns = [],
  fulfillmentMode,
  fulfillmentFacilityId,
  deliveryMode,
  customerDeliveryStoreId,
  isLoading = false,
  serviceabilityData,
  isMTO,
  isMTC,
  isJIT,
  width = '150px',
  customerAddress,
  shouldShowOtherFacilities = true,
  defaultTat,
  savedPromiseDate,
  cartItem,
}: IDestinationSelectorProps) => {
  const facilities = useAppSelector(offlineFacilitiesSelector);
  const currentFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const [customerDeliveryLocation, setCustomerDeliveryLocation] = useState<{
    name: string | keyof typeof CUSTOMER_DELIVERY_LOCATION_NAMES;
    id: number | DeliveryModeType;
  }>({
    id: -1,
    name: '',
  });

  const [isPopupOpen, popupOpenActions] = useBoolean();
  const [isClickOutsideAllowed, clickOutsideAllowedActions] = useBoolean(true);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  useOnClickOutside(wrapperRef, () => {
    if (isClickOutsideAllowed) {
      popupOpenActions.off();
    }
  });

  // TODO: handle fulfillmentFacilityId from inventory details modal

  useEffect(() => {
    if (currentFacilityId) {
      setCustomerDeliveryLocation({
        id: currentFacilityId,
        name: facilities.data.records[currentFacilityId]?.name,
      });
    }
  }, []);

  const currentFacility = currentFacilityId ? facilities.data.records[currentFacilityId] : null;

  const shouldShowDelivery = columns.includes('deliveryDate');
  const shouldShowInventory = columns.includes('inventory');

  // TODO: Revisit this logic
  useEffect(() => {
    if (deliveryMode === STORE_PURCHASE) {
      if (currentFacility) {
        setCustomerDeliveryLocation({
          id: currentFacility.id,
          name: currentFacility.name || '',
        });
      }
    } else if (deliveryMode === STORE_PICKUP) {
      if (customerDeliveryStoreId) {
        const deliveryFacility = facilities.data.records[customerDeliveryStoreId];

        setCustomerDeliveryLocation({
          id: customerDeliveryStoreId,
          name: deliveryFacility?.name || '',
        });
      }
    } else if (deliveryMode === HOME_DELIVERY) {
      setCustomerDeliveryLocation({
        id: HOME_DELIVERY,
        name: CUSTOMER_DELIVERY_LOCATION_NAMES[HOME_DELIVERY],
      });
    }
  }, [
    fulfillmentMode,
    fulfillmentFacilityId,
    deliveryMode,
    customerDeliveryStoreId,
    facilities.data.records,
    currentFacility,
  ]);

  const defaultHomeDeliveryDate = '';
  let homeDeliveryDate = defaultHomeDeliveryDate;

  const deliverability = useAppSelector(store =>
    cartItem?.id ? store.pointOfSale.deliverability[cartItem?.id] : null
  );

  let filteredServiceabilityData = structuredClone(serviceabilityData);

  if (cartItem?.fulfillmentMode === 'JIT') {
    filteredServiceabilityData = serviceabilityData.filter(
      item => item.from.facilityId === String(cartItem.fulfillmentFacilityId)
    );
  }

  const serviceabilityForHomeDelivery = filteredServiceabilityData?.find(
    s => s.to === customerAddress?.pincode
  );

  const opsTat =
    (cartItem?.fulfillmentMode === 'ONHAND'
      ? serviceabilityForHomeDelivery?.info?.innerOpsTat
      : serviceabilityForHomeDelivery?.info?.outerOpsTat) ?? 0;

  const courierTat =
    (deliverability?.preferredShipping === 'PRIORITY'
      ? serviceabilityForHomeDelivery?.info.priorityCourierTat
      : serviceabilityForHomeDelivery?.info.standardCourierTat) ??
    serviceabilityForHomeDelivery?.info.standardCourierTat;

  let determinedVendorTat = 0;

  if (cartItem?.fulfillmentMode !== 'ONHAND') {
    switch (cartItem?.fulfillmentMode) {
      case 'MTO': {
        determinedVendorTat = serviceabilityForHomeDelivery?.info.mtoTat ?? 0;
        break;
      }
      case 'MTC': {
        determinedVendorTat = serviceabilityForHomeDelivery?.info.mtcTat ?? 0;
        break;
      }
      case 'JIT': {
        determinedVendorTat = serviceabilityForHomeDelivery?.info.jitTat ?? 0;
        break;
      }
      default: {
        determinedVendorTat = 0;
      }
    }
  }

  const savedVendorTat = cartItem
    ? cartItem.vendorDeliveryDate
      ? cartItem.vendorTat
      : determinedVendorTat
    : 0;

  const sumOfAllTats = cartItem ? (courierTat ?? 0) + (savedVendorTat ?? 0) + opsTat : 0;

  const isHomeDeliverySelected = customerDeliveryLocation.id === HOME_DELIVERY;

  if (cartItem && customerAddress?.pincode) {
    homeDeliveryDate = formatToReadableDate(addDays(new Date(), sumOfAllTats));
  }

  if (
    serviceabilityForHomeDelivery?.info.express &&
    customerAddress?.pincode &&
    cartItem?.fulfillmentMode === 'ONHAND'
  ) {
    homeDeliveryDate = 'EXPRESS';
  }

  function onChangeHomeDelivery(promiseDate: string) {
    onChange?.({
      deliveryMode: HOME_DELIVERY,
      promiseDate,
      callback: passThrough => {
        if (passThrough) {
          setCustomerDeliveryLocation({
            id: HOME_DELIVERY,
            name: CUSTOMER_DELIVERY_LOCATION_NAMES[HOME_DELIVERY],
          });
        }
      },
    });
  }

  return (
    <Styles.Wrapper ref={wrapperRef} $width={width}>
      <Styles.InputWrapper ref={ref => setReferenceElement(ref)} onClick={popupOpenActions.on}>
        <input type="text" value={customerDeliveryLocation.name} readOnly />
        {isPopupOpen ? <ChevronRightIcon /> : <ChevronDownIcon />}
      </Styles.InputWrapper>
      <AnimatePresence>
        {isPopupOpen && (
          <Portal>
            <Styles.PopupWrapper
              $isLoading={isLoading}
              ref={ref => setPopperElement(ref)}
              style={{
                ...styles.popper,
                width: 420,
              }}
              {...attributes.popper}
              onMouseEnter={clickOutsideAllowedActions.off}
              onMouseLeave={clickOutsideAllowedActions.on}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: '200px' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              <Styles.Table>
                <FacilitiesList
                  isMTO={isMTO}
                  isMTC={isMTC}
                  isJIT={isJIT}
                  defaultTat={defaultTat}
                  shouldShowDelivery={shouldShowDelivery}
                  shouldShowInventory={shouldShowInventory}
                  shouldShowOtherFacilities={shouldShowOtherFacilities}
                  customerDeliveryLocationId={customerDeliveryLocation.id}
                  variant={variant}
                  facilities={facilities.data}
                  onChange={(selectedFacilityId, promiseDate) => {
                    if (isLoading) {
                      return;
                    }

                    onChange?.({
                      customerDeliveryStoreId: selectedFacilityId,
                      deliveryMode: STORE_PICKUP,
                      promiseDate,

                      callback: passThrough => {
                        if (passThrough) {
                          setCustomerDeliveryLocation(() => ({
                            id: selectedFacilityId,
                            name: facilities.data.records[selectedFacilityId].name,
                          }));
                        }
                      },
                    });
                  }}
                  serviceabilityData={serviceabilityData}
                  savedPromiseDate={savedPromiseDate}
                  cartItem={cartItem}
                />

                <DeliverToHome
                  homeDeliveryDate={homeDeliveryDate}
                  shouldShowDelivery={shouldShowDelivery}
                  isSelected={isHomeDeliverySelected}
                  onChange={promiseDate => {
                    if (isLoading) {
                      return;
                    }

                    onChangeHomeDelivery(promiseDate);
                  }}
                  savedPromiseDate={savedPromiseDate}
                  cartItem={cartItem}
                />
              </Styles.Table>
            </Styles.PopupWrapper>
          </Portal>
        )}
      </AnimatePresence>
    </Styles.Wrapper>
  );
};

export default DestinationSelector;
