import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 8px;
  height: 100%;
  /* border: 1px solid black; */
  background: var(--white);
  border-radius: 1px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 8px;
`;

export const UnorderedList = styled.ul`
  //
`;

export const ListItem = styled.li<{ $isActive: boolean }>`
  padding: 4px 8px 4px 12px;
  cursor: pointer;
  border-radius: 1px;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: var(--athens-gray);
    `}

  &:hover {
    background: var(--athens-gray);
  }
`;
