import { useEffect } from 'react';
import { Modal, Text } from '@point-of-sale/components';
import { ProductDTOType } from '@point-of-sale/schemas';
import { SizeChartTable } from '../../../shared/SizeChartTable';
import useSizeChartData from '../../../../hooks/useSizeChartData';
import * as Styles from './styles';

interface ISizeChartModalProps {
  isOpen: boolean;
  onClose: () => void;
  product: ProductDTOType;
}

const SizeChartModal = ({ isOpen, onClose, product }: ISizeChartModalProps) => {
  const { sizeChartData, error, isLoading, fetchSizeChart } = useSizeChartData(product.id);

  useEffect(() => {
    if (isOpen) {
      fetchSizeChart();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      heading={
        <Text weight="semibold" fontSize={20} color="var(--cod-gray)">
          Size Chart for {product.brand.name} {product.name}
        </Text>
      }
    >
      <Styles.Wrapper>
        <SizeChartTable data={sizeChartData} isLoading={isLoading} error={error} />
      </Styles.Wrapper>
    </Modal>
  );
};

export default SizeChartModal;
