import { Button } from '@point-of-sale/components';
import { requestNotificationPermission, useAppSelector } from '@point-of-sale/store';
import WithMobileHeader from '../../../components/mobile/WithMobileHeader';
import NotificationItem from '../../../components/shared/Notifications/NotificationItem';
import * as Styles from './styles';
import { mockNotifications } from './data';

const Notifications = () => {
  // const notifications = useAppSelector(store => store.notifications.notifications);

  return (
    <Styles.Wrapper>
      {mockNotifications.map(notification => (
        <NotificationItem key={notification.id} data={notification} />
      ))}
      {/* <Button
        onClick={() => {
          requestNotificationPermission();
        }}
      >
        Ask for permission
      </Button> */}
    </Styles.Wrapper>
  );
};

export default WithMobileHeader(Notifications, {
  name: 'Notifications',
});
