import { useMemo } from 'react';
import { searchProducts, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { useFetchOnListEnd } from '@point-of-sale/hooks';
import { NoResults } from '@runway/illustrations';
import { SpinnerIcon } from '@point-of-sale/components';
import ProductListItem from './ProductListItem';
import * as Styles from './styles';

// TODO: Virtualize the list
const ProductsList = () => {
  const isLoading = useAppSelector(store => store.pointOfSale.products.isLoading);
  const ids = useAppSelector(store => store.pointOfSale.products.data.ids);
  const isFiltersApplied = useAppSelector(
    store =>
      Object.keys(store.pointOfSale.filters).length > 0 || store.pointOfSale.search.query.length > 0
  );

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(searchProducts('EXPLORE_PRODUCTS'));
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  const renderedItemIds = useMemo(() => (isFiltersApplied ? ids : []), [isFiltersApplied, ids]);

  return (
    <Styles.Wrapper>
      {renderedItemIds.map((id, index) => (
        <ProductListItem
          key={id}
          id={id}
          lastItemRef={index === renderedItemIds.length - 2 ? lastItemRef : undefined}
        />
      ))}

      {!isLoading && renderedItemIds.length === 0 && (
        <Styles.NoResultsWrapper>
          <NoResults />
        </Styles.NoResultsWrapper>
      )}
      {isLoading && renderedItemIds.length === 0 && (
        <Styles.LoaderWrapper>
          <SpinnerIcon size={48} />
        </Styles.LoaderWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default ProductsList;
