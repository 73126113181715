import { useState } from 'react';
import { AnimatePresence } from 'motion/react';
import { Button, ColorSelector, SizeSelector } from '@point-of-sale/components';
import {
  setSelectedColor,
  setSelectedSize,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import useGetQuantityForSizeByColor from './useGetQuantityForSizeByColor';
import SizeChartModal from '../SizeChartModal';
import useProductContext from '../context/useProductContext';
import { getVariantFilterOptions } from '../utils';

const VariantSelection = () => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const { data, isExpanded } = useProductContext();

  const dispatch = useAppDispatch();

  const { getQuantityForSizeByColor } = useGetQuantityForSizeByColor(data);

  const [isSizeChartOpen, sizeChartOpenActions] = useBoolean();

  const [enabledSizes, setEnabledSizes] = useState<Array<string>>([]);

  const allOptions = data.options.sort((a, b) => b.position - a.position);
  const allColorOptions = getVariantFilterOptions(allOptions, 'COLOR');
  const allSizeOptions = getVariantFilterOptions(allOptions, 'SIZE').map((item, index) => ({
    id: index + 1,
    name: item.displayName,
    quantity: getQuantityForSizeByColor(item.displayName),
  }));

  return (
    <AnimatePresence>
      {isExpanded &&
        allOptions.map(option => {
          if (option.name === 'COLOR') {
            return (
              <ColorSelector
                label="Color"
                key={option.name}
                availableColors={allColorOptions.map(item => ({
                  id: item.displayName,
                  name: item.displayName,
                  colorCodes: item.colorCodes ?? [],
                }))}
                enabledColors={allColorOptions.map(item => item.displayName)}
                selectedColor={data.selectedColor}
                onSelectColor={newSelectedColor => {
                  if (data.selectedColor === newSelectedColor.name) {
                    dispatch(
                      setSelectedColor({
                        id: data.id,
                        value: null,
                      })
                    );

                    const filteredColors = data.variants.map(variant => variant.size);

                    setEnabledSizes(filteredColors);

                    return;
                  }

                  const filteredSizes = data.variants
                    .filter(variant => variant.color === newSelectedColor.name)
                    .map(variant => variant.size);

                  dispatch(
                    setSelectedColor({
                      id: data.id,
                      value: newSelectedColor.name,
                    })
                  );
                  setEnabledSizes(filteredSizes);

                  if (data.selectedSize && !filteredSizes.includes(data.selectedSize)) {
                    dispatch(
                      setSelectedSize({
                        id: data.id,
                        value: null,
                      })
                    );
                  }
                }}
              />
            );
          }

          if (option.name === 'SIZE') {
            return (
              <SizeSelector
                key={option.name}
                label="Sizes"
                rightLabel={
                  data.isSizeChartValid ? (
                    <Button
                      onClick={sizeChartOpenActions.on}
                      sizeVariant="xs"
                      variant="ghost"
                      rightIcon="ArrowEastIcon"
                      colorScheme="dark"
                    >
                      Size Guide
                    </Button>
                  ) : null
                }
                shouldShowCustomTailored={mode === 'MADE_TO_ORDER'}
                availableSizes={allSizeOptions}
                enabledSizes={enabledSizes}
                selectedSize={data.selectedSize}
                onSelectSize={selectedSize => {
                  if (data.selectedSize === selectedSize.name) {
                    dispatch(
                      setSelectedSize({
                        id: data.id,
                        value: null,
                      })
                    );

                    // const filteredColors = data.variants.map(
                    //   variant => variant.color
                    // );

                    return;
                  }

                  const filteredColors = data.variants
                    .filter(variant => variant.size === selectedSize.name)
                    .map(variant => variant.color);

                  dispatch(
                    setSelectedSize({
                      id: data.id,
                      value: selectedSize.name,
                    })
                  );

                  if (data.selectedColor && !filteredColors.includes(data.selectedColor)) {
                    dispatch(
                      setSelectedColor({
                        id: data.id,
                        value: null,
                      })
                    );
                  }
                }}
              />
            );
          }

          return null;
        })}

      {data.isSizeChartValid && (
        <SizeChartModal
          isOpen={isSizeChartOpen}
          onClose={sizeChartOpenActions.off}
          product={data}
        />
      )}
    </AnimatePresence>
  );
};

export default VariantSelection;
