import { useEffect, useRef } from 'react';
import { IconButton, Modal } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { ZbarBarcodeScanner } from '@lyskraft/zbar-sdk';
import * as Styles from './styles';

interface IScannerProps {
  onChange: (value: string) => void;
}

const Scanner = ({ onChange }: IScannerProps) => {
  const [isModalOpen, modalOpenActions] = useBoolean();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const scanner = ZbarBarcodeScanner.getInstance();

  useEffect(() => {
    if (isModalOpen && containerRef.current) {
      scanner.subscribe('scan', (barcode: string) => {
        onChange(barcode);
        modalOpenActions.off();
      });

      scanner.run(containerRef.current);
    }

    if (!isModalOpen) {
      scanner.unsubscribe('scan');
    }
  }, [isModalOpen]);

  return (
    <>
      <IconButton icon="ScannerIcon" sizeVariant="large" onClick={modalOpenActions.on} />

      <Modal
        size="regular"
        isOpen={isModalOpen}
        onClose={modalOpenActions.off}
        heading="Put barcode in view"
      >
        <Styles.Wrapper>
          <div ref={containerRef} style={{ height: '100%', width: '100%' }} />
        </Styles.Wrapper>
      </Modal>
    </>
  );
};

export default Scanner;
