import { Button } from '@point-of-sale/components';
import { getInventoryStatus, useAppSelector } from '@point-of-sale/store';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';
import BuyFromAnotherStore from '../AddOperations/BuyFromAnotherStore';
import useProductContext from '../context/useProductContext';
import PlaceMadeToOrder from '../AddOperations/PlaceMadeToOrder';
import * as Styles from './styles';

interface IFooterProps {
  serviceabilityData: INormalizedServiceabilityData;
  isFetchingServiceability: boolean;
}

const Footer = ({ serviceabilityData, isFetchingServiceability }: IFooterProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const { isExpanded, selectedVariant, expandActions } = useProductContext();

  const {
    hasInventoryInCurrentStoreOrWarehouse,
    hasInventoryInOtherStoreOrWarehouse,
    hasNoInventoryInAnyStoreOrWarehouse,
    inventoryInOtherStoreAndWarehouses,
  } = getInventoryStatus(selectedVariant);

  let Left;

  if (hasInventoryInOtherStoreOrWarehouse) {
    const firstFacilityWithInventory = inventoryInOtherStoreAndWarehouses[0];

    Left = () => (
      <Styles.Left>
        <BuyFromAnotherStore
          anotherStore={firstFacilityWithInventory}
          serviceabilityData={serviceabilityData}
          isFetchingServiceability={isFetchingServiceability}
        />
      </Styles.Left>
    );
  } else if (hasNoInventoryInAnyStoreOrWarehouse) {
    Left = () => (
      <Styles.Left>
        <PlaceMadeToOrder doesNotAddToCart />
      </Styles.Left>
    );
  }

  const shouldShowLeftPart =
    isExpanded &&
    // isBarcodish &&
    mode === 'BUY_NOW' &&
    !hasInventoryInCurrentStoreOrWarehouse;

  return (
    <Styles.Wrapper
      style={{
        justifyContent: shouldShowLeftPart ? 'space-between' : 'flex-end',
      }}
    >
      {shouldShowLeftPart && Left && <Left />}
      <Button
        sizeVariant="xs"
        variant="outline"
        rightIcon={isExpanded ? 'DoubleChevronUp' : 'DoubleChevronDown'}
        onClick={expandActions.toggle}
        colorScheme="dark"
        // colorScheme={mode === 'BUY_NOW' ? 'primary' : 'secondary'}
      >
        {isExpanded ? 'Less' : 'More'} Details
      </Button>
    </Styles.Wrapper>
  );
};

export default Footer;
