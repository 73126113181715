import toast from 'react-hot-toast';
import {
  addItemToCart,
  jitOrMtoOrMtcItemsSelector,
  setMode,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { Button } from '@point-of-sale/components';
import { CUSTOM_TAILORED } from '@point-of-sale/constants';
import { useBoolean } from '@point-of-sale/hooks';
import { CartItemDTOType, CartItemTatUpdateDTOType } from '@point-of-sale/schemas';
import useProductContext from '../context/useProductContext';

interface IPlaceMadeToOrderProps {
  /**
   * means that this button will
   * expand the Product View and Set the Mode to MTO
   * instead of adding the item to Cart
   */
  doesNotAddToCart?: boolean;
}

const PlaceMadeToOrder = ({ doesNotAddToCart = false }: IPlaceMadeToOrderProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();
  const selectedAddressId = useAppSelector(store => store.pointOfSale.selectedAddressId);

  const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);

  const facilities = useAppSelector(store => store.common.facilities);
  const addresses = useAppSelector(store => store.pointOfSale.addresses);

  const jitOrMtoOrMtcItems = useAppSelector(jitOrMtoOrMtcItemsSelector);
  const cartItemRecords = useAppSelector(store => store.pointOfSale.cart.data?.cartItems.records);

  const {
    quantity,
    selectedVariant,
    fulfillmentFacilityId,
    expandActions,
    data,
    serviceabilityData,
  } = useProductContext();

  const itemServiceability = serviceabilityData?.[selectedVariant?.skuCode ?? ''];

  const [isLoading, loadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  function addToCart() {
    if (!selectedVariant) {
      toast.error('Please select a variant');
      return;
    }

    if (fulfillmentFacilityId) {
      let filteredServiceability;
      let determinedCustomerDeliveryStoreId = selectedSalesChannel?.facilityId;
      let determinedDeliveryMode = 'STORE_PICKUP';

      // TODO: maybe Modularize this logic
      let firstNeighbor: CartItemDTOType | undefined;

      // * determine the "TO" of serviceability
      // * using the first neighbor of the same bucket
      if (jitOrMtoOrMtcItems.length > 0) {
        firstNeighbor = cartItemRecords[jitOrMtoOrMtcItems[0]];

        // * if the first neighbor is a store pickup
        if (
          firstNeighbor?.deliveryMode === 'STORE_PICKUP' &&
          firstNeighbor?.customerDeliveryStoreId
        ) {
          determinedDeliveryMode = 'STORE_PICKUP';

          const customerDeliveryStoreId = firstNeighbor.customerDeliveryStoreId;
          determinedCustomerDeliveryStoreId = customerDeliveryStoreId;

          filteredServiceability = itemServiceability?.filter(
            item => item.to === facilities.data.records[customerDeliveryStoreId]?.address?.pincode
          );
        }

        // * if the first neighbor is a home delivery
        if (
          firstNeighbor?.deliveryMode === 'HOME_DELIVERY' &&
          firstNeighbor?.customerDeliveryAddressId
        ) {
          determinedDeliveryMode = 'HOME_DELIVERY';

          const customerDeliveryAddressId = firstNeighbor.customerDeliveryAddressId;

          filteredServiceability = itemServiceability?.filter(
            item => item.to === addresses.data.records[customerDeliveryAddressId]?.pincode
          );
        }
      }

      let extras = {} as any;
      // * consider home delivery
      if (jitOrMtoOrMtcItems.length === 0 && isSelectedSalesChannelOnline) {
        filteredServiceability = itemServiceability?.filter(
          item => item.to === addresses.data.records[selectedAddressId]?.pincode
        );

        determinedDeliveryMode = 'HOME_DELIVERY';
        if (selectedAddressId !== -1) {
          extras.customerDeliveryAddressId = selectedAddressId;
        }
      }

      if (determinedDeliveryMode === 'HOME_DELIVERY') {
        extras.deliveryMode = 'HOME_DELIVERY';
        extras.customerDeliveryAddressId = selectedAddressId;
        delete extras.customerDeliveryStoreId;
      }

      if (determinedDeliveryMode === 'STORE_PICKUP') {
        extras.deliveryMode = 'STORE_PICKUP';
        extras.customerDeliveryStoreId = determinedCustomerDeliveryStoreId;
        delete extras.customerDeliveryAddressId;
      }

      loadingActions.on();

      // TODO: Confirm this from Sanjay
      const [firstServiceability] = filteredServiceability ?? itemServiceability;

      const courierTat = firstServiceability?.info?.standardCourierTat;
      const opsTat = firstServiceability?.info?.outerOpsTat ?? 0;
      const vendorTat =
        selectedVariant.size === CUSTOM_TAILORED.key
          ? firstServiceability?.info?.mtcTat
          : firstServiceability?.info?.mtoTat;

      const tatRelatedFields: Partial<CartItemTatUpdateDTOType> = {
        courierTat: courierTat,
        isExpress: false,
        isPriority: firstNeighbor?.isPriority ?? false,
        opsTat: opsTat,
        vendorTat: vendorTat,
      };

      if (selectedVariant.size === CUSTOM_TAILORED.key) {
        extras = {
          alterationEntry: {
            referenceColor: selectedVariant.color,
            referenceSize: selectedVariant.size,
            customizationCategory: data.customizationCategory,
          },
          ...extras,
        };
      }

      dispatch(
        addItemToCart(
          {
            skuId: selectedVariant.skuId,
            skuCode: selectedVariant.skuCode,
            // DEFAULT IN STORE PICKUP
            deliveryMode: firstNeighbor?.deliveryMode ?? 'STORE_PICKUP',
            fulfillmentMode: selectedVariant.size === CUSTOM_TAILORED.key ? 'MTC' : 'MTO',
            fulfillmentFacilityId: -1,
            quantity,
            // customerDeliveryStoreId: determinedCustomerDeliveryStoreId,
            // customerDeliveryAddressId: selectedAddressId,
            ...extras,
            ...tatRelatedFields,
          },
          loadingActions.off
        )
      );
    }
  }

  function expandAndSetModeToMTO() {
    expandActions.on();
    dispatch(setMode('MADE_TO_ORDER'));
  }

  if (!selectedVariant?.mtoAcceptedByBrand) {
    return null;
  }

  return (
    <Button
      sizeVariant={doesNotAddToCart ? 'xs' : 'medium'}
      variant="fill"
      colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
      onClick={doesNotAddToCart ? expandAndSetModeToMTO : addToCart}
      isDisabled={!selectedVariant?.mtoAcceptedByBrand || !selectedVariant}
      isLoading={isLoading}
    >
      {doesNotAddToCart ? 'Switch To MTO' : 'Place MTO'}
    </Button>
  );
};

export default PlaceMadeToOrder;
