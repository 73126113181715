import { AnimatePresence } from 'motion/react';
import { OmsFilterNames, useAppSelector } from '@point-of-sale/store';
import { IconButton, Text } from '@point-of-sale/components';
import { format, fromUnixTime } from 'date-fns';
import { removeUnderscoresAndCapitalize } from '@point-of-sale/utils';
import { LINE_ITEM_STATUS_FILTERS } from '../../../../hooks/useLineItemsDiscoveryFilters';
import * as Styles from './styles';

interface ISelectedFiltersProps {
  filtersToBeApplied: Record<string, any>;
  setFiltersToBeApplied: (filters: Record<string, any>) => void;
  onClick: (filtersToBeApplied: Record<string, any>) => void;
}

const SelectedFilters = ({
  filtersToBeApplied,
  onClick,
  setFiltersToBeApplied,
}: ISelectedFiltersProps) => {
  const storeStaff = useAppSelector(store => store.common.storeStaff.data);
  const salesChannels = useAppSelector(store => store.common.salesChannels.data.records);

  const erpUser = useAppSelector(store => store.identity.erpUser);

  function getDisplayableValue(filterName: OmsFilterNames, filterValue: any): string {
    switch (filterName) {
      case 'salesStaffId': {
        const staffId = Number(filterValue);
        if (staffId === erpUser?.id) {
          return erpUser?.name || 'Unknown Staff';
        }

        return storeStaff.records[staffId]?.name || 'Unknown Staff';
      }
      case 'deliveryMode':
      case 'fulfillmentMode': {
        return removeUnderscoresAndCapitalize(filterValue);
      }
      case 'createdOn': {
        const dateRange = filterValue as { from: number; to: number };
        if (!dateRange.from || !dateRange.to) return '';

        const fromDate = format(fromUnixTime(dateRange.from), 'dd MMM yyyy');
        const toDate = format(fromUnixTime(dateRange.to), 'dd MMM yyyy');
        return `${fromDate} - ${toDate}`;
      }
      case 'salesChannelId': {
        return (
          Object.values(salesChannels).find(s => String(s.id) === String(filterValue))?.name ??
          `Facility ${filterValue}`
        );
      }
      case 'status': {
        const filterOption = LINE_ITEM_STATUS_FILTERS.find(filter => filter.value === filterValue);

        return `${filterOption?.label}`;
      }

      default: {
        return String(filterValue);
      }
    }
  }

  if (Object.keys(filtersToBeApplied).length === 0) return null;

  return (
    <Styles.Wrapper>
      <AnimatePresence initial={false}>
        {Object.entries(filtersToBeApplied).map(([filterName, values]) => {
          if (filterName === 'createdOn') {
            const displayValue = getDisplayableValue(filterName, values);
            if (!displayValue) return null;

            return (
              <FilterChip
                key={`createdOn-${values.from}-${values.to}`}
                label={displayValue}
                onRemove={() => {
                  const newFilters = { ...filtersToBeApplied };
                  // delete newFilters[filterName];
                  newFilters[filterName] = { from: 0, to: 0 };
                  setFiltersToBeApplied(newFilters);
                  onClick(newFilters);
                }}
              />
            );
          }

          return (values as Array<{ value: any }>).map(value => (
            <FilterChip
              key={`${filterName}-${value.value}`}
              label={getDisplayableValue(filterName as OmsFilterNames, value.value)}
              onRemove={() => {
                const newFilters = structuredClone(filtersToBeApplied);
                newFilters[filterName] = (newFilters[filterName] as any[]).filter(
                  f => f.value !== value.value
                );
                if (newFilters[filterName].length === 0) {
                  newFilters[filterName] = [];
                }
                setFiltersToBeApplied(newFilters);

                onClick(newFilters);
              }}
            />
          ));
        })}
      </AnimatePresence>
    </Styles.Wrapper>
  );
};

// Extract FilterChip component for reusability and cleaner code
const FilterChip = ({ label, onRemove }: { label: string; onRemove: () => void }) => (
  <Styles.Filter
    layout="position"
    initial={{ scale: 0, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0, opacity: 0 }}
    transition={{
      type: 'spring',
      stiffness: 900,
      damping: 40,
      duration: 0.3,
    }}
  >
    <Text fontSize={10} color="var(--cod-gray)">
      {label}
    </Text>
    <IconButton
      icon="CrossIcon"
      onClick={onRemove}
      shapeVariant="circle"
      sizeVariant="extraSmall"
    />
  </Styles.Filter>
);

export default SelectedFilters;
