import { IconPropsType } from '../types';

const ArrowWestIcon = ({ fill = 'var(--cod-gray)', size = 24, ...props }: IconPropsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 25 25" {...props}>
    <path
      fill={fill}
      d="M24 12.001H2.914l5.294-5.295-.707-.707L1 12.501l6.5 6.5.707-.707-5.293-5.293H24z"
    />
  </svg>
);
export default ArrowWestIcon;
