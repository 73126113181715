import { Button, Modal, Text } from '@point-of-sale/components';
import {
  createNewCustomer,
  searchCustomer,
  setCustomerDetails,
  updateCustomer,
  upsertCustomerInOrder,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { CustomerDTOType, OrderDTOType } from '@point-of-sale/schemas';
import { EMAIL_REGEX, PHONE_REGEX, PLACEHOLDERS } from '@point-of-sale/constants';
import { useBoolean } from '@point-of-sale/hooks';
import KeyValuePair from '../KeyValuePair';
import Box from '../Box';
import CustomerDetails from '../../CustomerDetails';
import toast from 'react-hot-toast';
import * as Styles from './styles';
import { useState } from 'react';

interface IOrderCustomerDetailsProps {
  data: OrderDTOType;
}

const OrderCustomerDetails = ({ data }: IOrderCustomerDetailsProps) => {
  const addresses = useAppSelector(store => store.pointOfSale.addresses.data.records);

  const [isModalOpen, modalOpenActions] = useBoolean();

  const [isNamePromptActive, setIsNamePromptActive] = useState(false);
  const [foundCustomer, setFoundCustomer] = useState<CustomerDTOType | null>(null);

  const dispatch = useAppDispatch();

  const addressFromLineItems = data.lineItems?.find(
    lineItem => lineItem.customerDeliveryAddress
  )?.customerDeliveryAddress;

  let defaultAddress = Object.values(addresses).find(address => address.isDefault);

  if (!defaultAddress) {
    defaultAddress = Object.values(addresses)[0];
  }

  let addressToShow = defaultAddress;

  if (addressFromLineItems) {
    addressToShow = addressFromLineItems;
  }

  const isGuestOrder = !data.customerId;

  return (
    <Box
      header="Customer Details"
      rightHeader={
        isGuestOrder ? (
          <Button
            colorScheme="dark"
            variant="outline"
            sizeVariant="xs"
            onClick={modalOpenActions.on}
          >
            Add Customer
          </Button>
        ) : null
      }
    >
      {!isGuestOrder ? (
        <>
          <KeyValuePair title="Name" value={data.customerName ?? PLACEHOLDERS.NOT_AVAILABLE} />
          <KeyValuePair title="Phone" value={data.customerPhone ?? PLACEHOLDERS.NOT_AVAILABLE} />
          {data.customerEmail && (
            <KeyValuePair title="Email" value={data.customerEmail ?? PLACEHOLDERS.NOT_AVAILABLE} />
          )}
          {data.customerGSTIN && (
            <KeyValuePair title="GSTIN" value={data.customerGSTIN ?? PLACEHOLDERS.NOT_AVAILABLE} />
          )}
          {addressToShow && (
            <Text weight="semibold" fontSize={14}>
              <Text weight="regular" color="var(--dove-gray)">
                Address:{' '}
              </Text>
              {addressToShow.addressLine}, {addressToShow.cityDisplayName},{' '}
              {addressToShow.stateDisplayName}, {addressToShow.pincode}
            </Text>
          )}
        </>
      ) : (
        <Text fontSize={14} color="var(--cod-gray)">
          Guest
        </Text>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          modalOpenActions.off();
        }}
        heading={
          <Text fontSize={20} weight="bold">
            Customer Details
          </Text>
        }
      >
        <CustomerDetails
          shouldShowAddresses={false}
          actionables={formData => {
            return (
              <Styles.ButtonContainer>
                <Button
                  isFullWidth
                  onClick={() => {
                    function onCustomerFound(newCustomerData: CustomerDTOType) {
                      setFoundCustomer(newCustomerData);
                      toast.success('Customer found! Click "Assign" to add to order.', {
                        duration: 3000,
                      });
                    }

                    // function onCustomerFound(newCustomerData: CustomerDTOType) {
                    //   dispatch(
                    //     upsertCustomerInOrder({
                    //       customerId: newCustomerData.id,
                    //       orderId: data.salesChannelOrderId,
                    //     })
                    //   );
                    // }

                    function onNoCustomerFound() {
                      // setPendingCustomerData(formData);
                      setIsNamePromptActive(true);
                      toast.success(
                        'Customer not found. Please provide a name to create a new customer.'
                      );
                    }

                    // function onNoCustomerFound() {
                    //   dispatch(
                    //     createNewCustomer(formData, newCustomerData => {
                    //       dispatch(
                    //         upsertCustomerInOrder({
                    //           customerId: newCustomerData.id,
                    //           orderId: data.salesChannelOrderId,
                    //         })
                    //       );
                    //     })
                    //   );
                    // }

                    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
                      toast.error('Please Enter a valid email');
                      return;
                    }
                    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
                      toast.error('Please Enter a valid phone number');
                      return;
                    }
                    if (formData.name && formData.name.length < 3) {
                      toast.error('Please enter a real name');
                      return;
                    }

                    if (!data.customerId) {
                      if (foundCustomer) {
                        // If we have a found customer, assign them to the order
                        dispatch(
                          upsertCustomerInOrder({
                            customerId: foundCustomer.id,
                            orderId: data.salesChannelOrderId,
                          })
                        );
                        setFoundCustomer(null);
                        modalOpenActions.off();
                        return;
                      }

                      if (isNamePromptActive) {
                        if (!formData.name || formData.name.length < 3) {
                          toast.error('Please enter a valid name (minimum 3 characters)');
                          return;
                        }
                        dispatch(
                          createNewCustomer(formData, newCustomerData => {
                            dispatch(
                              upsertCustomerInOrder({
                                customerId: newCustomerData.id,
                                orderId: data.salesChannelOrderId,
                              })
                            );
                            // setPendingCustomerData(null);
                            setIsNamePromptActive(false);
                            modalOpenActions.off();
                          })
                        );
                        return;
                      }

                      if (formData.phone) {
                        dispatch(
                          searchCustomer({
                            searchParams: {
                              countryCode: formData.country ?? '+91',
                              phoneNumber: formData.phone,
                            },
                            callbacks: {
                              // onComplete: successCallback,
                              onExistingCustomer: onCustomerFound,
                              onNewCustomer: onNoCustomerFound,
                            },
                          })
                        );
                      } else if (formData.email) {
                        dispatch(
                          searchCustomer({
                            searchParams: {
                              email: formData.email,
                            },
                            callbacks: {
                              // onComplete: successCallback,
                              onExistingCustomer: onCustomerFound,
                              onNewCustomer: onNoCustomerFound,
                            },
                          })
                        );
                      }
                    } else if (data.customerId) {
                      if (!formData.name) {
                        toast.error('Please enter a name');
                        return;
                      }
                      // customerInfoModalUpdateCallback?.();
                      // dispatch(setCustomerInfoModalUpdateCallback(undefined));
                      dispatch(
                        updateCustomer(data.customerId, formData, () =>
                          // dispatch(setIsEditCustomerInfoModalOpen(false))
                          modalOpenActions.off()
                        )
                      );
                    } else {
                      // this should never ever happen
                      toast.error('Customer ID not found, Cannot Update');
                    }
                  }}
                  colorScheme="dark"
                >
                  {isGuestOrder
                    ? foundCustomer
                      ? 'Assign'
                      : isNamePromptActive
                      ? 'Create Customer'
                      : 'Search'
                    : 'Update'}
                </Button>
                {foundCustomer && (
                  <Button
                    isFullWidth
                    variant="outline"
                    colorScheme="dark"
                    onClick={() => {
                      setFoundCustomer(null);
                      dispatch(
                        setCustomerDetails({
                          data: {},
                          isLoading: false,
                          isSuccess: true,
                        })
                      );
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Styles.ButtonContainer>
            );
          }}
        />
      </Modal>
    </Box>
  );
};

export default OrderCustomerDetails;
