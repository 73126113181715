import { motion } from 'motion/react';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  padding: 8px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Divider = styled.div`
  height: 1px;
  background: var(--athens-gray);
`;
