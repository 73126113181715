import { HTMLProps } from 'react';
import styled from 'styled-components';
import { ThemeType } from '../themes/types';
import Text from '../Typography/Text';

const Wrapper = styled.div<{ $isFullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: ${props => (props.$isFullWidth ? '100%' : 'auto')};
`;

const StyledTextArea = styled.textarea<{ theme: ThemeType }>`
  resize: vertical;

  border: 1px solid var(--alto);
  flex: 1;

  outline: none;
  padding: 16px 8px;

  min-height: 100px;

  font-size: ${props => props.theme.typography.fonts.size[14]};
  font-weight: ${props => props.theme.typography.fonts.weight.regular};
`;

interface ITextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isFullWidth?: boolean;
}

const TextArea = ({ label, onChange, isFullWidth = false, ...props }: ITextAreaProps) => {
  return (
    <Wrapper $isFullWidth={isFullWidth}>
      <Text fontSize={20}>{label}</Text>
      <StyledTextArea onChange={onChange} {...props} />
    </Wrapper>
  );
};

export default TextArea;
