// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';
import { callApi } from '../services/callApi';
import { SkuMovementDTOType } from '@point-of-sale/schemas';

export const skuMovementSearchApi = ({
  page = 1,
  size = 10,
  filters = [],
  orderBy = 'createdOn',
}: {
  page: number;
  size: number;
  filters: Array<IFilter>;
  orderBy: string;
}) =>
  callApi('POST', `/pos/skumovement/search?page=${page}&size=${size}`, {
    filters,
    orderBy,
  });

export const createSkuMovementApi = (data: {
  barcode: string;
  movementType: SkuMovementDTOType['movementType'];
  fromFacilityId: number;
  toFacilityId: number;
}) => callApi('POST', `/pos/skumovement`, data);

export const deleteSkuMovementApi = (id: number) => callApi('DELETE', `/pos/skumovement/${id}`);
