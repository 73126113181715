import { motion } from 'motion/react';
import styled, { css } from 'styled-components';

export const Wrapper = styled(motion.nav)`
  position: absolute;
  inset: 0;
  z-index: 5;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 12px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const NavLink = styled(motion.button)<{
  $isActive: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  outline: none;
  border: none;
  background: var(--white);
  border-radius: 8px;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  padding: 12px;
  margin: 0;

  /* border: 1px solid red; */

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: var(--athens-gray);
    `}
`;
