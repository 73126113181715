import { type FirebaseApp, initializeApp } from 'firebase/app';
import { type Database, getDatabase } from 'firebase/database';
// import { ENV } from '@point-of-sale/env';
import { FIREBASE_APPLICATION_NAMES } from '../constants';
import { DatabaseOptionsType } from './types';

// const firebaseRunwayApp = initializeApp(
//   {
//     apiKey: ENV.VITE_FIREBASE_RUNWAY_API_KEY,
//     authDomain: ENV.VITE_FIREBASE_RUNWAY_AUTH_DOMAIN,
//     projectId: ENV.VITE_FIREBASE_RUNWAY_PROJECT_ID,
//     storageBucket: ENV.VITE_FIREBASE_RUNWAY_STORAGE_BUCKET,
//     messagingSenderId: ENV.VITE_FIREBASE_RUNWAY_MESSAGING_SENDER_ID,
//     appId: ENV.VITE_FIREBASE_RUNWAY_APP_ID,
//     measurementId: ENV.VITE_FIREBASE_RUNWAY_MEASUREMENT_ID,
//   },
//   {
//     name: FIREBASE_APPLICATION_NAMES.RUNWAY,
//   }
// );

let _app: FirebaseApp | null = null;
let _databaseUrl: string | null = null;
let _isInitialized = false;

export function initializeRealtimeDatabaseApp(options: DatabaseOptionsType) {
  if ('app' in options) {
    _app = options.app;
    _databaseUrl = options.databaseUrl;
  } else {
    if (_isInitialized) {
      return;
    }

    _app = initializeApp(options.config, {
      name: FIREBASE_APPLICATION_NAMES.RUNWAY,
    });
    _isInitialized = true;
    _databaseUrl = options.config.databaseURL;
  }
}

function ensureInitialized() {
  if (!_app || !_databaseUrl) {
    throw new Error('Firebase Database not initialized. Call initializeRealtimeDatabaseApp first.');
  }
}

export function getFirebaseRealtimeDatabase(): Database {
  ensureInitialized();
  return getDatabase(_app!, _databaseUrl!);
}
