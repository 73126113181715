import { useAppSelector } from '@point-of-sale/store';
import { Capsule, Image, Text } from '@point-of-sale/components';
import { getNameInitials, removeUnderscoresAndCapitalize } from '@point-of-sale/utils';
import * as Styles from './styles';

const AboutMe = () => {
  const erpUser = useAppSelector(store => store.identity.erpUser);

  return (
    <Styles.Wrapper>
      <Styles.Profile>
        <Styles.ProfileImage>
          {erpUser?.profileImageUrl ? (
            <Image height="100%" width="100%" src={erpUser?.profileImageUrl} />
          ) : (
            <Text fontSize={24} weight="semibold" textAlign="center">
              {getNameInitials(erpUser?.name)}
            </Text>
          )}
        </Styles.ProfileImage>
        <Styles.ProfileDetails>
          <Text fontSize={12} weight="semibold">
            {erpUser?.name}
          </Text>
          <Text fontSize={12}>{erpUser?.email}</Text>
          <Styles.Roles>
            {erpUser?.roles?.map(role => (
              <Capsule
                key={role.name}
                essence="light"
                complexion="neutral"
                size="small"
                content={removeUnderscoresAndCapitalize(role.name)}
              />
            ))}
          </Styles.Roles>
        </Styles.ProfileDetails>
      </Styles.Profile>
    </Styles.Wrapper>
  );
};

export default AboutMe;
