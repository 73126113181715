import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  padding: 12px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* background: var(--white); */
`;
