import { z } from 'zod';
import { APIResponseType, ApiResponseEnum, ApiResponseHandlerType } from '@point-of-sale/types';
import { ENV } from '@point-of-sale/env';
import toast from 'react-hot-toast';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SentryService } from '@point-of-sale/services';

export async function apiResponseHandler<T>(
  apiPromise: Promise<APIResponseType<T>>,
  schema?: z.ZodType<T>
): Promise<ApiResponseHandlerType<T>> {
  try {
    const response = await apiPromise;

    if (response?.success) {
      return {
        type: ApiResponseEnum.Success,
        meta: response.status,
        data:
          schema && ENV.DEV
            ? response.data
              ? schema.parse(response.data)
              : response.data
            : response.data,
      };
    }

    SentryService.catchError(new Error("API didn't return success"));

    return {
      type: ApiResponseEnum.Failure,
      meta: response?.status,
      source: 'response',
    };
  } catch (error) {
    if (ENV.DEV) {
      if (error instanceof z.ZodError) {
        SentryService.catchError(error);
        toast.error('API CONTRACT BREACH');
        console.group('API CONTRACT BREACH');
        console.trace(error.issues);
        console.groupEnd();
      } else {
        console.log('NETWORK ERROR');
        console.log(error);
      }
    }

    SentryService.catchError(error as Error);

    return {
      type: ApiResponseEnum.Failure,
      error: error as Error,
      source: 'network',
    };
  }
}

type CacheEntry<T> = {
  data: T;
  expiry: number;
};

const apiCache = new Map<string, CacheEntry<any>>();

// async function getCacheKey(url: string, payload: any): Promise<string> {
//   const encoder = new TextEncoder();
//   const data = encoder.encode(`${url}:${JSON.stringify(payload)}`);
//   const hashBuffer = await crypto.subtle.digest('SHA-256', data);
//   const hash = Array.from(new Uint8Array(hashBuffer))
//     .map(b => b.toString(16).padStart(2, '0'))
//     .join('');

//   return hash;
// }

function sortObjectKeys(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  }

  return Object.keys(obj)
    .sort()
    .reduce((result: Record<string, any>, key) => {
      result[key] = sortObjectKeys(obj[key]);
      return result;
    }, {});
}

async function getCacheKey(url: string, payload: any): Promise<string> {
  // Normalize the URL by trimming and converting to lowercase
  const normalizedUrl = url.trim().toLowerCase();

  // Sort object keys to ensure consistent ordering
  const normalizedPayload = payload ? JSON.stringify(sortObjectKeys(payload)) : '';

  const encoder = new TextEncoder();
  const data = encoder.encode(`${normalizedUrl}:${normalizedPayload}`);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hash = Array.from(new Uint8Array(hashBuffer))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');

  return hash;
}

export async function memoizedApiResponseHandler<T>({
  apiPromiseFactory,
  url,
  payload,
  schema,
  cacheDurationMs = 60000,
}: {
  apiPromiseFactory: () => Promise<APIResponseType<T>>;
  url: string;
  payload: any;
  schema?: z.ZodType<T>;
  cacheDurationMs?: number;
}): Promise<ApiResponseHandlerType<T>> {
  const cacheKey = await getCacheKey(url, payload);

  const now = Date.now();

  if (apiCache.has(cacheKey)) {
    const cached = apiCache.get(cacheKey) ?? { data: null, expiry: 0 };
    if (now < cached.expiry) {
      return {
        type: ApiResponseEnum.Success,
        meta: {
          code: 200,
          message: 'Cached response',
          count: 0,
          key: cacheKey,
          type: 'cache',
        },
        data: cached.data,
      };
    }

    apiCache.delete(cacheKey);
  }

  try {
    const response = await apiPromiseFactory();

    if (response?.success) {
      const parsedData = schema ? schema.parse(response.data) : response.data;
      apiCache.set(cacheKey, { data: parsedData, expiry: now + cacheDurationMs });
      return { type: ApiResponseEnum.Success, meta: response.status, data: parsedData };
    }

    SentryService.catchError(new Error("API didn't return success"));
    return { type: ApiResponseEnum.Failure, meta: response.status, source: 'response' };
  } catch (error) {
    SentryService.catchError(error as Error);
    return { type: ApiResponseEnum.Failure, error: error as Error, source: 'network' };
  }
}
