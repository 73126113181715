import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--white);

  height: calc(100dvh - 52px);
`;
