import toast from 'react-hot-toast';
import { z } from 'zod';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, updateArrayByKey } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import {
  BulkCancelOrderItemDTOType,
  BulkReturnOrderItemDTOType,
  LineItemDTOSchema,
  LineItemDTOType,
  LineItemsDTOSchema,
  LineItemsDTOType,
  OrderDTOSchema,
  OrderDTOType,
  OrdersDTOSchema,
  OrdersDTOType,
} from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';
import { normalizeArray } from '../utils/normalizeArray';
import { searchCustomer } from '../pointOfSale/thunks/customerThunks';
import { ThunkActionType } from '../store';
import {
  setOrders,
  setOrdersPagination,
  setOrderDetails,
  setLineItems,
  setLineItemsPagination,
} from './actions';
import {
  addRefundReceiptToOrderApi,
  bulkCancelOrderItemsApi,
  bulkReturnOrderItemsApi,
  getOrdersApi,
  getOrdersDetailsByIdApi,
  lineItemsSearchApi,
  upsertCustomerInOrderApi,
  upsertSalesStaffInOrderApi,
} from './api';
import { setCustomerDetails } from '../pointOfSale/actions';
import { findBulkErpUsersIfNotPresentAlready } from '../common/thunks';
import { isBarcodish } from '../pointOfSale/utils';

export const getOrders = (): ThunkActionType => async (dispatch, getState) => {
  const { page, size, shouldFetchMore } = getState().oms.pagination;
  const search = getState().oms.search;

  if (!shouldFetchMore) {
    return;
  }

  dispatch(
    setOrders({
      isLoading: true,
    })
  );

  const filters = getState().oms.filters;
  const createdOn = getState().oms.createdOn;

  const filterBuilder = new FilterBuilder();

  const isErpUserSuperAdmin = getState().identity.erpUser?.roles.some(
    role => role.name === 'SUPER_ADMIN'
  );

  if (!isErpUserSuperAdmin) {
    filterBuilder.addFilter({
      field: 'lineItems.isTestOrder',
      filterType: 'EQ',
      value: 'false',
    });
  }

  if (createdOn.from) {
    filterBuilder.addFilter({
      field: 'createdOn',
      filterType: 'GTE',
      value: createdOn.from,
    });
  }

  if (createdOn.to) {
    filterBuilder.addFilter({
      field: 'createdOn',
      filterType: 'LTE',
      value: createdOn.to,
    });
  }

  if (search.query.length > 0) {
    if (isBarcodish(search.query)) {
      filterBuilder.addFilter({
        field: 'lineItems.skuBarcode',
        filterType: 'EQ',
        value: search.query,
      });
    } else {
      if (!Number.isNaN(Number(search.query))) {
        if (search.query.length === 10) {
          filterBuilder.addFilter({
            field: 'customerPhone',
            filterType: 'ICONTAINS',
            value: search.query,
          });
        } else if (search.query.length > 10) {
          filterBuilder.addFilter({
            field: 'salesChannelOrderId',
            filterType: 'ICONTAINS',
            value: search.query,
          });
        } else {
          filterBuilder.addFilter({
            field: 'customerName',
            filterType: 'ICONTAINS',
            value: search.query,
          });
        }
      } else {
        filterBuilder.addFilter({
          field: 'customerName',
          filterType: 'ICONTAINS',
          value: search.query,
        });
      }
    }
  }

  // TODO: Remove
  // filterBuilder.addFilter({
  //   field: 'lineItems.status',
  //   filterType: 'EQ',
  //   value: 'RETURN_WIP', // RETURNED, RETURN_WIP
  // });

  const newPage = page + 1;

  const payload = {
    page: newPage,
    size: size,
    filters: [...filterBuilder.build(), ...Object.values(filters)],
  };

  const promise = getOrdersApi(payload);

  const response = await apiResponseHandler<OrdersDTOType>(promise, OrdersDTOSchema);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setOrdersPagination({
        shouldFetchMore: false,
      })
    );
    dispatch(
      setOrders({
        isLoading: false,
        isError: true,
      })
    );
    return;
  }

  const shouldAppend = newPage > 1;

  let { ids, records } = normalizeArray(response.data);

  if (shouldAppend) {
    ids = [...getState().oms.orders.data.ids, ...ids];
    records = { ...getState().oms.orders.data.records, ...records };
  }

  dispatch(
    setOrders({
      data: {
        ids: Array.from(new Set(ids)),
        records,
      },
      isLoading: false,
      isSuccess: true,
    })
  );

  dispatch(
    setOrdersPagination({
      page: newPage,
      shouldFetchMore: response.data.length !== 0,
    })
  );

  dispatch(
    findBulkErpUsersIfNotPresentAlready({
      userIds: Object.values(records)
        .map(o => o.salesStaffId)
        .filter((id): id is number => id !== undefined && id !== null),
      operation: 'APPEND',
    })
  );
};

export const getOrderDetailsById =
  (id: string): ThunkActionType =>
  async dispatch => {
    dispatch(
      setOrderDetails({
        isLoading: true,
      })
    );

    const promise = getOrdersDetailsByIdApi(id);

    const response = await apiResponseHandler<OrderDTOType>(promise, OrderDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setOrderDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCustomerDetails({
        data: {},
        isLoading: false,
        isSuccess: true,
      })
    );

    if (response.data.customerId) {
      dispatch(
        searchCustomer({
          searchParams: { customerId: String(response.data.customerId) },
          callbacks: {
            onComplete: () => {
              //
            },
          },
        })
      );
    }

    dispatch(
      setOrderDetails({
        data: response.data,
        isLoading: false,
        isSuccess: true,
      })
    );

    if (response.data.salesStaffId) {
      dispatch(
        findBulkErpUsersIfNotPresentAlready({
          userIds: [response.data.salesStaffId],
          operation: 'REPLACE',
        })
      );
    }
  };

export const bulkCancelOrderItems =
  (
    data: BulkCancelOrderItemDTOType,
    setPdfData: (pdfData: Array<LineItemDTOType>) => void
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = bulkCancelOrderItemsApi(data);

    const response = await apiResponseHandler<Array<LineItemDTOType>>(
      promise,
      z.array(LineItemDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      return;
      // return response;
    }

    const orderDetails = getState().oms.details.data;

    dispatch(
      setOrderDetails({
        data: {
          ...orderDetails,
          lineItems: updateArrayByKey(orderDetails.lineItems, response.data, 'id'),
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    setPdfData(response.data);
  };

export const bulkReturnOrderItems =
  (
    data: BulkReturnOrderItemDTOType,
    setPdfData: (pdfData: Array<LineItemDTOType>) => void
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = bulkReturnOrderItemsApi(data);

    const response = await apiResponseHandler<Array<LineItemDTOType>>(
      promise,
      z.array(LineItemDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      return;
    }

    const orderDetails = getState().oms.details.data;

    dispatch(
      setOrderDetails({
        data: {
          ...orderDetails,
          lineItems: updateArrayByKey(orderDetails.lineItems, response.data, 'id'),
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    setPdfData(response.data);
  };

export const addRefundReceiptToOrder =
  (
    refundId: number,
    asset: {
      id: number;
      url: string;
    },
    callback: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const promise = addRefundReceiptToOrderApi({
      refundId,
      asset,
    });

    const response = await apiResponseHandler(promise);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      return;
    }

    // dispatch(setProcessedOrder({ data: response.data }));

    callback();
  };

export const upsertSalesStaffInOrder =
  (orderId: number, salesStaffId: number): ThunkActionType =>
  async dispatch => {
    dispatch(
      setOrderDetails({
        isLoading: true,
      })
    );

    const promise = upsertSalesStaffInOrderApi({
      orderId,
      salesStaffId,
    });

    const response = await apiResponseHandler<OrderDTOType>(promise, OrderDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setOrderDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    // if (response.data.customerId) {
    //   dispatch(
    //     searchCustomer({ customerId: String(response.data.customerId) }, () => {
    //       //
    //     })
    //   );
    // }

    dispatch(
      setOrderDetails({
        data: response.data,
        isLoading: false,
        isSuccess: true,
      })
    );
  };

export const getLineItems = (): ThunkActionType => async (dispatch, getState) => {
  const { page, size, shouldFetchMore } = getState().oms.lineItemsPagination;

  if (!shouldFetchMore) {
    return;
  }

  dispatch(
    setLineItems({
      isLoading: true,
    })
  );

  const filters = getState().oms.filters;
  const search = getState().oms.search;

  const createdOn = getState().oms.createdOn;

  const filterBuilder = new FilterBuilder();

  if (createdOn.from) {
    filterBuilder.addFilter({
      field: 'createdOn',
      filterType: 'GTE',
      value: createdOn.from.toString(),
    });
  }

  if (createdOn.to) {
    filterBuilder.addFilter({
      field: 'createdOn',
      filterType: 'LTE',
      value: createdOn.to.toString(),
    });
  }

  if (search.query.length > 0) {
    if (isBarcodish(search.query)) {
      filterBuilder.addFilter({
        field: 'skuBarcode',
        filterType: 'EQ',
        value: search.query,
      });
    } else {
      if (!Number.isNaN(Number(search.query))) {
        if (search.query.length === 10) {
          filterBuilder.addFilter({
            field: 'customerPhone',
            filterType: 'ICONTAINS',
            value: search.query,
          });
        } else if (search.query.length > 10) {
          filterBuilder.addFilter({
            field: 'salesChannelOrderId',
            filterType: 'ICONTAINS',
            value: search.query,
          });
        } else {
          filterBuilder.addFilter({
            field: 'customerName',
            filterType: 'ICONTAINS',
            value: search.query,
          });
        }
      } else {
        filterBuilder.addFilter({
          field: 'customerName',
          filterType: 'ICONTAINS',
          value: search.query,
        });
      }
    }
  }
  const newPage = page + 1;

  const payload = {
    page: newPage,
    size: size,
    filters: [...filterBuilder.build(), ...Object.values(filters)],
    enrichOrder: false,
  };

  const promise = lineItemsSearchApi(payload);

  const response = await apiResponseHandler<LineItemsDTOType>(promise, LineItemsDTOSchema);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setLineItemsPagination({
        shouldFetchMore: false,
      })
    );
    dispatch(
      setLineItems({
        isLoading: false,
        isError: true,
      })
    );
    return;
  }

  const shouldAppend = newPage > 1;

  let { ids, records } = normalizeArray(response.data);

  if (shouldAppend) {
    ids = [...getState().oms.lineItems.data.ids, ...ids];
    records = { ...getState().oms.lineItems.data.records, ...records };
  }

  dispatch(
    setLineItems({
      data: {
        ids: Array.from(new Set(ids)),
        records,
      },
      isLoading: false,
      isSuccess: true,
    })
  );

  dispatch(
    setLineItemsPagination({
      page: newPage,
      shouldFetchMore: response.data.length !== 0,
    })
  );

  dispatch(
    findBulkErpUsersIfNotPresentAlready({
      userIds: Object.values(records)
        .map(o => o.salesStaffId)
        .filter((id): id is number => id !== undefined && id !== null),
      operation: 'APPEND',
    })
  );
};

export const upsertCustomerInOrder =
  ({
    customerId,
    orderId,
    successCallback,
    failureCallback,
  }: {
    orderId: string;
    customerId: number;
    successCallback?: CallableFunction;
    failureCallback?: CallableFunction;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setOrderDetails({
        isLoading: true,
      })
    );
    const promise = upsertCustomerInOrderApi({
      orderId,
      customerId,
    });
    const response = await apiResponseHandler<OrderDTOType>(promise, OrderDTOSchema);
    if (response.type === ApiResponseEnum.Failure) {
      // toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setOrderDetails({
          isLoading: false,
          isError: true,
        })
      );
      failureCallback?.();
      return;
    }

    dispatch(
      setOrderDetails({
        data: response.data,
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.();
  };
