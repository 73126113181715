import { useEffect } from 'react';
import { Select } from '@point-of-sale/components';
import {
  getSalesChannels,
  setSelectedSalesChannel,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';

const SelectSalesChannel = () => {
  const selectedSalesChannelId = useAppSelector(
    store => store.identity.selectedSalesChannel?.id ?? null
  );

  const {
    data: { ids: salesChannelsIds, records: salesChannelsRecords },
    isLoading,
  } = useAppSelector(state => state.common.salesChannels);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSalesChannels());
  }, []);

  const options = salesChannelsIds.map(id => ({
    label: salesChannelsRecords[id].name,
    value: id,
  }));

  return (
    <Select
      isLoading={isLoading}
      label="Select Sales Channel"
      options={options}
      placeholder="Select Sales Channel"
      value={options.find(option => String(option.value) === String(selectedSalesChannelId))}
      onChange={selectedOption => {
        if (!selectedOption) {
          return;
        }

        const newSalesChannel = Object.values(salesChannelsRecords).find(
          item => String(item.id) === String(selectedOption.value)
        );

        if (newSalesChannel) {
          dispatch(setSelectedSalesChannel(newSalesChannel));
        }
      }}
    />
  );
};

export default SelectSalesChannel;
