import { useCallback } from 'react';
import { IOption } from '@point-of-sale/components';
import {
  deletePosFilter,
  PosFilterNames,
  searchProducts,
  SearchProductsUsage,
  setPosPagination,
  setProducts,
  setSearch,
  updatePosFilters,
  useAppDispatch,
} from '@point-of-sale/store';
import { IDiscountOption } from '../components/desktop/ProductDiscovery/helpers';

interface IUseProductDiscoveryProps {
  usage: SearchProductsUsage;
}

export const useProductDiscovery = ({ usage }: IUseProductDiscoveryProps) => {
  const dispatch = useAppDispatch();

  const onChangeSearch = useCallback(
    (searchQuery: string) => {
      dispatch(
        setPosPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );
      dispatch(
        setProducts({
          data: {
            ids: [],
            records: {},
          },
        })
      );

      dispatch(
        setSearch({
          query: searchQuery,
        })
      );
      dispatch(searchProducts(usage));
    },
    [dispatch, usage]
  );

  const onChangeFilter = useCallback(
    (field: PosFilterNames, options: Array<IOption | IDiscountOption>) => {
      dispatch(
        setPosPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );

      // ! Atrocious implementation of discount filters
      // * Different handling for discount filters
      if (
        field === 'discounts' ||
        field === 'variants.discountGroupId' ||
        field === 'variants.totalDiscount'
      ) {
        const discountOptions = options as Array<IDiscountOption>;

        // TODO: Refactor
        // nested ternary to handle removing filters
        const discountGroupIdFilters =
          field === 'discounts'
            ? discountOptions
                .filter(option => option.filter === 'variants.discountGroupId')
                .map(option => option.value)
                .join(',')
            : field === 'variants.discountGroupId'
            ? discountOptions.map(option => option.value).join(',')
            : '';
        // TODO: Refactor
        // nested ternary to handle removing filters
        const totalDiscountFilters =
          field === 'discounts'
            ? discountOptions
                .filter(option => option.filter === 'variants.totalDiscount')
                .map(option => option.value)
                .join(',')
            : field === 'variants.totalDiscount'
            ? discountOptions.map(option => option.value).join(',')
            : '';

        if (field === 'discounts' || field === 'variants.discountGroupId') {
          dispatch(
            updatePosFilters({
              filter: {
                field: 'variants.discountGroupId',
                value: discountGroupIdFilters,
                filterType: 'EQ',
              },
              name: 'variants.discountGroupId',
            })
          );
        }
        if (field === 'discounts' || field === 'variants.totalDiscount') {
          dispatch(
            updatePosFilters({
              filter: {
                field: 'variants.totalDiscount',
                value: totalDiscountFilters,
                filterType: 'EQ',
              },
              name: 'variants.totalDiscount',
            })
          );
        }

        return;
      }

      dispatch(
        updatePosFilters({
          filter: {
            field,
            value: options.map(option => option.value).join(','),
            filterType: 'IN',
          },
          name: field,
        })
      );
    },
    [dispatch]
  );

  const onRemoveFilter = useCallback(
    (filterName: PosFilterNames) => {
      dispatch(deletePosFilter(filterName));
    },
    [dispatch]
  );

  return { onChangeSearch, onChangeFilter, onRemoveFilter };
};
