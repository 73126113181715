import { z } from 'zod';

export const NotificationTokenDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  userId: z.number(),
  deviceId: z.string(),
  app: z.string(),
  platform: z.string(),
  token: z.string(),
});

export type NotificationDTOType = z.infer<typeof NotificationTokenDTOSchema>;

export const NotificationTokensDTOSchema = z.array(NotificationTokenDTOSchema);

export type NotificationTokensDTOType = z.infer<typeof NotificationTokensDTOSchema>;
