import { combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
  PersistConfig,
} from 'redux-persist';

import type { Action } from 'redux';
import storage from 'redux-persist/lib/storage';
import { ENV } from '@point-of-sale/env';
import { identityReducer } from './identity/reducer';
import { pointOfSaleReducer } from './pointOfSale/reducer';
import { checkoutReducer } from './checkout/reducer';
import { commonReducer } from './common/reducer';
import { openCartsReducer } from './openCarts/reducer';
import { pendingTasksReducer } from './pendingTasks/reducer';
import { orderManagementSystemReducer } from './orderManagementSystem/reducer';
import { notificationsReducer } from './notifications/reducer';

import { permissionReducer } from './permissions/slice';
import { userInterfaceReducer } from './userInterface/slice';
import { skuMovementReducer } from './skuMovement/reducer';

// TODO: see if this is being used or not
import { serviceabilityReducer } from './serviceability/reducer';
import { firebaseReducer } from './firebase/reducer';

// const middleware = [thunk];

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage: storage,
  whitelist: ['identity'],
};

const notificationsPersistConfig: PersistConfig<any> = {
  key: 'notifications',
  storage: storage,
  whitelist: ['fcmToken'],
};

// const staticReducers = {};

// const desktopOnlyReducers = {};

// const mobileOnlyReducers = {};

const rootReducer = combineReducers({
  identity: identityReducer,
  pointOfSale: pointOfSaleReducer,
  checkout: checkoutReducer,
  common: commonReducer,
  oms: orderManagementSystemReducer,
  permissions: permissionReducer,
  openCarts: openCartsReducer,
  pendingTasks: pendingTasksReducer,
  userInterface: userInterfaceReducer,
  skuMovement: skuMovementReducer,
  serviceability: serviceabilityReducer,
  notifications: persistReducer(notificationsPersistConfig, notificationsReducer),
  firebase: firebaseReducer,
});

// const createRootReducer = (
//   asyncReducers: typeof desktopOnlyReducers | typeof mobileOnlyReducers = desktopOnlyReducers
// ) =>
//   combineReducers({
//     ...staticReducers,
//     ...asyncReducers,
//   });

// <any, any> because of an open issue https://github.com/rt2zz/redux-persist/issues/1140
const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);
// const persistedReducer = persistReducer<any, any>(persistConfig, createRootReducer());

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: ENV.VITE_ENVIRONMENT === 'local' || ENV.VITE_ENVIRONMENT === 'staging',
});

export const persistor = persistStore(store);

export type RootStateType = ReturnType<typeof rootReducer>;
// export type RootStateType = ReturnType<typeof createRootReducer>;

// export const injectReducer = (reducers: Record<string, any>) => {
//   store.replaceReducer(persistReducer<any, any>(persistConfig, createRootReducer(reducers)));
// };

// if (isMobile) {
//   injectReducer(mobileOnlyReducers);
// }

export type AppDispatch = typeof store.dispatch;

export type ThunkActionType<ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootStateType,
  unknown,
  Action
>;
