import toast from 'react-hot-toast';
import { Button, Image, Modal, Text } from '@point-of-sale/components';
import {
  addItemToCart,
  onlineFacilitiesSelector,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import { formatNumberToLocale, updateAttributesOfCdnImage } from '@point-of-sale/utils';
import useProductContext from '../../context/useProductContext';
import { CURRENCY_SYMBOLS, CurrencyType } from '@point-of-sale/constants';
import * as Styles from './styles';

const BuyWithoutInventory = () => {
  const [isModalOpen, modalOpenActions] = useBoolean();
  const [isLoading, loadingActions] = useBoolean();

  const buyingWithoutInventoryAllowedForBarcode = useAppSelector(
    store => store.pointOfSale.buyingWithoutInventoryAllowedForBarcode
  );

  const selectedSalesChannel = useAppSelector(store => store.identity.selectedSalesChannel);

  const allFacilities = useAppSelector(store => store.common.facilities.data.records);
  const onlineFacilities = useAppSelector(onlineFacilitiesSelector);

  const { data, quantity, selectedVariant, mannerOfPurchase, isExpanded } = useProductContext();

  const dispatch = useAppDispatch();

  function addToCart() {
    if (!selectedVariant) {
      toast.error('Please select a variant');
      return;
    }

    if (selectedSalesChannel && selectedSalesChannel.facilityId) {
      const fulfillmentFacility = allFacilities[selectedSalesChannel.facilityId];

      loadingActions.on();
      dispatch(
        addItemToCart(
          {
            fulfillmentFacilityId: selectedSalesChannel.facilityId,
            skuId: selectedVariant.skuId,
            skuCode: selectedVariant.skuCode,
            deliveryMode: 'STORE_PURCHASE',
            fulfillmentMode: 'ONHAND',
            fulfillmentFacilityGroupId: fulfillmentFacility.facilityGroupId,
            fulfillmentFacilityType: fulfillmentFacility.facilityType,
            quantity,
            ...(mannerOfPurchase.customerDeliveryStoreId && {
              customerDeliveryStoreId: mannerOfPurchase.customerDeliveryStoreId,
            }),
            addedWithoutInventory: true,
          },
          () => {
            loadingActions.off();
            modalOpenActions.off();
          }
        )
      );
    }
  }

  function getButtonText() {
    if (selectedVariant) {
      return 'Buy With No Inventory';
    }

    return 'Add To Bag';
  }

  if (onlineFacilities?.data.ids.includes(selectedSalesChannel?.facilityId ?? -1)) {
    return null;
  }

  if (buyingWithoutInventoryAllowedForBarcode !== selectedVariant?.barcode) {
    return null;
  }

  return (
    <>
      <Button
        sizeVariant={isExpanded ? 'medium' : 'xs'}
        variant="fill"
        onClick={modalOpenActions.on}
        isDisabled={!selectedVariant}
      >
        {getButtonText()}
      </Button>

      <Modal
        size="small"
        isOpen={isModalOpen}
        onClose={() => {
          loadingActions.off();
          modalOpenActions.off();
        }}
        heading={
          <Text textAlign="center" fontSize={24}>
            Are you sure you want to continue billing?
          </Text>
        }
        footer={
          <Styles.ConfirmationModalFooter>
            <Button onClick={addToCart} sizeVariant="large" isLoading={isLoading}>
              Continue Billing
            </Button>
          </Styles.ConfirmationModalFooter>
        }
      >
        <Styles.ConfirmationModalContent>
          <Styles.ProductInfoWrapper>
            <Styles.ImageWrapper>
              <Image
                height={165}
                width={110}
                src={updateAttributesOfCdnImage(data.images?.[0].url, {
                  width: 360,
                  quality: 100,
                })}
                alt="Product"
              />
            </Styles.ImageWrapper>
            <Styles.ProductInfoTextContainer>
              <Styles.ProductInfoTextContainerTop>
                <Text fontSize={12} color="var(--dove-gray)">
                  {data.brand.name}
                </Text>
                <Text fontSize={24} weight="bold">
                  {data.name}
                </Text>
              </Styles.ProductInfoTextContainerTop>
              <Styles.ProductInfoTextContainerTop>
                <Text fontSize={14}>Size: {selectedVariant?.size}</Text>
                <Text fontSize={14}>Color: {selectedVariant?.color}</Text>
              </Styles.ProductInfoTextContainerTop>
              <Styles.ProductInfoTextContainerBottom>
                <Text fontSize={24} color="var(--persian-red)">
                  Not available in store
                </Text>
                <Text fontSize={24} weight="bold">
                  {selectedVariant?.currency &&
                    CURRENCY_SYMBOLS[(selectedVariant.currency as CurrencyType) ?? 'INR']?.code}
                  &nbsp;
                  {formatNumberToLocale(selectedVariant?.sellingPrice ?? 0)}
                </Text>
              </Styles.ProductInfoTextContainerBottom>
            </Styles.ProductInfoTextContainer>
          </Styles.ProductInfoWrapper>
        </Styles.ConfirmationModalContent>
      </Modal>
    </>
  );
};

export default BuyWithoutInventory;
