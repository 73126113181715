import { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { BrowserRouter, Route, Routes } from 'react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import {
  DesignSystemProvider,
  ErrorFallback,
  MainErrorBoundary,
  RouteProtector,
  posThemeTokens,
} from '@point-of-sale/components';
import { persistor, store } from '@point-of-sale/store';
import { useIsOnline } from '@point-of-sale/hooks';
import { SentryService } from '@point-of-sale/services';
import Login from '../pages/shared/Login';

import MobileRoutes from '../routes/Routes.mobile';
import DesktopRoutes from '../routes/Routes.desktop';

function App() {
  useIsOnline();

  useEffect(() => {
    SentryService.init();
  }, []);

  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DesignSystemProvider themeTokens={posThemeTokens}>
            <Toaster
              position="top-center"
              toastOptions={{
                style: {
                  wordBreak: 'break-all',
                },
              }}
            />
            <MainErrorBoundary FallbackComponent={ErrorFallback}>
              <Routes>
                <Route
                  path="*"
                  element={
                    <RouteProtector>
                      {isMobileOnly ? <MobileRoutes /> : <DesktopRoutes />}
                    </RouteProtector>
                  }
                />
                <Route path="/login" element={<Login />} />
              </Routes>
            </MainErrorBoundary>
          </DesignSystemProvider>
        </PersistGate>
      </ReduxProvider>
    </BrowserRouter>
  );
}

export default App;
