import React from 'react';
import styled from 'styled-components';
import { IDateRange } from '@point-of-sale/types';
import { format, getUnixTime, startOfDay, endOfDay, fromUnixTime } from 'date-fns';
import Text from '../Typography/Text';

const DateRangeWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DateInput = styled.input`
  padding: 12px;
  border: 1px solid var(--athens-gray);
  border-radius: 1px;
  font-size: 16px;
  color: var(--cod-gray);
  background: var(--white);
`;

const DateLabel = styled(Text)`
  color: var(--cod-gray);
  font-size: 14px;
`;

const InputLabel = styled(Text)`
  color: var(--cod-gray);
  font-size: 12px;
`;

interface INativeDateRangePickerProps {
  label?: string;
  value: IDateRange;
  onChange: (dates: IDateRange) => void;
  disabled?: boolean;
}

const NativeDateRangePicker = ({
  label,
  value,
  onChange,
  disabled = false,
}: INativeDateRangePickerProps) => {
  const today = format(new Date(), 'yyyy-MM-dd');

  const formatDateForInput = (timestamp: number | null) => {
    if (!timestamp) return '';
    try {
      return format(fromUnixTime(timestamp), 'yyyy-MM-dd');
    } catch {
      return '';
    }
  };

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    if (!date) {
      onChange({ from: 0, to: value.to });
      return;
    }

    const timestamp = getUnixTime(startOfDay(new Date(date)));

    if (value.to && timestamp > value.to) {
      // If selected 'from' date is greater than 'to' date, update both
      const endTimestamp = getUnixTime(endOfDay(new Date(date)));
      onChange({
        from: timestamp,
        to: endTimestamp,
      });
    } else {
      onChange({
        ...value,
        from: timestamp,
      });
    }
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    if (!date) {
      onChange({ from: value.from, to: 0 });
      return;
    }

    const timestamp = getUnixTime(endOfDay(new Date(date)));

    if (value.from && getUnixTime(startOfDay(new Date(date))) < value.from) {
      // If selected 'to' date is smaller than 'from' date, update both
      const startTimestamp = getUnixTime(startOfDay(new Date(date)));
      onChange({
        from: startTimestamp,
        to: timestamp,
      });
    } else {
      onChange({
        ...value,
        to: timestamp,
      });
    }
  };

  return (
    <DateRangeWrapper>
      {label && <DateLabel>{label}</DateLabel>}
      <DateInputContainer>
        <InputLabel>From</InputLabel>
        <DateInput
          type="date"
          value={formatDateForInput(value.from)}
          onChange={handleFromDateChange}
          max={formatDateForInput(value.to) || today}
          disabled={disabled}
          data-testid="from-date-input"
        />
      </DateInputContainer>
      <DateInputContainer>
        <InputLabel>To</InputLabel>
        <DateInput
          type="date"
          value={formatDateForInput(value.to)}
          onChange={handleToDateChange}
          min={formatDateForInput(value.from)}
          max={today}
          disabled={disabled}
          data-testid="to-date-input"
        />
      </DateInputContainer>
    </DateRangeWrapper>
  );
};

export default NativeDateRangePicker;
