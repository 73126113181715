import React, { useEffect } from 'react';
import { Sheet } from 'react-modal-sheet';
import { Text } from '@point-of-sale/components';
import { ProductDTOType } from '@point-of-sale/schemas';
import { SizeChartTable } from '../../../../shared/SizeChartTable';
import useSizeChart from '../../../../../hooks/useSizeChartData';
import * as Styles from './styles';

interface ISizeChartBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  product: ProductDTOType;
}

const SizeChartBottomSheet = ({ isOpen, onClose, product }: ISizeChartBottomSheetProps) => {
  const { sizeChartData, isLoading, error, fetchSizeChart } = useSizeChart(product.id);

  // Fetch data when sheet opens
  useEffect(() => {
    if (isOpen) {
      fetchSizeChart();
    }
  }, [isOpen]);

  return (
    <Sheet isOpen={isOpen} onClose={onClose} snapPoints={[600]}>
      <Sheet.Container>
        <Sheet.Header>
          <Styles.Header>
            <Text weight="semibold" fontSize={14} color="var(--cod-gray)" textAlign="left">
              Size Chart for {product.brand.name} {product.name}
            </Text>
          </Styles.Header>
        </Sheet.Header>
        <Sheet.Content>
          <Styles.ContentWrapper>
            <SizeChartTable data={sizeChartData} isLoading={isLoading} error={error} />
            {/* {sizeChartData?.sizeChartImageAssetUrl && (
              <Styles.ImageContainer>
                <Styles.ChartImage
                  src={sizeChartData.sizeChartImageAssetUrl}
                  alt="Size Chart"
                  loading="lazy"
                />
              </Styles.ImageContainer>
            )} */}
          </Styles.ContentWrapper>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
};

export default SizeChartBottomSheet;
