import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AnimatePresence } from 'motion/react';
import {
  logout,
  setIsMobileNavigationOpen,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import {
  BellIcon,
  Button,
  CancelledOrderIcon,
  CoyuLogo,
  IconButton,
  OrdersIcon,
  ScannerIcon,
  SearchIcon,
  SettingsIcon,
  Text,
} from '@point-of-sale/components';

import { useReleaseIdentifier } from '../../../hooks/useReleaseIdentifier';
import * as Styles from './styles';

const NAVIGATION_VARIANTS = {
  opened: {
    x: '0%',
    transition: {
      delay: 0.15,
      duration: 0.63,
      ease: [0.74, 0, 0.19, 1.02],
    },
  },
  closed: {
    x: '-100%',
    transition: {
      delay: 0.35,
      duration: 0.63,
      ease: [0.74, 0, 0.19, 1.02],
    },
  },
};

const NAVIGATION_ITEMS = [
  {
    path: '/products',
    title: 'Inventory',
    icon: SearchIcon,
  },
  {
    path: '/sku-movement',
    title: 'Track Trials',
    icon: ScannerIcon,
  },
  {
    path: '/orders',
    title: 'Orders',
    icon: OrdersIcon,
  },
  {
    path: '/orders?status=cancelled',
    title: 'Cancelled Orders',
    icon: CancelledOrderIcon,
  },
  {
    path: '/settings',
    title: 'Settings',
    icon: SettingsIcon,
  },
  {
    path: '/notifications',
    title: 'Notifications',
    icon: BellIcon,
  },
] as const;

const Navigation = () => {
  const isMobileNavigationOpen = useAppSelector(
    store => store.userInterface.isMobileNavigationOpen ?? true
  );

  const dispatch = useAppDispatch();

  const location = useLocation();

  const [activePath, setActivePath] = useState(location.pathname + location.search);

  const { releaseIdentifier } = useReleaseIdentifier();

  useEffect(() => {
    setActivePath(location.pathname + location.search);
  }, [location]);

  const navigate = useNavigate();

  const handleNavigation = async (path: string) => {
    setActivePath(path);
    await navigate(path);
    dispatch(setIsMobileNavigationOpen(false));
  };

  return (
    <AnimatePresence>
      {isMobileNavigationOpen && (
        <Styles.Wrapper
          initial="closed"
          exit="closed"
          animate={isMobileNavigationOpen ? 'opened' : 'closed'}
          variants={NAVIGATION_VARIANTS}
        >
          <Styles.Header>
            <CoyuLogo mode="LONG" fill="var(--cod-gray)" />

            <IconButton
              icon="CrossIcon"
              shapeVariant="circle"
              sizeVariant="large"
              onClick={() => {
                dispatch(setIsMobileNavigationOpen(false));
              }}
            />
          </Styles.Header>

          <Styles.Content>
            {NAVIGATION_ITEMS.map(item => {
              const Icon = item.icon;

              return (
                <Styles.NavLink
                  key={item.path}
                  $isActive={activePath === item.path}
                  onClick={() => handleNavigation(item.path)}
                  whileTap={{ scale: 0.95 }}
                >
                  <Icon stroke="var(--dove-gray)" />
                  <Text fontSize={16} weight="semibold" width="fit-content" textAlign="left">
                    {item.title}
                  </Text>
                </Styles.NavLink>
              );
            })}
          </Styles.Content>
          <Styles.Footer>
            <Button
              onClick={() => {
                dispatch(logout());
              }}
              variant="ghost"
              // sizeVariant=""
              colorScheme="dark"
              leftIcon="LogoutIcon"
              isFullWidth
            >
              Logout
            </Button>

            {releaseIdentifier && (
              <Text fontSize={10} color="var(--oslo-gray)">
                v1.0.{releaseIdentifier}
              </Text>
            )}
          </Styles.Footer>
        </Styles.Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Navigation;
