import { useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import { Button } from '@point-of-sale/components';
import { useAnimatedVirtualKeyboard } from '@point-of-sale/hooks';
import { emptyFunction } from '@point-of-sale/utils';
import { useAppSelector } from '@point-of-sale/store';
import KeyValuePair from '../../../../../components/desktop/OrderDetails/KeyValuePair';
import { useProductListItem } from '../context';
import { useOutOfStockDemandTrackerApi } from './useOutOfStockDemandTrackerApi';
import PhoneNumberInput from '../../../../../components/desktop/PhoneNumberInput';
import * as Styles from './styles';

interface ILossOfSaleBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const LossOfSaleBottomSheet = ({ isOpen, onClose }: ILossOfSaleBottomSheetProps) => {
  const selectedSalesChannelId = useAppSelector(store => store.identity.selectedSalesChannel?.id);

  const [inputValue, setInputValue] = useState('');
  const [countryCode, setCountryCode] = useState('+91');

  const { selectedVariant, data } = useProductListItem();

  const { keyboardHeight } = useAnimatedVirtualKeyboard();

  function onChange(value: string) {
    setInputValue(value);
  }

  const { trackOutOfStockDemand, isLoading, error } = useOutOfStockDemandTrackerApi();

  return (
    <Sheet rootId="root" isOpen={isOpen} onClose={onClose} detent="content-height">
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content style={{ paddingBottom: keyboardHeight }}>
          <Styles.Wrapper>
            <KeyValuePair
              title="SKU"
              value={selectedVariant?.skuCode ?? ''}
              valueProps={{ textTransform: 'uppercase' }}
            />
            <KeyValuePair title="Color" value={selectedVariant?.color ?? ''} />
            <KeyValuePair title="Size" value={selectedVariant?.size ?? ''} />
            <KeyValuePair title="Product Id" value={data.id} />

            <PhoneNumberInput
              countryCode={countryCode}
              inputValue={inputValue}
              onChange={onChange}
              setCountryCode={setCountryCode}
              label={`Enter customer's phone number (optional)`}
            />

            <Button
              isFullWidth
              isLoading={isLoading}
              onClick={() => {
                if (!selectedSalesChannelId) {
                  return;
                }

                trackOutOfStockDemand(
                  {
                    salesChannelId: selectedSalesChannelId,
                    customerPhone: `${countryCode}${inputValue}`,
                    skuCode: selectedVariant?.skuCode ?? '',
                    productId: data.id,
                  },
                  {
                    successCallback: () => {
                      onClose();
                    },
                  }
                );
              }}
            >
              Track Out of Stock Demand
            </Button>
          </Styles.Wrapper>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={emptyFunction} />
    </Sheet>
  );
};

export default LossOfSaleBottomSheet;
