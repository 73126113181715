import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootStateType } from '../store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { isEmptyObject } from '@point-of-sale/utils';

const selectFacilities = (state: RootStateType) => state.common.facilities;

export const offlineFacilitiesSelector = createDraftSafeSelector(selectFacilities, facilities => {
  const offlineFacilityIds = facilities.data.ids.filter(
    id => !facilities.data.records[id].isOnline
  );

  const newFacilities = structuredClone(facilities);

  newFacilities.data.ids = offlineFacilityIds;
  newFacilities.data.records = offlineFacilityIds.reduce((acc, id) => {
    acc[id] = newFacilities.data.records[id];
    return acc;
  }, {} as typeof newFacilities.data.records);

  return newFacilities;
});

export const onlineFacilitiesSelector = createDraftSafeSelector(selectFacilities, facilities => {
  const onlineFacilityIds = facilities.data.ids.filter(id => facilities.data.records[id].isOnline);

  const newFacilities = structuredClone(facilities);

  newFacilities.data.ids = onlineFacilityIds;
  newFacilities.data.records = onlineFacilityIds.reduce((acc, id) => {
    acc[id] = newFacilities.data.records[id];
    return acc;
  }, {} as typeof newFacilities.data.records);

  return newFacilities;
});

export const selectSelectedSalesChannelOnlineStatus = createDraftSafeSelector(
  [
    (state: RootStateType) => state.identity.selectedSalesChannel,
    (state: RootStateType) => state.common.facilities.isLoading,
    (state: RootStateType) => state.common.facilities.data.records,
  ],
  (selected, isLoadingFacilities, facilities) => {
    if (isEmptyObject(facilities)) {
      return { isSelectedSalesChannelOnline: false, isLoadingFacilities: true };
    }

    if (!selected?.facilityId) {
      return { isSelectedSalesChannelOnline: false, isLoadingFacilities };
    }

    const selectedFacility = facilities[selected.facilityId];

    return {
      isSelectedSalesChannelOnline: selectedFacility?.isOnline ?? false,
      isLoadingFacilities,
    };
  }
);
