import { MutableRefObject } from 'react';
import Footer from './Footer';
import Address from './Address';
import Contact from './Contact';
import Header from './Header';
import SectionHeading from './SectionHeading';
import Section from './Section';
import LineItems from './LineItems';
import { CartTransactionDTOType, OrderDTOType } from '@point-of-sale/schemas';
import { CURRENCY_SYMBOLS, CurrencyType, PLACEHOLDERS } from '@point-of-sale/constants';
import { format } from 'date-fns';
import { useAppSelector } from '@point-of-sale/store';
import * as Styles from './styles';

interface IPrintableInvoiceProps {
  componentRef?: MutableRefObject<HTMLDivElement | null>;
  data: OrderDTOType;
  type: 'INVOICE' | 'RECEIPT' | 'CREDIT_NOTE';
  cartTransactions?: Record<string, CartTransactionDTOType>;
  customerGSTIN?: string;
  isBeingRegenerated?: boolean;
}

const PrintableInvoice = ({
  componentRef,
  data,
  type,
  cartTransactions,
  customerGSTIN,
  isBeingRegenerated = false,
}: IPrintableInvoiceProps) => {
  const salesperson = useAppSelector(
    // @ts-ignore
    store => store.common.storeStaff.data.records[data.salesStaffId]?.name
  );
  const selectedSalesChannelFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );
  const currentFacilityGroupId = useAppSelector(
    store =>
      // @ts-ignore
      store.common.facilities.data.records[selectedSalesChannelFacilityId]?.facilityGroupId
  );

  const currency = CURRENCY_SYMBOLS[(data.currency as CurrencyType) ?? 'INR']?.code;

  function getHeading() {
    if (type === 'INVOICE') {
      return 'Tax Invoice';
    }

    return 'Credit Note';
  }

  const nonMTOLineItems: OrderDTOType['lineItems'] = isBeingRegenerated
    ? data.lineItems?.filter(item => item.fulfillmentMode === 'ONHAND')
    : data.lineItems?.filter(
        item =>
          item.fulfillmentMode === 'ONHAND' &&
          item.fulfillmentFacilityGroupId === currentFacilityGroupId
      );

  const nonMTOLineItemIds = new Set(nonMTOLineItems.map(item => item.id));
  const mtoLineItems: OrderDTOType['lineItems'] = data.lineItems?.filter(
    item => !nonMTOLineItemIds.has(item.id)
  );

  const nonMTOLineItemsSummary = nonMTOLineItems.reduce(
    (acc, item) => {
      acc.total += item.costBreakup?.chargeableAmount ?? 0;
      acc.sgst += item.costBreakup?.sgst ?? 0;
      acc.igst += item.costBreakup?.igst ?? 0;
      acc.cgst += item.costBreakup?.cgst ?? 0;
      const discount =
        (item.costBreakup.mrp ?? 0) -
        (item.costBreakup.sellingPrice ?? 0) +
        (item.costBreakup.couponDiscount ?? 0);
      acc.discount += discount;
      acc.taxableValue += item.costBreakup?.taxableValue ?? 0;
      return acc;
    },
    { sgst: 0, igst: 0, cgst: 0, discount: 0, total: 0, taxableValue: 0 }
  );

  const mtoLineItemsSummary = mtoLineItems.reduce(
    (acc, item) => {
      acc.total += item.costBreakup?.chargeableAmount ?? 0;
      const discount =
        (item.costBreakup.mrp ?? 0) -
        (item.costBreakup.sellingPrice ?? 0) +
        (item.costBreakup.couponDiscount ?? 0);
      acc.discount += discount;

      return acc;
    },
    { discount: 0, total: 0 }
  );

  const tenderDetails = cartTransactions
    ? Object.values(cartTransactions).map(item => {
        let key = item.paymentModeDisplayName ?? '';

        if (item.paymentModeDisplayName === 'Card') {
          const cardDetails = item?.metadata?.['Card Number'] ?? '';
          key = cardDetails
            ? `${item.paymentModeDisplayName} - ${cardDetails}`
            : item.paymentModeDisplayName;
        }

        if (item.paymentModeDisplayName === 'UPI') {
          const upiDetails = item?.metadata?.['Customer VPA'] ?? '';
          key = `${item.paymentModeDisplayName} - ${upiDetails}`;
        }

        return {
          key: key,
          value: `${currency} ${item.amount}`,
        };
      })
    : [];

  if (data.costBreakup?.amountRemaining && data.costBreakup?.amountRemaining > 0) {
    tenderDetails.push({
      key: 'Amount Remaining',
      value: `${currency} ${data.costBreakup.amountRemaining}`,
    });
  }

  const changeAmount = cartTransactions
    ? Object.values(cartTransactions).reduce((acc, item) => acc + (item.changeAmount ?? 0), 0)
    : 0;

  const nonMtoServiceCharge = nonMTOLineItems.reduce(
    (acc, item) =>
      acc +
      (item.costBreakup.customChargesViaOrder?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0),
    0
  );

  const nonMtoServiceChargeSummary = [];

  if (nonMtoServiceCharge > 0) {
    nonMtoServiceChargeSummary.push({
      key: 'Service Charge',
      value: `${currency} ${nonMtoServiceCharge.toFixed(2)}`,
    });
  }

  const mtoServiceCharge = mtoLineItems.reduce(
    (acc, item) =>
      acc +
      (item.costBreakup.customChargesViaOrder?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0),
    0
  );

  const mtoServiceChargeSummary = [];

  if (mtoServiceCharge > 0) {
    mtoServiceChargeSummary.push({
      key: 'Service Charge',
      value: `${currency} ${mtoServiceCharge.toFixed(2)}`,
    });
  }

  return (
    <Styles.Wrapper ref={componentRef}>
      <Styles.PrintableWrapper>
        <Header src={data.sellerInfo?.logo.url ?? ''} />
        <Styles.LineBreak />
        {nonMTOLineItems.length > 0 && (
          <>
            <SectionHeading heading={getHeading()} />
            <Section
              heading={''}
              data={[
                {
                  key: 'Invoice No.',
                  value: data.invoiceNumber,
                },
                {
                  key: 'Date',
                  value: format(new Date(data.createdOn), 'Pp'),
                },
              ]}
            />
            <LineItems data={nonMTOLineItems} currency={currency} showTax />

            <Section heading="" data={nonMtoServiceChargeSummary} />

            <Section
              heading=""
              data={[
                {
                  key: 'Total (inclusive of all taxes)',
                  value: `${currency} ${Number(nonMTOLineItemsSummary.total).toFixed(2)}`,
                },
              ]}
            />

            <Styles.HorizontalRule />
            <Section
              heading="Tax Summary"
              data={
                nonMTOLineItemsSummary.igst > 0
                  ? [
                      {
                        key: 'IGST',
                        value: Number(nonMTOLineItemsSummary.igst).toFixed(2),
                      },
                    ]
                  : [
                      {
                        key: 'CGST',
                        value: Number(nonMTOLineItemsSummary.cgst).toFixed(2),
                      },
                      {
                        key: 'SGST',
                        value: Number(nonMTOLineItemsSummary.sgst).toFixed(2),
                      },
                    ]
              }
            />

            <Section
              heading=""
              data={[
                {
                  key: 'Taxable Amount',
                  value: `${currency} ${Number(nonMTOLineItemsSummary.taxableValue).toFixed(2)}`,
                },
              ]}
            />
          </>
        )}
        {mtoLineItems.length > 0 && (
          <>
            <SectionHeading heading={`Advance Receipt Voucher`} />
            <Section
              heading=""
              data={[
                {
                  key: 'Advance Receipt Voucher Number',
                  value: data.advanceVoucherNumber,
                },
              ]}
            />
            <LineItems data={mtoLineItems} currency={currency} showTax={false} />
            <Section heading="" data={mtoServiceChargeSummary} />
            <Section
              heading=""
              data={[
                {
                  key: 'Total Amount',
                  value: `${currency} ${Number(mtoLineItemsSummary.total).toFixed(2)}`,
                },
              ]}
            />
          </>
        )}
        <Styles.HorizontalRule />

        <Section
          heading="Tender Details"
          data={[
            ...tenderDetails,
            {
              key: 'Change Returned',
              value: `${currency} ${changeAmount.toFixed(2)}`,
            },
            ...(data.couponCode ? [{ key: 'Coupon Code', value: data.couponCode }] : []),
            ...(data.costBreakup?.bagDiscount
              ? [
                  {
                    key: 'Bag Discount',
                    value: `${currency} ${data.costBreakup.bagDiscount.toFixed(2)}`,
                  },
                ]
              : []),
          ]}
        />

        <Styles.HorizontalRule />
        <Footer
          type={type}
          salesChannelOrderId={data.salesChannelOrderId}
          phone={data.sellerInfo?.phone}
        />

        {data.customerName && (
          <Section
            heading="Customer Details"
            data={[
              {
                key: 'Customer',
                value: data.customerName,
              },
              {
                key: 'Contact',
                value: data.customerPhone,
              },
              {
                key: 'Email',
                value: data.customerEmail || PLACEHOLDERS.NOT_AVAILABLE,
              },
              ...(customerGSTIN
                ? [
                    {
                      key: 'GSTIN',
                      value: customerGSTIN,
                    },
                  ]
                : []),
            ]}
          />
        )}
        <SectionHeading heading={`Contact Us`} />
        <Address data={data.sellerInfo} />
        <Styles.HorizontalRule />
        <Contact data={data.sellerInfo} fashionConsultant={salesperson ?? ''} />
      </Styles.PrintableWrapper>
    </Styles.Wrapper>
  );
};

export default PrintableInvoice;
