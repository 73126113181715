import { addDays } from 'date-fns';
import {
  AddressDTOType,
  FacilityDTOType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import { getCurrentStoreAndWarehouseFacilityIds, useAppSelector } from '@point-of-sale/store';
import { formatToReadableDate } from '@point-of-sale/utils';
import useProductContext from '../../Product/context/useProductContext';

import { IDestination } from '../index';
import { useMemo } from 'react';

interface IUseTableDataProps {
  serviceabilityData: INormalizedServiceabilityData[string];
  customerAddress: AddressDTOType;
  destination: IDestination;
  demandedPincode: string;
}

const useTableData = ({
  serviceabilityData,
  customerAddress,
  destination,
  demandedPincode,
}: IUseTableDataProps) => {
  const isBarcodish = useAppSelector(store => store.pointOfSale.isBarcodish);
  const facilities = useAppSelector(store => store.common.facilities.data);

  const currentStoreFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const { selectedVariant } = useProductContext();

  const { currentStoreAndWarehousesFacilityIds } = getCurrentStoreAndWarehouseFacilityIds();

  let deliverToPincode: FacilityDTOType['address']['pincode'] =
    '' as FacilityDTOType['address']['pincode'];

  // TODO: Modularize this logic
  if (destination.deliveryMode === 'HOME_DELIVERY') {
    if (customerAddress) {
      deliverToPincode = customerAddress.pincode;
    } else if (demandedPincode) {
      deliverToPincode = demandedPincode;
    }
  } else if (destination.deliveryMode === 'STORE_PICKUP' && destination.customerDeliveryStoreId) {
    deliverToPincode = facilities.records[destination.customerDeliveryStoreId].address?.pincode;
  } else if (destination.deliveryMode === 'STORE_PURCHASE' && currentStoreFacilityId) {
    // current store facility id or current warehouse
    deliverToPincode = facilities.records[currentStoreFacilityId].address?.pincode;
  }

  const data = useMemo(() => {
    return facilities.ids
      .filter(id => facilities.records[id].enabled)
      .map(id => {
        const record = facilities.records[id];

        let estimate = '';
        let isJit = false;

        const serviceability = serviceabilityData.find(
          item => String(item.from.facilityId) === String(id) && item.to === deliverToPincode
        );

        // * this modal is only visible in onhand
        const opsTat = serviceability?.info?.innerOpsTat ?? 0;

        if (serviceability?.info?.express) {
          estimate = 'EXPRESS';
        } else if (serviceability?.info.jit) {
          isJit = true;
          estimate = serviceability?.info.jitTat
            ? formatToReadableDate(
                addDays(
                  new Date(),
                  serviceability?.info.jitTat + serviceability.info.standardCourierTat + opsTat
                )
              )
            : '';
        } else {
          estimate = serviceability
            ? formatToReadableDate(
                addDays(new Date(), serviceability.info.standardCourierTat + opsTat)
              )
            : '';
        }
        const inventory = selectedVariant?.facilityInventory?.[id]?.availableInventory ?? 0;

        function getIsDisabled() {
          if (inventory === 0) {
            if (isBarcodish && currentStoreAndWarehousesFacilityIds.includes(id)) {
              return false;
            }

            if (!isBarcodish && currentStoreAndWarehousesFacilityIds.includes(id)) {
              return true;
            }

            if (isBarcodish && !currentStoreAndWarehousesFacilityIds.includes(id)) {
              return true;
            }

            if (!isBarcodish && !currentStoreAndWarehousesFacilityIds.includes(id)) {
              return true;
            }
          }

          return false;
        }

        return {
          id,
          name: record.name,
          cityDisplayName: record.address.cityDisplayName ?? '',
          inventory: inventory,
          expectedDeliveryDate: inventory > 0 ? estimate : '',
          pincode: record.address.pincode ?? '',
          isDisabled: getIsDisabled(),
          isOnline: record.isOnline,
          isJit: isJit,
        };
      });
  }, [
    facilities.ids,
    facilities.records,
    serviceabilityData,
    deliverToPincode,
    selectedVariant,
    isBarcodish,
    currentStoreAndWarehousesFacilityIds,
  ]);

  data
    .sort((a, b) => (a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1))
    .sort((a, b) => b.inventory - a.inventory);

  return data;
};

export default useTableData;

export type TableDataType = ReturnType<typeof useTableData>;
