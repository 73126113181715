import { callApi } from '../services/callApi';

export const getStoreStaffApi = () =>
  callApi('POST', `/pos/storestaff/list?page=1&size=100`, {
    orderBy: 'name',
    orderDirection: 'ASC',
  });

export const getSalesChannelsApi = () =>
  callApi('POST', `/pos/saleschannel/search?page=1&size=100`, {
    filters: [
      // {
      //   field: 'salesChannelType',
      //   filterType: 'IN',
      //   value: 'POS','MARKETPLACE'
      // },
      {
        field: 'salesChannelType',
        filterType: 'IN',
        value: 'POS,SHOPIFY',
      },
    ],
  });

export const getFacilitiesApi = () =>
  callApi('POST', `/pos/facility/search?page=1&size=100`, {
    filters: [
      {
        filterType: 'IN',
        value: 'STORE,WAREHOUSE,VENDOR', // VENDOR for jit
        field: 'facilityType',
      },
    ],
  });

export const getAnalyticsDashboardUrlApi = (facilityName: string) =>
  callApi('GET', `/pos/analytics-dashboard?facilityName=${facilityName}`);

export const getVendorsApi = () =>
  callApi('POST', `/pos/vendor/search?page=1&size=1000`, {
    filters: [
      {
        filterType: 'EQ',
        field: 'jitEnabled',
        value: 'true',
      },
    ],
  });

export const findBulkErpUsersApi = (ids: Array<number> = []) =>
  callApi('POST', `/pos/identity/erpuser/findBulk`, ids);
