import styled from 'styled-components';
import { IOption, NativeDateRangePicker, Text } from '@point-of-sale/components';
import FilterValue from './FilterValue';
import { useDiscovery } from '../context';
import { alphabeticallyCategorizeObjects, isArray } from '@point-of-sale/utils';
import { IDiscountOption } from '../../../desktop/ProductDiscovery/helpers';
import { IDateRange } from '@point-of-sale/types';

interface IStyledFilterValueContainerProps {
  $gap?: 2 | 12;
}

const StyledFilterValueContainer = styled.div<IStyledFilterValueContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  gap: ${props => props.$gap}px;
`;

StyledFilterValueContainer.defaultProps = {
  $gap: 2,
};

const FilterValueLabel = styled(Text)`
  position: sticky;
  top: 0;
  background: var(--white);
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SelectAllContainer = styled.div`
  /* padding: 8px 0; */
  /* border-bottom: 1px solid var(--athens-gray); */
  /* margin-bottom: 8px; */
`;

const FilterValueContainer = () => {
  const { selectedFilterName, filters, filtersToBeApplied, setFiltersToBeApplied } = useDiscovery();

  const selectedFilter = filters.find(filter => filter.name === selectedFilterName);
  const isSingleSelect = selectedFilter?.type === 'SINGLE_SELECT';

  const getSortedOptions = (options: Array<IOption> = []) => {
    if (!options.length) return [];

    return selectedFilter?.shouldSortAlphabetically
      ? [...options].sort((a, b) => String(a.label).localeCompare(String(b.label)))
      : options;
  };

  const handleFilterClick = (option: IOption | IDiscountOption) => {
    if (!selectedFilterName) return;

    setFiltersToBeApplied(prev => {
      const currentValue = prev[selectedFilterName] as Array<IOption | IDiscountOption>;
      const isOptionSelected = currentValue?.some(f => f.value === option.value);

      return {
        ...prev,
        [selectedFilterName]: isOptionSelected
          ? currentValue.filter(v => v.value !== option.value)
          : isSingleSelect
          ? [option]
          : [...(currentValue || []), option],
      };
    });
  };

  const handleDateRangeChange = (dates: IDateRange) => {
    if (!selectedFilterName) {
      return;
    }

    setFiltersToBeApplied(prev => ({
      ...prev,
      [selectedFilterName]: dates,
    }));
  };

  const handleSelectAll = (options: Array<IOption>) => {
    if (!selectedFilterName) return;

    if (options.length === 0) {
      setFiltersToBeApplied(prev => ({
        ...prev,
        [selectedFilterName]: [],
      }));
      return;
    }

    setFiltersToBeApplied(prev => {
      const currentValue = prev[selectedFilterName] as Array<IOption>;
      const areAllSelected = options.every(option =>
        currentValue?.some(f => f.value === option.value)
      );

      return {
        ...prev,
        [selectedFilterName]: areAllSelected ? [] : options,
      };
    });
  };

  const renderFilterValue = (option: IOption) => (
    <FilterValue
      key={option.value}
      isSelected={isOptionSelected(option)}
      label={option.label}
      value={option.value}
      {...(option.count && { count: option.count })}
      onClick={() => handleFilterClick(option)}
    />
  );

  const CategorizedOptions = () => {
    const sortedOptions = getSortedOptions(selectedFilter?.options);
    const categorizedOptions = alphabeticallyCategorizeObjects(sortedOptions, 'label');

    const isAllSelected = () => {
      if (!selectedFilterName) return false;

      const currentFilters = filtersToBeApplied[selectedFilterName];
      if (!Array.isArray(currentFilters)) return false;

      return sortedOptions.every(option => currentFilters.some(f => f.value === option.value));
    };

    return (
      <StyledFilterValueContainer $gap={12}>
        {selectedFilter?.shouldShowSelectAll && (
          <SelectAllContainer>
            <FilterValue
              key={'select-all'}
              isSelected={isAllSelected()}
              label="SELECT ALL"
              value="select-all"
              onClick={() => handleSelectAll(sortedOptions)}
            />
          </SelectAllContainer>
        )}
        {Object.entries(categorizedOptions).map(([letter, options]) => (
          <CategoryContainer key={letter}>
            <FilterValueLabel fontSize={14} color="var(--dove-gray)">
              {letter}
            </FilterValueLabel>
            {options.map(renderFilterValue)}
          </CategoryContainer>
        ))}
      </StyledFilterValueContainer>
    );
  };

  const SimpleOptions = () => {
    const sortedOptions = getSortedOptions(selectedFilter?.options);

    const isAllSelected = () => {
      if (!selectedFilterName || !filtersToBeApplied[selectedFilterName]) {
        return false;
      }

      const currentFilter = filtersToBeApplied[selectedFilterName];

      // Check if it's an array before using array methods
      if (Array.isArray(currentFilter)) {
        return sortedOptions.every(option => currentFilter.some(f => f.value === option.value));
      }

      return false;
    };

    return (
      <StyledFilterValueContainer>
        {selectedFilter?.shouldShowSelectAll && (
          <SelectAllContainer>
            <FilterValue
              isSelected={isAllSelected()}
              label="SELECT ALL"
              value="select-all"
              onClick={() => handleSelectAll(sortedOptions)}
            />
          </SelectAllContainer>
        )}
        {sortedOptions.map(renderFilterValue)}
      </StyledFilterValueContainer>
    );
  };

  const renderFilterByType = () => {
    if (!selectedFilter) return null;

    switch (selectedFilter.type) {
      case 'DATE_RANGE': {
        if (!selectedFilterName) {
          return null;
        }

        return (
          <NativeDateRangePicker
            value={(filtersToBeApplied[selectedFilterName] as IDateRange) || { from: '', to: '' }}
            onChange={handleDateRangeChange}
          />
        );
      }
      case 'SINGLE_SELECT':
      case 'MULTISELECT': {
        return selectedFilter.shouldCategorizeAlphabetically ? (
          <CategorizedOptions />
        ) : (
          <SimpleOptions />
        );
      }

      default: {
        return <SimpleOptions />;
      }
    }
  };

  const isOptionSelected = (option: IOption) => {
    if (!selectedFilterName || !filtersToBeApplied[selectedFilterName]) return false;

    const currentValue = filtersToBeApplied[selectedFilterName];

    return isArray(currentValue) && currentValue.some(f => f.value === option.value);
  };

  return renderFilterByType();
};

export default FilterValueContainer;
