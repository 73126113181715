import { useMemo } from 'react';
import { Button, Image, Text } from '@point-of-sale/components';
import { useOrderListItem } from '../../context';
import { updateAttributesOfCdnImage } from '@point-of-sale/utils';
import KeyValuePair from '../../../../../../components/desktop/OrderDetails/KeyValuePair';
import * as Styles from './styles';

const ProductDetails = () => {
  const { data } = useOrderListItem();

  const highResImage = useMemo(
    () =>
      updateAttributesOfCdnImage(data.productImage?.url, {
        width: 240,
        quality: 100,
      }),
    [data.productImage?.url]
  );

  return (
    <Styles.Wrapper>
      <Styles.ImageContainer>
        <Image height={'100%'} width={'100%'} src={highResImage} />
      </Styles.ImageContainer>
      <Styles.InfoContainer>
        <Text color="var(--dove-gray)" fontSize={16} weight="semibold">
          {data.brandName}
        </Text>

        <Text color="var(--cod-gray)" fontSize={18} weight="semibold">
          {data.productName}
        </Text>

        <KeyValuePair title="Color" value={data.color} />
        <KeyValuePair title="Size" value={data.size} />
        <KeyValuePair title="SKU" value={data.skuCode} />
      </Styles.InfoContainer>
    </Styles.Wrapper>
  );
};

export default ProductDetails;
