import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, RadioGroup } from '@point-of-sale/components';
import { collectPendingPayment, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import PaymentStatusIndicator from '../PaymentStatusIndicator';
import { usePaymentCollectionContext } from '../context';
import { PropsWithSalesPoc } from '../types';
import * as Styles from './styles';
import { OrderTransactionDTOType } from '@point-of-sale/schemas';

const CreditCard = ({ salesPoc, remainingAmount }: PropsWithSalesPoc) => {
  const paymentOptions = useAppSelector(store => store.pendingTasks.paymentOptions.data);

  const isCartTractionsLoading = useAppSelector(store => store.checkout.cartTransactions.isLoading);

  const { salesChannelOrderId, lineItemIds, packageId } = usePaymentCollectionContext();

  const [isLoading, loadingActions] = useBoolean();

  const [isStatusModalOpen, statusModalOpenActions] = useBoolean();
  const [transactionId, setTransactionId] = useState<number>();

  const [requestValue, setRequestValue] = useState<string>(String(remainingAmount));
  const [paymentTerminal, setPaymentTerminal] = useState('');

  const paymentTerminals = Object.values(paymentOptions.records).find(
    item => item.gateway === 'PINE_LABS'
  )?.paymentTerminals;

  const dispatch = useAppDispatch();

  const amount = Number(requestValue);

  // TODO: make a hook for this
  const onRequestMoney = () => {
    loadingActions.on();

    const payload =
      paymentTerminal === 'MANUAL'
        ? {
            amount: amount,
            lineItemIds: lineItemIds,
            packageId: packageId,
            paymentMode: 'CREDIT_CARD',
            paymentGateway: 'MANUAL',
          }
        : {
            amount: amount,
            lineItemIds: lineItemIds,
            packageId: packageId,
            paymentTerminalId: Number(paymentTerminal),
            paymentMode: 'CREDIT_CARD',
            paymentGateway: 'PINE_LABS',
          };

    const successCallback =
      paymentTerminal === 'MANUAL'
        ? (transaction: OrderTransactionDTOType) => {
            toast.success(`Amount of ${amount} collected successfully`);
            loadingActions.off();

            setRequestValue(String(transaction.order?.costBreakup.amountRemaining));
          }
        : (transaction: OrderTransactionDTOType) => {
            toast.success(`Amount of ${amount} collected successfully`);
            setTransactionId(transaction.id);
            setRequestValue(String(transaction.order?.costBreakup.amountRemaining));
            // setPaymentTerminal('');
            loadingActions.off();
            statusModalOpenActions.on();
          };

    dispatch(
      collectPendingPayment(salesChannelOrderId, payload, {
        successCallback,
        failCallback: () => {
          toast.error(`Amount of ${amount} could not be collected`);
          loadingActions.off();
        },
      })
    );
  };

  const paymentTerminalOptions = [
    ...(paymentTerminals?.map(item => ({
      label: item.displayName,
      value: String(item.id),
    })) ?? []),
    {
      value: 'MANUAL',
      label: 'Manual',
    },
  ];

  return (
    <Styles.Wrapper>
      <RadioGroup
        name="options"
        options={paymentTerminalOptions}
        selectedValue={paymentTerminal}
        onChange={selectedValue => {
          if (selectedValue === 'MANUAL') {
            setPaymentTerminal(selectedValue);
            return;
          }

          const newPaymentTerminal = paymentTerminalOptions.find(
            item => String(item.value) === String(selectedValue)
          );

          if (newPaymentTerminal) {
            setPaymentTerminal(String(newPaymentTerminal.value));
          }
        }}
      />

      <Input
        variant="outline"
        sizeVariant="small"
        type="number"
        placeholder={paymentTerminal === 'MANUAL' ? 'Add Amount' : 'Enter request amount'}
        isFullWidth
        value={requestValue}
        onKeyDown={e => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
        onChange={e => {
          if (Number(e.target.value) < 0) {
            return;
          }

          if (Number(e.target.value) > remainingAmount) {
            return;
          }

          setRequestValue(e.target.value);
        }}
      />
      <Styles.ButtonGroup>
        <Button
          variant="outline"
          isLoading={isCartTractionsLoading || isLoading}
          onClick={onRequestMoney}
          isDisabled={!requestValue || !paymentTerminal || !salesPoc}
        >
          {paymentTerminal === 'MANUAL' ? 'Add Amount' : 'Request'}
        </Button>
        <PaymentStatusIndicator
          transactionId={transactionId}
          isOpen={isStatusModalOpen}
          onClose={statusModalOpenActions.off}
        />
      </Styles.ButtonGroup>
    </Styles.Wrapper>
  );
};

export default CreditCard;
