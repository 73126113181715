import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $flexOne: boolean }>`
  border: 1px solid var(--athens-gray);

  ${({ $flexOne }) =>
    $flexOne &&
    css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `}
`;

export const Header = styled.div`
  background: var(--zircon);
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IContentProps {
  $hasPadding?: boolean;
  $flexOne: boolean;
}

export const Content = styled.div<IContentProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding: 16px;
    `}

  ${({ $flexOne }) =>
    $flexOne &&
    css`
      flex: 1;
    `}
`;
