import { FirebaseConfig } from './types';

export async function registerFirebaseServiceWorker(config: FirebaseConfig) {
  if (!('serviceWorker' in navigator)) {
    throw new Error('Service Worker is not supported in this browser');
  }

  const swPath = new URL('/firebase-messaging-sw.js', window.location.origin).href;
  try {
    const registration = await navigator.serviceWorker.register(swPath);
    registration.update();

    registration.active?.postMessage({
      type: 'FIREBASE_CONFIG',
      config,
    });

    console.log('Firebase Service worker Registration successful, scope is:', registration.scope);
    return registration;
  } catch (error) {
    console.error('Firebase Service worker registration failed:', error);
    throw error;
  }
}

export async function unregisterFirebaseServiceWorker() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready;
    await registration.unregister();
  }
}
