import { z } from 'zod';

const EnvSchema = z.object({
  DEV: z.boolean(),
  VITE_TOKEN_KEY: z.string(),
  VITE_BASE_URL: z.string(),
  VITE_ENVIRONMENT: z.enum(['staging', 'production', 'local']),
  VITE_SENTRY_DSN: z.string().optional(),

  VITE_TRY_AT_HOME_FACILITY_ID: z.string().optional(),
  VITE_TEST_FACILITY: z.string().optional(),

  // * Firebase Notifications app config
  VITE_FIREBASE_NOTIFICATIONS_API_KEY: z.string().optional(),
  VITE_FIREBASE_NOTIFICATIONS_AUTH_DOMAIN: z.string().optional(),
  VITE_FIREBASE_NOTIFICATIONS_PROJECT_ID: z.string().optional(),
  VITE_FIREBASE_NOTIFICATIONS_STORAGE_BUCKET: z.string().optional(),
  VITE_FIREBASE_NOTIFICATIONS_MESSAGING_SENDER_ID: z.string().optional(),
  VITE_FIREBASE_NOTIFICATIONS_APP_ID: z.string().optional(),
  VITE_FIREBASE_NOTIFICATIONS_MEASUREMENT_ID: z.string().optional(),
  VITE_FIREBASE_NOTIFICATIONS_VAPID_KEY: z.string().optional(),

  // * Firebase Runway app config
  VITE_FIREBASE_RUNWAY_API_KEY: z.string().optional(),
  VITE_FIREBASE_RUNWAY_AUTH_DOMAIN: z.string().optional(),
  VITE_FIREBASE_RUNWAY_PROJECT_ID: z.string().optional(),
  VITE_FIREBASE_RUNWAY_STORAGE_BUCKET: z.string().optional(),
  VITE_FIREBASE_RUNWAY_MESSAGING_SENDER_ID: z.string().optional(),
  VITE_FIREBASE_RUNWAY_APP_ID: z.string().optional(),
  VITE_FIREBASE_RUNWAY_MEASUREMENT_ID: z.string().optional(),
  VITE_FIREBASE_RUNWAY_REALTIME_DATABASE_URL: z.string().optional(),
});

type EnvType = z.infer<typeof EnvSchema>;

const ENV: EnvType = {} as EnvType;

// export const ENV = EnvSchema.parse(import.meta.env);

const envParsingResult = EnvSchema.safeParse(import.meta.env);

if (envParsingResult.success) {
  Object.assign(ENV, envParsingResult.data);
} else {
  const errorStyles = ['color: red', 'background: yellow', 'font-size: 24px'].join(';');

  envParsingResult.error.errors.forEach((error, index) => {
    console.log(
      `%c[ENV] Error #${index + 1}: ${error.path.toString()} => ${error.message}`,
      errorStyles
    );
  });

  throw new Error('ENV variables not found');
}

if (
  ENV.VITE_ENVIRONMENT === 'production' &&
  ENV.VITE_BASE_URL !== 'https://gateway.lyskraft.in/api'
) {
  throw new Error('Invalid base url for production');
}

export { ENV };
