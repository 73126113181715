import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import DeliveryDetails from './Sections/DeliveryDetails';
import ProductDetails from './Sections/ProductDetails';
import CustomerDetails from './Sections/CustomerDetails';
import PriceDetails from './Sections/PriceDetails';
import { OrderListItemContext } from './context';
import Actionables from './Sections/Actionables';
import * as Styles from './styles';

interface IOrderListItemProps {
  id: number;
  lastItemRef?: (node: HTMLDivElement) => void;
}

const OrderListItem = ({ id, lastItemRef }: IOrderListItemProps) => {
  const [isExpanded, expandedActions] = useBoolean();

  const data = structuredClone(
    useAppSelector(store => store.oms.lineItems.data.records[id], shallowEqual)
  );

  return (
    <OrderListItemContext.Provider value={{ id, data, isExpanded, expandedActions }}>
      <Styles.Wrapper ref={lastItemRef}>
        <DeliveryDetails />
        <Styles.Divider />
        <ProductDetails />
        <Styles.Divider />
        <CustomerDetails />
        {isExpanded && <PriceDetails />}
        <Actionables />
      </Styles.Wrapper>
    </OrderListItemContext.Provider>
  );
};

export default OrderListItem;
