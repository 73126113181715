import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { ENV } from '@point-of-sale/env';
import {
  getFacilities,
  getSalesChannels,
  getStoreStaff,
  getVendors,
  listenToNewReleases,
  searchNotificationToken,
  useAppDispatch,
} from '@point-of-sale/store';
/**
 *  TODO : figure out some way to clean this up
 * - maybe use a separate file to define the routes
 *  layout and page must be coupled together
 *  or should they ?
 */
import Layout from '../components/mobile/Layout';

import ExploreProducts from '../pages/mobile/ExploreProducts';
import SkuMovement from '../pages/mobile/SkuMovement';
import Settings from '../pages/mobile/Settings';
import OrderManagementSystem from '../pages/mobile/OrderManagementSystem';
import Notifications from '../pages/mobile/Notifications';

import ReleasePromptBottomSheet from '../components/mobile/ReleasePromptBottomSheet';
import {
  initializeNotificationsApp,
  initializeRealtimeDatabaseApp,
  useForegroundFirebaseMessaging,
} from '@lyskraft/firebase';

const MobileRoutes = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStoreStaff());
    dispatch(getSalesChannels());
    dispatch(getFacilities());
    dispatch(getVendors());

    // * Initialize Firebase Applications
    // TODO: move this a level up
    initializeNotificationsApp({
      config: {
        apiKey: ENV.VITE_FIREBASE_NOTIFICATIONS_API_KEY as string,
        authDomain: ENV.VITE_FIREBASE_NOTIFICATIONS_AUTH_DOMAIN as string,
        projectId: ENV.VITE_FIREBASE_NOTIFICATIONS_PROJECT_ID as string,
        storageBucket: ENV.VITE_FIREBASE_NOTIFICATIONS_STORAGE_BUCKET as string,
        messagingSenderId: ENV.VITE_FIREBASE_NOTIFICATIONS_MESSAGING_SENDER_ID as string,
        appId: ENV.VITE_FIREBASE_NOTIFICATIONS_APP_ID as string,
        measurementId: ENV.VITE_FIREBASE_NOTIFICATIONS_MEASUREMENT_ID as string,
        vapidKey: ENV.VITE_FIREBASE_NOTIFICATIONS_VAPID_KEY as string,
      },
    });

    // TODO: move this a level up
    initializeRealtimeDatabaseApp({
      config: {
        apiKey: ENV.VITE_FIREBASE_RUNWAY_API_KEY as string,
        authDomain: ENV.VITE_FIREBASE_RUNWAY_AUTH_DOMAIN as string,
        projectId: ENV.VITE_FIREBASE_RUNWAY_PROJECT_ID as string,
        storageBucket: ENV.VITE_FIREBASE_RUNWAY_STORAGE_BUCKET as string,
        messagingSenderId: ENV.VITE_FIREBASE_RUNWAY_MESSAGING_SENDER_ID as string,
        appId: ENV.VITE_FIREBASE_RUNWAY_APP_ID as string,
        measurementId: ENV.VITE_FIREBASE_RUNWAY_MEASUREMENT_ID as string,
        databaseURL: ENV.VITE_FIREBASE_RUNWAY_REALTIME_DATABASE_URL as string,
      },
    });

    dispatch(listenToNewReleases(ENV.VITE_ENVIRONMENT));

    dispatch(searchNotificationToken());
  }, []);

  useForegroundFirebaseMessaging();

  return (
    <>
      <Routes>
        <Route path="/products" element={<Layout />}>
          <Route path="" element={<ExploreProducts />} />
        </Route>

        <Route path="/sku-movement" element={<Layout />}>
          <Route path="" element={<SkuMovement />} />
        </Route>

        <Route path="/orders" element={<Layout />}>
          <Route path="" element={<OrderManagementSystem />} />
          <Route path="/orders?status=cancelled" element={<OrderManagementSystem />} />
        </Route>

        <Route path="/settings" element={<Layout />}>
          <Route path="" element={<Settings />} />
        </Route>

        <Route path="/notifications" element={<Layout />}>
          <Route path="" element={<Notifications />} />
        </Route>

        <Route path="*" element={<Navigate to="/products" replace={true} />} />
      </Routes>

      <ReleasePromptBottomSheet />
    </>
  );
};

export default MobileRoutes;
