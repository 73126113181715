import { useSettingsContext } from '../context';
import { CONFIGURATION } from '../configuration';
import * as Styles from './styles';

const Display = () => {
  const { selectedSection, selectedSubsection } = useSettingsContext();

  const SelectedSubSection =
    CONFIGURATION[selectedSection].groupItems[selectedSubsection].component;

  return (
    <Styles.Wrapper>
      <SelectedSubSection />
    </Styles.Wrapper>
  );
};

export default Display;
