import { getLineItems, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { useFetchOnListEnd } from '@point-of-sale/hooks';
import { NoResults } from '@runway/illustrations';
import { SpinnerIcon } from '@point-of-sale/components';
import OrderListItem from './OrderListItem';
import * as Styles from './styles';

// TODO: Virtualize the list
const OrdersList = () => {
  const isLoading = useAppSelector(store => store.oms.lineItems.isLoading);
  const ids = useAppSelector(store => store.oms.lineItems.data.ids);

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(getLineItems());
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  return (
    <Styles.Wrapper>
      {ids.map((id, index) => (
        <OrderListItem
          key={id}
          id={id}
          lastItemRef={index === ids.length - 2 ? lastItemRef : undefined}
        />
      ))}

      {!isLoading && ids.length === 0 && (
        <Styles.NoResultsWrapper>
          <NoResults />
        </Styles.NoResultsWrapper>
      )}
      {isLoading && ids.length === 0 && (
        <Styles.LoaderWrapper>
          <SpinnerIcon size={48} />
        </Styles.LoaderWrapper>
      )}
    </Styles.Wrapper>
  );
};

export default OrdersList;
