import { IconPropsType } from '../types';

const ArrowSouthIcon = ({ size = 24, fill = 'var(--cod-gray)', ...props }: IconPropsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 25 25" {...props}>
    <path
      fill={fill}
      d="m18.294 16.793-5.293 5.293V1h-1v21.086l-5.295-5.294-.707.707L12.501 24l6.5-6.5z"
    />
  </svg>
);
export default ArrowSouthIcon;
