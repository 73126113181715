import { useEffect, useState } from 'react';
import { Text } from '@point-of-sale/components';
import { getPostOrderPaymentOptions, useAppDispatch, useAppSelector } from '@point-of-sale/store';
import { swallowEvent } from '@point-of-sale/utils';
import Cash from './Cash';
import CreditCard from './CreditCard';
import CreditNote from './CreditNote';
import UnifiedPaymentsInterface from './UnifiedPaymentsInterface';
import PaymentStatusModal from './PaymentStatusModal';
import { PAYMENT_MODES, PaymentModeTypes } from './constants';
import * as Styles from './styles';
import { PaymentCollectionContext } from './context';
import { StoreStaffDTOType } from '@point-of-sale/schemas';

type DisabledProps = {
  isDisabled: true;
  disabledReason: string;
};

type PaymentCollectionProps = (
  | DisabledProps
  | {
      isDisabled: false;
      salesPoc: StoreStaffDTOType;
    }
) & { packageId: number };

const PaymentCollectionPostOrder = (props: PaymentCollectionProps) => {
  const [section, setSection] = useState<PaymentModeTypes | null>();

  const dispatch = useAppDispatch();

  const packageDetails = useAppSelector(
    store => store.pendingTasks.storePickups.data.records[props.packageId]
  );

  useEffect(() => {
    dispatch(getPostOrderPaymentOptions(packageDetails.salesChannelOrderId));
  }, [packageDetails.orderId]);

  return (
    <PaymentCollectionContext.Provider
      value={{
        packageId: props.packageId,
        costBreakup: packageDetails.costBreakup,
        salesChannelOrderId: packageDetails.salesChannelOrderId,
        lineItemIds: packageDetails.orderLineItemIds,
      }}
    >
      <Styles.Wrapper>
        {props.isDisabled && (
          <>
            <Styles.DisabledOverlay onClick={swallowEvent} />
            <Styles.DisabledReason textAlign="center" fontSize={36}>
              {props.disabledReason}
            </Styles.DisabledReason>
          </>
        )}

        <Styles.Heading>
          <Text fontSize={20} weight="bold">
            Choose Payment Mode
          </Text>
        </Styles.Heading>
        <Styles.Main>
          <Styles.PaymentModesWrapper>
            {PAYMENT_MODES.map(item => (
              <Styles.PaymentModesItem
                key={item.title}
                onClick={() => setSection(item.title)}
                $isActive={section === item.title}
                // $isDisabled={
                //   !Object.values(paymentOptionsRecords)
                //     .map(item => item.gateway)
                //     .includes(item.title)
                // }
              >
                {item.icon}
                <Text fontSize={20} weight={section === item.title ? 'bold' : 'regular'}>
                  {item.title}
                </Text>
              </Styles.PaymentModesItem>
            ))}
          </Styles.PaymentModesWrapper>
          <Styles.SectionsWrapper>
            <Styles.SectionsContent>
              {!props.isDisabled && (
                <>
                  {section === 'Cash' && (
                    <Cash
                      salesPoc={props.salesPoc}
                      remainingAmount={packageDetails.costBreakup.amountRemaining ?? 0}
                    />
                  )}
                  {section === 'Credit Card' && (
                    <CreditCard
                      salesPoc={props.salesPoc}
                      remainingAmount={packageDetails.costBreakup.amountRemaining ?? 0}
                    />
                  )}
                  {section === 'UPI' && (
                    <UnifiedPaymentsInterface
                      salesPoc={props.salesPoc}
                      remainingAmount={packageDetails.costBreakup.amountRemaining ?? 0}
                    />
                  )}
                  {section === 'Credit Note' && (
                    <CreditNote
                      salesPoc={props.salesPoc}
                      remainingAmount={packageDetails.costBreakup.amountRemaining ?? 0}
                    />
                  )}
                </>
              )}
            </Styles.SectionsContent>

            <PaymentStatusModal />
          </Styles.SectionsWrapper>
        </Styles.Main>
      </Styles.Wrapper>
    </PaymentCollectionContext.Provider>
  );
};

export default PaymentCollectionPostOrder;
