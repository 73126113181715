import { useEffect } from 'react';
import { AnimatePresence } from 'motion/react';
import { useBoolean } from '@point-of-sale/hooks';
import ImageGallery from '../../ImageGallery';
import { Button, Capsule, ColorSelector, SizeSelector, Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';
import { formatNumberToLocale, isEmptyObject } from '@point-of-sale/utils';
import InventoryTable from '../../../shared/InventoryTable';
import { Ribbon } from '../../Ribbon';
import { useProductVariants } from '../../../../hooks/useProductVariants';
import useDiscount from '../../Product/hooks/useDiscount';
import CopyableSkuInfo from '../../../shared/CopyableSkuInfo';
import SizeChartModal from '../../Product/SizeChartModal';
import * as Styles from './styles';
import * as TableStyles from '../../Table/styles';

interface ITableRowProps {
  id: number;
  lastItemRef: any;
}

const TableRow = ({ id, lastItemRef }: ITableRowProps) => {
  const data = structuredClone(
    useAppSelector(store => store.pointOfSale.products.data.records[id])
  );
  const buyingWithoutInventoryAllowedForBarcode = useAppSelector(
    store => store.pointOfSale.buyingWithoutInventoryAllowedForBarcode
  );

  const [isSizeChartOpen, sizeChartOpenActions] = useBoolean();

  const {
    allColorOptions,
    allSizeOptions,
    selectedVariant,
    enabledSizes,
    onSelectColor,
    onSelectSize,
  } = useProductVariants({
    data,
  });

  const [isExpanded, expandedActions] = useBoolean();

  useEffect(() => {
    // * this flag indicates that the user searched via barcode
    // * and the product was not found in the inventory
    if (buyingWithoutInventoryAllowedForBarcode) {
      expandedActions.on();
    }
  }, [buyingWithoutInventoryAllowedForBarcode]);

  const { currencySymbol, discount } = useDiscount({
    product: data,
    selectedVariant,
  });

  return (
    <>
      <TableStyles.TableRow
        $isSelected={false}
        ref={lastItemRef}
        $haveHoverEffect={false}
        style={{
          position: 'relative',
        }}
      >
        <TableStyles.TableData>
          <ImageGallery
            heading="Product Gallery"
            images={
              data.images?.map(item => ({
                src: item.url,
                subText: data.brand.name,
                text: data.name,
              })) ?? []
            }
          />
          {selectedVariant?.discountGroupId === 1 && (
            <Ribbon $position="top" $background="var(--chateau-green)">
              Buy 2 Get 20% Off
            </Ribbon>
          )}
          {selectedVariant?.discountGroupId !== 1 && discount > 0 && (
            <Ribbon $position="top" $background="var(--chateau-green)">
              {discount}% OFF
            </Ribbon>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          <Text fontSize={12}>{data.brand.name}</Text>
          <br />
          <Text fontSize={14}>{data.name}</Text>
        </TableStyles.TableData>
        <TableStyles.TableData>
          {selectedVariant && (
            <Text fontSize={14}>
              {currencySymbol}
              &nbsp;
              {formatNumberToLocale(selectedVariant.mrp)}
            </Text>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>{data.components}</TableStyles.TableData>
        <TableStyles.TableData>
          {data.productType?.parent?.name && `${data.productType.parent?.name} > `}
          {data.productType?.name}
        </TableStyles.TableData>

        <TableStyles.TableData>
          {selectedVariant && (
            <>
              <Capsule
                essence="light"
                complexion={selectedVariant?.mtoAcceptedByBrand ? 'success' : 'danger'}
                size="small"
                content="MTO"
                style={{ margin: 4 }}
                iconName={selectedVariant?.mtoAcceptedByBrand ? 'CheckMarkIcon' : 'CrossIcon'}
                iconProps={{ size: 24 }}
              />
              <Text textAlign="center" display="inline-block">
                {selectedVariant?.mtoAcceptedByBrand &&
                  selectedVariant?.mtoTAT &&
                  `${selectedVariant?.mtoTAT} Days`}
              </Text>
            </>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          {selectedVariant && (
            <>
              <Capsule
                essence="light"
                complexion={selectedVariant?.mtcAcceptedByBrand ? 'success' : 'danger'}
                size="small"
                content="MTC"
                style={{ margin: 4 }}
                iconName={selectedVariant?.mtcAcceptedByBrand ? 'CheckMarkIcon' : 'CrossIcon'}
                iconProps={{ size: 24 }}
              />
              <Text textAlign="center" display="inline-block">
                {selectedVariant?.mtcAcceptedByBrand &&
                  selectedVariant?.mtcTAT &&
                  `${selectedVariant?.mtcTAT} Days`}
              </Text>
            </>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          {selectedVariant && (
            <>
              <Capsule
                essence="light"
                complexion={
                  (selectedVariant?.jitFacilities?.length ?? 0) > 0 ? 'success' : 'danger'
                }
                size="small"
                content="JIT"
                style={{ margin: 4 }}
                iconName={
                  (selectedVariant?.jitFacilities?.length ?? 0) > 0 ? 'CheckMarkIcon' : 'CrossIcon'
                }
                iconProps={{ size: 24 }}
              />
              <Text textAlign="center" display="inline-block">
                {(selectedVariant?.jitFacilities?.length ?? 0) > 0 &&
                  selectedVariant?.jitTAT &&
                  `${selectedVariant?.jitTAT} Days`}
              </Text>
            </>
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          <ColorSelector
            availableColors={allColorOptions.map(item => ({
              id: item.displayName,
              name: item.displayName,
              colorCodes: item.colorCodes ?? [],
            }))}
            enabledColors={allColorOptions.map(item => item.displayName)}
            selectedColor={data.selectedColor}
            onSelectColor={onSelectColor}
            shouldAnimate={false}
          />
        </TableStyles.TableData>
        <TableStyles.TableData>
          <SizeSelector
            boxSize="COMPACT"
            rightLabel={
              data.isSizeChartValid ? (
                <Button
                  onClick={sizeChartOpenActions.on}
                  sizeVariant="xs"
                  variant="ghost"
                  rightIcon="ArrowEastIcon"
                  colorScheme="dark"
                >
                  Size Guide
                </Button>
              ) : null
            }
            shouldShowCustomTailored={false}
            availableSizes={allSizeOptions}
            enabledSizes={enabledSizes}
            selectedSize={data.selectedSize}
            onSelectSize={onSelectSize}
            shouldAnimate={false}
          />
          <CopyableSkuInfo selectedVariant={selectedVariant} />
          {data.isSizeChartValid && (
            <SizeChartModal
              isOpen={isSizeChartOpen}
              onClose={sizeChartOpenActions.off}
              product={data}
            />
          )}
        </TableStyles.TableData>
        <TableStyles.TableData>
          <Button
            sizeVariant="xs"
            colorScheme="dark"
            variant="outline"
            onClick={expandedActions.toggle}
            isDisabled={
              !selectedVariant?.facilityInventory ||
              isEmptyObject(selectedVariant?.facilityInventory)
            }
          >
            {isExpanded ? 'Less' : 'More'}
          </Button>
        </TableStyles.TableData>
      </TableStyles.TableRow>

      <AnimatePresence>
        {isExpanded && (
          <Styles.ExpandedRow>
            <TableStyles.TableData colSpan={9}></TableStyles.TableData>
            <InventoryTable inventory={selectedVariant?.facilityInventory} />
            <TableStyles.TableData></TableStyles.TableData>
          </Styles.ExpandedRow>
        )}
      </AnimatePresence>
    </>
  );
};

export default TableRow;
