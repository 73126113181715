import OrdersList from '../../../components/mobile/OrdersList';
import OrdersDiscovery from '../../../components/mobile/OrdersDiscovery';

import * as Styles from './styles';
import { useLocation } from 'react-router';
import { useMemo } from 'react';
import WithMobileHeaderWrapper from '../../../components/mobile/WithMobileHeaderWrapper';

type Usage = 'DEFAULT' | 'CANCELLED_ORDERS';

const OrderManagementSystem = () => {
  const location = useLocation();

  const usage: Usage = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('status') === 'cancelled' ? 'CANCELLED_ORDERS' : 'DEFAULT';
  }, [location.search]);

  const heading = usage === 'CANCELLED_ORDERS' ? 'Cancelled Orders' : 'OMS';

  return (
    <WithMobileHeaderWrapper name={heading}>
      <Styles.Wrapper>
        <OrdersDiscovery usage={usage} />
        <Styles.Content>
          <OrdersList />
        </Styles.Content>
      </Styles.Wrapper>
    </WithMobileHeaderWrapper>
  );
};

export default OrderManagementSystem;
