import { FacilityDTOType } from '@point-of-sale/schemas';
import { callApi } from '../services/callApi';

export const getServiceabilityApi = ({
  skuCodes,
  destinationPincodes,
  sourceFacilities,
}: {
  skuCodes: Array<string>;
  destinationPincodes: Array<string>;
  sourceFacilities: Array<FacilityDTOType>;
}) => {
  return callApi('POST', `/pos/serviceability`, {
    skuCodes,
    destinationPincodes,
    sourceFacilities,
  });
};
