import { DeleteIcon, MinusIcon, PlusIcon } from '../icons';
import * as Styles from './styles';

interface ICounterProps {
  value: number;
  onIncrement: (value: number) => void;
  onDecrement: (value: number) => void;
  step?: number;
  min?: number;
  max?: number;
  onDelete?: VoidFunction;
  isLoading?: boolean;
}

const Counter = ({
  value,
  max,
  min,
  onIncrement,
  onDecrement,
  onDelete,
  step = 1,
  isLoading = false,
}: ICounterProps) => {
  const isDecrementDisabled = min !== undefined && value <= min;
  const isIncrementDisabled = max !== undefined && value >= max;

  return (
    <Styles.Wrapper>
      <Styles.Button
        $isLoading={isLoading}
        onClick={() => onDecrement(value - step)}
        disabled={isDecrementDisabled || isLoading}
      >
        {value === 1 ? <DeleteIcon size={14} /> : <MinusIcon size={12} />}
      </Styles.Button>
      <Styles.Value>{value}</Styles.Value>
      <Styles.Button
        $isLoading={isLoading}
        onClick={() => onIncrement(value + step)}
        disabled={isIncrementDisabled || isLoading}
      >
        <PlusIcon size={12} />
      </Styles.Button>
      {onDelete && (
        <Styles.DeleteButton onClick={onDelete}>
          <DeleteIcon />
        </Styles.DeleteButton>
      )}
    </Styles.Wrapper>
  );
};

export default Counter;
