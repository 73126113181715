import { useEffect, useState } from 'react';
import { IOption } from '@point-of-sale/components';
import { DiscoveryContext } from './context';
import Filters from './Filters';
import * as Styles from './styles';

export interface IFilter {
  name: string;
  placeholder: string;
  options: Array<IOption>;
  values:
    | Array<string>
    | Array<number>
    | {
        from: number;
        to: number;
      };
  type?: 'MULTISELECT' | 'DATE_RANGE' | 'SINGLE_SELECT';
}

interface IDiscoveryProps {
  searchQuery: string;
  onPressEnter?: (search: string) => void;
  filters: Array<IFilter>;
  customInput?: React.ReactNode;
  onApply: (filtersToBeApplied: Record<string, any>) => void;
  onClearAll: () => void;
  // onClearSingleFilter: (filter: IPosFilter) => void;
  placeholder?: string;
  renderSelectedFilters?: (
    filtersToBeApplied: Record<string, any>,
    setFiltersToBeApplied: (filters: Record<string, any>) => void,
    onApply: (filtersToBeApplied: Record<string, any>) => void
  ) => React.ReactNode;
}

const Discovery = ({
  searchQuery,
  onPressEnter,
  filters,
  onApply,
  onClearAll,
  customInput,
  placeholder,
  renderSelectedFilters,
}: IDiscoveryProps) => {
  const [search, setSearch] = useState('');

  const [filtersToBeApplied, setFiltersToBeApplied] = useState({});

  useEffect(() => {
    setSearch(searchQuery);
  }, [searchQuery]);

  const [selectedFilterName, setSelectedFilterName] = useState<string | null>(null);

  useEffect(() => {
    const initialFilters = filters.reduce((acc, filter) => {
      if (filter.values && Array.isArray(filter.values)) {
        acc[filter.name] = filter.values.filter(Boolean).map(option => ({ value: option }));
      } else if (filter.values && 'from' in filter.values && 'to' in filter.values) {
        // Handle date range object
        acc[filter.name] = filter.values;
      }
      return acc;
    }, {} as Record<string, any>);

    setFiltersToBeApplied(initialFilters);

    if (!selectedFilterName && filters.length > 0) {
      setSelectedFilterName(filters[0].name);
    }
  }, [filters]);

  return (
    <DiscoveryContext.Provider
      value={{
        filters,
        selectedFilterName,
        setSelectedFilterName,
        filtersToBeApplied,
        setFiltersToBeApplied,
      }}
    >
      <Styles.Wrapper>
        <Styles.Row>
          <Styles.SearchInput
            placeholder={placeholder}
            value={search}
            autoFocus
            type="search"
            enterKeyHint="search"
            onChange={e => {
              if (e.target.type === 'clear') {
                setSearch('');
                onPressEnter?.('');
                return;
              }

              setSearch(e.target.value);
            }}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                onPressEnter?.(search);
              }
            }}
          />

          {customInput}

          {filters.length > 0 && (
            <Filters
              onApply={() => onApply(filtersToBeApplied)}
              onClearAll={() => {
                setFiltersToBeApplied({});
                onClearAll();
              }}
            />
          )}
        </Styles.Row>

        {renderSelectedFilters?.(filtersToBeApplied, setFiltersToBeApplied, onApply)}
      </Styles.Wrapper>
    </DiscoveryContext.Provider>
  );
};

export default Discovery;
