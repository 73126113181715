import { Button } from '@point-of-sale/components';
import { useOrderListItem } from '../../context';
import styled from 'styled-components';
import AlterationEntryBottomSheet from '../AlterationEntryBottomSheet';
import { useBoolean } from '@point-of-sale/hooks';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: right;
`;

const Actionables = () => {
  const { isExpanded, expandedActions, data } = useOrderListItem();

  const [isBottomSheetOpen, bottomSheetActions] = useBoolean();

  const hasAlteration =
    (data.alterationEntry && Object.values(data.alterationEntry).length > 0) ?? false;

  return (
    <Wrapper>
      {hasAlteration && (
        <Button onClick={bottomSheetActions.on} sizeVariant="xs" variant="outline" isFullWidth>
          View Customizations
        </Button>
      )}
      <Button
        onClick={expandedActions.toggle}
        sizeVariant="xs"
        variant="outline"
        {...(hasAlteration ? { isFullWidth: true } : {})}
      >
        View {isExpanded ? 'Less' : 'More'}
      </Button>

      {hasAlteration && data.alterationEntry && (
        <AlterationEntryBottomSheet
          data={data.alterationEntry}
          isOpen={isBottomSheetOpen}
          onClose={bottomSheetActions.off}
        />
      )}
    </Wrapper>
  );
};

export default Actionables;
