import { shallowEqual } from 'react-redux';
import { Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';
import { CONFIGURATION } from '../configuration';
import { useSettingsContext } from '../context';
import { ConfigurationType } from '../types';
import * as Styles from './styles';

const Controls = () => {
  const roles = useAppSelector(
    store => store.identity.erpUser?.roles.map(role => role.name),
    shallowEqual
  );

  const { selectedSection, selectedSubsection, setSelectedSection, setSelectedSubsection } =
    useSettingsContext();

  const hasAccess = (subsectionRoles: Array<string>) => {
    return subsectionRoles.includes('*') || subsectionRoles.some(role => roles?.includes(role));
  };

  const renderSubsections = (
    section: ConfigurationType[string],
    filteredSubsections: Array<ConfigurationType[string]['groupItems'][string]>
  ) => {
    return filteredSubsections.map(subsection => {
      const isActive = selectedSection === section.slug && selectedSubsection === subsection.slug;

      return (
        <Styles.ListItem
          key={subsection.slug}
          $isActive={isActive}
          onClick={() => {
            setSelectedSection(section.slug);
            setSelectedSubsection(subsection.slug);
          }}
        >
          <Text fontSize={14} weight={isActive ? 'semibold' : 'regular'}>
            {subsection.name}
          </Text>
        </Styles.ListItem>
      );
    });
  };

  return (
    <Styles.Wrapper>
      {Object.values(CONFIGURATION).map(section => {
        const filteredSubsections = Object.values(section.groupItems).filter(subsection =>
          hasAccess(subsection.roles || [])
        );

        if (filteredSubsections.length === 0) {
          return null;
        }

        return (
          <Styles.Group key={section.slug}>
            <Text fontSize={16} weight="bold" textTransform="uppercase">
              {section.groupHeading}
            </Text>
            <Styles.UnorderedList>
              {renderSubsections(section, filteredSubsections)}
            </Styles.UnorderedList>
          </Styles.Group>
        );
      })}
    </Styles.Wrapper>
  );
};

export default Controls;
