import Lottie, { type LottieProps } from 'react-lottie-player';
import animationData from './calendar.json';

interface ICalendarLottieProps extends Omit<LottieProps, 'animationData'> {
  height?: number;
  width?: number;
}

const CalendarLottie = ({ height = 400, width = 400, ...props }: ICalendarLottieProps) => {
  return (
    <Lottie
      {...props}
      animationData={animationData}
      loop
      play
      style={{
        height: height,
        width: width,
      }}
    />
  );
};

export default CalendarLottie;
