import { useState } from 'react';
import toast from 'react-hot-toast';
import { createSearchParams, useNavigate } from 'react-router';
import { Button, Text } from '@point-of-sale/components';
import {
  addCustomerToCart,
  createNewCustomer,
  dispatchThunksSequentially,
  initializeCart,
  resetCheckoutState,
  resetPointOfSaleState,
  setAddressesData,
  setCustomerDetails,
  updateCustomer,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { PHONE_REGEX } from '@point-of-sale/constants';
import CustomerDetails from '../../../components/desktop/CustomerDetails';
import * as Styles from './styles';

const OtherDetails = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isNextLoading, setIsNextLoading] = useState(false);

  const customerId = useAppSelector(store => store.pointOfSale.customer.data.id);
  const isCustomerDetailsLoading = useAppSelector(store => store.pointOfSale.customer.isLoading);

  const { isSelectedSalesChannelOnline } = useIsSelectedSalesChannelOnline();

  function successCallback(cartId: string) {
    const params = createSearchParams({
      id: cartId,
    });

    navigate(`/point-of-sale/cart?${params.toString()}`);
  }

  async function handleNextClick() {
    setIsNextLoading(true);

    try {
      await dispatchThunksSequentially(dispatch, [
        [initializeCart],
        [addCustomerToCart, successCallback],
      ]);
    } catch (error) {
      console.error('Error in processing cart actions:', error);
    } finally {
      setIsNextLoading(false);
    }
  }

  function handleGuestCheckout() {
    dispatch(
      setCustomerDetails({
        data: {},
        isLoading: false,
        isSuccess: true,
      })
    );
    dispatch(
      setAddressesData({
        data: {
          ids: [],
          records: {},
        },
        isLoading: false,
        isSuccess: true,
      })
    );
    dispatch(resetPointOfSaleState());
    dispatch(resetCheckoutState());

    dispatch(initializeCart(successCallback));
  }

  return (
    <CustomerDetails
      actionables={formData => {
        return customerId ? (
          <Styles.ButtonContainer>
            <Button
              sizeVariant="large"
              colorScheme="dark"
              isLoading={isNextLoading || isCustomerDetailsLoading}
              onClick={() => {
                dispatch(updateCustomer(customerId, formData, handleNextClick));
              }}
            >
              NEXT
            </Button>
          </Styles.ButtonContainer>
        ) : (
          <Styles.ButtonGroup>
            <Styles.ButtonContainer>
              <Button
                sizeVariant="large"
                colorScheme="dark"
                onClick={() => {
                  if (!formData.name) {
                    toast.error('Name is required');
                    return;
                  }

                  if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
                    toast.error('Please Enter a valid phone number');
                    return;
                  }

                  if (formData.phone.length !== 10) {
                    toast.error('Please Enter a valid phone number');
                    return;
                  }

                  dispatch(createNewCustomer(formData, handleNextClick));
                }}
              >
                CREATE NEW CUSTOMER
              </Button>
            </Styles.ButtonContainer>

            {!isSelectedSalesChannelOnline && (
              <>
                <Text textAlign="center">or</Text>
                <Styles.ButtonContainer>
                  <Button sizeVariant="large" colorScheme="dark" onClick={handleGuestCheckout}>
                    GUEST CHECKOUT
                  </Button>
                </Styles.ButtonContainer>
              </>
            )}
          </Styles.ButtonGroup>
        );
      }}
    />
  );
};

export default OtherDetails;
