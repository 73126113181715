import { addDays, isSameDay } from 'date-fns';
import { CheckBox, Text } from '@point-of-sale/components';
import {
  AddressDTOType,
  CartItemDTOType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import { emptyFunction, formatToReadableDate } from '@point-of-sale/utils';
import { HOME_DELIVERY } from './constants';

import * as Styles from './styles';

interface IDeliverToHomeProps {
  onChange: (promiseDate: string) => void;
  isSelected: boolean;
  shouldShowDelivery: boolean;
  homeDeliveryDate: string;

  savedPromiseDate?: string;
  cartItem?: CartItemDTOType;
}

const DeliverToHome = ({
  onChange,
  isSelected,
  shouldShowDelivery,
  homeDeliveryDate,
  savedPromiseDate,
  cartItem,
}: IDeliverToHomeProps) => {
  return (
    <Styles.Tr $isSelected={isSelected} onClick={() => onChange(homeDeliveryDate)}>
      <Styles.Td>
        <CheckBox
          shape="round"
          name={HOME_DELIVERY}
          isChecked={isSelected}
          onChange={emptyFunction}
        />
      </Styles.Td>
      <Styles.Td>Deliver To Home</Styles.Td>
      <Styles.Td></Styles.Td>
      <Styles.Td
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {shouldShowDelivery && (
          <>
            {homeDeliveryDate !== 'EXPRESS' && <>Est. Delivery: &nbsp;</>}
            {homeDeliveryDate}
            {/* {isSelected && homeDeliveryDate !== 'EXPRESS' && savedPromiseDate ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {isSameDay(new Date(homeDeliveryDate), new Date(savedPromiseDate)) ? (
                  <Text as="span" color="var(--dove-gray)">
                    {homeDeliveryDate}
                  </Text>
                ) : (
                  <>
                    <Text as="span" textDecorationLine="line-through" color="var(--dove-gray)">
                      {homeDeliveryDate}
                    </Text>
                    &nbsp;
                    <Text as="span" color="var(--dove-gray)">
                      {formatToReadableDate(savedPromiseDate)}
                    </Text>
                  </>
                )}
              </>
            ) : (
              homeDeliveryDate
            )} */}
          </>
        )}
      </Styles.Td>
    </Styles.Tr>
  );
};

export default DeliverToHome;
