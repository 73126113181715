import { useState } from 'react';
import { format } from 'date-fns';
import { Button, Input, Text } from '@point-of-sale/components';
import { addTransactionToCart, useAppDispatch, useAppSelector } from '@point-of-sale/store';

import * as TableStyles from '../../../../../components/desktop/Table/styles';
import * as Styles from './styles';
import { useBoolean } from '@point-of-sale/hooks';

const CreditNote = () => {
  const [isLoading, loadingActions] = useBoolean();

  const creditNotes = useAppSelector(
    store =>
      Object.values(store.checkout.paymentOptions.data.records).find(
        item => item.gateway === 'CREDIT_NOTE'
      )?.options
  );

  const [newCreditNote, setNewCreditNote] = useState('');

  const dispatch = useAppDispatch();

  const onApplyAutomaticCreditNote = () => {
    loadingActions.on();

    dispatch(
      addTransactionToCart(
        {
          paymentGateway: 'CREDIT_NOTE',
          paymentMode: 'CREDIT_NOTE',
          metadata: {},
        },
        {
          successCallback: () => {
            loadingActions.off();
          },
          failCallback: () => {
            loadingActions.off();
          },
        }
      )
    );
  };

  const onApplyManualCreditNote = (newCode: string) => {
    loadingActions.on();

    dispatch(
      addTransactionToCart(
        {
          paymentGateway: 'CREDIT_NOTE',
          paymentMode: 'CREDIT_NOTE',
          metadata: {
            CREDIT_NOTE_REF: newCode,
          },
        },
        {
          successCallback: () => {
            loadingActions.off();
          },
          failCallback: () => {
            loadingActions.off();
          },
        }
      )
    );
  };

  const totalCredit = creditNotes?.reduce(
    (acc, item) => acc + (item.meta?.AVAILABLE_CREDIT as number),
    0
  );

  const doesCreditNoteExist = creditNotes && creditNotes?.length > 0;

  return (
    <Styles.Wrapper>
      {doesCreditNoteExist && (
        <TableStyles.Wrapper
          style={{
            width: '100%',
          }}
        >
          <TableStyles.Table>
            <TableStyles.Thead>
              <TableStyles.TableHeadingRow>
                <TableStyles.TableHeader>Code</TableStyles.TableHeader>
                <TableStyles.TableHeader>Available credit</TableStyles.TableHeader>
                <TableStyles.TableHeader>Valid till</TableStyles.TableHeader>
              </TableStyles.TableHeadingRow>
            </TableStyles.Thead>
            <tbody>
              {creditNotes?.map((data, index) => {
                const expiryDate = data.meta?.EXPIRY_DATE
                  ? new Date(data.meta?.EXPIRY_DATE as number)
                  : '';

                return (
                  <TableStyles.TableRow key={data.id} $isSelected={false}>
                    <TableStyles.TableData>{data.name}</TableStyles.TableData>
                    <TableStyles.TableData>Rs. {data.meta?.AVAILABLE_CREDIT}</TableStyles.TableData>
                    <TableStyles.TableData>
                      {data.meta?.EXPIRY_DATE && format(expiryDate, 'dd MMM yyyy')}
                    </TableStyles.TableData>
                  </TableStyles.TableRow>
                );
              })}
              <TableStyles.TableRow $isSelected={false}>
                <TableStyles.TableData></TableStyles.TableData>
                <TableStyles.TableData>
                  <b>Total: Rs. {totalCredit}</b>
                </TableStyles.TableData>
                <TableStyles.TableData></TableStyles.TableData>
              </TableStyles.TableRow>
            </tbody>
          </TableStyles.Table>
        </TableStyles.Wrapper>
      )}
      <Styles.Row>
        {doesCreditNoteExist && (
          <>
            <Button
              variant="outline"
              sizeVariant="xs"
              isLoading={isLoading}
              onClick={() => {
                onApplyAutomaticCreditNote();
              }}
            >
              Use Credit Note
            </Button>

            <Text>Or</Text>
          </>
        )}
        <Styles.InputWrapper>
          <Input
            variant="outline"
            sizeVariant="extraSmall"
            placeholder="Enter Code"
            value={newCreditNote}
            onChange={e => {
              setNewCreditNote(e.target.value);
            }}
          />
          <Button
            variant="outline"
            sizeVariant="xs"
            isLoading={isLoading}
            onClick={() => {
              onApplyManualCreditNote(newCreditNote);
            }}
          >
            Add Manually
          </Button>
        </Styles.InputWrapper>
      </Styles.Row>
    </Styles.Wrapper>
  );
};

export default CreditNote;
