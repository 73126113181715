import { IconPropsType } from '../types';

const ArrowNorthIcon = ({ fill = 'var(--cod-gray)', size = 24, ...props }: IconPropsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 25 25" {...props}>
    <path
      fill={fill}
      d="m12.499 1-6.5 6.5.707.707 5.293-5.293V24h1V2.914l5.295 5.294.707-.707L12.499 1z"
    />
  </svg>
);
export default ArrowNorthIcon;
