import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Select, SpinnerIcon, Text } from '@point-of-sale/components';
import {
  getSalesChannels,
  setSelectedSalesChannel,
  useAppDispatch,
  useAppSelector,
  useIsErpUserSuperAdmin,
} from '@point-of-sale/store';
import * as Styles from './styles';

const InputSalesChannel = () => {
  const selectedSalesChannelId = useAppSelector(
    store => store.identity.selectedSalesChannel?.id ?? null
  );
  const storeSalesChannelIds = useAppSelector(
    store => store.identity.erpUser?.storeSalesChannelIds ?? []
  );
  const {
    data: { ids: salesChannelsIds, records: salesChannelsRecords },
    isLoading,
  } = useAppSelector(state => state.common.salesChannels);

  const { isErpUserSuperAdmin } = useIsErpUserSuperAdmin();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    dispatch(getSalesChannels());
  }, []);

  const optionIds = isErpUserSuperAdmin
    ? salesChannelsIds.filter(id => salesChannelsRecords[id].enabled)
    : salesChannelsIds
        .filter(id => storeSalesChannelIds.includes(id))
        .filter(id => salesChannelsRecords[id].enabled);

  useEffect(() => {
    if (optionIds.length === 1) {
      dispatch(setSelectedSalesChannel(salesChannelsRecords[optionIds[0]]));
      navigate(from);
    }
  }, [optionIds, salesChannelsRecords]);

  return (
    <Styles.FormWrapper>
      {isLoading ? (
        <SpinnerIcon size={28} />
      ) : (
        <>
          <Text>Select Sales Channel</Text>

          <Select
            className="login-input"
            options={optionIds.map(id => ({
              label: salesChannelsRecords[id]?.name,
              value: id,
            }))}
            placeholder="Select Sales Channel"
            value={
              selectedSalesChannelId
                ? {
                    label: salesChannelsRecords[selectedSalesChannelId]?.name,
                    value: selectedSalesChannelId,
                  }
                : null
            }
            onChange={selectedOption => {
              if (!selectedOption) {
                return;
              }

              const newSalesChannel = Object.values(salesChannelsRecords).find(
                item => String(item.id) === String(selectedOption.value)
              );

              if (newSalesChannel) {
                dispatch(setSelectedSalesChannel(newSalesChannel));
                navigate(from);
              }
            }}
          />
        </>
      )}
    </Styles.FormWrapper>
  );
};

export default InputSalesChannel;
