import toast from 'react-hot-toast';
import { CartDTOSchema, CartDTOType, CartItemTatUpdateDTOType } from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, normalizeArrayToIdsAndRecords } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import { ThunkActionType } from '../../store';
import { setCartDetails } from '../actions';
import { updateTurnAroundTimeApi } from '../api';

export const updateItemTurnAroundTime =
  ({
    cartId,
    itemId,
    data,
    successCallback,
  }: {
    cartId: number;
    itemId: number;
    data: Partial<CartItemTatUpdateDTOType>;
    successCallback?: CallableFunction;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    // const cart = structuredClone(getState().pointOfSale.cart.data);
    const cartItem = getState().pointOfSale.cart.data?.cartItems.records[itemId];

    const promise = updateTurnAroundTimeApi({
      cartId,
      itemId,
      data: {
        ...cartItem,
        ...data,
      },
    });
    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArrayToIdsAndRecords(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };
