import { IconPropsType } from '../types';

const ArrowEastIcon = ({ size = 24, fill = 'var(--cod-gray)', ...props }: IconPropsType) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 25 25" {...props}>
    <path
      fill={fill}
      d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499z"
    />
  </svg>
);
export default ArrowEastIcon;
