import { useEffect, useState } from 'react';
import { useStep } from '@point-of-sale/hooks';
import { RunwayLogo, Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';
import { isEmptyObject } from '@point-of-sale/utils';
import InputCredentials from './InputCredentials';
import InputOtp from './InputOtp';
import InputSalesChannel from './InputSalesChannel';
import * as Styles from './styles';

const Login = () => {
  const isLoggedIn = useAppSelector(store => Boolean(store.identity.token));

  const [currentStep, { setStep }] = useStep(3);

  const [email, setEmail] = useState('');

  useEffect(() => {
    if (isLoggedIn) {
      setStep(3);
    }
  }, [isLoggedIn]);

  return (
    <Styles.Wrapper>
      <Styles.Left>
        <RunwayLogo />
      </Styles.Left>
      <Styles.Right>
        <Styles.RightContent>
          <Styles.Heading>
            <Text fontSize={20} weight="bold" color="var(--cod-gray)">
              Staff Login
            </Text>
          </Styles.Heading>

          {currentStep === 1 && (
            <InputCredentials setStep={setStep} email={email} setEmail={setEmail} />
          )}

          {currentStep === 2 && <InputOtp email={email} setStep={setStep} />}
          {currentStep === 3 && <InputSalesChannel />}
        </Styles.RightContent>
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default Login;
