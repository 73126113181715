import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { onForegroundFCMMessage } from '../index';

export const useForegroundFirebaseMessaging = () => {
  useEffect(() => {
    const setupMessaging = async () => {
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker.ready;

        const unsubscribe = onForegroundFCMMessage(payload => {
          if ('Notification' in window && Notification.permission === 'granted') {
            new Notification(payload.data?.title ?? 'New message received', {
              body: payload.data?.body,
            });
          } else {
            toast(payload.data?.message ?? 'New message received');
          }
        });
        return unsubscribe;
      } else {
        console.warn('Service Worker is not supported in this browser.');
        return () => {
          //
        };
      }
    };

    let unsubscribe: () => void | undefined;

    setupMessaging()
      .then(unsub => {
        // toast('Foreground Firebase Messaging setup complete');
        unsubscribe = unsub;
      })
      .catch(error => {
        // toast.error('Foreground Firebase Messaging setup failed');
        console.error('Foreground Firebase Messaging setup failed:', error);
      });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);
};
