import { Outlet } from 'react-router';
import Navigation from '../Navigation';
import * as Styles from './styles';

const Layout = () => {
  return (
    <Styles.Wrapper>
      <Navigation />
      <Outlet />
    </Styles.Wrapper>
  );
};

export default Layout;
