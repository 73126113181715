import { useCallback, useEffect } from 'react';
import { Button } from '@point-of-sale/components';
import {
  searchProducts,
  setMode,
  setSearch,
  useAppDispatch,
  useAppSelector,
  PosFilterNames,
  setPosPagination,
  SearchProductsUsage,
} from '@point-of-sale/store';
import { useDebounce } from '@point-of-sale/hooks';
import CustomerInfo from './CustomerInfo';
import SelectedFilters from './SelectedFilters';
import Discovery from '../Discovery';
import { ScanditModal } from '@runway/barcode-scanner';
import { getRootNode } from '@point-of-sale/utils';
import { useProductDiscoveryFilter } from '../../../hooks/useProductDiscoveryFilter';
import { useProductDiscovery } from '../../../hooks/useProductDiscovery';
import * as Styles from './styles';

interface IProductDiscoveryProps {
  usage: SearchProductsUsage;
}

const ProductDiscovery = ({ usage }: IProductDiscoveryProps) => {
  const pointOfSaleMode = useAppSelector(store => store.pointOfSale.mode);
  const search = useAppSelector(store => store.pointOfSale.search);
  const filters = useAppSelector(store => store.pointOfSale.filters);
  const isLoadingProducts = useAppSelector(store => store.pointOfSale.products.isLoading);

  const dispatch = useAppDispatch();

  const { onChangeSearch, onChangeFilter, onRemoveFilter } = useProductDiscovery({ usage });

  const debouncedApiCall = useDebounce(() => dispatch(searchProducts(usage)), 300);

  useEffect(() => {
    debouncedApiCall.implementation();
  }, [filters, debouncedApiCall]);

  useEffect(() => {
    return () => {
      dispatch(
        setPosPagination({
          page: 0,
          shouldFetchMore: true,
        })
      );
      dispatch(
        setSearch({
          query: '',
        })
      );
    };
  }, []);

  const mode = useAppSelector(store => store.pointOfSale.mode);

  useEffect(() => {
    if (usage !== 'POS') {
      return;
    }

    function setRootBackground(
      color: 'var(--alice-blue)' | 'var(--athens-gray)' | 'var(--white-lilac)'
    ) {
      getRootNode().style.background = color;
    }

    if (mode === 'BUY_NOW') {
      setRootBackground('var(--alice-blue)');
    } else if (mode === 'MADE_TO_ORDER') {
      setRootBackground('var(--white-lilac)');
    } else {
      setRootBackground('var(--athens-gray)');
    }

    return () => {
      setRootBackground('var(--athens-gray)');
    };
  }, [mode]);

  const { discoveryFilters } = useProductDiscoveryFilter();

  const handleScanditChange = useCallback(
    (value: string) => {
      if (value === search.query) {
        return;
      }

      onChangeSearch(value);
    },
    [search.query]
  );

  return (
    <Styles.Wrapper>
      {usage === 'POS' && (
        <Styles.Row>
          <Styles.ModeSwitchContainer>
            <Button
              sizeVariant="large"
              onClick={() => dispatch(setMode('BUY_NOW'))}
              colorScheme={pointOfSaleMode === 'BUY_NOW' ? 'primary' : 'tertiary'}
            >
              Buy Now
            </Button>
            <Button
              sizeVariant="large"
              onClick={() => dispatch(setMode('MADE_TO_ORDER'))}
              colorScheme={pointOfSaleMode === 'MADE_TO_ORDER' ? 'secondary' : 'tertiary'}
            >
              Made To Order
            </Button>
          </Styles.ModeSwitchContainer>
          <Styles.Right>
            <CustomerInfo />
          </Styles.Right>
        </Styles.Row>
      )}

      <Discovery
        filters={discoveryFilters}
        isLoading={isLoadingProducts}
        searchQuery={search.query}
        // onSearchChange={onChangeSearch}
        onPressEnter={onChangeSearch}
        onFilterChange={onChangeFilter}
        onFilterClear={filterName => onRemoveFilter(filterName as PosFilterNames)}
        renderSelectedFilters={() => <SelectedFilters onChangeFilter={onChangeFilter} />}
        placeholder="Search by name or scan"
        customInput={<ScanditModal onChange={handleScanditChange} />}
      />
    </Styles.Wrapper>
  );
};

export default ProductDiscovery;
