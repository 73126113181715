import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* align-items: center; */

  /* border: 1px solid red; */
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  /* border: 1px solid green; */
`;

export const Left = styled.div`
  /* border: 1px solid blue; */
`;

export const Right = styled.div`
  display: flex;
  gap: 4px;
`;
