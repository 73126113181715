import { Sheet } from 'react-modal-sheet';
import { useBoolean } from '@point-of-sale/hooks';
import { Button, IconButton, Text } from '@point-of-sale/components';
import { emptyFunction } from '@point-of-sale/utils';
import FilterNameContainer from './FilterNameContainer';
import FilterValueContainer from './FilterValueContainer';
import * as Styles from './styles';

interface IFiltersProps {
  onClearAll: CallableFunction;
  onApply: CallableFunction;
}

const Filters = ({ onApply, onClearAll }: IFiltersProps) => {
  const [isBottomSheetOpen, bottomSheetActions] = useBoolean();

  return (
    <>
      <IconButton icon="FilterIcon" sizeVariant="large" onClick={bottomSheetActions.on} />
      <Sheet
        rootId="root"
        snapPoints={[0.75]}
        isOpen={isBottomSheetOpen}
        onClose={bottomSheetActions.off}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Header>
            <Styles.HeaderContainer>
              <Text weight="semibold" fontSize={18}>
                Filters
              </Text>
              <IconButton icon="CrossIcon" shapeVariant="circle" onClick={bottomSheetActions.off} />
            </Styles.HeaderContainer>
          </Sheet.Header>
          <Sheet.Content>
            <Styles.ContentWrapper>
              <Styles.ContentBody>
                <Styles.Wrapper>
                  <Styles.Left>
                    <FilterNameContainer />
                  </Styles.Left>
                  <Styles.Right>
                    <FilterValueContainer />
                  </Styles.Right>
                </Styles.Wrapper>
              </Styles.ContentBody>
              <Styles.BottomSheetFooter>
                <Button
                  onClick={() => {
                    onClearAll();
                  }}
                  variant="outline"
                  isFullWidth
                >
                  Clear All
                </Button>
                <Button
                  onClick={() => {
                    onApply();
                    bottomSheetActions.off();
                  }}
                  isFullWidth
                >
                  Apply
                </Button>
              </Styles.BottomSheetFooter>
            </Styles.ContentWrapper>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={emptyFunction} />
      </Sheet>
    </>
  );
};

export default Filters;
