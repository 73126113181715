import { useState, useRef } from 'react';
import { z } from 'zod';
import toast from 'react-hot-toast';
import {
  FacilityDTOType,
  INormalizedServiceabilityData,
  ServiceabilityDTOSchema,
  ServiceabilityDTOType,
} from '@point-of-sale/schemas';
import {
  getNormalizedServiceabilityData,
  getServiceabilityApi,
  setServiceabilityForCart,
  useAppDispatch,
} from '@point-of-sale/store';
import { ApiResponseEnum } from '@point-of-sale/types';
import { memoizedApiResponseHandler } from '@point-of-sale/utils';
import { useBoolean } from '@point-of-sale/hooks';

const useGetServiceability = () => {
  const isFetchingRef = useRef(false);

  const [isFetching, fetchingActions] = useBoolean();

  const dispatch = useAppDispatch();

  const [data, setData] = useState<INormalizedServiceabilityData>({});

  const fetchServiceabilityData = async (
    skuCodes: Array<string>,
    destinationPincodes: Array<string>,
    sourceFacilities: Array<FacilityDTOType>,
    {
      successCallback,
    }: {
      successCallback?: (result: INormalizedServiceabilityData) => void;
    }
  ) => {
    if (
      skuCodes?.filter(Boolean).length === 0 ||
      destinationPincodes?.filter(Boolean).length === 0 ||
      sourceFacilities?.filter(Boolean).length === 0
    ) {
      return { ids: [], records: {} };
    }

    if (isFetchingRef.current) {
      return { ids: [], records: {} };
    }

    fetchingActions.on();

    const payload = {
      skuCodes,
      destinationPincodes,
      sourceFacilities,
    };

    // const promise = getServiceabilityApi(payload);
    // const response = await apiResponseHandler<Array<ServiceabilityDTOType>>(
    //   promise,
    //   z.array(ServiceabilityDTOSchema)
    // );
    const response = await memoizedApiResponseHandler<Array<ServiceabilityDTOType>>({
      apiPromiseFactory: () => getServiceabilityApi(payload),
      schema: z.array(ServiceabilityDTOSchema),
      payload,
      url: 'getServiceability',
      cacheDurationMs: 1000 * 60 * 5, // * 5 minutes
    });

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Serviceability data could not be fetched');
      fetchingActions.off();
    } else {
      const normalizedData = getNormalizedServiceabilityData(response.data);
      successCallback?.(normalizedData);
      dispatch(setServiceabilityForCart(normalizedData));
      setData(normalizedData);
    }

    // PERSISTENT_FLAGS.isLoading = false;
    fetchingActions.off();
  };

  return {
    serviceabilityData: data,
    fetchServiceabilityData,
    isFetchingServiceability: isFetching,
  };
};

export default useGetServiceability;
