import { OpenCartsActionsTypes } from './constants';
import { OpenCartsActionsType, IOpenCartsState } from './types';
import { SMALL_PAGINATION_SIZE } from '../constants';
import { createDescribedState } from '../utils/createDescribedState';

const INITIAL_STATE: IOpenCartsState = {
  search: {
    query: '',
  },

  carts: createDescribedState({
    ids: [],
    records: {},
  }),

  pagination: {
    page: 0,
    size: SMALL_PAGINATION_SIZE,
    shouldFetchMore: true,
  },
} as const;

export const openCartsReducer = (
  state = INITIAL_STATE,
  action: OpenCartsActionsType
): IOpenCartsState => {
  switch (action.type) {
    case OpenCartsActionsTypes.SET_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    }

    case OpenCartsActionsTypes.SET_CARTS: {
      return {
        ...state,
        carts: {
          ...state.carts,
          ...action.payload,
        },
      };
    }

    case OpenCartsActionsTypes.SET_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }

    case OpenCartsActionsTypes.RESET_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
