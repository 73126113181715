import Text from '../Typography/Text';
import * as Styles from './styles';

const SelectAllCheckbox = ({
  onChange,
  isChecked,
}: {
  onChange: () => void;
  isChecked: boolean;
}) => (
  <Styles.OptionCheckbox
    key="select-all"
    name="select-all"
    onChange={onChange}
    isChecked={isChecked}
    label={
      <Text fontSize={14} weight="bold" color="var(--cod-gray)" textTransform="uppercase">
        Select All
      </Text>
    }
  />
);

export default SelectAllCheckbox;
