import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LineItemDTOType, OrderDTOType } from '@point-of-sale/schemas';
import {
  getOrderDetailsById,
  setOrderDetails,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { isEmptyObject } from '@point-of-sale/utils';
import { useBoolean } from '@point-of-sale/hooks';
import { RackLottie } from '@runway/illustrations';
import LineItem from './LineItem';
import { IOrderDetailsContext, OrderDetailsContext } from './context';
import { OperationType } from './types';
import ReturnDetails from './ReturnDetails';
import CancellationDetails from './CancellationDetails';
import BasicDetails from './BasicDetails';
import RefundReceiptGenerationModal from './RefundReceiptGenerationModal';
import OperationSwitch from './OperationSwitch';
import OrderCustomerDetails from './OrderCustomerDetails';
import * as Styles from './styles';

type ParamsType = {
  id: string;
};

const OrderDetails = () => {
  const { id } = useParams<ParamsType>();

  const isLoading = useAppSelector(state => state.oms.details.isLoading);
  const data = useAppSelector(state => state.oms.details.data);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate('/orders');
      return;
    }

    if (isEmptyObject(data) || id !== String(data.id)) {
      dispatch(getOrderDetailsById(id));
      return;
    }
  }, [id, data]);

  const [isModalOpen, modalOpenActions] = useBoolean();

  // * For context
  // TODO: Maybe move these to a separate file
  const [operation, setOperation] = useState<OperationType>('READ_ONLY');
  const [lineItemsSelection, setLineItemsSelection] = useState<
    IOrderDetailsContext['lineItemsSelection']
  >({});
  const [reasons, setReasons] = useState({});
  const [specificReasons, setSpecificReasons] = useState({});

  const onToggleLineItemSelection = (lineItemId: number) => {
    setLineItemsSelection(prevState => ({
      ...prevState,
      [lineItemId]: !prevState[lineItemId],
    }));
  };

  const onReasonChange = (lineItemId: number, reason: string) => {
    if (operation === 'CANCELLATION') {
      const allIds = Object.keys(lineItemsSelection).map(Number);

      const newReasons = allIds.reduce((acc: Record<number, string>, curr) => {
        acc[curr] = reason;
        return acc;
      }, {});

      setReasons(newReasons);
      return;
    }

    setReasons(prevState => ({
      ...prevState,
      [lineItemId]: reason,
    }));
  };

  const onSpecificReasonChange = (lineItemId: number, reason: string) => {
    // sync for cancellations
    // individual for returns and exchanges

    if (operation === 'CANCELLATION') {
      const allIds = Object.keys(lineItemsSelection).map(Number);

      const newReasons = allIds.reduce((acc: Record<number, string>, curr) => {
        acc[curr] = reason;
        return acc;
      }, {});

      setSpecificReasons(newReasons);
      return;
    }

    setSpecificReasons(prevState => ({
      ...prevState,
      [lineItemId]: reason,
    }));
  };

  const resetReasonsAndSelections = useCallback(() => {
    if (data.lineItems) {
      const lineItemsSelection: Record<number, boolean> = {};
      const returnReasons: Record<number, string> = {};
      const specificReasons: Record<number, string> = {};
      data.lineItems.forEach(item => {
        lineItemsSelection[item.id] = false;
        returnReasons[item.id] = '';
        specificReasons[item.id] = '';
      });

      setLineItemsSelection(lineItemsSelection);
      setReasons(returnReasons);
      setSpecificReasons(specificReasons);
    }
  }, [data.lineItems]);

  useEffect(() => {
    resetReasonsAndSelections();
  }, [resetReasonsAndSelections]);

  const [pdfData, setPdfData] = useState<Array<LineItemDTOType>>([]);

  useEffect(() => {
    return () => {
      setPdfData([]);

      dispatch(
        setOrderDetails({
          data: {} as OrderDTOType,
        })
      );
    };
  }, []);

  const lineItems = data.lineItems
    ? structuredClone(data.lineItems).sort((a, b) => a.id - b.id)
    : [];

  return (
    <OrderDetailsContext.Provider
      value={{
        operation,
        lineItemsSelection,
        onToggleLineItemSelection,
        reasons,
        onReasonChange,
        specificReasons,
        onSpecificReasonChange,
      }}
    >
      <Styles.Wrapper>
        <Styles.Content>
          <Styles.Top>
            <BasicDetails data={data} />
          </Styles.Top>
          <Styles.Bottom>
            <Styles.Left>
              <OperationSwitch
                data={data}
                operation={operation}
                setOperation={setOperation}
                resetReasonsAndSelections={resetReasonsAndSelections}
                // setReasons={setReasons}
                // setLineItemsSelection={setLineItemsSelection}
              />
              <Styles.LineItemsContainer>
                {lineItems?.map(item => (
                  <LineItem key={item.id} data={item} />
                ))}
              </Styles.LineItemsContainer>
            </Styles.Left>
            <Styles.Right>
              {operation === 'RETURNS_AND_EXCHANGES' && (
                <ReturnDetails
                  data={data}
                  setPdfData={dataToPrint => {
                    setPdfData(dataToPrint);
                    modalOpenActions.on();
                  }}
                />
              )}
              {operation === 'CANCELLATION' && (
                <CancellationDetails
                  data={data}
                  setPdfData={dataToPrint => {
                    setPdfData(dataToPrint);
                    modalOpenActions.on();
                  }}
                />
              )}
              <OrderCustomerDetails data={data} />
              <RefundReceiptGenerationModal
                orderData={data}
                pdfData={pdfData}
                isOpen={isModalOpen}
                onClose={modalOpenActions.off}
              />
            </Styles.Right>
          </Styles.Bottom>

          {isLoading && (
            <Styles.LoaderWrapper>
              <RackLottie height={300} width={300} />
            </Styles.LoaderWrapper>
          )}
        </Styles.Content>
      </Styles.Wrapper>
    </OrderDetailsContext.Provider>
  );
};

export default OrderDetails;
