export const mockNotifications = [
  {
    id: '1',
    title: 'New Order Received',
    body: 'You have received a new order #12345 worth $150.00',
    created_on: Date.now() - 1000 * 60 * 5, // 5 minutes ago
    is_read: false,
    is_seen: false,
    image: 'https://picsum.photos/48/48?random=1',
    deeplink: '/orders/12345',
  },
  {
    id: '2',
    title: 'Payment Successful',
    body: 'Payment of $89.99 has been received for order #12344',
    created_on: Date.now() - 1000 * 60 * 60, // 1 hour ago
    is_read: true,
    is_seen: true,
    deeplink: '/payments/89933',
  },
  {
    id: '3',
    title: 'Low Stock Alert',
    body: 'Product "Organic Coffee Beans" is running low on stock (5 units remaining)',
    created_on: Date.now() - 1000 * 60 * 60 * 2, // 2 hours ago
    is_read: false,
    is_seen: true,
    image: 'https://picsum.photos/48/48?random=3',
    deeplink: '/inventory/coffee-beans',
  },
  {
    id: '4',
    title: 'Daily Sales Report',
    body: 'Your store generated $1,250 in sales today. View detailed report.',
    created_on: Date.now() - 1000 * 60 * 60 * 24, // 1 day ago
    is_read: true,
    is_seen: true,
    deeplink: '/reports/daily',
  },
  {
    id: '5',
    title: 'New Feature Available',
    body: 'Check out our new inventory management system. Click to learn more.',
    created_on: Date.now() - 1000 * 60 * 60 * 24 * 2, // 2 days ago
    is_read: false,
    is_seen: false,
    image: 'https://picsum.photos/48/48?random=5',
    deeplink: '/whats-new',
  },
  {
    id: '6',
    title: 'Customer Review',
    body: 'New 5-star review received from John Doe',
    created_on: Date.now() - 1000 * 60 * 60 * 24 * 3, // 3 days ago
    is_read: true,
    is_seen: true,
    image: 'https://picsum.photos/48/48?random=6',
    deeplink: '/reviews',
  },
  {
    id: '7',
    title: 'System Maintenance',
    body: 'Scheduled maintenance on July 1st at 2 AM EST',
    created_on: Date.now() - 1000 * 60 * 60 * 24 * 4, // 4 days ago
    is_read: true,
    is_seen: true,
    deeplink: null,
  },
  {
    id: '8',
    title: 'Price Update Required',
    body: '3 products need price updates due to supplier changes',
    created_on: Date.now() - 1000 * 60 * 60 * 24 * 5, // 5 days ago
    is_read: false,
    is_seen: true,
    deeplink: '/inventory/price-updates',
  },
  {
    id: '9',
    title: 'Staff Meeting',
    body: 'Monthly staff meeting scheduled for next Monday at 10 AM',
    created_on: Date.now() - 1000 * 60 * 60 * 24 * 6, // 6 days ago
    is_read: true,
    is_seen: true,
    image: 'https://picsum.photos/48/48?random=9',
    deeplink: '/calendar',
  },
  {
    id: '10',
    title: 'Backup Completed',
    body: 'Weekly data backup completed successfully',
    created_on: Date.now() - 1000 * 60 * 60 * 24 * 7, // 7 days ago
    is_read: true,
    is_seen: true,
    deeplink: null,
  },
];
