import { PendingTasksActionsTypes } from './constants';
import { PendingTasksActionsType, IPendingTasksState } from './types';
import { STANDARD_PAGINATION_SIZE } from '../constants';
import { createDescribedState } from '../utils/createDescribedState';

const INITIAL_STATE: IPendingTasksState = {
  search: {
    query: '',
  },

  storePickups: createDescribedState({
    ids: [],
    records: {},
  }),

  storePickupsPagination: {
    page: 0,
    size: STANDARD_PAGINATION_SIZE,
    shouldFetchMore: true,
  },

  customizationRemarks: createDescribedState({
    ids: [],
    records: {},
  }),

  customizationRemarksPagination: {
    page: 0,
    size: STANDARD_PAGINATION_SIZE,
    shouldFetchMore: true,
  },

  paymentOptions: createDescribedState({
    ids: [],
    records: {},
  }),
} as const;

export const pendingTasksReducer = (
  state = INITIAL_STATE,
  action: PendingTasksActionsType
): IPendingTasksState => {
  switch (action.type) {
    case PendingTasksActionsTypes.SET_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    }

    case PendingTasksActionsTypes.SET_STORE_PICKUPS: {
      return {
        ...state,
        storePickups: {
          ...state.storePickups,
          ...action.payload,
        },
      };
    }

    case PendingTasksActionsTypes.SET_STORE_PICKUPS_PAGINATION: {
      return {
        ...state,
        storePickupsPagination: {
          ...state.storePickupsPagination,
          ...action.payload,
        },
      };
    }

    case PendingTasksActionsTypes.SET_CUSTOMIZATION_REMARKS: {
      return {
        ...state,
        customizationRemarks: {
          ...state.customizationRemarks,
          ...action.payload,
        },
      };
    }

    case PendingTasksActionsTypes.SET_CUSTOMIZATION_REMARKS_PAGINATION: {
      return {
        ...state,
        customizationRemarksPagination: {
          ...state.customizationRemarksPagination,
          ...action.payload,
        },
      };
    }

    case PendingTasksActionsTypes.SET_PAYMENT_OPTIONS: {
      return {
        ...state,
        paymentOptions: {
          ...state.paymentOptions,
          ...action.payload,
        },
      };
    }

    case PendingTasksActionsTypes.RESET_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
