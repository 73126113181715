import { SkuMovementDTOType } from '@point-of-sale/schemas';
import { SkuMovementActionsTypes } from './constants';
import { ISkuMovementsState } from './types';

export const setSkuMovementSearch = (payload: Partial<ISkuMovementsState['search']>) => ({
  type: SkuMovementActionsTypes.SET_SEARCH,
  payload,
});

export const setStockKeepingUnits = (
  payload: Partial<ISkuMovementsState['stockKeepingUnits']>
) => ({
  type: SkuMovementActionsTypes.SET_STOCK_KEEPING_UNITS,
  payload,
});

export const addStockKeepingUnit = (payload: SkuMovementDTOType) => ({
  type: SkuMovementActionsTypes.ADD_STOCK_KEEPING_UNIT,
  payload,
});

export const removeStockKeepingUnit = (payload: number) => ({
  type: SkuMovementActionsTypes.REMOVE_STOCK_KEEPING_UNIT,
  payload,
});

export const addRecentlyAddedSkuMovement = (payload: number) => ({
  type: SkuMovementActionsTypes.ADD_RECENTLY_ADDED_ID,
  payload,
});

export const setSkuMovementsPagination = (payload: Partial<ISkuMovementsState['pagination']>) => ({
  type: SkuMovementActionsTypes.SET_PAGINATION,
  payload,
});

export const resetSkuMovementsState = () => ({
  type: SkuMovementActionsTypes.RESET_STATE,
});
