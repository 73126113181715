import { LineItemDTOType } from '@point-of-sale/schemas';
import { IStepItem } from '@point-of-sale/components';
// import { formatToReadableDate } from '@point-of-sale/utils';

export function getReturnEntrySteps(lineItem: LineItemDTOType): Array<IStepItem> {
  if (!lineItem.returnEntry || !lineItem.returnEntry.status) {
    return [];
  }

  const currentStatus = lineItem.returnEntry.status;

  // Base steps in order
  const steps: Array<IStepItem> = [
    {
      title: 'Return initiated',
      status: 'INCIPIENT',
      // subTitle:
      //   currentStatus === 'CREATED'
      //     ? formatToReadableDate(lineItem.returnEntry.createdOn)
      //     : undefined,
    },
    {
      title: 'Pickup initiated',
      status: 'INCIPIENT',
    },
    {
      title: 'Out for pickup',
      status: 'INCIPIENT',
    },
    {
      title: 'Picked up',
      status: 'INCIPIENT',
    },
    {
      title: 'Delivered to warehouse',
      status: 'INCIPIENT',
    },
  ];

  // Status to index mapping for determining completed steps
  const statusToIndex: Record<string, number> = {
    CREATED: 0,
    PICKUP_INITIATED: 1,
    OUT_FOR_PICKUP: 2,
    PICKED_UP: 3,
    DELIVERED_TO_WAREHOUSE: 4,
  };

  // Handle normal flow
  if (currentStatus in statusToIndex) {
    const currentIndex = statusToIndex[currentStatus];

    // Mark all previous steps as completed and current step as in progress
    steps.forEach((step, index) => {
      if (index < currentIndex) {
        step.status = 'COMPLETED';
      } else if (index === currentIndex) {
        step.status = 'IN_PROGRESS';
      }
    });
  }

  // Special handling for direct completion states
  if (currentStatus === 'PICKED_UP' || currentStatus === 'DELIVERED_TO_WAREHOUSE') {
    const finalIndex = statusToIndex[currentStatus];
    steps.forEach((step, index) => {
      if (index <= finalIndex) {
        step.status = 'COMPLETED';
      }
    });
  }

  // Handle failure cases
  if (currentStatus === 'PICKUP_FAILED') {
    // Mark steps up to OUT_FOR_PICKUP as completed
    steps.forEach((step, index) => {
      if (index <= statusToIndex['OUT_FOR_PICKUP']) {
        step.status = 'COMPLETED';
      }
    });

    // Add failure step
    steps.push({
      title: 'Pickup failed',
      status: 'COMPLETED',
      // subTitle: formatToReadableDate(lineItem.returnEntry.updatedOn),
    });
  }

  // Handle cancellation
  if (currentStatus === 'CANCELLED') {
    // Add cancellation as the final step
    return [
      {
        title: 'Return cancelled',
        status: 'COMPLETED',
        // subTitle: formatToReadableDate(lineItem.returnEntry.updatedOn),
      },
    ];
  }

  return steps;
}
