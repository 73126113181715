import { useState } from 'react';
import { WithHeader } from '@point-of-sale/components';
import { SettingsContext } from './context';
import Controls from './Controls';
import Display from './Display';
import * as Styles from './styles';

const Settings = () => {
  const [selectedSection, setSelectedSection] = useState('ACCOUNT');
  const [selectedSubsection, setSelectedSubsection] = useState('ABOUT_ME');

  return (
    <SettingsContext.Provider
      value={{
        selectedSection,
        selectedSubsection,
        setSelectedSection,
        setSelectedSubsection,
      }}
    >
      <Styles.Wrapper>
        <Styles.Left>
          <Controls />
        </Styles.Left>
        <Styles.Right>
          <Display />
        </Styles.Right>
      </Styles.Wrapper>
    </SettingsContext.Provider>
  );
};

export default WithHeader(Settings, {
  name: 'Settings',
});
