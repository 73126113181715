import { useState } from 'react';
import toast from 'react-hot-toast';
import { createSearchParams, useNavigate } from 'react-router';
import { Button, RadioGroup, Skeleton, Text } from '@point-of-sale/components';
import { PHONE_REGEX } from '@point-of-sale/constants';
import { useBoolean } from '@point-of-sale/hooks';
import {
  initializeCart,
  resetCheckoutState,
  resetPointOfSaleState,
  searchCustomer,
  setAddressesData,
  setCustomerDetails,
  setSourceForOnlinePos,
  useAppDispatch,
  useAppSelector,
  useIsSelectedSalesChannelOnline,
} from '@point-of-sale/store';
import { SourceForOnlinePosEnum, SourceForOnlinePosEnumType } from '@point-of-sale/schemas';
import PhoneNumberInput from '../../../../components/desktop/PhoneNumberInput';
import * as Styles from './styles';

interface IPhoneOrEmailProps {
  setStep: (step: number) => void;
}

const PhoneOrEmail = ({ setStep }: IPhoneOrEmailProps) => {
  const [inputValue, setInputValue] = useState('');
  const [countryCode, setCountryCode] = useState('+91');

  const sourceForOnlinePos = useAppSelector(store => store.pointOfSale.sourceForOnlinePos);

  const isLoading = useAppSelector(store => store.pointOfSale.customer.isLoading);

  const { isSelectedSalesChannelOnline, isLoadingFacilities } = useIsSelectedSalesChannelOnline();

  const [isGuestCheckoutLoading, guestCheckoutLoadingActions] = useBoolean();

  const [areCTAsEnabled, ctasEnabledActions] = useBoolean();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  function onChange(value: string) {
    setInputValue(value);

    if (PHONE_REGEX.test(value)) {
      if (countryCode.length > 0) {
        ctasEnabledActions.on();
      } else {
        ctasEnabledActions.off();
      }

      if (value.length !== 10) {
        ctasEnabledActions.off();
      }
    }
  }

  function handleContinue() {
    if (!areCTAsEnabled) {
      toast.error('Provide valid phone number to proceed', {
        duration: 2000,
      });
      return;
    }

    if (isSelectedSalesChannelOnline && !sourceForOnlinePos) {
      toast.error('Select source to proceed', {
        duration: 1000,
      });
      return;
    }

    function successCallback() {
      setStep(2);
    }

    dispatch(
      searchCustomer({
        searchParams: {
          phoneNumber: inputValue,
          countryCode,
        },
        callbacks: {
          onComplete: successCallback,
        },
      })
    );
  }

  function handleGuestCheckout() {
    function successCallback(cartId: string) {
      const params = createSearchParams({
        id: cartId,
      });

      guestCheckoutLoadingActions.off();

      navigate(`/point-of-sale/cart?${params.toString()}`);
    }

    guestCheckoutLoadingActions.on();

    dispatch(
      setCustomerDetails({
        data: {},
        isLoading: false,
        isSuccess: true,
      })
    );
    dispatch(
      setAddressesData({
        data: {
          ids: [],
          records: {},
        },
        isLoading: false,
        isSuccess: true,
      })
    );
    dispatch(resetPointOfSaleState());
    dispatch(resetCheckoutState());

    dispatch(initializeCart(successCallback));
  }

  return (
    <Styles.Wrapper>
      <PhoneNumberInput
        countryCode={countryCode}
        inputValue={inputValue}
        onChange={onChange}
        setCountryCode={setCountryCode}
        hasMarginBottom={!isSelectedSalesChannelOnline}
      />

      {isSelectedSalesChannelOnline && (
        <Styles.SourceInputWrapper>
          <Text fontSize={14} color="var(--dove-gray)">
            Source
          </Text>
          <RadioGroup
            horizontal
            name="refund-mode"
            options={[
              {
                label: 'Try at Home',
                value: SourceForOnlinePosEnum.Enum.TRY_AT_HOME,
              },
              {
                label: 'Whatsapp',
                value: SourceForOnlinePosEnum.Enum.WHATSAPP,
              },
              {
                label: 'Video Call',
                value: SourceForOnlinePosEnum.Enum.VIDEO_CALL,
              },
              {
                label: 'Abandoned Cart',
                value: SourceForOnlinePosEnum.Enum.ABANDONED_CART,
              },
            ]}
            selectedValue={sourceForOnlinePos ?? ''}
            onChange={selectedValue => {
              dispatch(setSourceForOnlinePos(selectedValue as SourceForOnlinePosEnumType));
            }}
          />
        </Styles.SourceInputWrapper>
      )}

      {isLoadingFacilities ? (
        <>
          <Skeleton height="40" width={300} />
          <Skeleton height="40" width={300} />
        </>
      ) : (
        <>
          <Button colorScheme="dark" onClick={handleContinue} isLoading={isLoading}>
            CONTINUE
          </Button>

          {!isSelectedSalesChannelOnline && (
            <>
              <Text textAlign="center">or</Text>
              <Button
                colorScheme="dark"
                variant="outline"
                onClick={handleGuestCheckout}
                isLoading={isGuestCheckoutLoading}
              >
                GUEST CHECKOUT
              </Button>
            </>
          )}
        </>
      )}
    </Styles.Wrapper>
  );
};

export default PhoneOrEmail;
