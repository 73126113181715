import { useEffect, useRef } from 'react';
import { Sheet } from 'react-modal-sheet';
import { IconButton } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import { emptyFunction } from '@point-of-sale/utils';
import { ZbarBarcodeScanner } from '@lyskraft/zbar-sdk';

interface IScannerProps {
  onChange: (value: string) => void;
}

export const ScanditBottomSheet = ({ onChange }: IScannerProps) => {
  const [isBottomSheetOpen, bottomSheetActions] = useBoolean();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const scanner = ZbarBarcodeScanner.getInstance();

  useEffect(() => {
    if (isBottomSheetOpen && containerRef.current) {
      scanner.subscribe('scan', (barcode: string) => {
        onChange(barcode);
        bottomSheetActions.off();
      });

      scanner.run(containerRef.current);
    }

    if (!isBottomSheetOpen) {
      scanner.unsubscribe('scan');
      scanner.stopScanning();
    }
  }, [isBottomSheetOpen]);

  return (
    <>
      <IconButton icon="ScannerIcon" sizeVariant="large" onClick={bottomSheetActions.on} />
      <Sheet
        rootId="root"
        snapPoints={[0.75]}
        isOpen={isBottomSheetOpen}
        onClose={bottomSheetActions.off}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div ref={containerRef} style={{ height: '100%', width: '100%' }} />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={emptyFunction} />
      </Sheet>
    </>
  );
};
