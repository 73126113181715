import { FirebaseActionsTypes } from './constants';
import { FirebaseActionsType, IFirebaseState } from './types';

const INITIAL_STATE: IFirebaseState = {
  isReleasePromptOpen: false,
  releases: {
    staging: {
      github_run_number: -1,
      timestamp: '',
    },
    production: {
      github_run_number: -1,
      timestamp: '',
    },
  },
} as const;

export const firebaseReducer = (
  state = INITIAL_STATE,
  action: FirebaseActionsType
): IFirebaseState => {
  switch (action.type) {
    case FirebaseActionsTypes.SET_IS_RELEASE_PROMPT_MODAL_OPEN: {
      return {
        ...state,
        isReleasePromptOpen: action.payload,
      };
    }

    case FirebaseActionsTypes.SET_RELEASE_DATA: {
      return {
        ...state,
        releases: {
          ...state.releases,
          [action.payload.environment]: {
            github_run_number: action.payload.github_run_number,
            timestamp: action.payload.timestamp,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};
