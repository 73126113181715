import { useState } from 'react';
import toast from 'react-hot-toast';
import { isMobileOnly } from 'react-device-detect';
import { Button, Input, Text } from '@point-of-sale/components';
import { getOtp, useAppDispatch } from '@point-of-sale/store';
import { EMAIL_REGEX } from '@point-of-sale/constants';
import { useBoolean } from '@point-of-sale/hooks';
import GoogleLoginButton from './GoogleLoginButton';
import * as Styles from './styles';

interface IInputCredentialsProps {
  setStep: (step: number) => void;
  email: string;
  setEmail: (email: string) => void;
}

const InputCredentials = ({ setStep, email, setEmail }: IInputCredentialsProps) => {
  const [isLoading, loadingActions] = useBoolean();

  const [error, setError] = useState('');

  const dispatch = useAppDispatch();

  function successCallback() {
    loadingActions.off();
    setStep(2);
  }

  return (
    <Styles.FormWrapper>
      <Input
        label="Staff Email"
        variant="outline"
        placeholder="Enter your email"
        className="login-input"
        name="email"
        sizeVariant={isMobileOnly ? 'medium' : 'small'}
        value={email}
        onChange={e => setEmail(e.target.value.toLowerCase())}
      />
      {error && <Text color="var(--alizarin-crimson)">{error}</Text>}
      <Button
        colorScheme="dark"
        className="big-button"
        isLoading={isLoading}
        sizeVariant={isMobileOnly ? 'large' : 'medium'}
        onClick={() => {
          if (!email) {
            toast.error('Please enter an email');
            return;
          }

          if (EMAIL_REGEX.test(email) === false) {
            toast.error('Please enter a valid email');
            return;
          }
          loadingActions.on();
          dispatch(
            getOtp(email, {
              successCallback,
              failureCallback: (message?: string) => {
                loadingActions.off();
                toast.error('Failed to get OTP');

                if (message) {
                  setError(`Cannot Proceed; Reason: ${message}`);
                }
              },
            })
          );
        }}
      >
        Get OTP
      </Button>

      {isMobileOnly && (
        <>
          <Text textAlign="center" fontSize={16}>
            OR
          </Text>
          <GoogleLoginButton setStep={setStep} />
        </>
      )}
    </Styles.FormWrapper>
  );
};

export default InputCredentials;
