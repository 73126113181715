import { LineItemDTOType } from '@point-of-sale/schemas';
import { Stepper, Text } from '@point-of-sale/components';
// import BrandStatusGraph from './BrandStatusGraph';
import * as Styles from './styles';

import { getStepperItems } from './utils';
import { getReturnEntrySteps } from './returnEntrySteps';

interface ILineItemStatusGraphProps {
  data: LineItemDTOType;
}

const LineItemStatusGraph = ({ data }: ILineItemStatusGraphProps) => {
  const steps = getStepperItems(data);
  const returnEntrySteps = getReturnEntrySteps(data);

  return (
    <Styles.Wrapper>
      <Text fontSize={12}>Status</Text>
      <Stepper items={[...steps, ...returnEntrySteps]} />
    </Styles.Wrapper>
  );
};

export default LineItemStatusGraph;
