import toast from 'react-hot-toast';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler } from '@point-of-sale/utils';
import { ThunkActionType } from '../store';
import { ApiResponseEnum } from '@point-of-sale/types';
import { setStockKeepingUnits, setSkuMovementsPagination } from './actions';
import { normalizeArray } from '../utils/normalizeArray';
import { SkuMovementsDTOSchema, SkuMovementsDTOType } from '@point-of-sale/schemas';
import { skuMovementSearchApi } from './api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';

export const getSkuMovements = (): ThunkActionType => async (dispatch, getState) => {
  const { page, size, shouldFetchMore } = getState().skuMovement.pagination;

  if (!shouldFetchMore) {
    return;
  }

  dispatch(
    setStockKeepingUnits({
      isLoading: true,
    })
  );

  const filterBuilder = new FilterBuilder();

  const selectedSalesChannel = getState().identity.selectedSalesChannel;

  filterBuilder.addFilter({
    filterType: 'EQ',
    field: 'movementType',
    value: 'STORE_TRIAL',
  });

  if (selectedSalesChannel?.facilityId) {
    filterBuilder.addFilter({
      filterType: 'EQ',
      field: 'toFacilityId',
      value: selectedSalesChannel?.facilityId,
    });
    filterBuilder.addFilter({
      filterType: 'EQ',
      field: 'fromFacilityId',
      value: selectedSalesChannel?.facilityId,
    });
  }

  const newPage = page + 1;

  const promise = skuMovementSearchApi({
    page: newPage,
    size: size,
    orderBy: 'createdOn',
    filters: filterBuilder.build(),
  });

  const response = await apiResponseHandler<SkuMovementsDTOType>(promise, SkuMovementsDTOSchema);

  if (response.type === ApiResponseEnum.Failure) {
    toast.error(response.meta?.message ?? 'Something went wrong');
    dispatch(
      setSkuMovementsPagination({
        shouldFetchMore: false,
      })
    );
    dispatch(
      setStockKeepingUnits({
        isLoading: false,
        isError: true,
      })
    );
    return;
  }

  const shouldAppend = newPage !== 1;

  let { ids, records } = normalizeArray(response.data);

  if (shouldAppend) {
    ids = [...getState().skuMovement.stockKeepingUnits.data.ids, ...ids];
    records = { ...getState().skuMovement.stockKeepingUnits.data.records, ...records };
  }

  dispatch(
    setStockKeepingUnits({
      data: {
        ids: Array.from(new Set(ids)),
        records,
      },
      isLoading: false,
      isSuccess: true,
    })
  );

  dispatch(
    setSkuMovementsPagination({
      page: newPage,
      shouldFetchMore: response.data.length !== 0,
    })
  );
};
