import toast from 'react-hot-toast';
import { ApiResponseEnum } from '@point-of-sale/types';
import { AddCartItemDTOType, CartDTOSchema, CartDTOType } from '@point-of-sale/schemas';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler, openInSameTab } from '@point-of-sale/utils';
import { ThunkActionType } from '../../store';
import {
  addToCart,
  deleteCartItem,
  setCartDetails,
  setCartItemQuantity,
  setSelectedAddressId,
  setSourceForOnlinePos,
} from '../actions';
import {
  addItemToCartApi,
  clearCartApi,
  getCartByIdApi,
  initializeCartApi,
  updateCartApi,
} from '../api';
import { normalizeArray } from '../../utils/normalizeArray';
import { searchCustomer } from './customerThunks';
import { getGstDetails } from './index';
import { selectSelectedSalesChannelOnlineStatus } from '../../common/selectors';

export const getCartById =
  (cartId: string, shouldNavigateAway = true): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const doesCartExist = getState().pointOfSale.cart.data.id !== -1;

    const promise = getCartByIdApi(cartId);

    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    if (shouldNavigateAway && !doesCartExist && response.data?.status === 'ORDER_PLACED') {
      openInSameTab('/point-of-sale/add-customer');
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    if (response.data.source) {
      dispatch(setSourceForOnlinePos(response.data.source));
    }

    const maybeCartItemWithCustomerDeliveryAddressId = response.data.cartItems.find(c =>
      Boolean(c.customerDeliveryAddressId)
    );

    if (maybeCartItemWithCustomerDeliveryAddressId?.customerDeliveryAddressId) {
      dispatch(
        setSelectedAddressId(maybeCartItemWithCustomerDeliveryAddressId.customerDeliveryAddressId)
      );
    }

    if (response.data.customerId) {
      dispatch(
        searchCustomer({
          searchParams: { customerId: String(response.data.customerId) },
        })
      );

      if (response.data.customerGSTIN) {
        dispatch(getGstDetails(response.data.customerGSTIN));
      }
    }
  };

export const initializeCart =
  (successCallback?: (cartId: string) => void): ThunkActionType =>
  async (dispatch, getState) => {
    const selectedSalesChannel = getState().identity.selectedSalesChannel;

    if (!selectedSalesChannel) {
      toast.error('Cannot initialize cart without a selected sales channel');
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const selectedAddressId = getState().pointOfSale.selectedAddressId;
    const selectedAddress = getState().pointOfSale.addresses.data.records[selectedAddressId];
    const customerGstData = getState().pointOfSale.customerGstDetails.data;

    const prematureCartData = getState().pointOfSale.cart.data;

    const { isSelectedSalesChannelOnline } = selectSelectedSalesChannelOnlineStatus(getState());

    const payload: Partial<CartDTOType> = {
      salesFacilityId: selectedSalesChannel.facilityId,
      customerPincode: selectedAddress?.pincode,
    };

    if (customerGstData.gstin) {
      payload.customerGSTIN = customerGstData.gstin;
    }

    if (prematureCartData.customerBillingAddressId) {
      payload.customerBillingAddressId = prematureCartData.customerBillingAddressId;
    }

    if (isSelectedSalesChannelOnline) {
      const sourceForOnlinePos = getState().pointOfSale.sourceForOnlinePos;

      if (!sourceForOnlinePos) {
        toast.error('Select source to continue', {
          duration: 1000,
        });

        dispatch(
          setCartDetails({
            isLoading: false,
          })
        );
        return;
      }

      payload.source = sourceForOnlinePos;
    }

    const promise = initializeCartApi(payload);

    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.(String(response.data.id));
  };

export const addItemToCart =
  (item: AddCartItemDTOType, successCallback?: CallableFunction): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const customerId = getState().pointOfSale.customer.data.id;

    const promise = addItemToCartApi({
      id: getState().pointOfSale.cart.data.id as number,
      item,
      customerId,
    });

    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      successCallback?.();
      return;
    }

    dispatch(addToCart(response.data.cartItems));

    const existingCart = getState().pointOfSale.cart.data;

    dispatch(
      setCartDetails({
        data: {
          ...existingCart,
          amountPaid: response.data.amountPaid,
          minAmountToBePaid: response.data.minAmountToBePaid,
          chargeableAmount: response.data.chargeableAmount,
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.();
  };

export const clearCartById =
  (cartId: string): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const promise = clearCartApi(cartId);

    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
        isLoading: false,
        isSuccess: true,
      })
    );
  };

export const updateQuantityOfCart =
  ({
    cartItemId,
    quantity,
    successCallback,
  }: {
    cartItemId: number;
    quantity: number;
    successCallback?: CallableFunction;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cartId = getState().pointOfSale.cart.data.id;

    const promise = updateCartApi(
      quantity === 0
        ? {
            cartId,
            cartItemId,
            operation: 'REMOVE',
          }
        : {
            cartId,
            cartItemId,
            quantity,
            operation: 'UPDATE_QUANTITY',
          }
    );

    const response = await apiResponseHandler<CartDTOType>(promise, CartDTOSchema);

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    if (quantity === 0) {
      dispatch(deleteCartItem({ cartItemId }));
    } else {
      dispatch(setCartItemQuantity({ cartItemId, quantity }));
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };
