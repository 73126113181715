import { format } from 'date-fns';
import { useAppSelector } from '@point-of-sale/store';
import { Button, IconButton, Modal, Text } from '@point-of-sale/components';
import { useBoolean } from '@point-of-sale/hooks';
import useDeleteSkuMovement from '../../hooks/useDeleteSkuMovement';
import * as Styles from './styles';

const FADE_UP_ANIMATION_VARIANTS = (index: number) => ({
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', delay: index * 0.1 },
  },
});

interface IStockKeepingUnitProps {
  // id: number;
  index: number;
  lastItemRef?: (node: HTMLElement | null) => void;
  style?: React.CSSProperties;
}

const StockKeepingUnit = ({ index, lastItemRef, style }: IStockKeepingUnitProps) => {
  const id = useAppSelector(store => store.skuMovement.stockKeepingUnits.data.ids[index]);
  const data = useAppSelector(store => store.skuMovement.stockKeepingUnits.data.records[id]);
  const shouldShowDeleteButton = useAppSelector(store =>
    store.skuMovement.recentlyAddedIds.includes(id)
  );

  const [isModalOpen, modalOpenActions] = useBoolean();

  const { deleteSkuMovement, error, isLoading } = useDeleteSkuMovement();

  if (!data) {
    return null;
  }

  return (
    <Styles.Wrapper
      ref={lastItemRef}
      // viewport={{ once: true }}
      // variants={FADE_UP_ANIMATION_VARIANTS(index)}
      style={{ ...style }}
    >
      <Styles.Left>
        <Text fontSize={16} textTransform="uppercase">
          SKU {data.skuCode}
        </Text>
        <Text fontSize={12} color="var(--dove-gray)">
          Tried on {format(data.createdOn, 'p, PP')}
        </Text>
      </Styles.Left>
      {shouldShowDeleteButton && (
        <Styles.Right>
          <IconButton
            sizeVariant="small"
            colorVariant="destructive"
            icon="DeleteIcon"
            onClick={modalOpenActions.on}
          />

          <Modal
            size="large"
            isOpen={isModalOpen}
            onClose={modalOpenActions.off}
            heading={
              <Text fontSize={14} weight="semibold" textTransform="uppercase">
                Are you sure ?
              </Text>
            }
            footer={
              <Styles.ModalFooterContainer>
                <Button
                  onClick={modalOpenActions.off}
                  sizeVariant="xs"
                  colorScheme="dark"
                  variant="outline"
                >
                  Close
                </Button>

                <Button
                  onClick={() => {
                    deleteSkuMovement(data.id, {
                      successCallback: modalOpenActions.off,
                    });
                  }}
                  sizeVariant="xs"
                  colorScheme="dark"
                  isLoading={isLoading}
                >
                  Yes, Remove
                </Button>
              </Styles.ModalFooterContainer>
            }
          >
            <Styles.ModalContent>
              {error ? (
                <Text fontSize={14} weight="semibold" color="var(--alizarin-crimson)">
                  Could not remove {data.skuCode.toUpperCase()}, because {error}
                </Text>
              ) : (
                <Text fontSize={14}>This action is irreversible</Text>
              )}
            </Styles.ModalContent>
          </Modal>
        </Styles.Right>
      )}
    </Styles.Wrapper>
  );
};

export default StockKeepingUnit;
