import { NotificationActionTypes } from './constants';
import { INotificationsState } from './types';

export const setNotifications = (payload: INotificationsState['notifications']) => ({
  type: NotificationActionTypes.SET_NOTIFICATIONS,
  payload,
});

export const setFcmToken = (payload: string) => ({
  type: NotificationActionTypes.SET_FCM_TOKEN,
  payload,
});

export const setDesktopNotificationsModalOpen = (payload: boolean) => ({
  type: NotificationActionTypes.SET_DESKTOP_NOTIFICATIONS_MODAL_OPEN,
  payload,
});

export const resetNotificationsState = () => ({
  type: NotificationActionTypes.RESET_STATE,
});
