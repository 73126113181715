import { Button, Modal, Text } from '@point-of-sale/components';
import { ENV } from '@point-of-sale/env';
import {
  setDesktopNotificationsModalOpen,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import styled from 'styled-components';
import NotificationItem from '../../../components/shared/Notifications/NotificationItem';
import { mockNotifications } from './data';

const Footer = styled.div`
  display: flex;
  gap: 24px;
`;

const Content = styled.div`
  padding: 12px;
`;

const NotificationsModal = () => {
  const isDesktopNotificationsModalOpen = useAppSelector(
    store => store.notifications.isDesktopNotificationsModalOpen
  );

  const dispatch = useAppDispatch();

  return (
    <Modal
      heading="33 unread Notifications"
      size="regular"
      isOpen={isDesktopNotificationsModalOpen}
      onClose={() => dispatch(setDesktopNotificationsModalOpen(false))}
      // footer={
      //   <Footer>
      //     <Button
      //       isFullWidth
      //       variant="outline"
      //       onClick={() => {
      //         dispatch(setIsReleasePromptModalOpen(false));
      //       }}
      //     >
      //       Ignore
      //     </Button>
      //     <Button
      //       isFullWidth
      //       onClick={() => {
      //         dispatch(setIsReleasePromptModalOpen(false));
      //         window.location.reload();
      //       }}
      //     >
      //       Update Now
      //     </Button>
      //   </Footer>
      // }
    >
      <Content>
        {mockNotifications.map(notification => (
          <NotificationItem key={notification.id} data={notification} />
        ))}
      </Content>
    </Modal>
  );
};

export default NotificationsModal;
