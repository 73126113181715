import { useEffect } from 'react';
import {
  getLineItems,
  OmsFilterNames,
  searchProducts,
  setPosPagination,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import Discovery from '../Discovery';
import { useLineItemsDiscoveryFilters } from '../../../hooks/useLineItemsDiscoveryFilters';
import SelectedFilters from './SelectedFilters';
import { useLineItemsDiscovery } from '../../../hooks/useLineItemsDiscovery';
import * as Styles from './styles';

interface IOrdersDiscoveryProps {
  usage: 'DEFAULT' | 'CANCELLED_ORDERS';
}

const OrdersDiscovery = ({ usage }: IOrdersDiscoveryProps) => {
  const searchQuery = useAppSelector(store => store.pointOfSale.search.query);
  const erpUser = useAppSelector(store => store.identity.erpUser);

  const selectedSalesChannelId = useAppSelector(store => store.identity.selectedSalesChannel?.id);

  const { discoveryFilters } = useLineItemsDiscoveryFilters({ usage });

  const { onChangeSearch, onChangeFilter, onRemoveFilter } = useLineItemsDiscovery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    // * ======== Default Filters ==============================================

    if (usage === 'CANCELLED_ORDERS') {
      onChangeFilter('status', [{ label: 'Cancelled', value: 'CANCELLED' }]);
    } else {
      onRemoveFilter('status');
    }

    if (usage === 'DEFAULT') {
      onChangeFilter('deliveryMode', [
        {
          label: 'Delivery Mode',
          value: 'HOME_DELIVERY,STORE_PICKUP',
        },
      ]);
    } else {
      onRemoveFilter('deliveryMode');
    }

    if (erpUser?.id) {
      onChangeFilter('salesStaffId', [
        {
          label: erpUser.name,
          value: erpUser.id,
        },
      ]);
    }

    if (selectedSalesChannelId) {
      onChangeFilter('salesChannelId', [
        {
          label: 'Sales Channel',
          value: selectedSalesChannelId,
        },
      ]);
    }

    // * =========================================================================

    dispatch(getLineItems());
  }, [usage, selectedSalesChannelId]);

  function onApply(filtersToBeApplied: Record<string, any>) {
    Object.entries(filtersToBeApplied).forEach(([field, options]) => {
      onChangeFilter(field as OmsFilterNames, options);
    });

    dispatch(getLineItems());
  }

  function onClearAll() {
    dispatch(
      setPosPagination({
        page: 0,
        shouldFetchMore: true,
      })
    );

    discoveryFilters.forEach(filter => {
      onRemoveFilter(filter.name as OmsFilterNames);
    });

    dispatch(searchProducts('EXPLORE_PRODUCTS'));
  }

  return (
    <Styles.Wrapper>
      <Discovery
        placeholder="Customer Name, Phone, Order number "
        searchQuery={searchQuery}
        filters={discoveryFilters}
        onPressEnter={onChangeSearch}
        onApply={onApply}
        onClearAll={onClearAll}
        renderSelectedFilters={(filtersToBeApplied, setFiltersToBeApplied, onApply) => (
          <SelectedFilters
            filtersToBeApplied={filtersToBeApplied}
            setFiltersToBeApplied={setFiltersToBeApplied}
            onClick={onApply}
          />
        )}
      />
    </Styles.Wrapper>
  );
};

export default OrdersDiscovery;
