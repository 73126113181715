import { createContext, useContext } from 'react';
import { BooleanActions } from '@point-of-sale/hooks';
import { LineItemDTOType } from '@point-of-sale/schemas';

interface OrderListItemContextProps {
  id: number;
  data: LineItemDTOType;

  isExpanded: boolean;
  expandedActions: BooleanActions;
}

export const OrderListItemContext = createContext<OrderListItemContextProps | undefined>(undefined);

export const useOrderListItem = (): OrderListItemContextProps => {
  const context = useContext(OrderListItemContext);
  if (context === undefined) {
    throw new Error('useOrderListItem must be used within OrderListItemContext.Provider');
  }
  return context;
};
