import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ModalContent = styled.div`
  padding: 20px;
  display: flex;
  align-items: stretch;
  gap: 20px;
`;

export const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ShippingPartnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;
  overflow: auto;
  max-height: 300px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
