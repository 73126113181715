import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, isSupported, getToken, onMessage, MessagePayload } from 'firebase/messaging';
// import { ENV } from '@point-of-sale/env';

import type { NotificationOptionsType } from './types';

let _app: FirebaseApp | null = null;
let _vapidKey: string | null = null;
let _isInitialized = false;

// const firebaseNotificationsApp = initializeApp({
//   apiKey: ENV.VITE_FIREBASE_NOTIFICATIONS_API_KEY,
//   authDomain: ENV.VITE_FIREBASE_NOTIFICATIONS_AUTH_DOMAIN,
//   projectId: ENV.VITE_FIREBASE_NOTIFICATIONS_PROJECT_ID,
//   storageBucket: ENV.VITE_FIREBASE_NOTIFICATIONS_STORAGE_BUCKET,
//   messagingSenderId: ENV.VITE_FIREBASE_NOTIFICATIONS_MESSAGING_SENDER_ID,
//   appId: ENV.VITE_FIREBASE_NOTIFICATIONS_APP_ID,
//   measurementId: ENV.VITE_FIREBASE_NOTIFICATIONS_MEASUREMENT_ID,
// });

export function initializeNotificationsApp(options: NotificationOptionsType) {
  if ('app' in options) {
    _app = options.app;
    _vapidKey = options.vapidKey;
  } else {
    if (_isInitialized) {
      return;
    }

    _app = initializeApp(options.config);
    _isInitialized = true;
    _vapidKey = options.config.vapidKey;
  }
}

function ensureInitialized() {
  if (!_app || !_vapidKey) {
    throw new Error('Firebase Messaging not initialized. Call initializeNotificationsApp first.');
  }
}

// * new methods, with module-level variables
export async function getFCMToken() {
  ensureInitialized();
  const messaging = getMessaging(_app!);
  const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
  return getToken(messaging, {
    vapidKey: _vapidKey!,
    serviceWorkerRegistration,
  });
}

export async function canGetFCMToken() {
  if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
    return isSupported();
  }
  return false;
}

export async function isFCMSupported() {
  if (typeof Notification !== 'undefined' && Notification.permission === 'default') {
    return isSupported();
  }
  return false;
}

export function onForegroundFCMMessage(callback: (payload: MessagePayload) => void) {
  ensureInitialized();
  const messaging = getMessaging(_app!);
  return onMessage(messaging, callback);
}

// // * Original Methods
// export async function getFirebaseCloudMessagingToken() {
//   const messaging = getMessaging(firebaseNotificationsApp);
//   const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
//   return getToken(messaging, {
//     vapidKey: ENV.VITE_FIREBASE_NOTIFICATIONS_VAPID_KEY,
//     serviceWorkerRegistration,
//   });
// }

// export async function canGetFirebaseCloudMessagingToken() {
//   if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
//     const hasSupport = await isSupported();
//     return hasSupport;
//   }
//   return false;
// }

// export async function isFirebaseCloudMessagingSupported() {
//   if (typeof Notification !== 'undefined' && Notification.permission === 'default') {
//     const hasSupport = await isSupported();
//     return hasSupport;
//   }
//   return false;
// }

// export function onForegroundFirebaseMessage(callback: (payload: MessagePayload) => void) {
//   const messaging = getMessaging(firebaseNotificationsApp);
//   return onMessage(messaging, payload => {
//     callback(payload);
//   });
// }
